import React from "react";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Collapse from "@mui/material/Collapse";
import OutlinedInput from "@mui/material/OutlinedInput";

import Backend from "../../classes/Backend";
import Commons from "./Commons";

//###########################################################
//###########################################################
//###########################################################

class Step1 extends React.Component {
  //###########################################################
  //###########################################################
  //###########################################################

  constructor(props) {
    super(props);

    this.parent = this.props.parent;
    this.appData = this.props.appData;
    this.commons = new Commons();

    this.stepIndex = 1;
    this.savedToDatabase = false;

    this.inputLabels = {
      yearOfBirth: "Year of birth",
      yearOfHairLoss: "When did your hair loss start?",
    };

    this.state = {
      display: "",

      //### Q-1
      gender: "Male",

      //### Q-2
      yearOfBirth: "",
      listYears: [],
      inputLabelYearOfBirth: this.inputLabels.yearOfBirth,
      inputPropYearOfBirth: (
        <OutlinedInput label={this.inputLabels.yearOfBirth} />
      ),

      //### Q-3
      isFallingNow: "No",

      //### Q-4
      yearOfHairLoss: "",
      listYearsOfHairLoss: [],
      inputLabelYearOfHairLoss: this.inputLabels.yearOfHairLoss,
      inputPropYearOfHairLoss: (
        <OutlinedInput label={this.inputLabels.yearOfHairLoss} />
      ),

      //### Q-5
      inYourFamily: "No",

      //### Q-6
      operatedBefore: "No",
      showTxtOperatedBefore: false,
      txtOperatedBefore: "",

      //### Q-7
      beingTreated: "No",
      showTxtBeingTreated: false,
      txtBeingTreated: "",
    };
  }

  //###########################################################
  //###########################################################
  //###########################################################

  componentDidMount() {
    //##########################
    //##########################
    //##########################

    let tempState = this.state;

    //##########################
    //##########################
    //##########################

    let year = this.appData.backend.getCurrentYear();
    let minAge = 18;
    let maxAge = 90;

    let temp = [];
    for (let i = year - minAge; i >= year - maxAge; i--) {
      temp.push(
        <MenuItem key={this.appData.getUUID()} value={i}>
          {i}
        </MenuItem>
      );
    }

    tempState.listYears = temp;

    //##########################
    //##########################
    //##########################

    temp = [];

    temp.push(
      <MenuItem key={this.appData.getUUID()} value={1}>
        1 year ago
      </MenuItem>
    );
    for (let i = 2; i <= 20; i++) {
      temp.push(
        <MenuItem key={this.appData.getUUID()} value={i + " years ago"}>
          {i} years ago
        </MenuItem>
      );
    }
    temp.push(
      <MenuItem key={this.appData.getUUID()} value={"More than 20 years ago"}>
        More than 20 years ago
      </MenuItem>
    );

    tempState.listYearsOfHairLoss = temp;

    //##########################
    //##########################
    //##########################

    this.setState(tempState);

    //##########################
    //##########################
    //##########################
  }

  //###########################################################
  //###########################################################
  //###########################################################

  setStateOne = (key, value, callBack) => {
    let tempState = this.state;
    tempState[key] = value;
    this.setState(tempState, () => {
      if (callBack !== undefined) {
        callBack();
      }
    });
  };

  setStateMultiple = (keyValuePairs, callBack) => {
    let tempState = this.state;

    keyValuePairs.map((keyValuePair) => {
      tempState[keyValuePair[0]] = keyValuePair[1];
    });

    this.setState(tempState, () => {
      if (callBack !== undefined) {
        callBack();
      }
    });
  };

  //###########################################################
  //###########################################################
  //###########################################################

  getGender = () => {
    return (
      <div
        style={this.commons.styleQuestionContainerRadio}
        className="font-family"
      >
        <FormLabel style={this.commons.styleQuestion}>Gender</FormLabel>
        <RadioGroup
          row
          defaultValue={this.state.gender}
          value={this.state.gender}
          onChange={(e) => {
            this.setStateOne("gender", e.target.value);
          }}
        >
          <FormControlLabel value="Male" control={<Radio />} label="Male" />
          <FormControlLabel value="Female" control={<Radio />} label="Female" />
        </RadioGroup>
      </div>
    );
  };

  getYearOfBirth = () => {
    return (
      <div style={this.commons.styleQuestionContainerSelect}>
        <FormLabel style={this.commons.styleQuestion}>
          {this.inputLabels.yearOfBirth}
        </FormLabel>
        <div className="divider-height-10" />
        <FormControl fullWidth>
          {/* <InputLabel>{this.state.inputLabelYearOfBirth}</InputLabel> */}
          <InputLabel />
          <Select
            className="regular-text"
            ref={(el) => (this.selectYearOfBirth = el)}
            // input={this.state.inputPropYearOfBirth}
            style={{ minWidth: "250px" }}
            value={this.state.yearOfBirth}
            onChange={(event) => {
              let tempState = this.state;
              tempState.yearOfBirth = event.target.value;
              this.setState(tempState);
            }}
            // onOpen={(event) => {
            //   let tempState = this.state;
            //   tempState.inputLabelYearOfBirth = "";
            //   tempState.inputPropYearOfBirth = null;
            //   this.setState(tempState);
            // }}
            // onClose={() => {
            //   let tempState = this.state;
            //   if (this.state.yearOfBirth === "") {
            //     tempState.inputLabelYearOfBirth = this.inputLabels.yearOfBirth;
            //     tempState.inputPropYearOfBirth = (
            //       <OutlinedInput label={this.inputLabels.yearOfBirth} />
            //     );
            //   } else {
            //     tempState.inputLabelYearOfBirth = "";
            //     tempState.inputPropYearOfBirth = null;
            //   }
            //   this.setState(tempState, () => {
            //     setTimeout(() => {
            //       document.activeElement.blur();
            //     }, 0);
            //   });
            // }}
          >
            <MenuItem key={this.appData.getUUID()} value={""}>
              {this.inputLabels.yearOfBirth}
            </MenuItem>
            {this.state.listYears}
          </Select>
        </FormControl>
      </div>
    );
  };

  getIsFallingNow = () => {
    return (
      <div style={this.commons.styleQuestionContainerRadio}>
        <FormLabel style={this.commons.styleQuestion}>
          Is your hair falling out now?
        </FormLabel>
        <RadioGroup
          row
          defaultValue={this.state.isFallingNow}
          value={this.state.isFallingNow}
          onChange={(e) => {
            this.setStateOne("isFallingNow", e.target.value);
          }}
        >
          <FormControlLabel value="No" control={<Radio />} label="No" />
          <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
        </RadioGroup>
      </div>
    );
  };

  getWhenDidStart = () => {
    return (
      <div style={this.commons.styleQuestionContainerSelect}>
        <FormLabel style={this.commons.styleQuestion}>
          {this.inputLabels.yearOfHairLoss}
        </FormLabel>
        <div className="divider-height-10" />
        <FormControl fullWidth>
          {/* <InputLabel>{this.state.inputLabelYearOfHairLoss}</InputLabel> */}
          <InputLabel />
          <Select
            className="regular-text"
            ref={(el) => (this.selectYearOfHairLoss = el)}
            // input={this.state.inputPropYearOfHairLoss}
            style={{ minWidth: "250px" }}
            value={this.state.yearOfHairLoss}
            onChange={(event) => {
              let tempState = this.state;
              tempState.yearOfHairLoss = event.target.value;
              this.setState(tempState);
            }}
            // onOpen={() => {
            //   let tempState = this.state;
            //   tempState.inputLabelYearOfHairLoss = "";
            //   tempState.inputPropYearOfHairLoss = null;
            //   this.setState(tempState);
            // }}
            // onClose={() => {
            //   let tempState = this.state;
            //   if (this.state.yearOfHairLoss === "") {
            //     tempState.inputLabelYearOfHairLoss = this.inputLabels.yearOfHairLoss;
            //     tempState.inputPropYearOfHairLoss = (
            //       <OutlinedInput label={this.inputLabels.yearOfHairLoss} />
            //     );
            //   } else {
            //     tempState.inputLabelYearOfHairLoss = "";
            //     tempState.inputPropYearOfHairLoss = null;
            //   }
            //   this.setState(tempState, () => {
            //     setTimeout(() => {
            //       document.activeElement.blur();
            //     }, 0);
            //   });
            // }}
          >
            <MenuItem key={this.appData.getUUID()} value={""}>
              {this.inputLabels.yearOfHairLoss}
            </MenuItem>
            {this.state.listYearsOfHairLoss}
          </Select>
        </FormControl>
      </div>
    );
  };

  getInYourFamily = () => {
    return (
      <div style={this.commons.styleQuestionContainerRadio}>
        <FormLabel style={this.commons.styleQuestion}>
          Do you have hair loss in your family?
        </FormLabel>
        <RadioGroup
          row
          defaultValue={this.state.inYourFamily}
          value={this.state.inYourFamily}
          onChange={(e) => {
            this.setStateOne("inYourFamily", e.target.value);
          }}
        >
          <FormControlLabel value="No" control={<Radio />} label="No" />
          <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
        </RadioGroup>
      </div>
    );
  };

  getTransplantOperationsBefore = () => {
    return (
      <div style={this.commons.styleQuestionContainerRadio}>
        <FormLabel style={this.commons.styleQuestion}>
          Have you had any hair transplant operation before?
        </FormLabel>
        <RadioGroup
          row
          defaultValue={this.state.operatedBefore}
          value={this.state.operatedBefore}
          onChange={(event) => {
            let tempState = this.state;

            //### somehow the value come as string and that mass up following collapse control
            let boolVal = false;
            if (event.target.value === "Yes") {
              boolVal = true;
            }
            tempState.operatedBefore = event.target.value;
            tempState.showTxtOperatedBefore = boolVal;
            this.setState(tempState);
          }}
        >
          <FormControlLabel value={"No"} control={<Radio />} label="No" />
          <FormControlLabel value={"Yes"} control={<Radio />} label="Yes" />
        </RadioGroup>

        <Collapse in={this.state.showTxtOperatedBefore}>
          <TextField
            // ref={(el) => (this.txtOperatedBefore = el)}
            label="Please Specify"
            multiline
            rows={4}
            style={{
              width: "100%",
              marginBottom: "10px",
              marginTop: "10px",
            }}
            InputProps={{
              className: "regular-text",
            }}
            value={this.state.txtOperatedBefore}
            onChange={(e) => {
              this.setStateOne("txtOperatedBefore", e.target.value);
            }}
          />
        </Collapse>
      </div>
    );
  };

  getAreYouBeingTreated = () => {
    return (
      <div style={this.commons.styleQuestionContainerRadio}>
        <FormLabel style={this.commons.styleQuestion}>
          Have you been treated or are you being treated for hair loss by any
          other medical practitioner?
        </FormLabel>
        <RadioGroup
          row
          defaultValue={this.state.beingTreated}
          value={this.state.beingTreated}
          onChange={(event) => {
            let tempState = this.state;

            //### somehow the value come as string and that mass up following collapse control
            let boolVal = false;
            if (event.target.value === "Yes") {
              boolVal = true;
            }

            tempState.beingTreated = event.target.value;
            tempState.showTxtBeingTreated = boolVal;
            this.setState(tempState);
          }}
        >
          <FormControlLabel value={"No"} control={<Radio />} label="No" />
          <FormControlLabel value={"Yes"} control={<Radio />} label="Yes" />
        </RadioGroup>

        <Collapse in={this.state.showTxtBeingTreated}>
          <TextField
            label="Please Specify"
            multiline
            rows={4}
            style={{
              width: "100%",
              marginBottom: "10px",
              marginTop: "10px",
            }}
            InputProps={{
              className: "regular-text",
            }}
            value={this.state.txtBeingTreated}
            onChange={(e) => {
              this.setStateOne("txtBeingTreated", e.target.value);
            }}
          />
        </Collapse>
      </div>
    );
  };

  //###########################################################
  //###########################################################
  //###########################################################

  show = () => {
    let tempState = this.state;
    tempState.display = "";
    this.setState(tempState);
  };

  hide = () => {
    let tempState = this.state;
    tempState.display = "none";
    this.setState(tempState);
  };

  //###########################################################
  //###########################################################
  //###########################################################

  getValues = () => {
    let res = [];

    //##########################
    res.push({ key: "Gender", value: this.state.gender });
    //##########################
    res.push({ key: "Year of birth", value: this.state.yearOfBirth });
    //##########################
    res.push({
      key: "Is your hair falling out now?",
      value: this.state.isFallingNow,
    });
    //##########################
    res.push({
      key: "When did your hair loss start?",
      value: this.state.yearOfHairLoss,
    });
    //##########################
    res.push({
      key: "Do you have hair loss in your family?",
      value: this.state.inYourFamily,
    });
    //##########################
    let txtOperatedBefore = "";
    if (
      this.state.operatedBefore === "Yes" &&
      this.state.txtOperatedBefore.trim() !== ""
    ) {
      txtOperatedBefore = ". " + this.state.txtOperatedBefore.trim();
    }
    res.push({
      key: "Have you had any hair transplant operation before?",
      value: this.state.operatedBefore + txtOperatedBefore,
    });
    //##########################
    let txtBeingTreated = "";
    if (
      this.state.beingTreated === "Yes" &&
      this.state.txtBeingTreated.trim() !== ""
    ) {
      txtBeingTreated = ". " + this.state.txtBeingTreated.trim();
    }
    res.push({
      key:
        "Have you been treated or are you being treated for hair loss by any other medical practitioner?",
      value: this.state.beingTreated + txtBeingTreated,
    });

    return res;
  };

  //###########################################################
  //###########################################################
  //###########################################################

  validate = (alwaysReturnTrue) => {
    let validationResult = { result: true, message: "" };

    let bullets = [];

    //############################

    if (this.state.yearOfBirth === "") {
      bullets.push(<li style={{ marginBottom: "5px" }}>Year of birth</li>);
    }

    //############################

    if (this.state.yearOfHairLoss === "") {
      bullets.push(
        <li style={{ marginBottom: "5px" }}>When did your hair loss start?</li>
      );
    }

    //############################
    if (
      this.state.operatedBefore === "Yes" &&
      this.state.txtOperatedBefore.trim() === ""
    ) {
      bullets.push(
        <li style={{ marginBottom: "5px" }}>
          Have you had any hair transplant operation before?
        </li>
      );
    }

    //############################
    if (
      this.state.beingTreated === "Yes" &&
      this.state.txtBeingTreated.trim() === ""
    ) {
      bullets.push(
        <li style={{ marginBottom: "5px" }}>
          Have you been treated or are you being treated for hair loss by any
          other medical practitioner?
        </li>
      );
    }

    //############################

    //### remove this part for production
    if (alwaysReturnTrue !== undefined && alwaysReturnTrue === true) {
      while (bullets.length > 0) {
        bullets.pop();
      }
    }

    //############################

    if (bullets.length > 0) {
      validationResult.result = false;
      validationResult.message = (
        <>
          <p>Please answer the following question(s):</p>
          <ul>{bullets}</ul>
        </>
      );
    }

    return validationResult;
  };

  //###########################################################
  //###########################################################
  //###########################################################

  getJSON = () => {
    let stateData = JSON.parse(JSON.stringify(this.state));

    delete stateData.display;
    delete stateData.listYears;
    delete stateData.inputLabelYearOfBirth;
    delete stateData.inputPropYearOfBirth;
    delete stateData.listYearsOfHairLoss;
    delete stateData.inputLabelYearOfHairLoss;
    delete stateData.inputPropYearOfHairLoss;

    let res = {};
    res.stateData = stateData;
    res.printData = this.getValues();

    //### handle double quotes in the json string
    res.stateData.txtOperatedBefore = this.appData.commonCalls.safeJsonString(
      res.stateData.txtOperatedBefore
    );
    res.stateData.txtBeingTreated = this.appData.commonCalls.safeJsonString(
      res.stateData.txtBeingTreated
    );

    for (let index = 0; index < res.printData.length; index++) {
      let element = res.printData[index];
      if (typeof element.value === "string") {
        element.value = this.appData.commonCalls.safeJsonString(element.value);
      }
    }

    return res;
  };

  setJSON = (obj, callBack) => {
    if (obj.stateData !== undefined) {
      obj = obj.stateData;
    }

    let tempState = this.state;

    let keys = Object.keys(obj);

    keys.forEach((key) => {
      //### handle double quotes in the json string
      if (typeof obj[key] === "string") {
        tempState[key] = this.appData.commonCalls.restoreJsonString(obj[key]);
      } else tempState[key] = obj[key];
    });

    this.setState(tempState, () => {
      if (callBack) {
        callBack();
      }
    });
  };

  saveToCookie = () => {};

  //###########################################################
  //###########################################################
  //###########################################################

  render() {
    return (
      <div
        className="width-with-padding-20"
        style={{
          display: this.state.display,
          padding: "10px 20px 20px 20px",
        }}
      >
        {/* ############ */}
        {/* title */}
        <div
          className="flex-column-center "
          style={this.commons.styleQuestionContainerSelect}
        >
          <span
            className="title-3"
            style={{ marginTop: "10px", marginBottom: "10px" }}
          >
            Hair Questions
          </span>
          <p
            className="no-padding no-margin regular-text"
            style={{ textAlign: "center" }}
          >
            In order to get a quick and direct consultation from our surgeon,
            please answer the questions below regarding your hair loss.
          </p>
        </div>
        {/* ############ */}
        {/* ############ */}
        {/* ############ */}
        <div className="divider-height-20" />
        {this.getGender()}
        {/* <hr className="line-20" /> */}
        <div className="divider-height-25" />
        {this.getYearOfBirth()}
        {/* <hr className="line-20" /> */}
        <div className="divider-height-25" />
        {this.getIsFallingNow()}
        {/* <hr className="line-20" /> */}
        <div className="divider-height-25" />
        {this.getWhenDidStart()}
        {/* <hr className="line-20" /> */}
        <div className="divider-height-25" />
        {this.getInYourFamily()}
        {/* <hr className="line-20" /> */}
        <div className="divider-height-25" />
        {this.getTransplantOperationsBefore()}
        {/* <hr className="line-20" /> */}
        <div className="divider-height-25" />
        {this.getAreYouBeingTreated()}
        {/* ############ */}
        {/* ############ */}
        {/* ############ */}
        {/* <div className="divider-height-25"></div> */}
        {/* <div
          className="button"
          onClick={() => {
            let values = this.getValues();
            console.clear();
            console.log(values);
          }}
        >
          Test Values
        </div> */}
        {/* ############ */}
        {/* ############ */}
        {/* ############ */}
      </div>
    );
  }
}

//###########################################################
//###########################################################
//###########################################################

export default Step1;

//###########################################################
//###########################################################
//###########################################################
