import axios from "axios";

//###########################################################
//###########################################################
//###########################################################

class VerificationManager {
  constructor(config) {
    this.serverURL = config.serverURL + "/Verification/";
    this.applicationConfigurationID = config.applicationConfigurationID;
    this.printConsole =
      config.printConsole !== undefined ? config.printConsole : false;
  }

  //###########################################################
  //###########################################################
  //###########################################################

  getAxiosConfig = (backEndCallName, data) => {
    return {
      method: "post",
      url: this.serverURL + backEndCallName,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
  };

  postProcessResponse = (response) => {
    //### I just need data value
    response = response.data;
    //### parse the Data
    response.Data = JSON.parse(response.Data);

    return response;
  };

  //###########################################################
  //###########################################################
  //###########################################################

  /*

    *** USAGE ***
  
    params = {
      userID: @userID,
      verificationID: @verificationID,
    };

    response.Result => RedirectURL
    
  */

  verifyEmailWithVerificationUUID = (params, callBack) => {
    var data = {
      ApplicationConfigurationID: this.applicationConfigurationID,
      QueryName: "",
      QueryParams: JSON.stringify(params),
    };

    let _this = this;

    axios(this.getAxiosConfig("VerifyEmailWithVerificationUUID", data))
      .then(function (response) {
        //### post process the response
        response = _this.postProcessResponse(response);

        callBack(response);
      })
      .catch(function (error) {
        callBack(error);
      });
  };

  //###########################################################
  //###########################################################
  //###########################################################

  /*

    *** USAGE ***
  
    input => userUUID and callBack function

    return => response.Data => Object
    
  */

  getUserVerificationStatus = (uuid, callBack) => {
    var data = {
      ApplicationConfigurationID: this.applicationConfigurationID,
      QueryName: "",
      QueryParams: JSON.stringify({
        userID: uuid,
      }),
    };

    let _this = this;

    axios(this.getAxiosConfig("GetUserVerificationStatus", data))
      .then(function (response) {
        //### post process the response
        response = _this.postProcessResponse(response);

        if (response.Data.length > 0) {
          response.Data = response.Data[0];
        }

        callBack(response);
      })
      .catch(function (error) {
        callBack(error);
      });
  };

  //###########################################################
  //###########################################################
  //###########################################################

  /*

    *** USAGE ***
  
    input => userUUID, phoneNumber and callBack function

    return => response.Data => Object
    
  */

  sendPhoneVerificationCode = (userID, phoneNumber, formattedPhoneNumber, phoneData, callBack) => {
    var data = {
      ApplicationConfigurationID: this.applicationConfigurationID,
      QueryName: "",
      QueryParams: JSON.stringify({
        userID: userID,
        phoneNumber: phoneNumber,
        formattedPhoneNumber: formattedPhoneNumber,
        phoneData: phoneData,
      }),
    };

    let _this = this;

    axios(this.getAxiosConfig("SendPhoneVerificationCode", data))
      .then(function (response) {
        //### post process the response
        response = _this.postProcessResponse(response);

        if (response.Data.length > 0) {
          response.Data = response.Data[0];
        }

        //#################################################
        //#################################################
        //#################################################
        
        //### Twilio's error documentation
        //### https://www.twilio.com/docs/api/errors
        if (response.Message.includes("~21211~")) {
          //### Code: 21211 = Invalid 'To' Phone Number
          response.Message = "Please check your phone number.";
        }
        
        //#################################################
        //#################################################
        //#################################################

        callBack(response);
      })
      .catch(function (error) {
        callBack(error);
      });
  };

  //###########################################################
  //###########################################################
  //###########################################################

  /*
  
      *** USAGE ***
    
      input => userUUID, verificationCode and callBack function
  
      return => response.Result as "Correct", "Wrong", "Error"
      
    */

  verifyEmailWithVerificationCode = (userID, verificationCode, callBack) => {
    var data = {
      ApplicationConfigurationID: this.applicationConfigurationID,
      QueryName: "",
      QueryParams: JSON.stringify({
        userID: userID,
        verificationCode: verificationCode,
      }),
    };

    let _this = this;

    axios(this.getAxiosConfig("VerifyEmailWithVerificationCode", data))
      .then(function (response) {
        //### post process the response
        response = _this.postProcessResponse(response);
        callBack(response);
      })
      .catch(function (error) {
        callBack(error);
      });
  };

  //###########################################################
  //###########################################################
  //###########################################################

  /*
  
      *** USAGE ***
    
      input => userUUID, verificationCode and callBack function
  
      return => response.Result as "Correct", "Wrong", "Error"
      
    */

  verifyPhoneWithVerificationCode = (userID, verificationCode, callBack) => {
    var data = {
      ApplicationConfigurationID: this.applicationConfigurationID,
      QueryName: "",
      QueryParams: JSON.stringify({
        userID: userID,
        verificationCode: verificationCode,
      }),
    };

    let _this = this;

    axios(this.getAxiosConfig("VerifyPhoneWithVerificationCode", data))
      .then(function (response) {
        //### post process the response
        response = _this.postProcessResponse(response);
        callBack(response);
      })
      .catch(function (error) {
        callBack(error);
      });
  };

  //###########################################################
  //###########################################################
  //###########################################################
}

//###########################################################
//###########################################################
//###########################################################

export default VerificationManager;

//###########################################################
//###########################################################
//###########################################################
