import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import uuid from "react-uuid";

import "./styles.css";
import "./responsive.css";

import CommonCalls from "./classes/CommonCalls";
import Backend from "./classes/Backend";
import MySessionManager from "./components/commons/MySessionManager";
import MyCookieManager from "./components/commons/MyCookieManager";
import InstantBackend from "./classes/build_in/InstantBackend";
import AccountManager from "./classes/build_in/AccountManager";
import VerificationManager from "./classes/build_in/VerificationManager";
import ImageProcessing from "./classes/build_in/ImageProcessing";

import MySnackbar from "./components/commons/MySnackbar";
import MyDialog from "./components/commons/MyDialog";
import MyContainer from "./components/commons/MyContainer";
import MyAlert from "./components/commons/MyAlert";
import MyConfirmation from "./components/commons/MyConfirmation";
import MyScreenConsole from "./components/commons/MyScreenConsole";

//### pages
import LandingPage from "./components/landing-page/TestPage";
import ListClinicCards from "./components/list-clinic-cards-bootstrap-studio/TestPage";
import MyAccount from "./components/my-account/TestPage";
//### static contents
import StaticListClinicCards from "./components/static-contents/list-of-turkish-hair-transplant-clinics/Generator";

import Clinic from "./components/clinic-bootstrap-studio/TestPage";
import Admin from "./components/admin/TestPage";
import Components from "./pages/Components";
import Header from "./components/header/TestPage";
import Authentication from "./components/authentication/TestPage";
import Footer from "./components/footer/TestPage";
import Quote from "./components/get-quote/TestPage";

import PageSignUp from "./components/authentication/pages/PageSignUp";
import PageConfirmationCode from "./components/authentication/pages/PageConfirmationCode";
import PageSignIn from "./components/authentication/pages/PageSignIn";
import PageForgotPassword from "./components/authentication/pages/PageForgotPassword";
import PageMessage from "./components/authentication/pages/PageMessage";

//### https://github.com/validatorjs/validator.js
import validator from "validator";

// import ProcessURL from "./components/authentication/ProcessURL";

// Selectors: https://github.com/duskload/react-device-detect/blob/master/docs/selectors.md
import {
  isMobileOnly,
  isTablet,
  isDesktop,
  browserName,
} from "react-device-detect";

//################################################
//################################################
//################################################

const getClientSize = () => {
  return [
    (window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth) - 0,
    (window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight) - 0,
  ];
};

function useCurrentSize() {
  let [clientSize, setClientSize] = useState(getClientSize());
  useEffect(() => {
    let timeoutId = null;
    const resizeListener = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        setClientSize(getClientSize());
      }, 150);
    };
    // set resize listener
    window.addEventListener("resize", resizeListener);
    return () => {
      window.removeEventListener("resize", resizeListener);
    };
  }, []);

  return clientSize;
}

//################################################
//################################################
//################################################

const setLocationDataAndSaveTheSession = async (sessionObject) => {
  try {
    const response = await fetch("https://geolocation-db.com/json/");
    const data = await response.json();
    sessionObject.geoLocation = data;

    backend
      .CreateLog(
        sessionObject.uuid,
        "new-session",
        JSON.stringify(sessionObject)
      )
      .then((data) => {})
      .catch((error) => {
        console.log(error);
      });
  } catch (e) {
    console.log(e);
  }
};
//################################################
//################################################
//################################################

const setCookieDataAndSaveTheSession = (sessionObject, clientUUID) => {
  // clientUUID = "63daa85-46e8-e6cc-3dc3-561060a68ea";
  backend
    .GetCookieDataByID(clientUUID)
    .then((data) => {
      sessionObject.cookieData = {};

      if (data.length > 0) {
        data.forEach((item) => {
          sessionObject.cookieData[item.Name] = JSON.parse(item.Data);
        });
      }
      //### set the session object
      sessionManager.setAsObject("sessionObject", sessionObject);
    })
    .catch((error) => {
      //### alert('There is an ERROR in GetCookieDataByID. Please check your console for more detail.');
      console.log(">>> ERROR in GetCookieDataByID >>> " + error);
    });
};

//################################################
//################################################
//################################################

const commonCalls = new CommonCalls();
const sessionManager = new MySessionManager();
const cookieManager = new MyCookieManager();
const backend = new Backend();

const myDialog = React.createRef();
const mySnackbar = React.createRef();
const myContainer = React.createRef();
const myAlert = React.createRef();
const myConfirmation = React.createRef();
const myScreenConsole = React.createRef();

//################################################
//################################################
//################################################

const getSessionObject = (printConsole, callBack) => {
  let res = sessionManager.getAsObject("sessionObject");

  if (res === null) {
    if (printConsole) console.log(">>> APP STARTED!!!");

    //### empty sesssion Object
    let res = { };
    res.device = {};
    res.cookieData = {};
    res.uuid = uuid();
    res.version = 2.0;
    res.groupIndex = commonCalls.getRandomInt(0, 50);
    res.formAction = "";
    res.printConsole = printConsole;
    //### device settings
    res.device.isMobile = isMobileOnly;
    res.device.isTablet = isTablet;
    res.device.isDesktop = isDesktop;
    res.device.browserName = browserName;
    res.device.screenSize = getClientSize();

    //### print the session object
    if (printConsole) {
      console.log(">>> New Session");
    }
    callBack(res);
  } else {
    //### print the session object
    if (printConsole) {
      console.log(">>> Existing Session");
    }
    callBack(res);
  }
};

const getCookieObject = (printConsole, callBack) => {
  let res = cookieManager.get("cookieObject");

  //### check if the cookie has all required fields
  //### otherwise, force to create a new cookie
  //### uuid
  if(res !== undefined && res.uuid === undefined){
    res = undefined;
  }

  if (res === undefined) {
    res = {};
    res.uuid = uuid();
    if (printConsole) {
      console.log(">>> New Cookie");
    }
    callBack(res);
  } else {
    if (printConsole) {
      console.log(">>> Existing Cookie");
    }
    callBack(res);
  }
};

//################################################
//################################################
//################################################

export default function App() {
  //************************************************************//
  //************************************************************//
  //************************************************************//
  //### read the .env file

  let isOnServer = process.env.REACT_APP_IS_ON_SERVER.toLowerCase() === "true";
  let version = parseFloat(process.env.REACT_APP_VERSION);
  let serverURL = process.env.REACT_APP_INSTANT_BACKEND_URL;
  let applicationConfigurationID =
    process.env.REACT_APP_INSTANT_BACKEND_APPLICATION_CONFIGURATION_ID;
  let printConsole =
    process.env.REACT_APP_INSTANT_BACKEND_PRINT_CONSOLE.toLowerCase() ===
    "true";

  //************************************************************//
  //************************************************************//
  //************************************************************//

  let instantBackend = new InstantBackend({
    serverURL: serverURL,
    applicationConfigurationID: applicationConfigurationID,
    printConsole: printConsole,
  });

  let accountManager = new AccountManager({
    serverURL: serverURL,
    applicationConfigurationID: applicationConfigurationID,
    printConsole: printConsole,
  });

  let verificationManager = new VerificationManager({
    serverURL: serverURL,
    applicationConfigurationID: applicationConfigurationID,
    printConsole: printConsole,
  });

  let imageProcessing = new ImageProcessing({
    serverURL: serverURL,
    applicationConfigurationID: applicationConfigurationID,
    printConsole: printConsole,
  });

  //************************************************************//
  //************************************************************//
  //************************************************************//

  let clientSize = useCurrentSize();

  //************************************************************//
  //************************************************************//
  //************************************************************//

  //### create or read session object
  getSessionObject(printConsole, (sessionObject) => {
    if (printConsole) console.log(sessionObject);

    //### set the session object
    sessionManager.setAsObject("sessionObject", sessionObject);

    //### get the geo location and save to logs
    setLocationDataAndSaveTheSession(sessionObject);

    //### create or read cookie object
    getCookieObject(printConsole, (cookieObject) => {
      if (printConsole) console.log(cookieObject);

      //### set the cookie
      cookieManager.set("cookieObject", cookieObject);

      //### fetch the cookie data from backend and put into the session object
      setCookieDataAndSaveTheSession(sessionObject, cookieObject.uuid);
    });
  });

  //************************************************************//
  //************************************************************//
  //************************************************************//

  let applicationData = {
    isOnServer: isOnServer,
    version: version,
    getClientSize: () => {
      let cs = getClientSize();
      return { width: cs[0], height: cs[1] };
    },
    sessionManager: sessionManager,
    cookieManager: cookieManager,
    backend: new Backend(instantBackend),
    commonCalls: commonCalls,
    instantBackend: instantBackend,
    accountManager: accountManager,
    verificationManager: verificationManager,
    imageProcessing: imageProcessing,
    navigate: useNavigate(),
    printConsole: printConsole,
    validator: validator, // https://github.com/validatorjs/validator.js
    getCurrentUser: () => {
      return accountManager.getUser();
    },
    backToHome: () => {
      window.location.href = "/";
    },
    getSessionObject: () => {
      return sessionManager.getAsObject("sessionObject");
    },
    setSessionObject: (obj) => {
      return sessionManager.setAsObject("sessionObject", obj);
    },
    getCookieObject: () => {
      return cookieManager.get("cookieObject");
    },
    getUUID: () => {
      return uuid();
    },
    container: () => {
      return myContainer.current;
    },
    dialog: () => {
      return myDialog.current;
    },
    alert: () => {
      return myAlert.current;
    },
    confirmation: () => {
      return myConfirmation.current;
    },
    screenConsole: () => {
      return myScreenConsole.current;
    },
    snackbar: () => {
      return mySnackbar.current;
    },
  };

  //************************************************************//
  //************************************************************//
  //************************************************************//

  return (
    //############################################
    //############################################
    //### asagidaki div'e ait style cok onemli !!!
    //### style.css icinde de body -> overflow: hidden; yapiyorsun
    //### ozetle, tum uygulama bu asagidaki div icinde render oluyor.
    //############################################
    //############################################
    <div
      style={{
        width: "100%",
        overflowY: "auto",
        overflowX: "hidden",
        height: clientSize[1],
      }}
    >
      {/**********************************************************/}
      {/**********************************************************/}
      {/**********************************************************/}
      {/* Performing forward references in the constructor of the following components */}
      {/* to set; applicationData.dialog AND applicationData.snackbar */}

      <MyDialog parent={this} ref={myDialog} appData={applicationData} />
      <MySnackbar parent={this} ref={mySnackbar} appData={applicationData} />
      <MyContainer parent={this} ref={myContainer} appData={applicationData} />
      <MyAlert parent={this} ref={myAlert} appData={applicationData} />
      <MyConfirmation
        parent={this}
        ref={myConfirmation}
        appData={applicationData}
      />
      <MyScreenConsole
        parent={this}
        ref={myScreenConsole}
        appData={applicationData}
      />

      {/**********************************************************/}
      {/**********************************************************/}
      {/**********************************************************/}
      {/* <BrowserRouter> */}
      <Routes>
        {/* ListClinicCards */}
        <Route
          path="/list-of-turkish-hair-transplant-clinics/index.html"
          element={<ListClinicCards appData={applicationData} />}
        />
        <Route
          path="/list-of-turkish-hair-transplant-clinics"
          element={<ListClinicCards appData={applicationData} />}
        />
        {/* MyAccount */}
        <Route
          path="/secure/my-account"
          element={<MyAccount appData={applicationData} />}
        />
        {/* StaticListClinicCards */}
        <Route
          path="/static-content/list-of-turkish-hair-transplant-clinics"
          element={<StaticListClinicCards appData={applicationData} />}
        />
        {/* <Route
          path="/process"
          element={
            <ProcessURL
              clientSize={{ width: clientSize[0], height: clientSize[1] }}
            />
          }
        /> */}
        {/* <Route
            path="/header"
            element={
              <Header
                clientSize={{ width: clientSize[0], height: clientSize[1] }}
              />
            }
          /> */}
        {/* <Route
            path="/authentication"
            element={
              <Authentication
                clientSize={{ width: clientSize[0], height: clientSize[1] }}
              />
            }
          /> */}
        {/* <Route
            path="/landing"
            element={
              <LandingPage
                clientSize={{ width: clientSize[0], height: clientSize[1] }}
              />
            }
          /> */}
        {/* <Route
            path="/footer"
            element={
              <Footer
                clientSize={{ width: clientSize[0], height: clientSize[1] }}
              />
            }
          /> */}
        {/* <Route
            path="/quote"
            element={
              <Quote
                clientSize={{ width: clientSize[0], height: clientSize[1] }}
              />
            }
          /> */}
        {/* <Route
            path="/test"
            element={
              <Components
                clientSize={{ width: clientSize[0], height: clientSize[1] }}
              />
            }
          /> */}
        {/* <Route
          path="/admin"
          element={
            <Admin
              appData={applicationData}
              clientSize={{ width: clientSize[0], height: clientSize[1] }}
            />
          }
        />
        <Route
          path="/signup"
          element={
            <PageSignUp
              clientSize={{ width: clientSize[0], height: clientSize[1] }}
            />
          }
        />
        <Route
          path="/signin"
          element={
            <PageSignIn
              clientSize={{ width: clientSize[0], height: clientSize[1] }}
            />
          }
        />
        <Route
          path="/forgotPassword"
          element={
            <PageForgotPassword
              clientSize={{ width: clientSize[0], height: clientSize[1] }}
            />
          }
        />
        <Route
          path="/authenticationMessages"
          element={
            <PageMessage
              clientSize={{ width: clientSize[0], height: clientSize[1] }}
            />
          }
        />
        <Route
          path="/confirmationCode"
          element={
            <PageConfirmationCode
              clientSize={{ width: clientSize[0], height: clientSize[1] }}
            />
          }
        /> */}
        {/* LandingPage */}
        <Route
          path="/index.html"
          element={<LandingPage appData={applicationData} action={undefined} />}
        />
        {/* LandingPage */}
        <Route
          path="/"
          element={<LandingPage appData={applicationData} action={undefined} />}
        />
      </Routes>
      {/* </BrowserRouter> */}
    </div>
  );
}
