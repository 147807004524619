import React from "react";
import CommonCalls from "./CommonCalls";
import ScreenConsole from "../../screen-console/ScreenConsole";
import AccountManager from "../../../classes/build_in/AccountManager";
import Backend from "../../../classes/Backend";
import MySnackbar from "../../commons/MySnackbar";
import ConfirmationCode from "../ConfirmationCode";

//###########################################################
//###########################################################
//###########################################################

class PageSignIn extends React.Component {
  //###########################################################
  //###########################################################
  //###########################################################

  constructor(props) {
    super(props);

    this.commonCalls = new CommonCalls();

    this.accountManager = new AccountManager({
      serverURL: process.env.REACT_APP_INSTANT_BACKEND_URL,
      applicationConfigurationID:
        process.env.REACT_APP_INSTANT_BACKEND_APPLICATION_CONFIGURATION_ID,
      printConsole:
        process.env.REACT_APP_INSTANT_BACKEND_PRINT_CONSOLE.toLowerCase() ===
        "true",
    });

    this.state = { content: null };
  }
  //###########################################################
  //###########################################################
  //###########################################################

  componentDidMount() {
    let tempState = this.state;
    tempState.content = (
      <ConfirmationCode parent={this} accountManager={this.accountManager} />
    );
    this.setState(tempState);
  }

  refresh = () => {
    this.screenConsole.show(() => {
      this.commonCalls.refresh();
    });
  };

  //###########################################################
  //###########################################################
  //###########################################################

  render() {
    return (
      <>
        <MySnackbar ref={(el) => (this.mySnackbar = el)} />
        <ScreenConsole ref={(el) => (this.screenConsole = el)} />
        <div
          className="flex-column-center"
          style={{ height: this.props.clientSize.height }}
        >
          {this.state.content}
        </div>
      </>
    );
  }
}

//###########################################################
//###########################################################
//###########################################################

export default PageSignIn;

//###########################################################
//###########################################################
//###########################################################
