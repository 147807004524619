import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step5 from "./Step5";
import GetQuote from "./GetQuote";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import MyDialog from "../commons/MyDialog";

//###########################################################
//###########################################################
//###########################################################

const theme = createTheme({
  typography: {
    allVariants: {
      fontFamily: "inherit"
    }
  }
});

class TestPage extends React.Component {
  //###########################################################
  //###########################################################
  //###########################################################

  constructor(props) {
    super(props);
    this.state = {};
  }

  //###########################################################
  //###########################################################
  //###########################################################

  componentDidMount() {}

  //###########################################################
  //###########################################################
  //###########################################################

  render() {
    return (
      <>
        <MyDialog ref={(el) => (this.myDialog = el)} />
        <div
          className="flex-column-start"
          style={{
            paddingTop: "30px",
            paddingLeft: "20px",
            paddingRight: "20px"
          }}
        >
          {/* <ThemeProvider theme={theme}> */}
          {/* <Link className="link" to="/">
            Home
          </Link> */}
          {/* <hr className="line-25" /> */}
          <div
            className="btn-primary"
            onClick={() => {
              this.myDialog.open({
                type: "container",
                content: <GetQuote container={this.myDialog} />,
                contentLayout: "top-center",
                hasCloseButton: false
              });
            }}
          >
            Open Quote Request Form
          </div>
          {/* <hr className="line-25" />
          <Step1 parent={this} />
          <hr className="line-25" />
          <Step2 parent={this} />
          <hr className="line-25" />
          <Step3 parent={this} />
          <hr className="line-25" />
          <Step4 parent={this} />
          <hr className="line-25" />
          <Step5 parent={this} /> */}
          {/* </ThemeProvider> */}
        </div>
      </>
    );
  }
}

//###########################################################
//###########################################################
//###########################################################

export default function (props) {
  const navigate = useNavigate();
  return <TestPage {...props} navigate={navigate} />;
}

//###########################################################
//###########################################################
//###########################################################
