import { maxWidth } from "@mui/system";
import React from "react";

// https://github.com/BosNaufal/react-file-base64
import FileBase64 from "../../libs/react-file-base64";

import Commons from "./Commons";

import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import LooksOneIcon from "@mui/icons-material/LooksOne";

import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

//###########################################################
//###########################################################
//###########################################################

class Step3 extends React.Component {
  //###########################################################
  //###########################################################
  //###########################################################

  constructor(props) {
    super(props);

    this.parent = this.props.parent;
    this.appData = this.props.appData;
    this.commons = new Commons();

    this.stepIndex = 3;
    this.savedToDatabase = false;

    this.defaultImages = {
      front: `${"../assets/images/get-quote/front-150.jpg"}`,
      side: `${"../assets/images/get-quote/side-150.jpg"}`,
      back: `${"../assets/images/get-quote/back-150.jpg"}`,
      top: `${"../assets/images/get-quote/top-150.jpg"}`,
    };

    this.progressMessages = {
      front: "",
      side: "",
      back: "",
      top: "",
    };

    this.state = {
      display: "",

      imgFront: null,
      imgFrontBase64: null,
      imgSide: null,
      imgSideBase64: null,
      imgBack: null,
      imgBackBase64: null,
      imgTop: null,
      imgTopBase64: null,
    };
  }

  //###########################################################
  //###########################################################
  //###########################################################

  componentDidMount() {}

  //###########################################################
  //###########################################################
  //###########################################################

  // getFiles(files) {
  //   alert("dd");
  //   console.log(files);
  //   this.setState({ files: files });
  // }

  //###########################################################
  //###########################################################
  //###########################################################

  show = () => {
    let tempState = this.state;
    tempState.display = "";
    this.setState(tempState);
  };

  hide = () => {
    let tempState = this.state;
    tempState.display = "none";
    this.setState(tempState);
  };

  //###########################################################
  //###########################################################
  //###########################################################

  getValues = () => {
    let res = [];
    res.push({
      key: "Picture from front",
      value:
        this.state.imgFront !== null
          ? this.state.imgFront.url
          : this.state.imgFrontBase64,
    });
    res.push({
      key: "Picture from side",
      value:
        this.state.imgSide !== null
          ? this.state.imgSide.url
          : this.state.imgSideBase64,
    });
    res.push({
      key: "Picture from back",
      value:
        this.state.imgBack !== null
          ? this.state.imgBack.url
          : this.state.imgBackBase64,
    });
    res.push({
      key: "Picture from top",
      value:
        this.state.imgTop !== null
          ? this.state.imgTop.url
          : this.state.imgSTopBase64,
    });
    return res;
  };

  //###########################################################
  //###########################################################
  //###########################################################

  validate = (alwaysReturnTrue) => {
    let validationResult = { result: true, message: "" };

    let bullets = [];

    //############################
    if (this.state.imgFrontBase64 === null && this.state.imgFront === null) {
      bullets.push(<li style={{ marginBottom: "5px" }}>Image from front</li>);
    }
    //############################
    if (this.state.imgSideBase64 === null && this.state.imgSide === null) {
      bullets.push(<li style={{ marginBottom: "5px" }}>Image from side</li>);
    }
    //############################
    if (this.state.imgBackBase64 === null && this.state.imgBack === null) {
      bullets.push(<li style={{ marginBottom: "5px" }}>Image from back</li>);
    }
    //############################
    if (this.state.imgTopBase64 === null && this.state.imgTop === null) {
      bullets.push(<li style={{ marginBottom: "5px" }}>Image from top</li>);
    }

    //############################
   
    //### remove this part for production
    if (alwaysReturnTrue !== undefined && alwaysReturnTrue === true) {
      while (bullets.length > 0) {
        bullets.pop();
      }
    }

    //############################

    if (bullets.length > 0) {
      validationResult.result = false;
      validationResult.message = (
        <>
          <p>Please provide the following image(s):</p>
          <ul>{bullets}</ul>
        </>
      );
    }

    return validationResult;
  };

  //###########################################################
  //###########################################################
  //###########################################################

  getImageUploadControl = (side) => {
    let imgSrc = "";
    let progressMessage = "";

    if (side === "Front") {
      if (this.state.imgFront !== null) {
        imgSrc = this.state.imgFront.url;
      } else if (this.state.imgFrontBase64 !== null) {
        imgSrc = this.state.imgFrontBase64;
      } else {
        imgSrc = this.defaultImages.front;
      }

      progressMessage = this.progressMessages.front;
    } else if (side === "Side") {
      if (this.state.imgSide !== null) {
        imgSrc = this.state.imgSide.url;
      } else if (this.state.imgSideBase64 !== null) {
        imgSrc = this.state.imgSideBase64;
      } else {
        imgSrc = this.defaultImages.side;
      }

      progressMessage = this.progressMessages.side;
    } else if (side === "Back") {
      if (this.state.imgBack !== null) {
        imgSrc = this.state.imgBack.url;
      } else if (this.state.imgBackBase64 !== null) {
        imgSrc = this.state.imgBackBase64;
      } else {
        imgSrc = this.defaultImages.back;
      }

      progressMessage = this.progressMessages.back;
    } else if (side === "Top") {
      if (this.state.imgTop !== null) {
        imgSrc = this.state.imgTop.url;
      } else if (this.state.imgTopBase64 !== null) {
        imgSrc = this.state.imgTopBase64;
      } else {
        imgSrc = this.defaultImages.top;
      }

      progressMessage = this.progressMessages.top;
    }

    return (
      <div
        className="flex-column-start"
        style={{
          border: "1px solid rgb(18, 87, 153)",
          borderRadius: "8px",
          padding: "15px",
          width: "100%",
        }}
      >
        <div style={{ fontWeight: "bold" }}>{side}</div>
        <div
          className="flex-row-center"
          style={{
            // border: "1px solid #CCC",
            marginTop: "15px",
            marginBottom: "15px",
            width: "100%",
            height: "250px",
          }}
        >
          {/* progress message */}
          <span style={{ display: progressMessage === "" ? "none" : "" }}>
            {progressMessage}
          </span>
          {/* image */}
          <img
            style={{
              display: progressMessage === "" ? "" : "none",
              maxWidth: "250px",
              maxHeight: "250px",
              borderRadius: "8px",
            }}
            src={imgSrc}
          />
        </div>

        <div
          className="flex-row-space-between"
          style={{
            borderTop: "1px solid #CCC",
            paddingTop: "15px",
            width: "100%",
          }}
        >
          <IconButton
            aria-label="delete"
            onClick={() => {
              this.handleImageDelete(side);
            }}
          >
            <DeleteIcon style={{ fontSize: "32px" }} />
          </IconButton>
          <Button
            variant="contained"
            color="success"
            startIcon={<FileUploadIcon />}
            style={{ paddingTop: "15px", paddingBottom: "15px" }}
            onClick={() => {
              if (side === "Front") {
                this.fileBase64Front.input.click();
              } else if (side === "Side") {
                this.fileBase64Side.input.click();
              } else if (side === "Back") {
                this.fileBase64Back.input.click();
              } else if (side === "Top") {
                this.fileBase64Top.input.click();
              }
            }}
          >
            Upload my {side} image
          </Button>
        </div>
      </div>
    );
  };
  //###########################################################
  //###########################################################
  //###########################################################

  getFileUploadControl = (side) => {
    return (
      <FileBase64
        ref={(el) => {
          if (side === "Front") this.fileBase64Front = el;
          else if (side === "Side") this.fileBase64Side = el;
          else if (side === "Back") this.fileBase64Back = el;
          else if (side === "Top") this.fileBase64Top = el;
        }}
        multiple={false}
        onDone={(file) => {

          //### step 1 - set the progress message
          let tempState = this.state;
          if (side === "Front") this.progressMessages.front = "Please wait...";
          else if (side === "Side")
            this.progressMessages.side = "Please wait...";
          else if (side === "Back")
            this.progressMessages.back = "Please wait...";
          else if (side === "Top") this.progressMessages.top = "Please wait...";
          this.setState(tempState, () => {
            //### step 2 - start the upload process
            this.appData.imageProcessing
              .uploadImage(file, {
                maxWidth: 1920,
                maxHeight: 1080,
                expiresInDay: 7,
                callBackResizedImage: (resizedBase64) => {
                  this.handleResizedImage(side, resizedBase64);
                },
              })
              .then((response) => {
                this.handleImageUpload(side, response);
              })
              .catch((error) => {
                this.appData.snackbar().alertError(error);
              });
          });
        }}
      />
    );
  };

  handleResizedImage = (side, resizedBase64) => {
    let tempState = this.state;

    if (side === "Front") tempState.imgFrontBase64 = resizedBase64;
    else if (side === "Side") tempState.imgSideBase64 = resizedBase64;
    else if (side === "Back") tempState.imgBackBase64 = resizedBase64;
    else if (side === "Top") tempState.imgTopBase64 = resizedBase64;

    this.setState(tempState);
  };

  handleImageDelete = (side) => {
    let tempState = this.state;
    if (side === "Front") {
      tempState.imgFront = null;
      tempState.imgFrontBase64 = null;
    } else if (side === "Side") {
      tempState.imgSide = null;
      tempState.imgSideBase64 = null;
    } else if (side === "Back") {
      tempState.imgBack = null;
      tempState.imgBackBase64 = null;
    } else if (side === "Top") {
      tempState.imgTop = null;
      tempState.imgTopBase64 = null;
    }
    this.setState(tempState, () => {
      this.parent.saveStepData(3);
    });
  };

  handleImageUpload = (side, response) => {
    if (this.appData.printConsole) {
      console.log(response);
    }
    let tempState = this.state;

    if (side === "Front") {
      this.progressMessages.front = "";
      tempState.imgFront = response;
    } else if (side === "Side") {
      this.progressMessages.side = "";
      tempState.imgSide = response;
    } else if (side === "Back") {
      this.progressMessages.back = "";
      tempState.imgBack = response;
    } else if (side === "Top") {
      this.progressMessages.top = "";
      tempState.imgTop = response;
    }

    this.setState(tempState, () => {
      this.parent.saveStepData(3);
    });
  };

  //###########################################################
  //###########################################################
  //###########################################################

  getJSON = () => {
    let stateData = JSON.parse(JSON.stringify(this.state));

    delete stateData.display;
    delete stateData.imgFrontBase64;
    delete stateData.imgSideBase64;
    delete stateData.imgBackBase64;
    delete stateData.imgTopBase64;

    let res = {};
    res.stateData = stateData;
    res.printData = {};

    return res;
  };

  setJSON = (obj, callBack) => {

    if(obj.stateData !== undefined){
      obj = obj.stateData;
    }

    let tempState = this.state;

    let keys = Object.keys(obj);

    keys.forEach((key) => {
      tempState[key] = obj[key];
    });

    this.setState(tempState, () => {
      if (callBack) {
        callBack();
      }
    });
  };
  //###########################################################
  //###########################################################
  //###########################################################

  render() {
    return (
      <div
        className="width-with-padding-20"
        style={{
          display: this.state.display,
          padding: "10px 20px 20px 20px",
        }}
      >
        {/* ############ */}
        {/* ############ */}
        {/* ############ */}
        {/* title */}
        <div
          className="flex-column-center"
          style={this.commons.styleQuestionContainerSelect}
        >
          <span
            className="title-3"
            style={{ marginTop: "10px", marginBottom: "10px" }}
          >
            Photos
          </span>
          <p
            className="no-padding no-margin regular-text"
            style={{ textAlign: "center" }}
          >
            We need four photos for your head area as explained in the below
            illustrations, you can upload it directly from your device or taking
            the photos using your smart phone camera. In addition, you can ask
            someone’s help to take the photos.
          </p>
        </div>
        {/* ############ */}
        {/* ############ */}
        {/* ############ */}

        <Grid
          container
          rowSpacing={{ xs: 3 }}
          columnSpacing={{ xs: 3 }}
          style={{ marginTop: "0px" }}
        >
          <Grid item sm={12} md={6} className="flex-row-center">
            {this.getImageUploadControl("Front")}
          </Grid>
          <Grid item sm={12} md={6} className="flex-row-center">
            {this.getImageUploadControl("Side")}
          </Grid>
          <Grid item sm={12} md={6} className="flex-row-center">
            {this.getImageUploadControl("Back")}
          </Grid>
          <Grid item sm={12} md={6} className="flex-row-center">
            {this.getImageUploadControl("Top")}
          </Grid>
        </Grid>

        {/* ############################################## */}

        {this.getFileUploadControl("Front")}
        {this.getFileUploadControl("Side")}
        {this.getFileUploadControl("Back")}
        {this.getFileUploadControl("Top")}

        {/* ############################################## */}
      </div>
    );
  }
}

//###########################################################
//###########################################################
//###########################################################

export default Step3;

//###########################################################
//###########################################################
//###########################################################
