import React from "react";
import "../bootstrap-studio/bootstrap/bootstrap.scoped.css";
import "../bootstrap-studio/styles/bs-landing.css";

import { Link, useNavigate } from "react-router-dom";

import Backend from "../../classes/Backend";
import MySessionManager from "../commons/MySessionManager";

import HomeRepairServiceIcon from "@mui/icons-material/HomeRepairService";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import LockIcon from "@mui/icons-material/Lock";
import Filter1Icon from "@mui/icons-material/Filter1";
import Filter2Icon from "@mui/icons-material/Filter2";
import Filter3Icon from "@mui/icons-material/Filter3";

import LandingTopRight from "./LandingTopRight";
import { ImageBackground } from "react-native-web";

import WarningIcon from "@mui/icons-material/Warning";
import HelpIcon from "@mui/icons-material/Help";
import CheckIcon from "@mui/icons-material/Check";

import InfoIcon from "@mui/icons-material/Info";
import PaidIcon from "@mui/icons-material/Paid";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";

import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import MessageIcon from "@mui/icons-material/Message";
import SecurityIcon from "@mui/icons-material/Security";
import { FaBalanceScaleLeft } from "react-icons/fa";
import { MdOutlineAttachMoney } from "react-icons/md";
import { IoInformationOutline } from "react-icons/io5";

import LooksOneIcon from "@mui/icons-material/LooksOne";
import LooksTwoIcon from "@mui/icons-material/LooksTwo";
import Looks3Icon from "@mui/icons-material/Looks3";
import Looks4Icon from "@mui/icons-material/Looks4";
import Looks5Icon from "@mui/icons-material/Looks5";
import Looks6Icon from "@mui/icons-material/Looks6";

import FactCheckIcon from "@mui/icons-material/FactCheck";
import LocalLibraryIcon from "@mui/icons-material/LocalLibrary";

//###########################################################
//###########################################################
//###########################################################

class LandingPage extends React.Component {
  //###########################################################
  //###########################################################
  //###########################################################

  constructor(props) {
    super(props);

    this.appData = this.props.appData;

    this.backend = new Backend();
    this.sessionManager = new MySessionManager();
    this.sessionObject = JSON.parse(this.sessionManager.get("sessionObject"));
    this.state = {};
  }
  //###########################################################
  //###########################################################
  //###########################################################

  componentDidMount() {}

  //###########################################################
  //###########################################################
  //###########################################################

  onButtonClicked = () => {
    //### create a log
    this.backend.CreateLog(
      this.sessionObject.uuid,
      "start-to-explore-clicked",
      "",
      (response) => {}
    );

    this.props.navigate("/list-of-turkish-hair-transplant-clinics");
  };

  onButtonClickedGetQuote = () => {
    this.appData.header.quoteButtonClicked();
  };

  //###########################################################
  //###########################################################
  //###########################################################

  render() {
    return (
      <div style={{ marginLeft: "auto", marginRight: "auto" }}>
        <div className="container bs-landing-max-width bs-landing-section-padding">
          <div className="row gy-5 row-cols-1 row-cols-md-2">
            <div className="col-md-7 d-flex flex-column align-items-start align-self-center">
              <span className="fs-1 fw-bold">
                Get <span className="bs-landing-blue-text">Multiple</span>{" "}
                Secure Turkish Hair Clinic{" "}
                <span className="bs-landing-blue-text">Quotes</span> with
                Reviews
              </span>
              <div className="bs-landing-section-inner-title-spacing" />
              <span className="fs-4">
                Your Search Hub for Over 50 Turkish Hair Clinics
              </span>
              <div className="bs-landing-section-inner-title-spacing" />
              <button
                className="btn btn-primary btn-lg fw-bold"
                type="button"
                style={{ padding: "20px" }}
                onClick={this.onButtonClicked}
              >
                EXPLORER THE TURKISH HAIR CLINICS NOW
              </button>
            </div>
            <div className="col-md-5 text-center align-self-center">
              <img
                src={`${"../assets/images/landing/before-after.jpg"}`}
                className="bs-landing-image"
              />
              <img
                className="d-none bs-landing-image"
                src="assets/img/before-after.jpg"
              />
            </div>
          </div>
        </div>
        <div
          className="bs-landing-section-padding"
          style={{ paddingTop: "0px" }}
        >
          <div className="container bs-landing-max-width">
            <div className="row">
              <div className="col d-flex flex-column">
                <span
                  className="fs-2 fw-bold text-center"
                  style={{ marginBottom: "10px" }}
                >
                  Welcome to Turkish Hair Clinics
                </span>
                <span className="fs-4 text-center">
                  We help you on the journey to finding the right hair clinic
                  for your hair transplantation.
                </span>
              </div>
            </div>
          </div>
          <div className="bs-landing-section-title-spacing" />
          <div className="container" style={{ maxWidth: "900px" }}>
            <div className="row gx-3 gy-3 row-cols-2 row-cols-md-4">
              <div className="col">
                <div
                  className="d-flex flex-column align-items-center bs-landing-item-box"
                  style={{
                    padding: "20px",
                    paddingTop: "30px",
                    border: "2px solid #484C7A",
                  }}
                >
                  <span
                    className="fw-bold d-flex flex-row justify-content-center align-items-center"
                    style={{
                      background: "#484C7A",
                      color: "rgb(255,255,255)",
                      borderRadius: "50%",
                      lineHeight: "1.0 !important",
                      marginBottom: "20px",
                      width: "60px",
                      height: "60px",
                      paddingBottom: "5px",
                      paddingRight: "2px",
                    }}
                  >
                    get
                  </span>
                  <span className="fs-5 text-center">
                    Hair
                    <br />
                    Transplant
                  </span>
                </div>
              </div>
              <div className="col">
                <div
                  className="d-flex flex-column align-items-center align-items-xl-center bs-landing-item-box"
                  style={{
                    padding: "20px",
                    paddingTop: "30px",
                    border: "2px solid #484C7A",
                  }}
                >
                  <span
                    className="fw-bold d-flex flex-row justify-content-center align-items-center"
                    style={{
                      background: "#484C7A",
                      color: "rgb(255,255,255)",
                      borderRadius: "50%",
                      lineHeight: "1.0 !important",
                      marginBottom: "20px",
                      width: "60px",
                      height: "60px",
                    }}
                  >
                    with
                  </span>
                  <span className="fs-5 text-center">
                    Less
                    <br />
                    Effort
                  </span>
                </div>
              </div>
              <div className="col">
                <div
                  className="d-flex flex-column align-items-center bs-landing-item-box"
                  style={{
                    padding: "20px",
                    paddingTop: "30px",
                    border: "2px solid #484C7A",
                  }}
                >
                  <span
                    className="fw-bold d-flex flex-row justify-content-center align-items-center"
                    style={{
                      background: "#484C7A",
                      color: "rgb(255,255,255)",
                      borderRadius: "50%",
                      lineHeight: "1.0 !important",
                      marginBottom: "20px",
                      width: "60px",
                      height: "60px",
                    }}
                  >
                    have
                  </span>
                  <span className="fs-5 text-center">
                    Multiple
                    <br />
                    Quotes
                  </span>
                </div>
              </div>
              <div className="col">
                <div
                  className="d-flex flex-column align-items-center align-items-xl-center bs-landing-item-box"
                  style={{
                    padding: "20px",
                    paddingTop: "30px",
                    border: "2px solid #484C7A",
                  }}
                >
                  <span
                    className="fw-bold d-flex flex-row justify-content-center align-items-center"
                    style={{
                      background: "#484C7A",
                      color: "rgb(255,255,255)",
                      borderRadius: "50%",
                      lineHeight: "1.0 !important",
                      marginBottom: "20px",
                      width: "60px",
                      height: "60px",
                    }}
                  >
                    in a
                  </span>
                  <span className="fs-5 text-center">
                    Secure
                    <br />
                    Way
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="bs-landing-section-padding"
          style={{ background: "#EDEDED" }}
        >
          <div className="container bs-landing-max-width">
            <div className="row">
              <div className="col d-flex justify-content-center">
                <span
                  className="fs-2 fw-bold text-center border rounded shadow"
                  style={{ padding: "10px 20px", color: "#484C7A" }}
                >
                  Why TurkishHairClinics.com?
                </span>
              </div>
            </div>
            <div className="bs-landing-section-title-spacing" />
            <div className="row">
              <div className="col d-flex flex-column">
                <p className="fs-5">
                  We are the leading online platform connecting patients with
                  hair transplant clinics in Turkey. With so many clinics to
                  choose from, it can be difficult to know which one is right
                  for you. That's where we come in.
                </p>
                <p className="fs-5">
                  We list all the essential information about each clinic,
                  including the most recent Google reviews, before and after
                  images, social media links, and more. This way, you can
                  compare clinics and make an informed decision.
                </p>
                <p className="fs-5">
                  When you fill out our Quote Request Form, you can receive
                  multiple quotes without sharing your personal information.
                  This way, you can compare prices and find the best deal.
                </p>
                <p className="fs-5">
                  Thank you for choosing TurkishHairClinics.com. We look forward
                  to helping you find the perfect hair transplant clinic for
                  your needs!
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="bs-landing-section-padding">
          <div className="container">
            <div className="row">
              <div className="col d-flex justify-content-center">
                <span
                  className="fs-2 fw-bold text-center border rounded shadow"
                  style={{ padding: "10px 20px", color: "#484C7A" }}
                >
                  Without TurkishHairClinics.com
                </span>
              </div>
            </div>
          </div>
          <div className="bs-landing-section-title-spacing" />
          <div className="container bs-landing-max-width">
            <div className="row gx-5 gy-5 row-cols-1 row-cols-md-2">
              <div
                className="col-md-7 align-self-center"
                style={{ textAlign: "center" }}
              >
                <div style={{ display: "inline-block", textAlign: "left" }}>
                  <div
                    className="d-flex justify-content-start align-items-start"
                    style={{ marginBottom: "10px" }}
                  >
                    <HelpIcon
                      style={{
                        fontSize: "2.0rem",
                        color: "#484C7A",
                        marginRight: "10px",
                        marginTop: "0px",
                      }}
                    />
                    <i
                      className="fa fa-question-circle fs-5 d-none"
                      style={{ marginRight: "20px", marginTop: "5px" }}
                    />
                    <span className="fs-5" style={{ textAlign: "left" }}>
                      Search for multiple hair clinics one by one
                    </span>
                  </div>
                  <div
                    className="d-flex justify-content-start align-items-start"
                    style={{ marginBottom: "10px" }}
                  >
                    <HelpIcon
                      style={{
                        fontSize: "2.0rem",
                        color: "#484C7A",
                        marginRight: "10px",
                        marginTop: "0px",
                      }}
                    />
                    <i
                      className="fa fa-question-circle fs-5 d-none"
                      style={{ marginRight: "20px", marginTop: "5px" }}
                    />
                    <span className="fs-5" style={{ textAlign: "left" }}>
                      Give your contact information to each one
                    </span>
                  </div>
                  <div
                    className="d-flex justify-content-start align-items-start"
                    style={{ marginBottom: "10px" }}
                  >
                    <HelpIcon
                      style={{
                        fontSize: "2.0rem",
                        color: "#484C7A",
                        marginRight: "10px",
                        marginTop: "0px",
                      }}
                    />
                    <i
                      className="fa fa-question-circle fs-5 d-none"
                      style={{ marginRight: "20px", marginTop: "5px" }}
                    />
                    <span className="fs-5" style={{ textAlign: "left" }}>
                      Send your photos to each clinic
                    </span>
                  </div>
                  <div
                    className="d-flex justify-content-start align-items-start"
                    style={{ marginBottom: "10px" }}
                  >
                    <HelpIcon
                      style={{
                        fontSize: "2.0rem",
                        color: "#484C7A",
                        marginRight: "10px",
                        marginTop: "0px",
                      }}
                    />
                    <i
                      className="fa fa-question-circle fs-5 d-none"
                      style={{ marginRight: "20px", marginTop: "5px" }}
                    />
                    <span className="fs-5" style={{ textAlign: "left" }}>
                      Wait for a response from each one
                    </span>
                  </div>
                  <div
                    className="d-flex justify-content-start align-items-start"
                    style={{ marginBottom: "10px" }}
                  >
                    <HelpIcon
                      style={{
                        fontSize: "2.0rem",
                        color: "#484C7A",
                        marginRight: "10px",
                        marginTop: "0px",
                      }}
                    />
                    <i
                      className="fa fa-question-circle fs-5 d-none"
                      style={{ marginRight: "20px", marginTop: "5px" }}
                    />
                    <span className="fs-5" style={{ textAlign: "left" }}>
                      Try to compare quotes with different formats
                    </span>
                  </div>
                  <div
                    className="d-flex justify-content-start align-items-start"
                    style={{ marginBottom: "10px" }}
                  >
                    <HelpIcon
                      style={{
                        fontSize: "2.0rem",
                        color: "#484C7A",
                        marginRight: "10px",
                        marginTop: "0px",
                      }}
                    />
                    <i
                      className="fa fa-question-circle fs-5 d-none"
                      style={{ marginRight: "20px", marginTop: "5px" }}
                    />
                    <span className="fs-5" style={{ textAlign: "left" }}>
                      Look up reviews
                    </span>
                  </div>
                  <div
                    className="d-flex justify-content-start align-items-start"
                    style={{ marginBottom: "10px" }}
                  >
                    <HelpIcon
                      style={{
                        fontSize: "2.0rem",
                        color: "#484C7A",
                        marginRight: "10px",
                        marginTop: "0px",
                      }}
                    />
                    <i
                      className="fa fa-question-circle fs-5 d-none"
                      style={{ marginRight: "20px", marginTop: "5px" }}
                    />
                    <span className="fs-5" style={{ textAlign: "left" }}>
                      Try to decide
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-md-5 d-flex justify-content-center align-self-center">
                <img
                  className="d-none d-md-block d-none d-md-none bs-landing-image"
                  src="assets/img/confused-man.png"
                />
                <img
                  className="bs-landing-image d-none d-md-block"
                  src={`${"../assets/images/landing/confused-man.png"}`}
                />
                <img
                  className="d-block d-md-none d-none bs-landing-image"
                  src="assets/img/confused-man-short.png"
                />
                <img
                  className="bs-landing-image d-block d-md-none"
                  src={`${"../assets/images/landing/confused-man-short.png"}`}
                />
              </div>
            </div>
          </div>
        </div>
        <div
          className="bs-landing-section-padding"
          style={{ background: "#EDEDED" }}
        >
          <div className="container">
            <div className="row">
              <div className="col d-flex justify-content-center">
                <span
                  className="fs-2 fw-bold text-center border rounded shadow"
                  style={{ padding: "10px 20px", color: "#484C7A" }}
                >
                  With TurkishHairClinics.com
                </span>
              </div>
            </div>
          </div>
          <div className="bs-landing-section-title-spacing" />
          <div className="container bs-landing-max-width">
            <div className="row gy-5">
              <div className="col-12 col-lg-5 d-flex justify-content-center align-self-center order-last order-lg-first">
                <img
                  className="bs-landing-image d-none d-lg-block"
                  src={`${"../assets/images/landing/happy-man.png"}`}
                />
                <img
                  className="d-none d-lg-block d-none d-lg-none bs-landing-image"
                  src="assets/img/happy-man.png"
                />
                <img
                  className="bs-landing-image d-block d-lg-none "
                  src={`${"../assets/images/landing/happy-man-short.png"}`}
                />
                <img
                  className="d-block d-lg-none d-none bs-landing-image"
                  src="assets/img/happy-man-short.png"
                />
              </div>
              <div className="col-12 col-lg-7 align-self-center">
                <div className="row gx-3 gy-3">
                  <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                    <div className="d-flex flex-column align-items-center bs-landing-item-box">
                      <div
                        style={{
                          background: "aquamarine",
                          borderRadius: "50px",
                          width: "60px",
                          height: "60px",
                          position: "relative",
                          border: "4px solid #484C7A",
                        }}
                      >
                        {" "}
                        <IoInformationOutline
                          style={{
                            fontSize: "40px",
                            color: "#484C7A",
                            position: "absolute",
                            left: "6px",
                            top: "6px",
                          }}
                        />{" "}
                      </div>
                      <div className="divider-height-20" />
                      <span
                        className="fw-bold d-flex flex-row justify-content-center align-items-center d-none"
                        style={{
                          background: "aquamarine",
                          color: "#484C7A",
                          borderRadius: "50%",
                          lineHeight: "1.0 !important",
                          width: "60px",
                          height: "60px",
                          marginBottom: "30px",
                          border: "2px solid #484C7A",
                        }}
                      >
                        hb
                      </span>
                      <span className="fs-5 text-center">
                        Add your details on Turkish Hair Clinics HERE
                      </span>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                    <div className="d-flex flex-column align-items-center bs-landing-item-box">
                      <div
                        style={{
                          background: "aquamarine",
                          borderRadius: "50px",
                          width: "60px",
                          height: "60px",
                          position: "relative",
                          border: "4px solid #484C7A",
                        }}
                      >
                        {" "}
                        <MdOutlineAttachMoney
                          style={{
                            fontSize: "40px",
                            color: "#484C7A",
                            position: "absolute",
                            left: "6px",
                            top: "6px",
                          }}
                        />{" "}
                      </div>
                      <div className="divider-height-20" />
                      <span
                        className="fw-bold d-flex flex-row justify-content-center align-items-center d-none"
                        style={{
                          background: "aquamarine",
                          color: "#484C7A",
                          borderRadius: "50%",
                          lineHeight: "1.0 !important",
                          width: "60px",
                          height: "60px",
                          marginBottom: "30px",
                          border: "2px solid #484C7A",
                        }}
                      >
                        hb
                      </span>
                      <span className="fs-5 text-center">
                        We request a FREE quote for you from over 100 clinics
                      </span>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12">
                    <div className="d-flex flex-column align-items-center bs-landing-item-box">
                      <div
                        style={{
                          background: "aquamarine",
                          borderRadius: "50px",
                          width: "60px",
                          height: "60px",
                          position: "relative",
                          border: "4px solid #484C7A",
                        }}
                      >
                        {" "}
                        <FaBalanceScaleLeft
                          style={{
                            fontSize: "40px",
                            color: "#484C7A",
                            position: "absolute",
                            left: "6px",
                            top: "6px",
                          }}
                        />{" "}
                      </div>
                      <div className="divider-height-20" />
                      {/* <div style={{ background: "#252F4A", borderRadius: "50px", width: "60px", height: "60px", position: "relative" }}>
                          <FaBalanceScaleLeft style={{ fontSize: "40px", color: "aquamarine", position: "absolute", left: "10px", top: "8px" }} />
                        </div> */}
                      <span
                        className="fw-bold d-flex flex-row justify-content-center align-items-center d-none"
                        style={{
                          background: "aquamarine",
                          color: "#484C7A",
                          borderRadius: "50%",
                          lineHeight: "1.0 !important",
                          width: "60px",
                          height: "60px",
                          marginBottom: "30px",
                          border: "2px solid #484C7A",
                        }}
                      >
                        hb
                      </span>
                      <span className="fs-5 text-center">
                        Read and compare your quotes from multiple Turkish Hair
                        clinics
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container d-flex flex-column align-items-center bs-landing-max-width">
            <div className="bs-landing-section-content-spacing" />
            <div className="row">
              <div className="col text-center">
                <span className="fs-5">
                  Every quote includes the services provided such as: Technique
                  (FUE, DHI, Sapphire FUE etc.), number of hair grafts,
                  medications, aftercare etc.
                </span>
              </div>
            </div>
          </div>
          <div className="bs-landing-section-content-spacing" />
          <div className="container bs-landing-max-width">
            <div className="row">
              <div className="col">
                <span className="fs-3 fw-bold">
                  Turkish Hair Clinics Listings Include:
                </span>
              </div>
            </div>
            <div className="bs-landing-section-inner-title-spacing" />
            <div className="row gx-3 gy-3">
              <div className="col">
                <div style={{ display: "inline-block", textAlign: "left" }}>
                  <div
                    className="d-flex justify-content-start align-items-start"
                    style={{ marginBottom: "10px" }}
                  >
                    <CheckIcon
                      style={{
                        fontSize: "2.0rem",
                        color: "#484C7A",
                        marginRight: "10px",
                        marginTop: "0px",
                      }}
                    />
                    <i
                      className="fas fa-check fs-5 d-none"
                      style={{ marginRight: "20px", marginTop: "5px" }}
                    />
                    <span className="fs-5" style={{ textAlign: "left" }}>
                      Each Clinic's Key Features
                    </span>
                  </div>
                  <div
                    className="d-flex justify-content-start align-items-start"
                    style={{ marginBottom: "10px" }}
                  >
                    <CheckIcon
                      style={{
                        fontSize: "2.0rem",
                        color: "#484C7A",
                        marginRight: "10px",
                        marginTop: "0px",
                      }}
                    />
                    <i
                      className="fas fa-check fs-5 d-none"
                      style={{ marginRight: "20px", marginTop: "5px" }}
                    />
                    <span className="fs-5" style={{ textAlign: "left" }}>
                      Google Reviews
                    </span>
                  </div>
                  <div
                    className="d-flex justify-content-start align-items-start"
                    style={{ marginBottom: "10px" }}
                  >
                    <CheckIcon
                      style={{
                        fontSize: "2.0rem",
                        color: "#484C7A",
                        marginRight: "10px",
                        marginTop: "0px",
                      }}
                    />
                    <i
                      className="fas fa-check fs-5 d-none"
                      style={{ marginRight: "20px", marginTop: "5px" }}
                    />
                    <span className="fs-5" style={{ textAlign: "left" }}>
                      Before and After Images
                    </span>
                  </div>
                  <div
                    className="d-flex justify-content-start align-items-start"
                    style={{ marginBottom: "10px" }}
                  >
                    <CheckIcon
                      style={{
                        fontSize: "2.0rem",
                        color: "#484C7A",
                        marginRight: "10px",
                        marginTop: "0px",
                      }}
                    />
                    <i
                      className="fas fa-check fs-5 d-none"
                      style={{ marginRight: "20px", marginTop: "5px" }}
                    />
                    <span className="fs-5" style={{ textAlign: "left" }}>
                      Social Media Links
                    </span>
                  </div>
                  <div className="d-flex justify-content-start align-items-start">
                    <CheckIcon
                      style={{
                        fontSize: "2.0rem",
                        color: "#484C7A",
                        marginRight: "10px",
                        marginTop: "0px",
                      }}
                    />
                    <i
                      className="fas fa-check fs-5 d-none"
                      style={{ marginRight: "20px", marginTop: "5px" }}
                    />
                    <span className="fs-5" style={{ textAlign: "left" }}>
                      and more...
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bs-landing-section-content-spacing" />
          <div className="container bs-landing-max-width">
            <div className="row">
              <div className="col text-center">
                <span className="fs-3 fw-bold">Selection Process Easier!</span>
              </div>
            </div>
            <div className="bs-landing-section-inner-title-spacing" />
            <div
              className="row gx-3 gy-3"
              style={{
                maxWidth: "1250px",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <div className="col-12 col-md-6 col-lg-4">
                <div className="d-flex flex-column align-items-center bs-landing-item-box">
                  <FavoriteBorderIcon
                    style={{ fontSize: "3.0rem", color: "#484C7A" }}
                  />{" "}
                  <div className="divider-height-20" />
                  <span
                    className="fw-bold d-flex flex-row justify-content-center align-items-center d-none"
                    style={{
                      background: "#252F4A",
                      color: "rgb(255,255,255)",
                      borderRadius: "50%",
                      lineHeight: "1.0 !important",
                      marginBottom: "30px",
                      width: "60px",
                      height: "60px",
                    }}
                  >
                    hb
                  </span>
                  <span className="fs-5 text-center">
                    Create a favorites list to make your selection process
                    easier
                  </span>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4">
                <div className="d-flex flex-column align-items-center bs-landing-item-box">
                  <MessageIcon
                    style={{ fontSize: "3.0rem", color: "#484C7A" }}
                  />{" "}
                  <div className="divider-height-20" />
                  <span
                    className="fw-bold d-flex flex-row justify-content-center align-items-center d-none"
                    style={{
                      background: "#252F4A",
                      color: "rgb(255,255,255)",
                      borderRadius: "50%",
                      lineHeight: "1.0 !important",
                      marginBottom: "30px",
                      width: "60px",
                      height: "60px",
                    }}
                  >
                    hb
                  </span>
                  <span className="fs-5 text-center">
                    Securely chat directly with any clinic through the Turkish
                    Hair Clinic app
                  </span>
                </div>
              </div>
              <div className="col-12 col-lg-4">
                <div className="d-flex flex-column align-items-center bs-landing-item-box">
                  <SecurityIcon
                    style={{ fontSize: "3.0rem", color: "#484C7A" }}
                  />{" "}
                  <div className="divider-height-20" />
                  <span
                    className="fw-bold d-flex flex-row justify-content-center align-items-center d-none"
                    style={{
                      background: "#252F4A",
                      color: "rgb(255,255,255)",
                      borderRadius: "50%",
                      lineHeight: "1.0 !important",
                      marginBottom: "30px",
                      width: "60px",
                      height: "60px",
                    }}
                  >
                    hb
                  </span>
                  <span className="fs-5 text-center">
                    Your contact information is always private
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bs-landing-section-padding">
          <div className="container">
            <div className="row">
              <div className="col d-flex justify-content-center">
                <span
                  className="fs-2 fw-bold text-center shadow"
                  style={{
                    background: "aquamarine",
                    borderRadius: "5px",
                    padding: "10px",
                    paddingRight: "20px",
                    paddingLeft: "20px",
                  }}
                >
                  How It Works?
                </span>
              </div>
            </div>
          </div>
          <div className="bs-landing-section-title-spacing" />
          <div className="container bs-landing-max-width">
            <div className="row gx-3 gy-3">
              <div className="col-12 col-md-6 col-lg-4">
                <div className="d-flex flex-column align-items-center bs-landing-item-box">
                  <span
                    className="fs-5 fw-bold d-flex flex-row justify-content-center align-items-center"
                    style={{
                      background: "aquamarine",
                      color: "#484C7A",
                      borderRadius: "50%",
                      lineHeight: "1.0 !important",
                      width: "60px",
                      height: "60px",
                      marginBottom: "30px",
                      border: "4px solid #484C7A",
                    }}
                  >
                    1
                  </span>
                  <span
                    className="fs-5 fw-bold text-center"
                    style={{ marginBottom: "20px" }}
                  >
                    Quote Form
                  </span>
                  <span className="fs-5 text-center d-flex justify-content-center align-items-center">
                    Fill in the Quote Form with the information the clinics need
                    to give you a quote
                  </span>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4">
                <div className="d-flex flex-column align-items-center bs-landing-item-box">
                  <span
                    className="fs-5 fw-bold d-flex flex-row justify-content-center align-items-center"
                    style={{
                      background: "aquamarine",
                      color: "#484C7A",
                      borderRadius: "50%",
                      lineHeight: "1.0 !important",
                      width: "60px",
                      height: "60px",
                      marginBottom: "30px",
                      border: "4px solid #484C7A",
                    }}
                  >
                    2
                  </span>
                  <span
                    className="fs-5 fw-bold text-center"
                    style={{ marginBottom: "20px" }}
                  >
                    View Your Quotes
                  </span>
                  <span className="fs-5 text-center d-flex justify-content-center align-items-center">
                    Sign-in to your account to view the no-obligation quotes
                    uploaded by multiple Turkish Hair Clinics{" "}
                  </span>
                </div>
              </div>
              <div className="col-12 col-lg-4">
                <div className="d-flex flex-column align-items-center bs-landing-item-box">
                  <span
                    className="fs-5 fw-bold d-flex flex-row justify-content-center align-items-center"
                    style={{
                      background: "aquamarine",
                      color: "#484C7A",
                      borderRadius: "50%",
                      lineHeight: "1.0 !important",
                      width: "60px",
                      height: "60px",
                      marginBottom: "30px",
                      border: "4px solid #484C7A",
                    }}
                  >
                    3
                  </span>
                  <span
                    className="fs-5 fw-bold text-center"
                    style={{ marginBottom: "20px" }}
                  >
                    Make Your Choice
                  </span>
                  <span className="fs-5 text-center d-flex justify-content-center align-items-center">
                    Select the right hair clinic for you from Google reviews,
                    Before &amp; After images, and more{" "}
                  </span>
                </div>
              </div>
            </div>
            <div className="bs-landing-section-content-spacing" />
            <div className="row">
              <div className="col-md-12">
                <div className="text-center d-flex flex-column align-items-center align-content-center">
                  <button
                    className="btn btn-primary btn-lg fw-bold"
                    type="button"
                    style={{ padding: "20px", marginBottom: "20px" }}
                    onClick={this.onButtonClicked}
                  >
                    EXPLORER THE TURKISH HAIR CLINICS NOW
                  </button>
                  <button
                    className="btn btn-link btn-lg fw-bold bs-landing-link-button"
                    type="button"
                    style={{ padding: "20px" }}
                    onClick={this.onButtonClickedGetQuote}
                  >
                    GET MULTIPLE FREE NO-OBLIGATION QUOTES
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="bs-landing-section-padding"
          style={{ background: "#EDEDED" }}
        >
          <div className="container">
            <div className="row">
              <div className="col d-flex justify-content-center">
                <span
                  className="fs-2 fw-bold text-center border rounded shadow"
                  style={{ padding: "10px 20px", color: "#484C7A" }}
                >
                  Go To Turkey For Your Hair Transplant
                </span>
              </div>
            </div>
          </div>
          <div className="bs-landing-section-title-spacing" />
          <div className="container bs-landing-max-width">
            <div className="row gx-5 gy-5">
              <div
                className="col-12 col-lg-7 d-flex"
                style={{ textAlign: "center" }}
              >
                <div
                  className="d-flex flex-column justify-content-center"
                  style={{ display: "inline-block", textAlign: "left" }}
                >
                  <span className="fs-5">
                    If you are considering a hair transplant, you may be
                    wondering if Turkey is the right place to go. While there
                    are many clinics around the world that offer hair
                    transplants, Turkey has become a popular destination for
                    this procedure due to its high quality of care and
                    affordable prices.
                    <br />
                  </span>
                  <div className="bs-landing-section-inner-title-spacing" />
                  <span className="fs-5">
                    Go on vacation to Turkey and return with a luscious head of
                    hair!
                  </span>
                  <div className="bs-landing-section-inner-title-spacing" />
                  <span className="fs-5">
                    Hair transplants in the U.K cost upwards of £20,000. In
                    western Europe, you could pay up to 15,000€.
                  </span>
                  <div className="bs-landing-section-inner-title-spacing" />
                  <span className="fs-5">
                    Medical tourism maintains high standards when you choose a
                    hair transplant procedure with Turkish Hair Clinics.
                  </span>
                </div>
              </div>
              <div className="col-12 col-lg-5 text-center align-self-center">
                <img
                  src={`${"../assets/images/landing/turkey.jpg"}`}
                  className="bs-landing-image"
                />
                <img
                  className="d-none bs-landing-image"
                  src="assets/img/turkey.jpg"
                />
              </div>
            </div>
            <div className="bs-landing-section-content-spacing" />
            <div className="row">
              <div className="col d-flex" style={{ textAlign: "center" }}>
                <div
                  className="d-flex flex-column justify-content-center"
                  style={{ display: "inline-block", textAlign: "left" }}
                >
                  <span className="fs-3 fw-bold">Best Price in Turkey</span>
                  <div className="bs-landing-section-inner-title-spacing" />
                  <span className="fs-5" style={{ marginBottom: "10px" }}>
                    The prices in Turkey are extremely competitive, without
                    compromising quality.
                  </span>
                  <span className="fs-5">
                    Hair transplant clinics in Turkey offer all-inclusive
                    vacation hair transplant packages.
                  </span>
                </div>
              </div>
            </div>
            <div className="bs-landing-section-content-spacing" />
            <div className="row gx-5 gy-5 row-cols-1 row-cols-lg-2">
              <div className="col-lg-5 text-center align-self-center">
                <img
                  src={`${"../assets/images/landing/health-tourism-2.jpg"}`}
                  className="bs-landing-image"
                />
                <img
                  className="d-none bs-landing-image"
                  src="assets/img/health-tourism-2.jpg"
                />
              </div>
              <div className="col-lg-7 d-flex" style={{ textAlign: "center" }}>
                <div
                  className="text-center"
                  style={{ display: "inline-block", textAlign: "left" }}
                >
                  <span className="fs-3 fw-bold">
                    For just $2,000 to $4,000, services include many options,
                    such as:
                  </span>
                  <div className="bs-landing-section-inner-title-spacing" />
                  <div style={{ display: "inline-block", textAlign: "left" }}>
                    <div
                      className="d-flex justify-content-start align-items-start"
                      style={{ marginBottom: "10px" }}
                    >
                      <CheckIcon
                        style={{
                          fontSize: "2.0rem",
                          color: "#484C7A",
                          marginRight: "10px",
                          marginTop: "0px",
                        }}
                      />
                      <i
                        className="fas fa-check fs-5 d-none"
                        style={{ marginRight: "20px", marginTop: "5px" }}
                      />
                      <span className="fs-5" style={{ textAlign: "left" }}>
                        Determining how many grafts you require and the hair
                        transplant method best for your hair
                      </span>
                    </div>
                    <div
                      className="d-flex justify-content-start align-items-start"
                      style={{ marginBottom: "10px" }}
                    >
                      <CheckIcon
                        style={{
                          fontSize: "2.0rem",
                          color: "#484C7A",
                          marginRight: "10px",
                          marginTop: "0px",
                        }}
                      />
                      <i
                        className="fas fa-check fs-5 d-none"
                        style={{ marginRight: "20px", marginTop: "5px" }}
                      />
                      <span className="fs-5" style={{ textAlign: "left" }}>
                        Accommodation at a luxury hotel for the duration of your
                        stay in Turkey
                      </span>
                    </div>
                    <div
                      className="d-flex justify-content-start align-items-start"
                      style={{ marginBottom: "10px" }}
                    >
                      <CheckIcon
                        style={{
                          fontSize: "2.0rem",
                          color: "#484C7A",
                          marginRight: "10px",
                          marginTop: "0px",
                        }}
                      />
                      <i
                        className="fas fa-check fs-5 d-none"
                        style={{ marginRight: "20px", marginTop: "5px" }}
                      />
                      <span className="fs-5" style={{ textAlign: "left" }}>
                        VIP transfers in a private car between the airport,
                        hotel, and clinic
                      </span>
                    </div>
                    <div
                      className="d-flex justify-content-start align-items-start"
                      style={{ marginBottom: "10px" }}
                    >
                      <CheckIcon
                        style={{
                          fontSize: "2.0rem",
                          color: "#484C7A",
                          marginRight: "10px",
                          marginTop: "0px",
                        }}
                      />
                      <i
                        className="fas fa-check fs-5 d-none"
                        style={{ marginRight: "20px", marginTop: "5px" }}
                      />
                      <span className="fs-5" style={{ textAlign: "left" }}>
                        Translations services if needed
                      </span>
                    </div>
                    <div
                      className="d-flex justify-content-start align-items-start"
                      style={{ marginBottom: "10px" }}
                    >
                      <CheckIcon
                        style={{
                          fontSize: "2.0rem",
                          color: "#484C7A",
                          marginRight: "10px",
                          marginTop: "0px",
                        }}
                      />
                      <i
                        className="fas fa-check fs-5 d-none"
                        style={{ marginRight: "20px", marginTop: "5px" }}
                      />
                      <span className="fs-5" style={{ textAlign: "left" }}>
                        Painless local anesthesia
                      </span>
                    </div>
                    <div
                      className="d-flex justify-content-start align-items-start"
                      style={{ marginBottom: "10px" }}
                    >
                      <CheckIcon
                        style={{
                          fontSize: "2.0rem",
                          color: "#484C7A",
                          marginRight: "10px",
                          marginTop: "0px",
                        }}
                      />
                      <i
                        className="fas fa-check fs-5 d-none"
                        style={{ marginRight: "20px", marginTop: "5px" }}
                      />
                      <span className="fs-5" style={{ textAlign: "left" }}>
                        Post-operative Care Essentials include shampoo, lotion,
                        special hat, neck pillow, pain killers, antibiotics,
                        etc.
                      </span>
                    </div>
                    <div
                      className="d-flex justify-content-start align-items-start"
                      style={{ marginBottom: "10px" }}
                    >
                      <CheckIcon
                        style={{
                          fontSize: "2.0rem",
                          color: "#484C7A",
                          marginRight: "10px",
                          marginTop: "0px",
                        }}
                      />
                      <i
                        className="fas fa-check fs-5 d-none"
                        style={{ marginRight: "20px", marginTop: "5px" }}
                      />
                      <span className="fs-5" style={{ textAlign: "left" }}>
                        PRP therapy to stimulate hair growth
                      </span>
                    </div>
                    <div
                      className="d-flex justify-content-start align-items-start"
                      style={{ marginBottom: "10px" }}
                    >
                      <CheckIcon
                        style={{
                          fontSize: "2.0rem",
                          color: "#484C7A",
                          marginRight: "10px",
                          marginTop: "0px",
                        }}
                      />
                      <i
                        className="fas fa-check fs-5 d-none"
                        style={{ marginRight: "20px", marginTop: "5px" }}
                      />
                      <span className="fs-5" style={{ textAlign: "left" }}>
                        Aftercare services for up to a year
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bs-landing-section-padding">
          <div className="container bs-landing-max-width">
            <div className="row">
              <div className="col d-flex justify-content-center">
                <span
                  className="fs-2 fw-bold text-center border rounded shadow"
                  style={{ padding: "10px 20px", color: "#484C7A" }}
                >
                  What is Hair Transplantation?
                </span>
              </div>
            </div>
            <div className="bs-landing-section-title-spacing" />
            <div className="row">
              <div className="col-md-12">
                <span className="fs-5">
                  Hair transplantation is a non-surgical procedure to restore
                  hair to balding areas on your scalp using your own hair.
                </span>
              </div>
            </div>
          </div>
          <div className="bs-landing-section-content-spacing" />
          <div className="container bs-landing-max-width">
            <div className="row gx-3 gy-5 gy-md-3 row-cols-1 row-cols-md-2">
              <div
                className="col-md-7 d-flex order-last order-md-first"
                style={{ textAlign: "center" }}
              >
                <div
                  className="d-flex flex-column justify-content-center"
                  style={{ display: "inline-block", textAlign: "left" }}
                >
                  <div style={{ display: "inline-block", textAlign: "left" }}>
                    <div
                      className="d-flex justify-content-start align-items-start"
                      style={{ marginBottom: "15px" }}
                    >
                      <LooksOneIcon
                        style={{
                          fontSize: "2.0rem",
                          color: "#484C7A",
                          marginRight: "10px",
                          marginTop: "0px",
                        }}
                      />
                      <i
                        className="material-icons fs-3 d-none"
                        style={{ marginRight: "20px", marginTop: "5px" }}
                      >
                        looks_one
                      </i>
                      <span className="fs-5" style={{ textAlign: "left" }}>
                        A hair transplant takes hair from where you don’t need
                        it and moves it to where you do{" "}
                      </span>
                    </div>
                    <div
                      className="d-flex justify-content-start align-items-start"
                      style={{ marginBottom: "15px" }}
                    >
                      <LooksTwoIcon
                        style={{
                          fontSize: "2.0rem",
                          color: "#484C7A",
                          marginRight: "10px",
                          marginTop: "0px",
                        }}
                      />
                      <i
                        className="material-icons fs-3 d-none"
                        style={{ marginRight: "20px", marginTop: "5px" }}
                      >
                        looks_two
                      </i>
                      <span className="fs-5" style={{ textAlign: "left" }}>
                        The doctor extracts hair follicles from the back or
                        sides of your head and the top of your neck{" "}
                      </span>
                    </div>
                    <div
                      className="d-flex justify-content-start align-items-start"
                      style={{ marginBottom: "15px" }}
                    >
                      <Looks3Icon
                        style={{
                          fontSize: "2.0rem",
                          color: "#484C7A",
                          marginRight: "10px",
                          marginTop: "0px",
                        }}
                      />
                      <i
                        className="material-icons fs-3 d-none"
                        style={{ marginRight: "20px", marginTop: "5px" }}
                      >
                        looks_3
                      </i>
                      <span className="fs-5" style={{ textAlign: "left" }}>
                        You take a short rest while the doctor, and your hair
                        follicles, keep working without you{" "}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-5 text-center align-self-center">
                <img
                  src={`${"../assets/images/landing/hair-transplant.jpg"}`}
                  className="bs-landing-image"
                />
                <img
                  className="d-none bs-landing-image"
                  src="assets/img/hair-transplant.jpg"
                />
              </div>
            </div>
            <div className="bs-landing-section-content-spacing" />
            <div className="row gx-3 gy-3 gy-sm-3 row-cols-1 row-cols-md-2">
              <div className="col-md-5 text-center d-none d-md-block align-self-center">
                <img
                  src={`${"../assets/images/landing/hair-transplant-3.jpg"}`}
                  className="bs-landing-image"
                />
                <img
                  className="d-none bs-landing-image"
                  src="assets/img/hair-transplant-3.jpg"
                />
              </div>
              <div className="col-md-7 d-flex" style={{ textAlign: "center" }}>
                <div
                  className="d-flex flex-column justify-content-center"
                  style={{ display: "inline-block", textAlign: "left" }}
                >
                  <div style={{ display: "inline-block", textAlign: "left" }}>
                    <div
                      className="d-flex justify-content-start align-items-start"
                      style={{ marginBottom: "15px" }}
                    >
                      <Looks4Icon
                        style={{
                          fontSize: "2.0rem",
                          color: "#484C7A",
                          marginRight: "10px",
                          marginTop: "0px",
                        }}
                      />
                      <i
                        className="material-icons fs-3 d-none"
                        style={{ marginRight: "20px", marginTop: "5px" }}
                      >
                        looks_4
                      </i>
                      <span className="fs-5" style={{ textAlign: "left" }}>
                        Then, the hair follicles are transplanted into your bald
                        patch
                      </span>
                    </div>
                    <div
                      className="d-flex justify-content-center align-items-start"
                      style={{ marginBottom: "15px" }}
                    >
                      <Looks5Icon
                        style={{
                          fontSize: "2.0rem",
                          color: "#484C7A",
                          marginRight: "10px",
                          marginTop: "0px",
                        }}
                      />
                      <i
                        className="material-icons fs-3 d-none"
                        style={{ marginRight: "20px", marginTop: "5px" }}
                      >
                        looks_5
                      </i>
                      <span className="fs-5" style={{ textAlign: "left" }}>
                        The whole process takes about 6 to 8 hours, including
                        your rest between the extraction and the transplantation
                        stages
                      </span>
                    </div>
                    <div
                      className="d-flex justify-content-start align-items-start"
                      style={{ marginBottom: "10px" }}
                    >
                      <Looks6Icon
                        style={{
                          fontSize: "2.0rem",
                          color: "#484C7A",
                          marginRight: "10px",
                          marginTop: "0px",
                        }}
                      />
                      <i
                        className="material-icons fs-3 d-none"
                        style={{ marginRight: "20px", marginTop: "5px" }}
                      >
                        looks_6
                      </i>
                      <span className="fs-5" style={{ textAlign: "left" }}>
                        This hair is permanent and not subject to hair loss
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="bs-landing-section-content-spacing" />
            <div className="row">
              <div className="col-md-12">
                <div className="text-center d-flex flex-column align-items-center align-content-center">
                  <button
                    className="btn btn-primary btn-lg fw-bold"
                    type="button"
                    style={{ padding: "20px", marginBottom: "20px" }}
                    onClick={this.onButtonClicked}
                  >
                    EXPLORER THE TURKISH HAIR CLINICS NOW
                  </button>
                  <button
                    className="btn btn-link btn-lg fw-bold bs-landing-link-button"
                    type="button"
                    style={{ padding: "20px" }}
                    onClick={this.onButtonClickedGetQuote}
                  >
                    GET FREE TURKISH HAIR CLINIC QUOTES
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="bs-landing-section-padding"
          style={{ background: "#EDEDED" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <span className="fs-5">
                  NOTE: Always consult a doctor to confirm you qualify medically
                  for a hair transplant.{" "}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

//###########################################################
//###########################################################
//###########################################################

// export default LandingPage;

//### this is a way to use hook from class component
export default function(props) {
  const navigate = useNavigate();
  return <LandingPage {...props} navigate={navigate} />;
}

//###########################################################
//###########################################################
//###########################################################
