import React from "react";

//###########################################################
//###########################################################
//###########################################################

class FormValidation {
  //###########################################################
  //###########################################################
  //###########################################################

  constructor(appData) {
    this.validator = appData.validator;// require("validator");

    this.state = {};
  }

  //###########################################################
  //###########################################################
  //###########################################################

  formInputs = (params, callBack) => {
    let res = {
      email: [],
      password: [],
      passwordConfirmation: [],
      fullName: [],
      warningText: "",
    };

    //### make null the missing attributes
    if (params.name === undefined) {
      params.name = null;
    }
    if (params.email === undefined) {
      params.email = null;
    }
    if (params.password === undefined) {
      params.password = null;
    }
    if (params.passwordConfirmation === undefined) {
      params.passwordConfirmation = null;
    }

    //### check name
    if (
      params.name !== null &&
      (params.name.length < 2 ||
        !this.validator.isAlpha(params.name.replace(" ", "")))
    ) {
      let tempWarningText = "Please check your name. (You can only use alphanumeric characters.)";
      res.fullName.push(tempWarningText);
      if (res.warningText !== "") {
        res.warningText += "\n";
      }
      res.warningText += tempWarningText;
    }

    //### check email
    if (params.email !== null && !this.validator.isEmail(params.email)) {
      let tempWarningText = "E-Mail format is wrong.";
      res.email.push(tempWarningText);
      if (res.warningText !== "") {
        res.warningText += "\n";
      }
      res.warningText += tempWarningText;
    }

    //### check password
    if (params.password !== null && params.password.length < 6) {
      let tempWarningText = "The password must 6 chars in length.";
      res.password.push(tempWarningText);
      if (res.warningText !== "") {
        res.warningText += "\n";
      }
      res.warningText += tempWarningText;
    }

    //### check passwordConfirmation
    if (
      params.passwordConfirmation !== null &&
      params.password !== params.passwordConfirmation
    ) {
      let tempWarningText = "The password confirmation is wrong.";
      res.passwordConfirmation.push(tempWarningText);
      if (res.warningText !== "") {
        res.warningText += "\n";
      }
      res.warningText += tempWarningText;
    }

    callBack(res);
  };

  //###########################################################
  //###########################################################
  //###########################################################

  componentDidMount() {}

  //###########################################################
  //###########################################################
  //###########################################################

  render() {
    return <></>;
  }
}

//###########################################################
//###########################################################
//###########################################################

export default FormValidation;

//###########################################################
//###########################################################
//###########################################################
