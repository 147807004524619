import React from "react";
import Backend from "./Backend";

//###########################################################
//###########################################################
//###########################################################

class BackendClinicList extends React.Component {
  //###########################################################
  //###########################################################
  //###########################################################

  constructor(props) {
    super(props);

    this.backend = new Backend();
    this.instantBackend = this.backend.instantBackend;

    this.state = {};
  }

  //###########################################################
  //###########################################################
  //###########################################################

  getClinicListByGroupIndex = (groupIndex, callBack) => {
    let paramsGetClinicListByGroupIndex = {
      groupIndex: groupIndex,
    };

    this.instantBackend.perform(
      "GetClinicListByGroupIndex",
      paramsGetClinicListByGroupIndex,
      (data) => {
        if (data === null) {
          data = [];
        } else if (data.length > 0) {
          data = data[0];
        }
        callBack(data);
      }
    );
  };

  //###########################################################
  //###########################################################
  //###########################################################

  getClinicListByRunIDGroupIndexPageIndex = (
    runID,
    groupIndex,
    pageIndex,
    callBack
  ) => {
    let paramsGetClinicListByRunIDGroupIndexPageIndex = {
      runID: runID,
      groupIndex: groupIndex,
      pageIndex: pageIndex,
    };

    this.instantBackend.perform(
      "GetClinicListByRunIDGroupIndexPageIndex",
      paramsGetClinicListByRunIDGroupIndexPageIndex,
      (data) => {
        if (data === null) {
          data = [];
        } else if (data.length > 0) {
          data = data[0];
        }
        callBack(data);
      }
    );
  };

  //###########################################################
  //###########################################################
  //###########################################################
}

//###########################################################
//###########################################################
//###########################################################

export default BackendClinicList;

//###########################################################
//###########################################################
//###########################################################
