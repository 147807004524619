import React from "react";
import FacebookCircularProgress from "../screen-console/FacebookCircularProgress";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";

//### you need to add its CSS to the public/index.html
import {
  LightgalleryProvider,
  LightgalleryItem,
  withLightgallery,
  useLightgallery,
} from "react-lightgallery";
import { ThreeDRotationSharp } from "@mui/icons-material";
import Button from "@mui/material/Button";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

//###########################################################
//###########################################################
//###########################################################

const PhotoItem = ({ image, thumb, group, size, callBack }) => (
  <LightgalleryItem group={group} src={image} thumb={thumb}>
    <img
      src={image}
      className="clinic-thumbnail"
      style={{ width: size.width, height: size.height }}
      alt=""
      onLoad={() => {
        callBack();
      }}
    />
  </LightgalleryItem>
);

const OpenButtonWithHook = (props) => {
  const { openGallery } = useLightgallery();
  return (
    <Button
      {...props}
      onClick={() => openGallery("g1", 3)}
      variant="outlined"
      // style={{ width: props.width, height: props.height }}
    >
      <div className="flex-column-center">{props.imageCount} more image(s)</div>
    </Button>
  );
};

//###########################################################
//###########################################################
//###########################################################

class BeforeAfter extends React.Component {
  //###########################################################
  //###########################################################
  //###########################################################

  constructor(props) {
    super(props);

    //### for forward references
    if (
      this.props.parent !== undefined &&
      this.props.parent.beforeAfter !== undefined
    ) {
      this.props.parent.beforeAfter = this;
    }

    this.parent = this.props.parent;
    this.sessionObject = this.parent.sessionObject;
    this.isMobile = this.sessionObject.device.isMobile;

    this.loadedImageCount = 0;
    this.onImageCount = 3;
    this.thumbnailSize = { width: 120, height: 120 };

    if (this.isMobile) {
      this.thumbnailSize = { width: 80, height: 80 };
    }

    this.state = {
      imageURLsOn: [],
      imageURLsOff: [],
      allLoaded: false,
      progressBarValue: 0,
    };
  }

  //###########################################################
  //###########################################################
  //###########################################################

  setImageUrls = (imageURLs) => {
    let tempState = this.state;

    while (tempState.imageURLsOn.length > 0) {
      tempState.imageURLsOn.pop();
    }
    while (tempState.imageURLsOff.length > 0) {
      tempState.imageURLsOff.pop();
    }

    for (let i = 0; i < this.onImageCount; i++) {
      tempState.imageURLsOn.push(imageURLs[i]);
    }
    for (let i = this.onImageCount; i < imageURLs.length; i++) {
      tempState.imageURLsOff.push(imageURLs[i]);
    }

    this.setState(tempState);
  };

  //###########################################################
  //###########################################################
  //###########################################################

  componentDidMount() {}

  //###########################################################
  //###########################################################
  //###########################################################

  render() {
    return (
      <>
        <div
          style={{
            display: this.state.allLoaded ? "inline-block" : "none",
            width: "100%",
          }}
        >
          <LightgalleryProvider
            onAfterOpen={() => {
              //### create a log
              this.parent.backend.CreateLog(
                this.sessionObject.uuid,
                "clinic-before-after-clicked",
                '{"uuid": "' + this.parent.props.uuid + '"}',
                (response) => {}
              );
            }}
          >
            <div
              style={{
                // display: this.state.allLoaded ? "inline-block" : "none",
                display: "inline-block",
                width: "100%",
                // background: "#FF0",
                padding: "0px 0px 0px 0px",
              }}
            >
              {this.state.imageURLsOn.map((p, idx) => (
                <PhotoItem
                  key={idx}
                  image={p}
                  group="g1"
                  size={this.thumbnailSize}
                  callBack={() => {
                    this.loadedImageCount++;

                    //## calculate and set progressBarValue
                    let tempState = this.state;
                    tempState.progressBarValue = Math.round(
                      (100 * this.loadedImageCount) /
                        (this.state.imageURLsOn.length +
                          this.state.imageURLsOff.length)
                    );
                    this.setState(tempState);

                    if (
                      this.loadedImageCount >=
                      this.state.imageURLsOn.length +
                        this.state.imageURLsOff.length
                    ) {
                      let tempState = this.state;
                      tempState.allLoaded = true;
                      this.setState(tempState);
                    }
                  }}
                />
              ))}
              {this.state.imageURLsOff.map((p, idx) => (
                <div style={{ display: "none" }}>
                  <PhotoItem
                    key={idx}
                    image={p}
                    group="g1"
                    size={this.thumbnailSize}
                    callBack={() => {
                      this.loadedImageCount++;

                      //## calculate and set progressBarValue
                      let tempState = this.state;
                      tempState.progressBarValue = Math.round(
                        (100 * this.loadedImageCount) /
                          (this.state.imageURLsOn.length +
                            this.state.imageURLsOff.length)
                      );
                      this.setState(tempState);

                      if (
                        this.loadedImageCount >=
                        this.state.imageURLsOn.length +
                          this.state.imageURLsOff.length
                      ) {
                        let tempState = this.state;
                        tempState.allLoaded = true;
                        this.setState(tempState);
                      }
                    }}
                  />
                </div>
              ))}

              <OpenButtonWithHook
                style={{
                  display: this.state.imageURLsOff.length <= 0 ? "none" : "",
                  fontSize: this.isMobile ? "0.85rem" : "1rem",
                  width: this.thumbnailSize.width,
                  height: this.thumbnailSize.height,
                  marginRight: "15px",
                  marginBottom: "15px",
                }}
                imageCount={this.state.imageURLsOff.length}
              />
            </div>

            <div style={{ fontSize: "0.85rem" }}>
              (*) Please read the description below.
            </div>
          </LightgalleryProvider>
        </div>
        <div
          style={{
            display: this.state.allLoaded ? "none" : "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <FacebookCircularProgress />
          <Box sx={{ width: "100%", maxWidth: "500px", marginBottom: "15px" }}>
            <LinearProgress
              variant="determinate"
              value={this.state.progressBarValue}
            />
          </Box>
        </div>
      </>
    );
  }
}

//###########################################################
//###########################################################
//###########################################################

export default BeforeAfter;

//###########################################################
//###########################################################
//###########################################################
