import React from "react";
import Backend from "./Backend";

//###########################################################
//###########################################################
//###########################################################

class BackendClinic extends React.Component {
  //###########################################################
  //###########################################################
  //###########################################################

  constructor(props) {
    super(props);

    this.backend = new Backend();
    this.instantBackend = this.backend.instantBackend;

    this.state = {};
  }

  //###########################################################
  //###########################################################
  //###########################################################

  getClinicData = (uuid, callBack) => {
    let paramsGetClinicData_1 = {
      uuid: uuid,
    };

    this.instantBackend.perform(
      "GetClinicData_1",
      paramsGetClinicData_1,
      (data) => {
        if (data === null) {
          data = [];
        } else if (data.length > 0) {
          data = data[0];
        }
        callBack(data);
      }
    );
  };

  getGoogleData = (uuid, callBack) => {
    let paramsGetGoogleData_1 = {
      uuid: uuid,
    };

    this.instantBackend.perform(
      "GetGoogleData_1",
      paramsGetGoogleData_1,
      (data) => {
        if (data === null) {
          data = [];
        } else if (data.length > 0) {
          data = data[0];

          //### startStr
          let startStr =
            data.Star.toString().length == 1
              ? data.Star.toString() + ".0"
              : data.Star;

          data.startStr = startStr;

          //### since and yearsAgo
          let yearsAgo = 0;
          let firstReview = data.FirstReview.trim().toLowerCase();

          if (firstReview.includes("year")) {
            yearsAgo = firstReview.split(" ")[0];
            yearsAgo = parseInt(yearsAgo);
          }

          let since = new Date().getFullYear() - yearsAgo;

          data.yearsAgo = yearsAgo;
          data.since = since;
        }
        callBack(data);
      }
    );
  };

  getClinicURLs = (uuid, callBack) => {
    let paramsGetClinicURLs_1 = {
      uuid: uuid,
    };

    let response = {};
    response.beforeAfter = "";
    response.facebook = "";
    response.instagram = "";
    response.linkedin = "";
    response.twitter = "";
    response.youtube = "";

    this.instantBackend.perform(
      "GetClinicURLs_1",
      paramsGetClinicURLs_1,
      (data) => {
        if (data === null) {
          data = [];
        } else  {
          for (let index = 0; index < data.length; index++) {
            let element = data[index];
            if(element.Name === "before-after"){
                element.Name = "beforeAfter"
            }
            response[element.Name] = element.URL;
          }
        }
        callBack(response);
      }
    );
  };

  
  getLast10JSON  = (uuid, callBack) => {
    let paramsGetClinicData_1 = {
      uuid: uuid,
    };

    this.instantBackend.perform(
      "GetLast10JSON ",
      paramsGetClinicData_1,
      (data) => {       
        if (data === null) {
          data = [];
        } else if (data.length > 0) {
          data = JSON.parse(data[0].Last10JSON);
        }
        callBack(data);
      }
    );
  };

  //###########################################################
  //###########################################################
  //###########################################################
}

//###########################################################
//###########################################################
//###########################################################

export default BackendClinic;

//###########################################################
//###########################################################
//###########################################################
