import React from "react";

//###########################################################
//###########################################################
//###########################################################

class Generator extends React.Component {
  //###########################################################
  //###########################################################
  //###########################################################

  constructor(props) {
    super(props);

    this.appData = this.props.appData;

    this.state = { listClinics: [] };
  }
  //###########################################################
  //###########################################################
  //###########################################################

  componentDidMount() {
    this.appData.instantBackend
      .performWithPromise("GetStaticClinics")
      .then((data) => {
        data = data.sort((a, b) => 0.5 - Math.random());

        let listClinics = [];
        data.forEach((clinicData) => {
          listClinics.push(this.getClinic(clinicData));
        });

        //### set the state
        let tempState = this.state;
        tempState.listClinics = listClinics;
        this.setState(tempState);
      })
      .catch((error) => {
        //### alert('There is an ERROR in GetStaticClinics. Please check your console for more detail.');
        console.log(">>> ERROR in GetStaticClinics >>> " + error);
      });
  }

  //###########################################################
  //###########################################################
  //###########################################################

  getClinic = (data) => {
    console.log(data);
    return (
      <div
        style={{
          display: "inline-block",
          padding: "20px",
          borderRadius: "10px",
          width: "calc(100% - 40px)",
          background: "#F1F3FA",
          marginBottom: "20px",
        }}
      >
        <div
          style={{
            fontSize: "1.5rem",
            fontWeight: "bold",
            padding: "0px 5px 0px 5px",
          }}
        >
          {data.Name}
        </div>
        <div style={{ marginBottom: "20px", padding: "15px 5px 0px 5px", lineHeight:"1.8" }}>
          {data.About}
        </div>

        <div style={{ width: "100%", display: "inline-block" }}>
          {this.getChip("Web", data.CorrectedURL)}
          {this.getChip("Before&After", data.BeforeAfter)}
          {this.getChip("Facebook", data.Facebook)}
          {this.getChip("Instagram", data.Instagram)}
          {this.getChip("Linkedin", data.Linkedin)}
          {this.getChip("Twitter", data.Twitter)}
          {this.getChip("Youtube", data.Youtube)}
        </div>
        <div style={{ width: "100%", display: "inline-block", marginTop:"10px" }}>
          {this.getChipGoogleReview("Google Reviews Count", data.GoogleCount)}
          {this.getChipGoogleReview("Google Reviews Stars", data.GoogleStar)}
          {this.getChipGoogleReview(
            "The First Google Review",
            data.GoogleFirstReview
          )}
          {this.getChipGoogleReview(
            "The Latest Google Review",
            data.GoogleLastReview
          )}
        </div>
      </div>
    );
  };

  getChip = (text, url) => {
    return (
      <div
        style={{
          display: "inline-block",
          float: "left",
          margin: "5px",
          background: url === "" ? "#D2D3D8" : "#004BFF",
          padding: "5px 10px 5px 10px",
          borderRadius: "10px",
        }}
      >
        {url === "" ? (
          <span
            style={{ color: "#FFF", textDecoration: "none", lineHeight: "1.0" }}
          >
            {text}
          </span>
        ) : (
          <a
            style={{ color: "#FFF", textDecoration: "none", lineHeight: "1.0" }}
            href={url}
            target="_blank"
          >
            {text}
          </a>
        )}
      </div>
    );
  };

  getChipGoogleReview = (text, val) => {
    return (
      <div
        style={{
          display: "inline-block",
          float: "left",
          margin: "5px",
          background: "#FFF",
          padding: "5px 10px 5px 10px",
          borderRadius: "10px",
          whiteSpace: "nowrap",
        }}
      >
        {text}
        {" : "}
        {val}
      </div>
    );
  };

  //###########################################################
  //###########################################################
  //###########################################################

  formatDate = (dateString) => {
    let date = new Date(dateString),
      day = date.getDate(),
      month = date.getMonth(),
      year = date.getFullYear(),
      months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

    return months[month] + " / " + day + " / " + year;
  };

  //###########################################################
  //###########################################################
  //###########################################################

  render() {
    return (
      <div
        style={{
          width: "calc(100% - 40px)",
          padding: "20px",
          marginLeft: "auto",
          marginRight: "auto",
          maxWidth: "1000px",
        }}
      >
        <h1 style={{ textAlign: "center", marginBottom: "10px" }}>
          List of Best Hair Transplant Clinics in Turkey
        </h1>
        <div style={{ width: "100%", textAlign: "center", marginBottom:"30px" }}>
          Last Update: {this.formatDate(new Date())}
        </div>
        <p style={{ marginBottom: "30px", lineHeight: "1.8" }}>
          If you are looking for the best hair transplant clinics in Turkey, you
          have come to the right place. Our list includes some of the most
          reputable and highly qualified clinics in the country. So whether you
          are considering a hair transplant surgery or just want to learn more
          about the process, these clinics can provide you with all the
          information you need. Contact them today to get started!
        </p>
        {this.state.listClinics}
      </div>
    );
  }
}

//###########################################################
//###########################################################
//###########################################################

export default Generator;

//###########################################################
//###########################################################
//###########################################################
