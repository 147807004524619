import React from "react";
import HomeIcon from "@mui/icons-material/Home";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import Button from "@mui/material/Button";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import VpnKeyOutlinedIcon from "@mui/icons-material/VpnKeyOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import FeedOutlinedIcon from "@mui/icons-material/FeedOutlined";

import QuoteRequestForm from "../get-quote/QuoteRequestForm";
import CreateNewPassword from "../authentication/CreateNewPassword";

//###########################################################
//###########################################################
//###########################################################

class MyAccount extends React.Component {
  //###########################################################
  //###########################################################
  //###########################################################

  constructor(props) {
    super(props);

    this.appData = this.props.appData;

    this.iconStyle = { fontSize: "1.0rem", marginRight: "8px" };

    this.state = {};
  }

  //###########################################################
  //###########################################################
  //###########################################################

  emptyFunction = () => {
    console.log("I am empty");
  };

  //###########################################################
  //###########################################################
  //###########################################################

  componentDidMount() {}

  //###########################################################
  //###########################################################
  //###########################################################

  getItem = (title, content, icon) => {
    if (icon === undefined) {
      icon = <></>;
    }

    let styleContainer = {
      position: "relative",
      padding: "0px 15px 0px 15px",
      background: "#F7F7F8",
      borderRadius: "5px",
      width:"calc(100% - 30px)"
    };

    let styleTitle = {
      fontSize: "0.80rem",
      background: "#E5E5E5",
      borderRadius: "5px",
      padding: "6px 10px 6px 10px",
      fontWeight: "bold",
      marginLeft: -15,
    };

    return (
      <div className="flex-column-start-left" style={styleContainer}>
        <div className="flex-row-start" style={styleTitle}>
          {icon}
          <div style={{ paddingTop: 1 }}>{title}</div>
        </div>
        <div className="divider-height-10 yellow1 fullWidth" />
        <div className="flex-row-start">{content}</div>
        <div className="divider-height-10 yellow1 fullWidth" />
      </div>
    );
  };

  getHomePageButton = () => {
    return (
      <Button
        onClick={() => {
          this.appData.screenConsole().open();
          window.location.href = "/";
        }}
        startIcon={<ArrowBackIcon />}
        variant="contained"
      >
        Back to the Home Page
      </Button>
    );
  };

  //###########################################################
  //###########################################################
  //###########################################################

  render() {
    let currentUser = this.appData.getCurrentUser();

    return (
      <div
        style={{
          minHeight: this.props.minHeight,
          width: "calc(100% - 40px)",
          maxWidth: "1000px",
          padding: "0px 20px 0px 20px",
          marginBottom: 60,
        }}
      >
        <div className="divider-height-20" />
        {this.getHomePageButton()}
        <div className="divider-height-20 " />
        <div className="flex-row-space-between">
          <div
            style={{
              fontSize: "2rem",
              flexGrow: 1,
              textAlign: "center",
              lineHeight: 1.0,
            }}
          >
            My Account
          </div>
        </div>
        <div className="divider-height-20" />
        {this.getItem(
          "Name",
          currentUser.Name,
          <AccountCircleOutlinedIcon style={this.iconStyle} />
        )}
        <div className="divider-height-20" />
        {this.getItem(
          "E-Mail",
          currentUser.Email,
          <EmailOutlinedIcon style={this.iconStyle} />
        )}
        <div className="divider-height-20" />
        {this.getItem(
          "Password",
          <Button
            variant="outlined"
            size="medium"
            style={{ marginTop: 5, marginBottom: 5 }}
            onClick={() => {

              let createNewPassword =  <CreateNewPassword key={this.appData.getUUID()} appData={this.appData} updatePassword={true} />;
        
              this.appData.container().open(createNewPassword, {
                vAlign: "center"
              });
            }}
          >
            Update Password
          </Button>,
          <VpnKeyOutlinedIcon style={this.iconStyle} />
        )}
        <div className="divider-height-20" />
        {this.getItem(
          "Phone",
          currentUser.PhoneData.maskedPhoneNumber,
          <LocalPhoneOutlinedIcon style={this.iconStyle} />
        )}
        <div className="divider-height-20" />
        {this.getItem(
          "Quote Request Form",
          <>
            <Button
              variant="outlined"
              style={{ marginTop: 5, marginBottom: 5 }}
              size="medium"
              onClick={() => {
                //### step 1
                this.appData.screenConsole().open();

                //### step 2
                let currentUser = this.appData.getCurrentUser();
                if (currentUser === null) {
                  alert("Please sign in first.");
                  window.location.href = "/";
                } else {
                  //### step 3
                  let paramsGetSubmissionSteps = {
                    userID: currentUser.UUID,
                  };
                  this.appData.instantBackend
                    .performWithPromise(
                      "GetSubmissionSteps",
                      paramsGetSubmissionSteps
                    )
                    .then((data) => {
                      //### step 4
                      let quoteRequestForm = (
                        <QuoteRequestForm appData={this.appData} formData={data} />
                      );
                      this.appData.container().open(quoteRequestForm, {
                        vAlign: "flex-start"
                      });
                      //### step 5
                      this.appData.screenConsole().close();
                    })
                    .catch((error) => {
                      if(this.appData.printConsole){
                        console.log(
                          ">>> ERROR in GetSubmissionSteps >>> " + error
                        );
                      }
                      //### step 5
                      this.appData.screenConsole().close();
                    });
                }
              }}
            >
              See the Form
            </Button>
            <div className="divider-width-15" />
            <Button variant="outlined" size="medium" color="error">
              Withdraw
            </Button>
          </>,
          <FeedOutlinedIcon style={this.iconStyle} />
        )}
      </div>
    );
  }
}

//###########################################################
//###########################################################
//###########################################################

export default MyAccount;

//###########################################################
//###########################################################
//###########################################################
