import React from "react";
import { Link, useNavigate } from "react-router-dom";
import DataTableRow from "./DataTableRow";

//###########################################################
//###########################################################
//###########################################################

class DataTable extends React.Component {
  //###########################################################
  //###########################################################
  //###########################################################

  constructor(props) {
    super(props);
    this.backend = this.props.parent.backend;
    this.adminControl = this.props.parent;

    //### for forward references
    if (
      this.props.parent !== undefined &&
      this.props.parent.dataTable !== undefined
    ) {
      this.props.parent.dataTable = this;
    }

    this.state = { rows: "loading..." };
  }

  //###########################################################
  //###########################################################
  //###########################################################

  componentDidMount() {
    this.fillTable(false);
  }

  //###########################################################
  //###########################################################
  //###########################################################

  fetchData = (callBack) => {
    switch (this.props.type) {
      case "clinic-annotation": {
        this.backend.getClinics_1((response) => {
          let data = response.Data;
          callBack(data);
        });
        break;
      }
      case "clinic-annotation-ready-ones": {
        this.backend.getClinicsReadyOnes((response) => {
          let data = response.Data;
          callBack(data);
        });
        break;
      }
      case "clinic-annotation-has-no-image": {
        this.backend.getClinicsHasNoImage((response) => {
          let data = response.Data;
          callBack(data);
        });
        break;
      }
      case "candidate-selection": {
        this.backend.getGoogleSearchResultsByCandidate("maybe", (response) => {
          let data = response.Data;
          callBack(data);
        });
        break;
      }
      default:
        break;
    }
  };

  //###########################################################
  //###########################################################
  //###########################################################

  fillTable = (selectFirstItem) => {
    //### set the rows as loading at the begining.
    let tempState = this.state;
    tempState.rows = "loading...";
    this.setState(tempState, () => {
      let _this = this;
      let rows = [];

      console.log(">>> Retrieving data...");
      //### fetch the data from backend
      this.fetchData((data) => {
        console.log(">>> Data has been retrieved.");

        //### loop in the data and set the first item
        let firstItem = null;
        data.forEach((item) => {
          if (firstItem === null) {
            firstItem = item;
          }
          //### push DataTableRow instance to the array
          // if (this.props.type === "candidate-selection" && rows.length < 5) {
            rows.push(
              <DataTableRow
                parent={_this}
                type={_this.props.type}
                data={item}
              />
            );
          // }
        });

        //### set the state
        let tempState = this.state;
        tempState.rows = rows;
        this.setState(tempState, () => {
          //### fire the function if the flag is true
          if (selectFirstItem) {
            this.props.onCurrentRowChanged(firstItem);
          }
        });
      });
    });
  };

  //###########################################################
  //###########################################################
  //###########################################################

  render() {
    return (
      <div
        className="width-with-padding-10"
        style={{
          height: this.props.innerContainerHeight - 21,
          overflowY: "scroll",
          overflowX: "hidden",
        }}
      >
        {this.state.rows}
      </div>
    );
  }
}

//###########################################################
//###########################################################
//###########################################################

export default function(props) {
  const navigate = useNavigate();
  return <DataTable {...props} navigate={navigate} />;
}

//###########################################################
//###########################################################
//###########################################################
