import React from "react";
import Rating from "@mui/material/Rating";

//###########################################################
//###########################################################
//###########################################################

class CommonsWithClinic extends React.Component {
  //###########################################################
  //###########################################################
  //###########################################################

  constructor(uuid, backend, sessionObject) {
    super();
    this.uuid = uuid;
    this.backend = backend;
    this.sessionObject = sessionObject;
    this.isMobile = this.sessionObject.device.isMobile;
    this.state = {};
  }

  //###########################################################
  //###########################################################
  //###########################################################

  //   componentDidMount() {}

  //###########################################################
  //###########################################################
  //###########################################################

  getColorClassName = (type, val) => {
    if (type === "last") {
      if (
        val.includes("minute") ||
        val.includes("hour") ||
        val.includes("day") ||
        val.trim().startsWith("a week") ||
        val.trim().startsWith("1 week")
      )
        return "clinic-card-chip-green";

      if (val.trim().startsWith("2 weeks")) return "clinic-card-chip-yellow";

      if (val.trim().startsWith("3 weeks")) return "clinic-card-chip-orange";

      return "clinic-card-chip-red";
    } else if (type === "first") {
      if (
        val.includes("minute") ||
        val.includes("hour") ||
        val.includes("day") ||
        val.includes("week") ||
        val.includes("month") ||
        val.trim().startsWith("a year") ||
        val.trim().startsWith("1 year") ||
        val.trim().startsWith("2 year")
      )
        return "clinic-card-chip-red";

      if (val.trim().startsWith("3 year") || val.trim().startsWith("4 year"))
        return "clinic-card-chip-orange";
      if (val.trim().startsWith("5 year") || val.trim().startsWith("6 year"))
        return "clinic-card-chip-yellow";
      return "clinic-card-chip-green";
    } else if (type === "star") {
      val = parseFloat(val);
      if (val >= 4) return "clinic-card-chip-green";
      if (val >= 3) return "clinic-card-chip-yellow";
      if (val >= 2) return "clinic-card-chip-orange";
      if (val < 2) return "clinic-card-chip-red";
    }

    return "clinic-card-chip-white";
  };

  //###########################################################
  //###########################################################
  //###########################################################

  googleRatingComponent = (data, caller) => {
    let startStr =
      data.Star.toString().length == 1
        ? data.Star.toString() + ".0"
        : data.Star;

    return (
      <div className="flex-row-center">
        <span
          className="clinic-card-rating-value"
          style={{
            marginTop: caller === "clinic" ? "3px" : "0px",
            fontSize: caller === "clinic" ? "1.8rem" : "",
            lineHeight: caller === "clinic" ? "1.0" : "",
          }}
        >
          {startStr}
        </span>
        <Rating
          value={Number(data.Star)}
          precision={0.1}
          readOnly
          size={caller === "clinic-card" ? "medium" : "large"}
        />
        {caller === "clinic-card" ? (
          <span
            className="clinic-card-count"
            style={{
              paddingTop: caller === "clinic" ? "3px" : "0px",
              lineHeight: caller === "clinic" ? "100%" : "",
            }}
          >
            {data.Count}
          </span>
        ) : (
          <span
            className="link"
            style={{
              fontSize: "1.1rem",
              marginLeft: "10px",
              marginTop: "4px",
              lineHeight: "100%",
            }}
            onClick={() => {
              this.isMobile
                ? window.open(data.DirectLinkMobile, "_blank")
                : window.open(data.DirectLink, "_blank");
              //### create a log
              this.backend.CreateLog(
                this.sessionObject.uuid,
                "clinic-google-direct-link-clicked",
                '{"uuid": "' + this.uuid + '"}',
                (response) => {}
              );
            }}
          >
            {data.Count} reviews
          </span>
        )}
      </div>
    );
  };

  googleRatingComponentLast10Avg = (data, caller) => {
    let last10Avg =
      data.Last10Avg.toString().length == 1
        ? data.Last10Avg.toString() + ".0"
        : data.Last10Avg;
    return (
      <div className="flex-row-center">
        <span
          className="clinic-card-rating-value"
          style={{
            fontSize: caller === "clinic" ? "1.5rem" : "",
            lineHeight: caller === "clinic" ? "1.0" : "",
          }}
        >
          {last10Avg}
        </span>
        <Rating
          value={Number(last10Avg)}
          precision={0.1}
          readOnly
          size="medium"
        />
        <span
          className="clinic-card-count"
          style={{
            lineHeight: caller === "clinic" ? "1.0" : "",
          }}
        >
          Last 10 Avg.
        </span>
      </div>
    );
  };

  googleReviewSummaryTable = (data, caller) => {
    return (
      <table width="100%" style={{ borderSpacing: "0px" }}>
        <tr>
          <td
            style={{
              fontSize: "0.85rem",
              verticalAlign: "bottom",
              textAlign: "center",
              lineHeight: "1.0",
            }}
          >
            First Rev.
          </td>
          <td
            style={{
              fontSize: "0.85rem",
              verticalAlign: "bottom",
              textAlign: "center",
              lineHeight: "1.0",
            }}
          >
            Latest Rev.
          </td>
          {/* {caller === "clinic-card" ? ( */}
          <td
            style={{
              fontSize: "0.85rem",
              verticalAlign: "bottom",
              textAlign: "center",
              lineHeight: "1.0",
            }}
          >
            Last 10 Avg.
          </td>
          {/* ) : (
             <></>
           )}*/}
        </tr>

        <tr>
          <td
            className={this.getColorClassName("first", data.FirstReview)}
            style={{
              borderRight: "3px #FFF solid",
              padding: caller === "clinic" ? "5px" : "10px 5px 10px 5px",
              fontSize: "0.9rem",
              verticalAlign: "middle",
              textAlign: "center",
              lineHeight: "1.1",
              borderRadius: "5px",
            }}
          >
            {data.FirstReview}
          </td>
          <td
            className={this.getColorClassName("last", data.LastReview)}
            style={{
              borderLeft: "3px #FFF solid",
              borderRight: "3px #FFF solid",
              padding: caller === "clinic" ? "5px" : "10px 5px 10px 5px",
              fontSize: "0.9rem",
              verticalAlign: "middle",
              textAlign: "center",
              lineHeight: "1.1",
              borderRadius: "5px",
            }}
          >
            {data.LastReview}
          </td>
          {/* {caller === "clinic-card" ? ( */}
          <td
            className={this.getColorClassName("star", data.Last10Avg)}
            style={{
              borderLeft: "3px #FFF solid",
              padding: caller === "clinic" ? "5px" : "10px 5px 10px 5px",
              fontSize: caller === "clinic" ? "1.5rem" : "0.9rem",
              verticalAlign: "middle",
              textAlign: "center",
              lineHeight: "1.1",
              borderRadius: "5px",
            }}
          >
            {data.Last10Avg.toString().length == 1
              ? data.Last10Avg.toString() + ".0"
              : data.Last10Avg}
          </td>
          {/* ) : (
            <></>
          )} */}
        </tr>
      </table>
    );
  };

  googleReviews = (data, caller) => {
    return (
      <div
        className={
          caller == "clinic" ? "width-with-padding-0" : "width-with-padding-10"
        }
        style={{
          // background: "#FFF",
          paddingBottom: "0px",
          paddingTop: "0px",
          // maxWidth: "400px",
        }}
      >
        {this.googleRatingComponent(data, caller)}
        {/* <div className="divider-height-15" />
        {this.googleReviewSummaryTable(data, caller)} */}

      </div>
    );
  };


}

//###########################################################
//###########################################################
//###########################################################

export default CommonsWithClinic;

// export default function(props) {
//   const navigate = useNavigate();
//   return <CommonsWithClinic {...props} navigate={navigate} />;
// }

//###########################################################
//###########################################################
//###########################################################
