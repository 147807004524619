//###########################################################
//###########################################################
//###########################################################

class MySessionManager {
  //###########################################################
  //###########################################################
  //###########################################################

  constructor() {
    this.sessionStorage = window.sessionStorage;
  }

  //###########################################################
  //###########################################################
  //###########################################################

  set = (name, value) => {
    this.sessionStorage.setItem(name, value);
  };

  setAsObject = (name, value) => {
    this.sessionStorage.setItem(name, JSON.stringify(value));
  };

  get = (name) => {
    return this.sessionStorage.getItem(name);
  };

  getAsObject = (name) => {
    return JSON.parse(this.sessionStorage.getItem(name));
  };

  delete = (name) => {
    return this.sessionStorage.removeItem(name);
  };

  deleteAll = () => {
    return this.sessionStorage.clear();
  };
}

//###########################################################
//###########################################################
//###########################################################

export default MySessionManager;

//###########################################################
//###########################################################
//###########################################################
