import React from "react";
import Backend from "../../classes/Backend";

//###########################################################
//###########################################################
//###########################################################

class ConsolePrinter extends React.Component {
  //###########################################################
  //###########################################################
  //###########################################################

  constructor(props) {
    super(props);

    this.backend = new Backend();
    this.parent = this.props.parent;

    //### for forward references
    if (this.parent !== undefined && this.parent.google !== undefined) {
      this.parent.consolePrinter = this;
    }

    this.state = {};
  }

  //###########################################################
  //###########################################################
  //###########################################################

  emptyFunction = () => {
    console.log("I am empty");
  };

  //###########################################################
  //###########################################################
  //###########################################################

  componentDidMount() {}

  //###########################################################
  //###########################################################
  //###########################################################

  render() {
    return (
      <div>
        <div
          className="btn-primary"
          onClick={() => {
            this.backend.getClinicUrlAndGoogleDirectLink(
              (response) => {
                if (response !== null) {
                    console.clear();
                    console.log(JSON.stringify(response));
                }
              }
            );
          }}
        >
          Clinic URL and GoogleDirectLink
        </div>
      </div>
    );
  }
}

//###########################################################
//###########################################################
//###########################################################

export default ConsolePrinter;

//###########################################################
//###########################################################
//###########################################################
