import React from "react";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import OutlinedInput from "@mui/material/OutlinedInput";
import ListItemText from "@mui/material/ListItemText";
import FormGroup from "@mui/material/FormGroup";
import TextField from "@mui/material/TextField";
import Collapse from "@mui/material/Collapse";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";

import Backend from "../../classes/Backend";
import Commons from "./Commons";

import CommonCalls from "../../classes/CommonCalls";

//###########################################################
//###########################################################
//###########################################################

class Step6 extends React.Component {
  //###########################################################
  //###########################################################
  //###########################################################

  constructor(props) {
    super(props);
    
    this.stepIndex = 6;

    // this.backend = new Backend();
    this.commons = new Commons();
    // this.commonCalls = new CommonCalls();

    this.state = { display: "none" };
  }

  //###########################################################
  //###########################################################
  //###########################################################

  componentDidMount() {}

  //###########################################################
  //###########################################################
  //###########################################################

  show = () => {
    let tempState = this.state;
    tempState.display = "";
    this.setState(tempState);
  };

  hide = () => {
    let tempState = this.state;
    tempState.display = "none";
    this.setState(tempState);
  };

  //###########################################################
  //###########################################################
  //###########################################################

  getValues = () => {
    let res = [];
    res.push({ key: "key", value: "value" });
    res.push({ key: "key", value: "value" });
    res.push({ key: "key", value: "value" });
    res.push({ key: "key", value: "value" });
    res.push({ key: "key", value: "value" });
    return res;
  };

  //###########################################################
  //###########################################################
  //###########################################################

  validate = () => {
    let validationResult = { result: true, message: "" };

    let bullets = [];

    //############################

    //### remove this part for production
    if (true) {
      while (bullets.length > 0) {
        bullets.pop();
      }
    }

    //############################

    if (bullets.length > 0) {
      validationResult.result = false;
      validationResult.message = (
        <>
          <p>Please answer the following question(s):</p>
          <ul>{bullets}</ul>
        </>
      );
    }

    return validationResult;
  };

  //###########################################################
  //###########################################################
  //###########################################################

  render() {
    return (
      <>
        <div
          style={{
            display: this.state.display,
            padding: "10px 20px 20px 20px"
          }}
        >
          {/* ############ */}
          {/* ############ */}
          {/* ############ */}
          {/* title */}
          <div
            className="flex-column-center"
            style={this.commons.styleQuestionContainerSelect}
          >
            <span
              className="bold"
              style={{ marginTop: "10px", marginBottom: "10px" }}
            >
              Step 6 - Verification
            </span>
            <p className="no-padding no-margin" style={{ textAlign: "center" }}>
              Please verify your phone number.
            </p>
          </div>
          {/* ############ */}
          {/* ############ */}
          {/* ############ */}
          <hr className="line-20" />
          {/* ############ */}
          {/* ############ */}
          {/* ############ */}
        </div>
      </>
    );
  }
}

//###########################################################
//###########################################################
//###########################################################

export default Step6;

//###########################################################
//###########################################################
//###########################################################
