import React from "react";
import { Link } from "react-router-dom";
import "./Authentication.css";

import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";

import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";
import InputAdornment from "@mui/material/InputAdornment";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";

import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import FormValidation from "./FormValidation";
import HomeIcon from "@mui/icons-material/Home";

// import { withSnackbar } from "notistack";

//###########################################################
//###########################################################
//###########################################################

class SignIn extends React.Component {
  //###########################################################
  //###########################################################
  //###########################################################

  constructor(props) {
    super(props);

    this.appData = this.props.appData;
    this.parent = this.props.parent;
    this.formValidation = new FormValidation(this.appData);

    this.state = {
      showPassword: false,
      loading: false,
      btnHomePageLoading: false,
      errors: {
        email: false,
        password: false,
      },
    };
  }

  //###########################################################
  //###########################################################
  //###########################################################

  handleClickShowPassword = () => {
    let tempState = this.state;
    tempState.showPassword = !tempState.showPassword;
    this.setState(tempState);
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  isFormInputValid = (callBack) => {
    this.formValidation.formInputs(
      { email: this.txtEmail.value, password: this.txtPassword.value },
      (validationResult) => {
        let tempState = this.state;

        tempState.errors.email =
          validationResult.email.length > 0 ? true : false;
        tempState.errors.password =
          validationResult.password.length > 0 ? true : false;

        if (validationResult.warningText !== "") {
          this.appData.snackbar().alertError("E-Mail and/or password is wrong.");
        } else {
          this.appData.snackbar().close();
        }

        this.setState(tempState);
        callBack(validationResult.warningText === "");
      }
    );
  };

  btnSignInClicked = () => {
    this.appData.snackbar().close();
    // this.appData.snackbar().close();
    // this.props.closeSnackbar();

    this.isFormInputValid((isFormValid) => {
      //#### form is valid
      if (isFormValid) {
        //### set as loading True
        let tempState = this.state;
        tempState.loading = true;
        this.setState(tempState, () => {
          //### set the params
          let paramsGetUser = {
            email: this.txtEmail.value,
            password: this.txtPassword.value,
          };
          //### make your call to backend
          this.appData.accountManager.signIn(paramsGetUser, (response) => {
            if (this.appData.printConsole) console.log(response);
            //### got your response
            //### set as loading False
            let tempState = this.state;
            tempState.loading = false;
            this.setState(tempState, () => {
              //### lets handle the error first
              if (response.Result === "Error") {
                this.appData.snackbar().alertError(response.Message);
              } else if (response.Result === "Failed") {
                this.appData.snackbar().alertError(
                  "E-Mail and/or password is wrong"
                );
              } else if (response.Result === "Succeeded") {
                let userFromBackend = this.appData.accountManager.getUser();
                if (userFromBackend.EmailVerified === "0") {
                  this.appData.snackbar().alertError(
                    "Your e-mail needs to be verified."
                  );
                  //### go to email verification
                  this.parent.openConfirmationCode();
                } else {
                  if (userFromBackend.EmailVerified === "1") {
                    this.appData.snackbar().alertSuccess(
                      "You have successfully signed in."
                    );
                    this.appData.container().close();
                  }
                  //### go to the home page
                  this.appData.header.refresh();
                  // this.parent.refresh();
                }
              }
            });
          });
        });
      }
    });
  };

  onTextFieldFocus = () => {
    this.appData.snackbar().close();
    let tempState = this.state;
    tempState.errors.email = false;
    tempState.errors.password = false;
    this.setState(tempState);
  };

  //###########################################################
  //###########################################################
  //###########################################################

  componentDidMount() {}

  //###########################################################
  //###########################################################
  //###########################################################

  render() {
    return (
      <>
        <div
          className="sign-in-container"
          style={{
            border: this.parent.authenticationDialog !== null ? "none" : "",
            maxWidth:this.props.options.maxWidth !== undefined?this.props.options.maxWidth:400
          }}
        >
          {/* message container */}
          <div
            className="authentication-message-div"
            style={{
              display: this.props.options.message !== undefined ? "" : "none",
            }}
          >
            {this.props.options.message}
          </div>
          {/* logo container */}
          <div
            className="authentication-logo-div"
            style={{
              display: this.props.options.logo !== undefined ? "" : "none",
            }}
          >
            {this.props.options.logo}
          </div>
          <div
            style={{
              textAlign: "center",
              fontWeight: "bold",
              fontSize: "1.4rem",
              padding: "0px 20px 0px 20px",
              marginBottom: "30px",
            }}
            className="text-color"
          >
            Sign In
          </div>
          {/* UI TEST */}
          <div
            style={{
              display: this.appData.isOnServer ? "none" : "",
              textAlign: "center",
              padding: "10px",
              background: "#efefef",
              marginBottom: "30px",
              borderRadius: "5px",
            }}
          >
            <span
              className="link"
              onClick={() => {
                this.txtEmail.value = "a@b.com";
                this.txtPassword.value = "112233";
              }}
            >
              Test
            </span>
          </div>
          {/* ########################## */}
          {/* ########################## */}
          {/* ########################## */}
          <TextField
            error={this.state.errors.email}
            inputRef={(el) => (this.txtEmail = el)}
            label="E-Mail"
            variant="outlined"
            style={{ width: "100%" }}
            InputProps={{
              style: { fontFamily: "Poppins", marginBottom: "20px" },
              startAdornment: (
                <InputAdornment position="start">
                  <EmailOutlinedIcon />
                </InputAdornment>
              ),
            }}
            onFocus={() => {
              this.onTextFieldFocus();
            }}
          />
          {/* ########################## */}
          {/* ########################## */}
          {/* ########################## */}
          <TextField
            error={this.state.errors.password}
            inputRef={(el) => (this.txtPassword = el)}
            label="Password"
            variant="outlined"
            style={{ width: "100%" }}
            type={this.state.showPassword ? "text" : "password"}
            InputProps={{
              style: { fontFamily: "Poppins" },
              startAdornment: (
                <InputAdornment position="start">
                  <LockOutlinedIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={this.handleClickShowPassword}
                    onMouseDown={this.handleMouseDownPassword}
                    edge="end"
                  >
                    {this.state.showPassword ? (
                      <VisibilityOff />
                    ) : (
                      <Visibility />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onFocus={() => {
              this.onTextFieldFocus();
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                this.btnSignInClicked();
              }
            }}
          />
          {/* ########################## */}
          {/* ########################## */}
          {/* ########################## */}
          <div
            className="link-color"
            style={{
              padding: "0px",
              marginTop: "5px",
              fontSize: "1.0rem",
              marginBottom: "30px",
            }}
            onClick={() => {
              this.parent.openForgotPassword();
            }}
          >
            Forgot password?
          </div>
          {/* ########################## */}
          {/* ########################## */}
          {/* ########################## */}

          <LoadingButton
            style={{
              width: "100%",
              paddingTop: "15px",
              paddingBottom: "15px",
              fontSize: "1rem",
              marginBottom: "20px",
            }}
            onClick={() => {
              this.btnSignInClicked();
            }}
            loading={this.state.loading}
            loadingIndicator="Please wait..."
            variant="contained"
          >
            Sign In
          </LoadingButton>
          <div
            style={{
              textAlign: "center",
              fontSize: "1.0rem",
              marginBottom: "0px",
            }}
            className="text-color flex-row-center"
          >
            Not a member?
            <span
              className="link-color"
              style={{
                marginLeft: "5px",
                padding: "0px",
                fontSize: "1.0rem",
              }}
              onClick={() => {
                this.parent.signUpClicked();
              }}
            >
              Sign up now
            </span>
          </div>
          <div
            style={{
              display: this.parent.authenticationDialog !== null ? "none" : "",
            }}
          >
            <div
              className="text-color flex-row-center"
              style={{
                borderTop: "1px solid #CCC",
                textAlign: "center",
                marginTop: "30px",
                paddingTop: "30px",
              }}
            >
              <LoadingButton
                size="small"
                onClick={() => {
                  let tempState = this.state;
                  tempState.btnHomePageLoading = true;
                  this.setState(tempState, () => {
                    setTimeout(function() {
                      window.location.href = "/";
                    }, 250);
                  });
                }}
                loading={this.state.btnHomePageLoading}
                loadingPosition="start"
                startIcon={<HomeIcon />}
                variant="contained"
              >
                Home Page
              </LoadingButton>
            </div>
          </div>
        </div>
      </>
    );
  }
}

//###########################################################
//###########################################################
//###########################################################

// export default withSnackbar(SignIn);
export default SignIn;
// export default function(props) {
//   const navigate = useNavigate();
//   return <SignIn {...props} navigate={navigate} />;
// }

//###########################################################
//###########################################################
//###########################################################
