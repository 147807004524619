import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Admin from "./Admin";
import Header from "../header/Header";
import Footer from "../footer/Footer";

//###########################################################
//###########################################################
//###########################################################

class TestPage extends React.Component {
  //###########################################################
  //###########################################################
  //###########################################################

  constructor(props) {
    super(props);
    this.header = null;
    this.state = { forceRender: false };
  }

  //###########################################################
  //###########################################################
  //###########################################################

  componentDidMount() {
    this.forceRender();
  }

  //###########################################################
  //###########################################################
  //###########################################################

  getInnerContainerHeight = () => {
    if (this.header === null) {
      return 300;
    }
    return (
      this.props.clientSize.height -this.header.getHeight()
    );
  };

  //###########################################################
  //###########################################################
  //###########################################################

  forceRender = () => {
    let tempState = this.state;
    tempState.forceRender = !tempState.forceRender;
    this.setState(tempState);
  };

  //###########################################################
  //###########################################################
  //###########################################################

  render() {
    let innerContainerHeight = this.getInnerContainerHeight();

    return (
      <div>
        <Header parent={this} />
        <Admin parent={this} innerContainerHeight={innerContainerHeight} />
      </div>
    );
  }
}

//###########################################################
//###########################################################
//###########################################################


export default function(props) {
  const navigate = useNavigate();
  return <TestPage {...props} navigate={navigate} />;
}

//###########################################################
//###########################################################
//###########################################################
