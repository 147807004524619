import React from "react";
import Backend from "../../classes/Backend";
import CommonCalls from "../../classes/CommonCalls";
// import ClinicsTable from "./ClinicsTable";
import SideBar from "./SideBar";
import DataTable from "./DataTable";

//###########################################################
//###########################################################
//###########################################################

class Admin extends React.Component {
  //###########################################################
  //###########################################################
  //###########################################################

  constructor(props) {
    super(props);
    this.colors = {
      red: "#FFD8D8",
      green: "#E6FFD8",
      blue:"#D6EAF8"
    };
    this.backend = new Backend();
    this.commonCalls = new CommonCalls();
    // this.clinicsTable = null;
    this.state = {
      // currentUUID: null,
      // currentClinicURL: null,
      // currentRow: null,
      currentTask: "clinic-annotation",
    };

    this.testPage = this.props.parent;
  }

  //###########################################################
  //###########################################################
  //###########################################################

  componentDidMount() {}

  //###########################################################
  //###########################################################
  //###########################################################

  onCurrentClinicChanged = (clinicURL) => {
    this.commonCalls.setState(this, ["currentClinicURL"], [clinicURL], () => {
      console.log(this.state.currentClinicURL);
      //### it is being set in SideBar.js -> React.useEffect
      this.sideBarOnCurrentClinicChanged(clinicURL);
    });
  };

  
  onCurrentUUIDChanged = (uuid, clinicURL) => {
    this.commonCalls.setState(this, ["currentUUID"], [uuid], () => {
      console.log(">>> Current UUID: " + this.state.currentUUID);
      //### it is being set in SideBar.js -> React.useEffect
      this.sideBarOnCurrentUUIDChanged(uuid);
    });
  };
  
  onCurrentRowChanged = (row) => {

    //### it is being set in SideBar.js -> React.useEffect
    this.sideBarOnCurrentRowChanged(row);

    // this.commonCalls.setState(this, ["currentRow"], [row], () => {
    //   console.log(">>> Current Row: ");
    //   console.log(this.state.currentRow);
    //   
    //   this.sideBarOnCurrentRowChanged(row);
    // });
  };

  //###########################################################
  //###########################################################
  //###########################################################

  setCurrentTask = (task) => {
    let tempState = this.state;
    tempState.currentTask = task;
    this.setState(tempState, () => {
      // this.clinicsTable.fillTable(true);
    });
  };

  //###########################################################
  //###########################################################
  //###########################################################

  render() {
    return (
      <div className="flex-row-start-top">
      <DataTable
        parent={this}
        innerContainerHeight={this.props.innerContainerHeight}
        type={this.state.currentTask}
        onCurrentRowChanged = {this.onCurrentRowChanged}
      />
        {/* <ClinicsTable
          parent={this}
          innerContainerHeight={this.props.innerContainerHeight}
        /> */}
        <SideBar
          parent={this}
          innerContainerHeight={this.props.innerContainerHeight}
        />
      </div>
    );
  }
}

//###########################################################
//###########################################################
//###########################################################

export default Admin;

//###########################################################
//###########################################################
//###########################################################
