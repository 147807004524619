import React from "react";
import { Link, useNavigate } from "react-router-dom";
import "./Header.css";

import MySnackbar from "../commons/MySnackbar";
import MyCookieManager from "../commons/MyCookieManager";
import MySessionManager from "../commons/MySessionManager";
import MyDialog from "../commons/MyDialog";

import Logo from "../logo/Logo";

import SignIn from "../authentication/SignIn";
import SignUp from "../authentication/SignUp";
import AuthenticationMessages from "../authentication/Message";
import ForgotPassword from "../authentication/ForgotPassword";
import CreateNewPassword from "../authentication/CreateNewPassword";
import ConfirmationCode from "../authentication/ConfirmationCode";

import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";

import Logout from "@mui/icons-material/Logout";
import StarIcon from "@mui/icons-material/Star";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import NotificationsIcon from "@mui/icons-material/Notifications";
import EmailIcon from "@mui/icons-material/Email";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import PersonAdd from "@mui/icons-material/PersonAdd";
import Settings from "@mui/icons-material/Settings";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";

// import UserIsExist from "../authentication/UserIsExist";
import Backend from "../../classes/Backend";

import GetQuote from "../get-quote/GetQuote";
import { ThirtyFpsSharp } from "@mui/icons-material";

import AccountManager from "../../classes/build_in/AccountManager";

import { isMobile } from "react-device-detect";
// import { withRouter } from "react-router-dom";

//###########################################################
//###########################################################
//###########################################################

/*


Favorites
Get Quote
Settings
Log Out

*/

//###########################################################
//###########################################################
//###########################################################

class Header extends React.Component {
  //###########################################################
  //###########################################################
  //###########################################################

  constructor(props) {
    super(props);

    this.appData = this.props.appData;
    //### I want to make the header accessible from anywhere via "this.appData.header"
    this.appData.header = this;

    this.parent = this.props.parent;

    this.sessionObject = this.appData.getSessionObject();
    this.desktopLogo = <Logo type="desktop" appData={this.appData} />;

    // this.backend = new Backend();
    // this.accountManager = new AccountManager({
    //   serverURL: process.env.REACT_APP_INSTANT_BACKEND_URL,
    //   applicationConfigurationID:
    //     process.env.REACT_APP_INSTANT_BACKEND_APPLICATION_CONFIGURATION_ID,
    //   printConsole:
    //     process.env.REACT_APP_INSTANT_BACKEND_PRINT_CONSOLE.toLowerCase() ===
    //     "true",
    // });
    // this.sessionManager = new MySessionManager();
    // this.cookieManager = new MyCookieManager();

    // this.sessionObject = this.sessionManager.getAsObject("sessionObject");
    // this.cookieObject = this.cookieManager.get("cookieObject");

    this.modalAuthentication = false;
    this.authenticationDialog = null;

    this.isTestting = false;
    this.state = {
      anchorEl: null,
      forceRender: true,
      // flags: {
      //   signedIn:
      //     this.props.options.signedIn !== undefined
      //       ? this.props.options.signedIn
      //       : false,
      //   submitted:
      //     this.props.options.submitted !== undefined
      //       ? this.props.options.submitted
      //       : false,
      // }
      flags: {
        signedIn: false,
        submitted: false,
      },
    };
  }

  //###########################################################
  //###########################################################
  //###########################################################

  componentDidMount() {
    this.refresh();
    this.processSessionFormAction();
    // this.fetchSessionCookieDataFromBackend();
  }

  //###########################################################
  //###########################################################
  //###########################################################
  //### database icindeki cookie datalarini, bir defaya mahsus fetch edip, session icine koyuyor.

  // fetchSessionCookieDataFromBackend = () => {
  //   if (this.sessionObject.cookie !== undefined) return;

  //   this.sessionObject.cookie = {};

  //   let cookieNames = [
  //     "GetQuoteStep1",
  //     "GetQuoteStep2",
  //     "GetQuoteStep3-FrontImage",
  //   ];

  //   cookieNames.forEach((cookieName) => {
  //     let paramsGetCookieData = {
  //       clientUUID: this.cookieObject.uuid,
  //       name: cookieName,
  //     };

  //     this.instantBackend
  //       .performWithPromise("GetCookieData", paramsGetCookieData)
  //       .then((data) => {
  //         //### Do your stuff with data
  //         if (data.length > 0) {
  //           let tempCookieData = JSON.parse(data[0].Data);
  //           this.sessionObject.cookie[cookieName] = tempCookieData;
  //           this.sessionManager.setAsObject(
  //             "sessionObject",
  //             this.sessionObject
  //           );
  //         }
  //       })
  //       .catch((error) => {
  //         alert(
  //           "There is an ERROR in GetCookieData. Please check your console for more detail."
  //         );
  //         console.log(">>> ERROR in GetCookieData >>> " + error);
  //       });
  //   });
  // };

  //###########################################################
  //###########################################################
  //###########################################################

  refresh = () => {
    //### close the dialog
    // if (this.authenticationDialog !== null) {
    //   this.authenticationDialog.close();
    // } else {
    //   console.log("xxxxxxxxxxxxxxxxxxxxxxxx");
    //   console.log("xxxxxxxxxxxxxxxxxxxxxxxx");
    //   console.log("xxxxxxxxxxxxxxxxxxxxxxxx");
    //   console.log(this.appData.container()());
    //   if(this.appData.dialog !== undefined){
    //     console.log("aaaaaaaaaaaaaaaaaaaaaa");
    //     console.log("aaaaaaaaaaaaaaaaaaaaaa");
    //     console.log("aaaaaaaaaaaaaaaaaaaaaa");
    //     this.appData.dialog.close();
    //   }
    // }

    //### STEP 1
    //### lets check if i have any user
    let currentUser = this.appData.accountManager.getUser();

    if (this.appData.printConsole) {
      console.log(">>> Current User: ");
      console.log(currentUser);
    }

    //### STEP 2
    //### if the page is private and there is no verified user => go to home page
    if (this.parent.isPrivate !== undefined && this.parent.isPrivate === true) {
      if (currentUser === null || currentUser.EmailVerified === "0") {
        this.appData.screenConsole().open();
        window.location.href = "/";
      }
    }

    //### STEP 3.1
    //### if there is a user, hide the quote request form first
    //### until detirmine from backend if the user submitted the form before...
    if (currentUser !== null && currentUser.EmailVerified === "1") {
      let tempState = this.state;
      tempState.flags.submitted = true;
      tempState.flags.signedIn = true;
      this.setState(tempState, () => {
        //### STEP 4
        this.setSubmitted(currentUser.UUID);
      });
    }
    //### STEP 3.2
    //### there is no valid user, so submitted can not be true
    else {
      let tempState = this.state;
      tempState.flags.signedIn = false;
      tempState.flags.submitted = false;
      this.setState(tempState);
    }
  };

  //###########################################################
  //###########################################################
  //###########################################################

  setSubmitted = (userID) => {
    let paramsGetSubmissionTime = {
      userID: userID,
    };

    this.appData.instantBackend
      .performWithPromise("GetSubmissionTime", paramsGetSubmissionTime)
      .then((data) => {
        if (data.length <= 0) {
          let tempState = this.state;
          tempState.flags.submitted = false;
          this.setState(tempState);
        }
      })
      .catch((error) => {
        //### alert('There is an ERROR in GetSubmissionTime. Please check your console for more detail.');
        console.log(">>> ERROR in GetSubmissionTime >>> " + error);
      });
  };

  //###########################################################
  //###########################################################
  //###########################################################

  processSessionFormAction = () => {
    if (this.sessionObject.formAction === "ResetPassword") {
      this.openCreateNewPassword();
    } else if (this.sessionObject.formAction === "VerifyEmail") {
      this.accountManager.logOut();
      this.signInClicked();
    }

    this.sessionObject.formAction = "";
    this.appData.setSessionObject(this.sessionObject);
  };

  //###########################################################
  //###########################################################
  //###########################################################

  getButtonsAsNotSignedIn = () => {
    return (
      <div className="flex-row-start">
        {this.state.flags.submitted === false ? (
          <>
            {this.quoteButton()}
            <div className="divider-width-15" />
          </>
        ) : (
          <></>
        )}
        {this.signUpButton()}
        <div className="divider-width-15" />
        {this.signInButton()}
      </div>
    );
  };

  getButtonsAsSignedIn = () => {
    return (
      <div className="flex-row-start">
        {this.state.flags.submitted === false ? (
          <>
            {this.quoteButton()}
            <div className="divider-width-15" />
          </>
        ) : (
          <></>
        )}
        {this.appData.version < 1.1 ? (
          <></>
        ) : (
          <>
            {this.myFavoritesButton()}
            <div className="divider-width-15" />
            {this.notificationButton()}
            <div className="divider-width-15" />
            <Divider orientation="vertical" flexItem />
            <div className="divider-width-10" />
          </>
        )}
        {this.userButtuon()}
      </div>
    );
  };

  //###########################################################
  //###########################################################
  //###########################################################

  signUpButton = () => {
    return (
      <div
        className="btn-secondary"
        style={{
          paddingTop: "8px",
          paddingBottom: "8px",
          lineHeight: "120%",
        }}
        onClick={() => {
          this.authenticationDialog = null;
          this.signUpClicked();
        }}
      >
        Sign Up
      </div>
    );
  };

  signInButton = () => {
    return (
      <div
        className="btn-primary"
        style={{
          paddingTop: "8px",
          paddingBottom: "8px",
          lineHeight: "120%",
        }}
        onClick={() => {
          this.authenticationDialog = null;
          this.signInClicked();
        }}
      >
        Sign In
      </div>
    );
  };

  //###########################################################
  //###########################################################
  //###########################################################

  handleMenuClick = (event) => {
    this.setAnchorEl(event.currentTarget);
  };

  setAnchorEl = (val) => {
    let tempState = this.state;
    tempState.anchorEl = val;
    this.setState(tempState);
  };

  handleMenuClose = () => {
    this.setAnchorEl(null);
  };

  //###########################################################
  //###########################################################
  //###########################################################

  logo = () => {
    let clientWidth = this.appData.getClientSize().width;
    let signedIn = this.state.flags.signedIn;
    let submitted = this.state.flags.submitted;

    let logoType = "desktop";

    //### not signed in nor Submitted
    if (!signedIn && !submitted) {
      if (clientWidth < 780) {
        logoType = "mobile";
      }
      if (clientWidth < 360) {
        logoType = "none";
      }
    }
    //### signed in but not Submitted
    else if (signedIn && !submitted) {
      if (clientWidth < 680) {
        logoType = "mobile";
      }
      if (clientWidth < 340) {
        logoType = "none";
      }
    }
    //### signed in and Submitted
    else if (signedIn && submitted) {
      if (clientWidth < 450) {
        logoType = "mobile";
      }
      if (clientWidth < 250) {
        logoType = "none";
      }
    }

    return <Logo type={logoType} appData={this.appData} />;
  };

  //###########################################################
  //###########################################################
  //###########################################################

  quoteButton = () => {
    let clientWidth = this.appData.getClientSize().width;
    let signedIn = this.state.flags.signedIn;

    let size = "large";

    if (!signedIn) {
      if (clientWidth < 570) {
        size = "medium";
      }
      if (clientWidth < 420) {
        size = "small";
      }
    } else {
      if (clientWidth < 500) {
        size = "medium";
      }
      if (clientWidth < 390) {
        size = "small";
      }
    }

    switch (size) {
      case "large": {
        return this.quoteButtonLarge();
      }
      case "medium": {
        return this.quoteButtonMedium();
      }
      case "small": {
        return this.quoteButtonSmall();
      }
      default: {
        return <div>Request Free Quotes</div>;
      }
    }
  };

  quoteButtonLarge = () => {
    return (
      <div className="mor-button-1" onClick={this.quoteButtonClicked}>
        <MonetizationOnIcon style={{ fontSize: "2rem" }} />
        <div
          style={{
            textAlign: "center",
            marginLeft: "5px",
          }}
        >
          Request Free Quotes
          <p
            style={{
              fontSize: "0.75rem",
              textTransform: "none",
              whiteSpace: "nowrap",
              margin: "3px 0px 0px 0px",
            }}
          >
            (From multiple clinics)
          </p>
        </div>
      </div>
    );
  };

  quoteButtonSmall = () => {
    return (
      <div className="mor-button-2" onClick={this.quoteButtonClicked}>
        <MonetizationOnIcon style={{ fontSize: "1.8rem" }} />
        <p
          style={{
            fontSize: "0.75rem",
            textTransform: "none",
            margin: "3px 0px 0px 0px",
            whiteSpace: "nowrap",
          }}
        >
          Free Quotes
        </p>
      </div>
    );
  };

  quoteButtonMedium = () => {
    return (
      <div className="mor-button-1" onClick={this.quoteButtonClicked}>
        <MonetizationOnIcon style={{ fontSize: "1.8rem" }} />
        <div
          style={{
            fontSize: "0.75rem",
            textTransform: "none",
            marginLeft: "5px",
            lineHeight: "110%",
            textAlign: "center",
          }}
        >
          Request
          <br />
          Quotes
        </div>
      </div>
    );
  };

  //###########################################################
  //###########################################################
  //###########################################################

  myFavoritesButton = () => {
    let clientWidth = this.appData.getClientSize().width;
    let submitted = this.state.flags.submitted;

    let size = "large";

    if (!submitted) {
      if (clientWidth < 800) {
        size = "small";
      }
    } else {
      if (clientWidth < 560) {
        size = "small";
      }
    }

    switch (size) {
      case "large": {
        return this.myFavoritesButtonLarge();
      }
      case "medium": {
        return this.myFavoritesButtonMedium();
      }
      case "small": {
        return this.myFavoritesButtonNoText();
      }

      default: {
        return <div>Favorites</div>;
      }
    }
  };

  myFavoritesButtonLarge = () => {
    return (
      <div className="white-button-1 header-button-color header-button">
        <StarIcon
          style={{
            fontSize: "2rem",
          }}
        />
        <div style={{ marginLeft: "5px", color: "#333" }}>Favorites</div>
      </div>
    );
  };

  myFavoritesButtonMedium = () => {
    return <>My Favorites - Medium</>;
  };

  myFavoritesButtonSmall = () => {
    return (
      <div className="white-button-2 header-button-color header-button">
        <StarIcon
          style={{
            fontSize: "1.8rem",
          }}
        />
        <p
          style={{
            fontSize: "0.75rem",
            textTransform: "none",
            margin: "3px 0px 0px 0px",
            whiteSpace: "nowrap",
            color: "#333",
          }}
        >
          Favorites
        </p>
      </div>
    );
  };

  myFavoritesButtonNoText = () => {
    return (
      <div className="white-button-2 header-button-color header-button">
        <StarIcon
          style={{
            fontSize: "2.0rem",
          }}
        />
      </div>
    );
  };

  //###########################################################
  //###########################################################
  //###########################################################

  notificationButton = () => {
    return this.notificationButtonNoText();
  };

  notificationButtonNoText = () => {
    return (
      <div className="white-button-2 header-button-color header-button">
        <NotificationsIcon style={{ fontSize: "2.0rem" }} />
      </div>
    );
  };

  //###########################################################
  //###########################################################
  //###########################################################

  userButtuon = () => {
    return (
      <Tooltip title="Account">
        <IconButton
          onClick={this.handleMenuClick}
          size="small"
          className="header-button-color header-button"
        >
          <AccountCircleIcon fontSize="large" />
        </IconButton>
      </Tooltip>
    );
  };

  //###########################################################
  //###########################################################
  //###########################################################

  getHeight = () => {
    return this.divContainer.clientHeight;
  };

  //###########################################################
  //###########################################################
  //###########################################################

  booleanToDisplay = (val) => {
    if (!val) {
      return "none";
    } else {
      return "";
    }
  };

  //###########################################################
  //###########################################################
  //###########################################################

  getAuthenticationModal = (control) => {
    if (this.authenticationDialog !== null) {
      let openParams = {
        type: "container",
        _contentPadding: isMobile ? "0px" : "0px 30px 0px 30px",
        closeButtonPosition: { right: 0, top: 0 },
        fullScreenDesktop: false,
        allowBackdrop: true,
        closeButton: "cross",
        closeButtonSize: "28px",
        content: control,
        zIndex: 1001,
        maxWidth: 500,
      };
      this.authenticationDialog.open(openParams);
    } else {
      let openParams = {
        type: "container",
        hasCloseButton: true,
        content: control,
        zIndex: 1001,
        // contentLayout: "top-center",
      };
      this.appData.dialog.open(openParams);
    }
  };

  //###########################################################
  //###########################################################
  //###########################################################

  quoteButtonClicked = () => {
    if (!this.state.flags.signedIn) {
      this.appData.container().open(
        <SignIn
          key={this.appData.getUUID()}
          parent={this}
          appData={this.appData}
          options={{
            maxWidth: 600,
            logo: this.desktopLogo,
            message: (
              <>
                <p>
                  To get <b>multiple quotes</b> and send/receive{" "}
                  <b>secure messages</b> from Turkish Hair Clinics, you need to{" "}
                  <b>Sign In</b> first.
                </p>
                {/* <p>
                Before requesting quotes from Turkish Hair Clinics, you need to <b>Sign In</b> first. 
                </p> */}
                <p>
                  If you don't have an account with us, you can{" "}
                  <a
                    className="link"
                    onClick={() => {
                      this.signUpClicked();
                    }}
                  >
                    Sign Up now
                  </a>{" "}
                  .
                </p>
              </>
            ),
          }}
        />
      );
    } else {
      if (this.state.flags.submitted) {
        this.appData.alert().open(
          <>
            <p>
              According to our records, you have already submitted your Quote
              Request Form.
            </p>
            <p>You can see your Quote Request Form under your account.</p>

            <p>Thank you!</p>
          </>,
          { maxWidth: 600 }
        );
      } else {
        this.appData
          .container()
          .open(
            <GetQuote
              key={this.appData.getUUID()}
              parent={this}
              appData={this.appData}
            />,
            { hasCloseButton: false }
          );
      }
    }

    // this.appData.dialog.open({
    //   type: "container",
    //   content: (
    //     <GetQuote
    //       parent={this}
    //       appData={this.appData}
    //       container={this.appData.dialog}
    //     />
    //   ),
    //   contentLayout: "top-center",
    //   hasCloseButton: false,
    // });

    // if (this.state.flags.signedIn) {
    //   this.appData.dialog.open({
    //     type: "container",
    //     content: <GetQuote parent={this} container={this.appData.dialog} />,
    //     contentLayout: "top-center",
    //     hasCloseButton: false,
    //   });
    // } else {
    //   this.modalAuthentication = true;
    //   this.signInClicked();
    // }
  };

  favoritesClicked = () => {};

  signInClicked = () => {
    // this.appData.confirmation().open("Are you sure want to cancel?", {
    //   type: "YesNo",
    //   callBack: () => {
    //     alert("deleted");
    //   },
    // });

    //  this.appData.alert().open("This is an allert :) ", {
    //   callBack: () => {
    //     alert("aletted");
    //   },
    // });

    this.appData
      .container()
      .open(
        <SignIn
          key={this.appData.getUUID()}
          parent={this}
          appData={this.appData}
          options={{ logo: this.desktopLogo }}
        />
      );
  };

  signUpClicked = () => {
    this.appData
      .container()
      .open(
        <SignUp
          key={this.appData.getUUID()}
          parent={this}
          appData={this.appData}
          options={{ logo: this.desktopLogo }}
        />
      );
  };

  openConfirmationCode = () => {
    this.appData
      .container()
      .open(
        <ConfirmationCode
          key={this.appData.getUUID()}
          parent={this}
          appData={this.appData}
          options={{ logo: this.desktopLogo }}
        />
      );
  };

  openForgotPassword = () => {
    this.appData
      .container()
      .open(
        <ForgotPassword
          key={this.appData.getUUID()}
          parent={this}
          appData={this.appData}
          options={{ logo: this.desktopLogo }}
        />
      );
  };

  openAuthenticationMessages = (params) => {
    this.appData
      .container()
      .open(
        <AuthenticationMessages
          key={this.appData.getUUID()}
          parent={this}
          appData={this.appData}
          params={params}
          options={{ logo: this.desktopLogo }}
        />
      );
  };

  openCreateNewPassword = () => {
    this.appData.dialog.open({
      type: "container",
      content: (
        <CreateNewPassword
          key={this.appData.getUUID()}
          parent={this}
          appData={this.appData}
        />
      ),
    });
  };

  signOutClicked = () => {
    this.appData.accountManager.logOut();
    this.refresh();
  };

  closeModal = () => {
    this.appData.dialog.close();
  };

  // userSignedIn = () => {
  //   this.closeModal();

  //   let sessionObject = this.sessionManager.getAsObject("sessionObject");
  //   //### check this.refresh!!!!!!!!!!!!!!!!!!

  //   console.log("User has been signed :)");
  //   console.log(sessionObject.currentUser);
  // };

  //###########################################################
  //###########################################################
  //###########################################################

  getMenu = (open) => {
    return (
      <Menu
        anchorEl={this.state.anchorEl}
        open={open}
        onClose={this.handleMenuClose}
        onClick={this.handleMenuClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {/* <MenuItem>
                <Avatar /> Profile
              </MenuItem> */}
        {/* <MenuItem
          className="header-button-color menu-item"
          style={{
            display: this.booleanToDisplay(!this.state.flags.submitted)
          }}
          onClick={this.quoteButtonClicked}
        >
          <ListItemIcon style={{ color: "inherit", marginRight: "0px" }}>
            <MonetizationOnIcon fontSize="medium" />
          </ListItemIcon>
          Get Quote
        </MenuItem> */}

        {this.appData.version < 1.1
          ? []
          : [
              <MenuItem disabled className="header-button-color menu-item">
                {this.state.flags.signedIn
                  ? this.appData.getCurrentUser().Email
                  : ""}
              </MenuItem>,
              <Divider />,
              <MenuItem className="header-button-color menu-item">
                <ListItemIcon style={{ color: "inherit", marginRight: "0px" }}>
                  <StarIcon fontSize="medium" />
                </ListItemIcon>
                My Favorites
              </MenuItem>,
              <MenuItem className="header-button-color menu-item">
                <ListItemIcon style={{ color: "inherit", marginRight: "0px" }}>
                  <EmailIcon fontSize="medium" />
                </ListItemIcon>
                My Messages
              </MenuItem>,
            ]}
        {/**** My Quotes ****/}
        <MenuItem
          className="header-button-color menu-item"
          style={{
            display: this.booleanToDisplay(this.state.flags.submitted),
          }}
        >
          <ListItemIcon style={{ color: "inherit", marginRight: "0px" }}>
            <MonetizationOnIcon fontSize="medium" />
          </ListItemIcon>
          My Quotes
        </MenuItem>
        {/**** Divider ****/}
        <Divider />

        {/**** My Account ****/}
        <MenuItem
          className="header-button-color menu-item"
          onClick={() => {
            this.props.navigate("/secure/my-account");
          }}
        >
          <ListItemIcon style={{ color: "inherit", marginRight: "0px" }}>
            <AccountCircleIcon fontSize="medium" />
          </ListItemIcon>
          My Account
        </MenuItem>
        {/**** Divider ****/}
        <Divider />
        {/**** Sign Out ****/}
        <MenuItem
          className="header-button-color menu-item"
          onClick={this.signOutClicked}
        >
          <ListItemIcon style={{ color: "inherit", marginRight: "0px" }}>
            <Logout fontSize="medium" />{" "}
          </ListItemIcon>
          Sign Out
        </MenuItem>

        {this.appData.version < 1.1
          ? []
          : [
              <Divider />,
              <MenuItem
                className="header-button-color menu-item"
                onClick={() => {
                  this.props.navigate("/admin");
                }}
              >
                Admin
              </MenuItem>,
            ]}
      </Menu>
    );
  };

  //###########################################################
  //###########################################################
  //###########################################################

  isSignedIn = () => {
    return this.state.flags.signedIn;
  };

  //###########################################################
  //###########################################################
  //###########################################################

  render() {
    const open = Boolean(this.state.anchorEl);
    return (
      <>
        {/* <MyDialog ref={(el) => (this.appData.dialog = el)} />
        <MySnackbar ref={(el) => (this.mySnackbar = el)} /> */}
        {this.getMenu(open)}
        <div
          className="header-container"
          ref={(el) => {
            this.divContainer = el;
          }}
          style={this.props.style}
        >
          {this.logo()}

          <div className="divider-width-15" />
          {this.state.flags.signedIn === false
            ? this.getButtonsAsNotSignedIn()
            : this.getButtonsAsSignedIn()}
        </div>
      </>
    );
  }
}

//###########################################################
//###########################################################
//###########################################################

// export default withRouter(Header);
// export default Header;

export default function(props) {
  const navigate = useNavigate();
  return <Header {...props} navigate={navigate} />;
}

//###########################################################
//###########################################################
//###########################################################
