import React from "react";
import "./ScreenConsole.css";
import CircularProgress from '@mui/material/CircularProgress';
import FacebookCircularProgress from "./FacebookCircularProgress";

//###########################################################
//###########################################################
//###########################################################

class ScreenConsole extends React.Component {
  //###########################################################
  //###########################################################
  //###########################################################

  constructor(props) {
    super(props);
    // this.timer= null
    // this.sessionManager = new MySessionManager();

    this.state = {
      display: "none",
      message: ""
    };
  }

  //###########################################################
  //###########################################################
  //###########################################################

  show = (callBack) => {
    let tempState = this.state;
    tempState.display = "";
    this.setState(tempState, () => {
      if (callBack !== undefined) {
        callBack();
      }
    });
  };

  hide = () => {
    let tempState = this.state;
    tempState.display = "none";
    this.setState(tempState);
  };

  setMessage = (msg) => {
    let tempState = this.state;
    tempState.display = ""; //### just in scase, show the component
    tempState.message = msg;
    this.setState(tempState);
  };

  isOpen = () => {
    return this.state.display === "";
  };

  //###########################################################
  //###########################################################
  //###########################################################

  componentDidMount() {
    // this.timer = setTimeout(
    //   function () {
    //     //##################
    //     //##################
    //     //##################
    //
    //     let sessionData = this.sessionManager.get("ScreenConsole");
    //     console.log(sessionData)
    //
    //       //##################
    //       //##################
    //       //##################
    //   }.bind(this),500
    // );
  }


    //###########################################################
    //###########################################################
    //###########################################################

    getMessage = ()=>{

      if(this.state.message !== ""){
        return (<div>[ {this.state.message} ]</div>);
      }
      else{
        return (<></>);
      }

    }

  //###########################################################
  //###########################################################
  //###########################################################

  render() {
    return (
      <div
        className="screen-console-container"
        style={{ display: this.state.display }}
      >
      <FacebookCircularProgress />
{this.getMessage()}
      </div>
    );
  }
}

//###########################################################
//###########################################################
//###########################################################

export default ScreenConsole;

//###########################################################
//###########################################################
//###########################################################
