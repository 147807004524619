//### from: https://codesandbox.io/embed/jlmp5kw30w?fontsize=14&codemirror=1

import React from "react";

import TextField from "@mui/material/TextField";

import { styled } from "@mui/system";

//### from: https://stackoverflow.com/questions/67943441/material-ui-show-pointer-cursor-when-hovering-over-textfield
const StyledTextField = styled(TextField)`
  /* label.Mui-focused {
    color: green;
    cursor: pointer;
  }
  .MuiOutlinedInput-input {
    cursor: pointer;
  } */
  .MuiOutlinedInput-root {
    /* fieldset {
      cursor: pointer;
    } */
    &:hover fieldset {
      border-color: #c4c4c4 !important;
    }
    &:hover {
      cursor: inherit;
    }
    /* &.Mui-focused fieldset {
      border-color: green;
      cursor: pointer;
    } */
  }
`;

const InputComponent = ({ inputRef, ...other }) => <div {...other} />;
const OutlinedDiv = ({ children, label }) => {
  return (
    <StyledTextField
      variant="outlined"
      label={label}
      multiline
      InputLabelProps={{ shrink: true }}
      InputProps={{
        inputComponent: InputComponent
      }}
      inputProps={{ children: children }}
      style={{ width: "100%" }}
    />
  );
};
export default OutlinedDiv;
