import React from "react";
import "./ReviewColorCodes.css";

import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import ReviewColorCodes from "./ReviewColorCodes";
import MyDialog from "../commons/MyDialog";

//###########################################################
//###########################################################
//###########################################################

class ReviewColorCodesTooltip extends React.Component {
  //###########################################################
  //###########################################################
  //###########################################################

  constructor(props) {
    super(props);

    //### for forward references
    if (
      this.props.parent !== undefined &&
      this.props.parent.reviewColorCodesTooltip !== undefined
    ) {
      this.props.parent.reviewColorCodesTooltip = this;
    }

    this.parent = this.props.parent;
    this.sessionObject = this.parent.sessionObject;
    this.cookieManager = this.parent.cookieManager;
    this.cookieObject = this.cookieManager.get("cookieObject");
    this.backend = this.parent.backend;

    this.state = { display: this.cookieObject.showColorCodes ? "" : "none" };
  }

  //###########################################################
  //###########################################################
  //###########################################################

  componentDidMount() {}

  //###########################################################
  //###########################################################
  //###########################################################

  onClick = () => {
    this.myDialog.open({
      type: "container",
      content: <ReviewColorCodes container={this.myDialog} parent={this} />,
    });
  };

  //###########################################################
  //###########################################################
  //###########################################################

  close = () => {
    let tempState = this.state;
    tempState.display = "none";
    this.setState(tempState);

      //### create log
      this.backend.CreateLog(
        this.sessionObject.uuid,
        "color-tooltip-closed",
        "",
        (response) => {}
      );


  };

  //###########################################################
  //###########################################################
  //###########################################################

  render() {
    return (
      <>
        <MyDialog ref={(el) => (this.myDialog = el)} />
        <div
          className="review-color-codes-tooltip-container"
          style={{ display: this.state.display }}
        >
          <IconButton
            onClick={this.close}
            style={{
              position: "absolute",
              right: 5,
              top: 5,
              zIndex: 1000,
            }}
          >
            <CloseIcon />
          </IconButton>

          <span className="link" onClick={this.onClick}>
            Meaning of Google reviews' colors?
          </span>
          <div className="flex-row-start" style={{ marginTop: "10px" }}>
            <div className="review-color-codes-legend clinic-card-chip-green" />
            <div className="review-color-codes-legend clinic-card-chip-yellow" />
            <div className="review-color-codes-legend clinic-card-chip-orange" />
            <div
              className="review-color-codes-legend clinic-card-chip-red"
              style={{ marginRight: "0px" }}
            />
          </div>

          <hr
            className="line-10"
            style={{
              marginTop: "15px",
              marginBottom: "5px",
              borderTop: "1px solid #125799",
            }}
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={this.state.haveNoIllnesses}
                onChange={(event) => {
                  let val = event.target.checked;

                  //### set the cookie
                  this.cookieObject.showColorCodes = !val;
                  this.cookieManager.set("cookieObject", this.cookieObject);

                  //### create log
                  this.backend.CreateLog(
                    this.sessionObject.uuid,
                    "color-tooltip-checkbox-clicked",
                    (!val).toString(),
                    (response) => {}
                  );

                }}
              />
            }
            label={
              <div style={{ lineHeight: "1.2", fontSize: "0.9rem" }}>
                <span>Don't show this message again.</span>
              </div>
            }
          />
        </div>
      </>
    );
  }
}

//###########################################################
//###########################################################
//###########################################################

export default ReviewColorCodesTooltip;

//###########################################################
//###########################################################
//###########################################################
