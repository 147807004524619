import * as React from 'react';
import Slider from '@mui/material/Slider';
import { styled } from '@mui/material/styles';

//###########################################################
//###########################################################
//###########################################################

const MySlider = styled(Slider)({
  color: '#484C7A',
  height: 2,
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-thumb': {
    height: 12,
    width: 12,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
  },
});

class FirstGoogleReviewSlider extends React.Component {
  //###########################################################
  //###########################################################
  //###########################################################

  constructor(props) {
    super(props);
    this.state = {};
  }

  //###########################################################
  //###########################################################
  //###########################################################

  componentDidMount() {}

  //###########################################################
  //###########################################################
  //###########################################################

  getFirstGoogleReviewSlider = (yearsAgo) => {
    return (
      <MySlider
        defaultValue={yearsAgo}
        value={yearsAgo}
        step={1}
        valueLabelDisplay="off"
        marks={[
          {
            value: yearsAgo,
            label: (
              <p
                style={{
                  margin: '-8px',
                  textAlign: 'center',
                  lineHeight: '1.1',
                  fontSize: '0.85rem',
                }}
              >
                Since {2022 - yearsAgo}
              </p>
            ),
          },
        ]}
        min={0}
        max={10}
        // track="inverted"
      />
    );
  };

  //###########################################################
  //###########################################################
  //###########################################################

  render() {
    return <div>{this.getFirstGoogleReviewSlider(this.props.yearsAgo)}</div>;
  }
}

//###########################################################
//###########################################################
//###########################################################

export default FirstGoogleReviewSlider;

//###########################################################
//###########################################################
//###########################################################
