import React from "react";
import { Link, useNavigate } from "react-router-dom";

//###########################################################
//###########################################################
//###########################################################

class Components extends React.Component {
  //###########################################################
  //###########################################################
  //###########################################################

  constructor(props) {
    super(props);
    this.state = {};
  }

  //###########################################################
  //###########################################################
  //###########################################################

  componentDidMount() {}

  //###########################################################
  //###########################################################
  //###########################################################

  render() {
    return (
      <div className="flex-column-start-left" style={{ padding: "20px" }}>
        <Link className="link" to="/landing">
          LandingPage
        </Link>
        <div className="divider-height-10"></div>
        <Link className="link" to="/header">
          Header
        </Link>
        <div className="divider-height-10"></div>
        <Link className="link" to="/authentication">
          Authentication
        </Link>
        <div className="divider-height-10"></div>
        <Link className="link" to="/clinic-card">
          Clinic Card
        </Link>
        <div className="divider-height-10"></div>
        <Link className="link" to="/list-of-turkish-hair-transplant-clinics">
          List Clinic Cards
        </Link>
        <div className="divider-height-10"></div>
        <Link className="link" to="/footer">
          Footer
        </Link>
        <div className="divider-height-10"></div>
        <Link className="link" to="/quote">
          Quote
        </Link>
      </div>
    );
  }
}

//###########################################################
//###########################################################
//###########################################################

// export default Header;

//### this is a way to use hook from class component
export default function (props) {
  const navigate = useNavigate();
  return <Components {...props} navigate={navigate} />;
}

//###########################################################
//###########################################################
//###########################################################
