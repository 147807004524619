import React from "react";
import { Link, useNavigate } from "react-router-dom";

import "../bootstrap-studio/bootstrap.scoped.css";
import "../bootstrap-studio/styles.min.css";

import "./Footer.css";

import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import TwitterIcon from "@mui/icons-material/Twitter";
import MySessionManager from "../commons/MySessionManager";

//###########################################################
//###########################################################
//###########################################################

class Footer extends React.Component {
  //###########################################################
  //###########################################################
  //###########################################################

  constructor(props) {
    super(props);

    this.appData = this.props.appData;
    //### I want to make the footer accessible from anywhere via "this.appData.footer"
    this.appData.footer = this;

    let alternateClassName = "";
    if (this.props.alternateClassName !== undefined) {
      alternateClassName = this.props.alternateClassName;
    }
    this.state = {
      alternateClassName: alternateClassName,
    };
  }

  //###########################################################
  //###########################################################
  //###########################################################

  componentDidMount() {}

  //###########################################################
  //###########################################################
  //###########################################################

  instagramClick = () => {
    alert("instagram");
  };

  facebookClick = () => {
    alert("Facebook");
  };

  twitterClick = () => {
    alert("Twitter");
  };

  homeClick = () => {
    this.props.navigate("/");
  };

  termsClick = () => {
    alert("Terms");
  };

  privacyPolicyClick = () => {
    alert("Privacy Policy");
  };

  //###########################################################
  //###########################################################
  //###########################################################

  getHeight = () => {
    return this.divContainer.clientHeight;
  };

  //###########################################################
  //###########################################################
  //###########################################################

  render() {
    return (
      <footer
        className={"footer-basic " + this.state.alternateClassName}
        style={{ width: "100%" }}
        ref={(el) => {
          this.divContainer = el;
        }}
      >
        {/* Start: Social Icons */}
        <div className="social">
          {/* Start: footer-tag */}
          {/* <footer
      style={{ width: "100%" }}
      className={"footer-basic " + this.state.alternateClassName}
    > */}
          {/* End: footer-tag */}
          {/* <a href="#">
            <i className="icon ion-social-instagram" />
          </a>
          <a href="#">
            <i className="icon ion-social-facebook" />
          </a>
          <a href="#">
            <i className="icon ion-social-twitter" />
          </a> */}
          {/* Start: social-icons */}
          <InstagramIcon
            className="social-icon"
            onClick={this.instagramClick}
            style={{
              marginRight: "15px",
            }}
          />
          <FacebookOutlinedIcon
            className="social-icon"
            onClick={this.facebookClick}
            style={{
              marginRight: "15px",
            }}
          />
          <TwitterIcon
            onClick={this.twitterClick}
            className="social-icon"
          />
          {/* End: social-icons */}
        </div>
        {/* End: Social Icons */}
        {/* Start: Links */}
        <ul
          className="list-inline"
          style={{ fontSize: "1.2rem", lineHeight: "1.8rem" }}
        >
          <li className="list-inline-item">
            {/* <a href="#">Home</a> */}
            <div className="footer-link" onClick={this.homeClick}>
              Home
            </div>
          </li>
          <li className="list-inline-item">
            {/* <a href="#">Terms</a> */}
            <div
              className="footer-link"
              onClick={this.termsClick}
            >
              Terms
            </div>
          </li>
          <li className="list-inline-item">
            {/* <a href="#">Privacy Policy</a> */}
            <div
              className="footer-link"
              onClick={this.privacyPolicyClick}
            >
              Privacy Policy
            </div>
          </li>
        </ul>
      </footer>
    );
  }
}

//###########################################################
//###########################################################
//###########################################################

export default function(props) {
  const navigate = useNavigate();
  return <Footer {...props} navigate={navigate} />;
}

//###########################################################
//###########################################################
//###########################################################
