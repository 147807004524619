import React from "react";
import MyDialog from "./MyDialog";
import Button from "@mui/material/Button";

//###########################################################
//###########################################################
//###########################################################

class MyConfirmation extends React.Component {
  //###########################################################
  //###########################################################
  //###########################################################

  constructor(props) {
    super(props);
    this.state = {};
  }

  //###########################################################
  //###########################################################
  //###########################################################

  /* USAGE
  
    this.appData.confirmation().open("Are you sure want to cancel?", {
      type: "YesNo",
      callBack: () => {
        alert("deleted");
      },
    });


  
  */

  open = (message, options) => {

    //### set the confirmation type
    let confirmationType = "YesNo"; // "OKCancel"
    if(options.type !== undefined){
      options.confirmationType = options.type;
    }
    else{
      options.confirmationType = confirmationType;
    }

    //### set the dialog options.
    let dialogOptions = options;
    dialogOptions.type = "confirmation";
    dialogOptions.message = message;

    if(dialogOptions.confirmationType === "YesNo"){
      dialogOptions.leftButton = <Button variant="contained">Yes</Button>;
      dialogOptions.rightButton = <Button>No</Button>;
    }
    else if(dialogOptions.confirmationType === "OKCancel"){
      dialogOptions.leftButton = <Button variant="contained">OK</Button>;
      dialogOptions.rightButton = <Button>Cancel</Button>;
    }

    dialogOptions.callBackLeftButton = dialogOptions.callBack;
    dialogOptions.zIndex= 2000;


    this.myDialog.open(dialogOptions);
  };

  close = () => {
    this.myDialog.close();
  };

  //###########################################################
  //###########################################################
  //###########################################################

  componentDidMount() {}

  //###########################################################
  //###########################################################
  //###########################################################

  render() {
    return <MyDialog ref={(el) => (this.myDialog = el)} />;
  }
}

//###########################################################
//###########################################################
//###########################################################

export default MyConfirmation;

//###########################################################
//###########################################################
//###########################################################
