import React from "react";
import "./Authentication.css";

import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";

import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";
import InputAdornment from "@mui/material/InputAdornment";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";

import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import FormValidation from "./FormValidation";
import HomeIcon from "@mui/icons-material/Home";

import Logo from "../logo/Logo";

// import { withSnackbar } from "notistack";

//###########################################################
//###########################################################
//###########################################################

class CreateNewPassword extends React.Component {
  //###########################################################
  //###########################################################
  //###########################################################

  constructor(props) {
    super(props);

    this.appData = this.props.appData;
    this.parent = this.props.parent;
    this.formValidation = new FormValidation(this.appData);

    this.updatePassword = this.props.updatePassword !== undefined;

    this.state = {
      showPassword: false,
      loading: false,
      btnHomePageLoading: false,
      errors: {
        password: false,
        passwordConfirmation: false,
      },
    };
  }

  //###########################################################
  //###########################################################
  //###########################################################

  handleClickShowPassword = () => {
    let tempState = this.state;
    tempState.showPassword = !tempState.showPassword;
    this.setState(tempState);
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  isFormInputValid = (callBack) => {
    this.formValidation.formInputs(
      {
        password: this.txtPassword.value,
        passwordConfirmation: this.txtPasswordConfirmation.value,
      },
      (validationResult) => {
        let tempState = this.state;

        tempState.errors.password =
          validationResult.password.length > 0 ? true : false;
        tempState.errors.passwordConfirmation =
          validationResult.passwordConfirmation.length > 0 ? true : false;

        if (validationResult.warningText !== "") {
          this.appData.snackbar().alertError(validationResult.warningText);
        } else {
          this.appData.snackbar().close();
        }

        this.setState(tempState);
        callBack(validationResult.warningText === "");
      }
    );
  };

  btnClicked = () => {
    this.appData.snackbar().close();

    this.isFormInputValid((isFormValid) => {
      //#### form is valid
      if (isFormValid) {
        //### set as loading True
        let tempState = this.state;
        tempState.loading = true;
        this.setState(tempState, () => {
          //### set the params
          let params = {
            uuid: this.appData.accountManager.getUser().UUID,
            password: this.txtPassword.value,
          };

          //### make your call to backend
          this.appData.accountManager.updatePassword(params, (response) => {
            if (this.appData.printConsole) console.log(response);
            //### got your response
            //### set as loading False
            let tempState = this.state;
            tempState.loading = false;
            this.setState(tempState, () => {
              //### lets handle the error first
              if (response.Result === "Error") {
                this.appData.snackbar().alertError(response.Message);
              } else if (response.Result === "OK") {
                this.appData
                  .snackbar()
                  .alertSuccess("Your password has been updated.");
                if(this.updatePassword){
                  this.appData.container().close();
                }else{
                  this.appData.accountManager.logOut();
                  this.parent.signInClicked();
                }
              }
            });
          });
        });
      }
    });
  };

  onTextFieldFocus = () => {
    this.appData.snackbar().close();
    let tempState = this.state;
    tempState.errors.email = false;
    tempState.errors.password = false;
    this.setState(tempState);
  };

  //###########################################################
  //###########################################################
  //###########################################################

  componentDidMount() {}

  //###########################################################
  //###########################################################
  //###########################################################

  render() {
    return (
      <>
        <Logo type="desktop" appData={this.appData} />
        <div className="sign-in-container">
          <div
            style={{
              textAlign: "center",
              fontWeight: "bold",
              fontSize: "1.4rem",
              padding: "0px 20px 0px 20px",
              marginBottom: "30px",
            }}
            className="text-color"
          >
            Create a New Password
          </div>
          {/* ########################## */}
          {/* ########################## */}
          {/* ########################## */}
          <TextField
            error={this.state.errors.password}
            inputRef={(el) => (this.txtPassword = el)}
            label="Password"
            variant="outlined"
            style={{ width: "100%", marginBottom: "20px" }}
            type={this.state.showPassword ? "text" : "password"}
            InputProps={{
              style: { fontFamily: "Poppins" },
              startAdornment: (
                <InputAdornment position="start">
                  <LockOutlinedIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={this.handleClickShowPassword}
                    onMouseDown={this.handleMouseDownPassword}
                    edge="end"
                  >
                    {this.state.showPassword ? (
                      <VisibilityOff />
                    ) : (
                      <Visibility />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onFocus={() => {
              this.onTextFieldFocus();
            }}
          />
          {/* ########################## */}
          {/* ########################## */}
          {/* ########################## */}
          <TextField
            error={this.state.errors.passwordConfirmation}
            inputRef={(el) => (this.txtPasswordConfirmation = el)}
            label="Confirm Password"
            variant="outlined"
            style={{ width: "100%", marginBottom: "30px" }}
            helperText="(Minimum  6 characters.)"
            type={this.state.showPassword ? "text" : "password"}
            InputProps={{
              style: { fontFamily: "Poppins" },
              startAdornment: (
                <InputAdornment position="start">
                  <LockOutlinedIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={this.handleClickShowPassword}
                    onMouseDown={this.handleMouseDownPassword}
                    edge="end"
                  >
                    {this.state.showPassword ? (
                      <VisibilityOff />
                    ) : (
                      <Visibility />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onFocus={() => {
              this.onTextFieldFocus();
            }}
          />
          {/* ########################## */}
          {/* ########################## */}
          {/* ########################## */}

          <LoadingButton
            style={{
              width: "100%",
              paddingTop: "15px",
              paddingBottom: "15px",
              fontSize: "1rem",
              marginBottom: "30px",
            }}
            onClick={() => {
              this.btnClicked();
            }}
            loading={this.state.loading}
            loadingIndicator="Please wait..."
            variant="contained"
          >
            Reset Password
          </LoadingButton>

          {this.updatePassword ? (
            <></>
          ) : (
            <div
              className="text-color flex-row-center"
              style={{
                borderTop: "1px solid #CCC",
                textAlign: "center",
                paddingTop: "30px",
              }}
            >
              <LoadingButton
                size="small"
                onClick={() => {
                  let tempState = this.state;
                  tempState.btnHomePageLoading = true;
                  this.setState(tempState, () => {
                    setTimeout(function() {
                      window.location.href = "/";
                    }, 250);
                  });
                }}
                loading={this.state.btnHomePageLoading}
                loadingPosition="start"
                startIcon={<HomeIcon />}
                variant="contained"
              >
                Home Page
              </LoadingButton>
            </div>
          )}
        </div>
      </>
    );
  }
}

//###########################################################
//###########################################################
//###########################################################

// export default withSnackbar(SignIn);
export default CreateNewPassword;

//###########################################################
//###########################################################
//###########################################################
