import React from "react";
import { Link, useNavigate } from "react-router-dom";
import ClinicBootstrapStudio from "./ClinicBootstrapStudio";
import DataFromBackEnd from "../../data/data.json";

//###########################################################
//###########################################################
//###########################################################

class TestPage extends React.Component {
  //###########################################################
  //###########################################################
  //###########################################################

  constructor(props) {
    super(props);
    this.state = {};
  }

  //###########################################################
  //###########################################################
  //###########################################################

  componentDidMount() {}

  //###########################################################
  //###########################################################
  //###########################################################

  render() {
    return (
      <>
        {/* <Link className="link" to="/">
          Home
        </Link> */}
        <ClinicBootstrapStudio parent={this} data={DataFromBackEnd[0]} />
      </>
    );
  }
}

//###########################################################
//###########################################################
//###########################################################

export default function(props) {
  const navigate = useNavigate();
  return <TestPage {...props} navigate={navigate} />;
}

//###########################################################
//###########################################################
//###########################################################
