import React from "react";
import "./GetQuote.css";
import FinalMessage from "./FinalMessage";

// import Backend from "../../classes/Backend";

// import MyConfirmation from "../commons/MyConfirmation";
import MyDialog from "../commons/MyDialog";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step5 from "./Step5";
import Step6 from "./Step6";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Commons from "./Commons";
import Navigator from "./Navigator";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import { isMobile } from "react-device-detect";

//###########################################################
//###########################################################
//###########################################################

// https://www.veraclinic.net/hairform/
// https://www.clinicana.com/en/g/492/
// https://elithair.co.uk/

class GetQuote extends React.Component {
  //###########################################################
  //###########################################################
  //###########################################################

  constructor(props) {
    super(props);

    this.parent = this.props.parent;
    this.appData = this.props.appData;
    this.sessionObject = this.appData.getSessionObject();
    this.cookieObject = this.appData.getCookieObject();

    this.maxWidth = 1000;

    this.styleSectionTitle = { fontWeight: "bold", marginBottom: "15px" };
    this.state = { stepIndex: 1, stepTitle: "" };
  }

  //###########################################################
  //###########################################################
  //###########################################################

  componentDidMount() {
    this.showCurrentStep();

    if (!this.parent.isSignedIn()) {
      // this.parent.authenticationDialog = this.myDialog;
      this.parent.signInClicked();
    }

    console.log(this.appData.getSessionObject());

    if (
      this.appData.getSessionObject().cookieData["GetQuoteStep1"] !== undefined
    ) {
      this.step1.setJSON(
        this.appData.getSessionObject().cookieData["GetQuoteStep1"],
        () => {}
      );
    }
    if (
      this.appData.getSessionObject().cookieData["GetQuoteStep2"] !== undefined
    ) {
      this.step2.setJSON(
        this.appData.getSessionObject().cookieData["GetQuoteStep2"],
        () => {}
      );
    }
    if (
      this.appData.getSessionObject().cookieData["GetQuoteStep3"] !== undefined
    ) {
      this.step3.setJSON(
        this.appData.getSessionObject().cookieData["GetQuoteStep3"],
        () => {}
      );
    }

    this.appData.commonCalls.getCountryCode((countryCode) => {
      this.step4.setIPCountyCode(countryCode);
    });
  }

  //###########################################################
  //###########################################################
  //###########################################################

  setStepIndex = (val) => {
    let tempState = this.state;
    tempState.stepIndex = val;
    this.setState(tempState, () => {
      this.showCurrentStep();
    });
  };

  //###########################################################
  //###########################################################
  //###########################################################

  cancel = () => {
    this.appData.confirmation().open("Are you sure want to cancel?", {
      type: "YesNo",
      callBack: () => {
        this.appData.container().close();
      },
    });

    // this.appData.dialog().open({
    //   type: "confirmation",
    //   message: "Are you sure want to cancel?",
    //   callBackLeftButton: () => {
    //     this.props.container.close();
    //   },
    // });
  };

  //###########################################################
  //###########################################################
  //###########################################################

  header = () => {
    return (
      <div
        className="flex-row-center width-with-padding-20 get-quote-header-container"
        style={{ background: "#FFF" }}
      >
        {/* ################################## */}
        {/* CANCEL BUTTON FOR DESKTOP */}
        {/* ################################## */}
        <span
          className="btn-primary"
          onClick={this.cancel}
          style={{
            position: "absolute",
            left: "15px",
            padding: "3px 10px 3px 10px",
            display: isMobile ? "none" : "block",
          }}
        >
          Cancel
        </span>
        {/* ################################## */}
        {/* CANCEL BUTTON FOR MOBILE */}
        {/* ################################## */}
        <CloseIcon
          className="text-color"
          style={{
            position: "absolute",
            right: "10px",
            top: "10px",
            fontSize: "26px",
            display: isMobile ? "block" : "none",
          }}
          onClick={this.cancel}
        />

        {/* ################# */}
        {/* header */}
        <span className="title-3" style={{ textAlign: "center" }}>
          {"Quote Request Form"} {isMobile ? <br /> : " "}{" "}
          {this.state.stepTitle}
          {/* {this.state.stepIndex === this.state.totalStepIndex
            ? "[Final Step]"
            : "[Step " +
              this.state.stepIndex +
              "/" +
              this.state.totalStepIndex +
              "]"} */}
        </span>
        {/* ################# */}
        <div />
      </div>
    );
  };

  //###########################################################
  //###########################################################
  //###########################################################

  showCurrentStep = () => {
    //### set the total step's index
    let totalStepIndex = 4;
    if (this.step4.isPhoneVerified) totalStepIndex = 3;

    if (this.navigator !== undefined) {
      //### set the Back and Next buttons' text and enable state
      this.navigator.setBackIsEnable(true);
      this.navigator.setNextIsEnable(true);
      this.navigator.setNextTextDefault();

      if (this.state.stepIndex === 1) {
        this.navigator.setBackIsEnable(false);
      } else if (this.state.stepIndex === 5) {
        this.navigator.setNextText("Submit");
        this.navigator.setNextIsEnable(this.step5.state.isUserAggree);
      }

      if (this.state.stepIndex === 4 && this.step4.isPhoneVerified === false) {
        this.navigator.setNextIsEnable(false);
      }

      let tempState = this.state;

      //### set the stepTitle
      if (tempState.stepIndex === 5) {
        tempState.stepTitle = "";
      } else {
        tempState.stepTitle =
          "[Step " + this.state.stepIndex + "/" + totalStepIndex + "]";
      }

      //### hide or show the step 4
      this.setState(tempState);
    }

    if (this.step1 !== undefined) this.step1.hide();
    if (this.step2 !== undefined) this.step2.hide();
    if (this.step3 !== undefined) this.step3.hide();
    if (this.step4 !== undefined) this.step4.hide();
    if (this.step5 !== undefined) this.step5.hide();
    // if (this.step6 !== undefined) this.step6.hide();

    switch (this.state.stepIndex) {
      case 1:
        if (this.step1 !== undefined) this.step1.show();
        break;
      case 2:
        if (this.step2 !== undefined) this.step2.show();
        break;
      case 3:
        if (this.step3 !== undefined) this.step3.show();
        break;
      case 4:
        if (this.step4 !== undefined) this.step4.show();
        break;
      case 5:
        if (this.step5 !== undefined) this.step5.show();
        break;
      // case 6:
      //   if (this.step6 !== undefined) this.step6.show();
      //   break;
      default:
        return <></>;
    }
  };

  //###########################################################
  //###########################################################
  //###########################################################

  afterHeader = () => {
    return (
      <div style={{ width: "100%", height: isMobile ? "60px" : "45px" }} />
    );
  };

  //###########################################################
  //###########################################################
  //###########################################################

  saveStepData = (stepIndex, callBack) => {
    let cookieName = "GetQuoteStep" + stepIndex;

    let targetStep = this.getStepByIndex(stepIndex);
    let jsonObj = targetStep.getJSON();


    if (this.appData.printConsole) console.log(jsonObj);

    //### set the session
    this.sessionObject.cookieData[cookieName] = jsonObj;
    this.appData.setSessionObject(this.sessionObject);

    //### save to backend
    let paramsSetCookieData = {
      clientUUID: this.cookieObject.uuid,
      name: cookieName,
      data: JSON.stringify(jsonObj),
      expiresInDay: 7,
    };

    console.log(paramsSetCookieData);

    //### set the flag as false
    targetStep.savedToDatabase = false;

    this.appData.backend
      .SetCookieData(
        paramsSetCookieData.name,
        paramsSetCookieData.clientUUID,
        paramsSetCookieData.data,
        paramsSetCookieData.expiresInDay
      )
      .then((data) => {
        //### set the flag as true
        targetStep.savedToDatabase = true;
        if (callBack !== undefined) callBack("OK");
      })
      .catch((error) => {
        //### alert('There is an ERROR in SetCookieData. Please check your console for more detail.');
        if (this.appData.printConsole)
          console.log(">>> ERROR in SetCookieData >>> " + error);
        if (callBack !== undefined) callBack("Error");
      });
  };

  //###########################################################
  //###########################################################
  //###########################################################

  moveTo = (direction) => {
    let currentStepIndex = this.state.stepIndex;

    //### step 1 - save the step's data
    if (currentStepIndex <= 2) {
      this.saveStepData(currentStepIndex);
    }

    //### step 2 - scroll to top
    this.appData.container().scrollTop();

    //### step 3 - validation
    let currentStep = this.getCurrentStep();
    let alwaysReturnTrue = false;
    let validationResult = currentStep.validate(alwaysReturnTrue);
    //### check validation if the user try to move forward
    if (direction === "next" && !validationResult.result) {
      this.appData.alert().open(validationResult.message);
    }
    //### is valid
    else {
      //### this is the final step and time to submit!
      if (currentStepIndex === 5 && direction === "next") {
        this.submitTheForm();
      }
      //### set the state's step index and render the step
      else {
        //### handle if the user's phone has been verified
        if (this.step4.isPhoneVerified) {
          if (
            (currentStepIndex === 3 && direction === "next") ||
            (currentStepIndex === 5 && direction === "back")
          ) {
            currentStepIndex = 4;
          }
        }

        //### set the current Step Index
        if (direction === "next") {
          currentStepIndex++;
        } else if (direction === "back") {
          currentStepIndex--;
        }

        let tempState = this.state;
        tempState.stepIndex = currentStepIndex;
        this.setState(tempState, () => {
          this.showCurrentStep();
        });
      }
    }
  };

  //###########################################################
  //###########################################################
  //###########################################################

  getStepByIndex = (index) => {
    switch (index) {
      case 1:
        return this.step1;
      case 2:
        return this.step2;
      case 3:
        return this.step3;
      case 4:
        return this.step4;
      case 5:
        return this.step5;
      default:
        return null;
    }
  };

  getCurrentStep = () => {
    return this.getStepByIndex(this.state.stepIndex);
  };

  //###########################################################
  //###########################################################
  //###########################################################

  submitTheForm = () => {
    this.appData.screenConsole().open({ progressBar: true });

    this.submitStep(1, (response) => {
      if (response === "Error") {
        this.exceptionDuringSubmission();
      } else {
        this.appData.screenConsole().set({ progress: 25 });
        this.submitStep(2, (response) => {
          if (response === "Error") {
            this.exceptionDuringSubmission();
          } else {
            this.appData.screenConsole().set({ progress: 50 });
            this.submitStep(3, (response) => {
              if (response === "Error") {
                this.exceptionDuringSubmission();
              } else {
                this.appData.screenConsole().set({ progress: 75 });
                let paramsCreateNewSubmission = {
                  userID: this.appData.getCurrentUser().UUID,
                  clientUUID: this.appData.getCookieObject().uuid,
                  sessionID: this.appData.getSessionObject().uuid,
                };

                this.appData.instantBackend
                  .performWithPromise(
                    "CreateNewSubmission",
                    paramsCreateNewSubmission
                  )
                  .then((data) => {
                    this.appData.screenConsole().set({ progress: 100 });

                    setTimeout(() => {
                      this.appData.screenConsole().close();
                      this.appData.container().close();
                      this.appData.header.refresh();

                      this.appData
                        .container()
                        .open(<FinalMessage appData={this.appData} />);
                    }, 300);
                  })
                  .catch((error) => {
                    //### alert('There is an ERROR in CreateNewSubmission. Please check your console for more detail.');
                    console.log(
                      ">>> ERROR in CreateNewSubmission >>> " + error
                    );
                  });
              }
            });
          }
        });
      }
    });
  };

  submitStep = (stepIndex, callBack) => {
    let targetStep = this.getStepByIndex(stepIndex);

    if (targetStep.savedToDatabase) callBack("OK");
    else {
      this.saveStepData(stepIndex, callBack);
    }
  };

  exceptionDuringSubmission = () => {
    alert("There is an error during form submission, please try again later.");
  };

  //###########################################################
  //###########################################################
  //###########################################################

  render() {
    return (
      <>
        {/* <MyDialog ref={(el) => (this.myDialog = el)} /> */}
        {/* #################### */}
        <div
          className="flex-column-space-between"
          style={{
            width: "100%",
            minHeight: window.innerHeight + "px",
            maxWidth: this.maxWidth,
            // background: "#FF0"
          }}
        >
          <div>
            {this.header()}
            {this.afterHeader()}
            <div className="divider-height-20" />
            <>
              <Step1
                ref={(el) => (this.step1 = el)}
                parent={this}
                appData={this.appData}
              />
              <Step2
                ref={(el) => (this.step2 = el)}
                parent={this}
                appData={this.appData}
              />
              <Step3
                ref={(el) => (this.step3 = el)}
                parent={this}
                appData={this.appData}
              />
              <Step4
                ref={(el) => (this.step4 = el)}
                parent={this}
                appData={this.appData}
              />
              <Step5
                ref={(el) => (this.step5 = el)}
                parent={this}
                appData={this.appData}
              />
              {/* <Step6 ref={(el) => (this.step6 = el)} parent={this} /> */}
            </>
          </div>
          <div>
            <Navigator
              ref={(el) => (this.navigator = el)}
              onBack={() => {
                this.moveTo("back");

                // //#############################
                // //#############################
                // //#############################

                // if (this.state.stepIndex <= 2) {
                //   this.saveStepData(this.state.stepIndex);
                // }

                // //#############################
                // //#############################
                // //#############################

                // this.appData.container().scrollTop();

                // //#############################
                // //#############################
                // //#############################
                // let tempState = this.state;

                // if(tempState.stepIndex === 5 && this.step4.isPhoneVerified){
                //   tempState.stepIndex = 4;
                // }

                // if (tempState.stepIndex > 1) {
                //   tempState.stepIndex--;
                // }

                // this.setState(tempState, () => {
                //   this.showCurrentStep();
                // });
              }}
              onNext={() => {
                this.moveTo("next");

                //   //#############################
                //   //#############################
                //   //#############################

                //   let currentStep = this.getCurrentStep();

                //   //#############################
                //   //#############################
                //   //#############################

                //   if (currentStep.stepIndex <= 2) {
                //     this.saveStepData(currentStep.stepIndex);
                //   }

                //   //#############################
                //   //#############################
                //   //#############################

                //   this.appData.container().scrollTop();

                //   //#############################
                //   //#############################
                //   //#############################
                //   //### validation
                //   let validationResult = currentStep.validate();

                //   //### check the validation result
                //   if (validationResult.result === false) {
                //     this.appData.dialog().open({
                //       type: "alert",
                //       title: " ",
                //       maxWidth: 500,
                //       button: (
                //         <Button
                //           variant="contained"
                //           style={{ marginBottom: "10px", marginRight: "15px" }}
                //         >
                //           OK
                //         </Button>
                //       ),
                //       message: validationResult.message,
                //       callBackButton: () => {
                //         return false;
                //       },
                //     });
                //   }
                //   //### the step is valid
                //   else {
                //     let tempState = this.state;

                //     if(currentStep.stepIndex === 3 && this.step4.isPhoneVerified){
                //       tempState.stepIndex = 4;
                //     }

                //     if (tempState.stepIndex < 5) {
                //       tempState.stepIndex++;

                //       this.setState(tempState, () => {
                //         this.showCurrentStep();
                //       });
                //     } else if (tempState.stepIndex === 5) {
                //       //########
                //       //########
                //       //########

                //       this.step5.submitThePatient(() => {
                //         this.appData.dialog().open({
                //           type: "alert",
                //           title: "Thank you",
                //           message: "Thank you for your submission.",
                //           callBackButton: () => {
                //             this.props.container.close();
                //           },
                //         });
                //       });

                //       //########
                //       //########
                //       //########
                //     }
                //   }
              }}
            />
            <div className="divider-height-5" />
          </div>
        </div>
      </>
    );
  }
}

//###########################################################
//###########################################################
//###########################################################

export default GetQuote;

//###########################################################
//###########################################################
//###########################################################
