import { chainPropTypes } from "@mui/utils";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

//###########################################################
//###########################################################
//###########################################################

class DataTableRowClinicAnnotation extends React.Component {
  //###########################################################
  //###########################################################
  //###########################################################

  constructor(props) {
    super(props);

    this.dataTableControl = this.props.parent;

    //### for forward references
    if (
      this.props.parent !== undefined &&
      this.props.parent.dataTableRowClinic !== undefined
    ) {
      this.props.parent.dataTableRowClinic = this;
    }

    this.state = {};
  }

  //###########################################################
  //###########################################################
  //###########################################################

  componentDidMount() {}

  //###########################################################
  //###########################################################
  //###########################################################

  render() {
    return (
      <div
        className="flex-row-space-between width-with-padding-10"
        style={{
          cursor: "pointer",
          border: "1px solid #CCCCCC",
          borderRadius: "5px",
          marginBottom: "15px",
        }}
        onClick={() => {
          this.props.parent.props.parent.onCurrentRowChanged(this.props.data);
        }}
      >
        <div className="flex-row-start">
          <div>{this.props.data.ClinicURL}</div>
          <div className="divider-width-15" />
          <div>{this.props.data.UpdateDate}</div>
          <div className="divider-width-15" />
          <div>
            [
            {this.props.data.CommentEnums.includes("*Main-Branch*") ? "1" : "0"}
            ]
          </div>
          <div className="divider-width-15" />
          <div>[img: {this.props.data.GaleryCount}]</div>
          <div className="divider-width-15" />
          <div>
            {this.props.data.CorrectedURL === "" ? (
              <></>
            ) : (
              <span style={{ color: "#F00" }}>*</span>
            )}
          </div>
        </div>
      </div>
    );
  }
}

//###########################################################
//###########################################################
//###########################################################

class DataTableRowGoogleSearchResult extends React.Component {
  //###########################################################
  //###########################################################
  //###########################################################

  constructor(props) {
    super(props);

    this.dataTableControl = this.props.parent;

    //### for forward references
    if (
      this.props.parent !== undefined &&
      this.props.parent.dataTableRowGoogleSearchResult !== undefined
    ) {
      this.props.parent.dataTableRowGoogleSearchResult = this;
    }

    this.adminControl = this.props.parent.props.parent;

    this.state = { candidate: "maybe" };
  }

  //###########################################################
  //###########################################################
  //###########################################################

  componentDidMount() {
    let tempState = this.state;
    tempState.candidate = this.props.data.Candidate;
    this.setState(tempState);
  }

  //###########################################################
  //###########################################################
  //###########################################################

  render() {
    return (
      <div
        className="flex-row-space-between width-with-padding-10"
        style={{
          cursor: "pointer",
          border: "1px solid #CCCCCC",
          borderRadius: "5px",
          marginBottom: "15px",
        }}
        onClick={() => {
          //   this.props.parent.props.parent.onCurrentRowChanged(this.props.data);
        }}
      >
        <div className="flex-row-start">
          <div
            className="link"
            onClick={() => {
              window.open(
                "http://" + this.props.data.ClinicURL,
                "_blank",
                "noopener,noreferrer"
              );
            }}
          >
            {this.props.data.ClinicURL}
          </div>

          <div className="divider-width-15" />
          {this.props.data.GoogleBusinessName}
          <div className="divider-width-15" />
          {this.props.data.GoogleCount}
          <div className="divider-width-15" />
          {this.props.data.UpdateDate}
          <div className="divider-width-15" />
        </div>
        <div className="flex-row-start">
          <FormControl size="small" style={{ width: "100px" }}>
            <InputLabel>Candidate</InputLabel>
            <Select
              value={
                ["maybe", "yes", "no", "check again"].includes(
                  this.state.candidate
                )
                  ? this.state.candidate
                  : "maybe"
              }
              label="Candidate"
              onChange={(event) => {
                let tempState = this.state;
                tempState.candidate = event.target.value;
                this.setState(tempState);
              }}
              style={{
                background:
                  this.state.candidate !== "maybe"
                    ? this.adminControl.colors.green
                    : this.adminControl.colors.red,
              }}
            >
              <MenuItem value="maybe">Maybe</MenuItem>
              <MenuItem value="yes">Yes</MenuItem>
              <MenuItem value="no">No</MenuItem>
              <MenuItem value="check again">Check Again</MenuItem>
            </Select>
          </FormControl>
          <div className="divider-width-15" />
          <div
            className="btn-primary"
            style={{ paddingTop: "5px", paddingBottom: "5px" }}
            onClick={() => {
              this.adminControl.backend.UpdateCandidateByClinicURL(
                this.props.data.ClinicURL,
                this.state.candidate,
                () => {
                  this.dataTableControl.adminControl.testPage.header.mySnackbar.alertSuccess(
                    this.props.data.ClinicURL + " done."
                  );
                  console.log(">>> " + this.props.data.ClinicURL + " done.");
                }
              );
            }}
          >
            Save
          </div>
        </div>
      </div>
    );
  }
}

//###########################################################
//###########################################################
//###########################################################

export default function(props) {
  const navigate = useNavigate();

  if (
    props.type === "clinic-annotation" ||
    props.type === "clinic-annotation-ready-ones" ||
    props.type === "clinic-annotation-has-no-image"
  ) {
    return <DataTableRowClinicAnnotation {...props} navigate={navigate} />;
  } else if (props.type === "candidate-selection") {
    return <DataTableRowGoogleSearchResult {...props} navigate={navigate} />;
  }
}

//###########################################################
//###########################################################
//###########################################################
