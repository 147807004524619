import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Footer from "./Footer";

//###########################################################
//###########################################################
//###########################################################

class TestPage extends React.Component {
  //###########################################################
  //###########################################################
  //###########################################################

  constructor(props) {
    super(props);
    this.state = {};
  }

  //###########################################################
  //###########################################################
  //###########################################################

  componentDidMount() {}

  //###########################################################
  //###########################################################
  //###########################################################

  render() {
    return (
      <div className="flex-column-start" style={{ paddingTop: "30px" }}>
        <Link className="link" to="/">
          Home
        </Link>
        <hr className="line-25" />
        <Footer parent={this} alternateClassName="footer-blue" />
      </div>
    );
  }
}

//###########################################################
//###########################################################
//###########################################################

export default function (props) {
  const navigate = useNavigate();
  return <TestPage {...props} navigate={navigate} />;
}

//###########################################################
//###########################################################
//###########################################################
