import React from "react";
import { Link, useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Backend from "../../classes/Backend";

//###########################################################
//###########################################################
//###########################################################

class Google extends React.Component {
  //###########################################################
  //###########################################################
  //###########################################################

  constructor(props) {
    super(props);

    this.backend = new Backend();
    //### the parent is the admin control
    this.parent = this.props.parent;
    this.testPage = this.parent.testPage;
    this.header = this.parent.testPage.header;

    //### for forward references
    if (this.parent !== undefined && this.parent.google !== undefined) {
      this.parent.google = this;
    }

    this.loading = {
      DirectLink: "loading...",
      DirectLinkMobile: "loading...",
      BusinessName: "loading...",
      Address: "loading...",
      Lat: "loading...",
      Lang: "loading...",
      Count: "loading...",
      Star: "loading...",
      LastReview: "loading...",
      UpdateDate: "loading...",
      FirstReview: "loading...",
      FirstReviewerID: "loading...",
      FirstReviewDataReviewID: "loading...",
      FirstReviewUpdateDate: "loading...",
      Last10Avg: "loading...",
      PlaceURL: "loading...",
    };

    this.emptyFields = {
      DirectLink: "",
      DirectLinkMobile: "",
      BusinessName: "",
      Address: "",
      Lat: "",
      Lang: "",
      Count: "",
      Star: "",
      LastReview: "",
      UpdateDate: "",
      FirstReview: "",
      FirstReviewerID: "",
      FirstReviewDataReviewID: "",
      FirstReviewUpdateDate: "",
      Last10Avg: "",
      PlaceURL: "",
    };

    this.state = {
      formFields: this.emptyFields,
      currentRow: null,
    };
  }

  //###########################################################
  //###########################################################
  //###########################################################

  componentDidMount() {
    this.props.onGoogleCurrentRowChanged.current = this.onCurrentRowChanged;
  }

  //###########################################################
  //###########################################################
  //###########################################################

  onCurrentRowChanged = (row) => {
    let tempState = this.state;
    tempState.currentRow = row;
    tempState.formFields = this.loading;
    this.setState(tempState, () => {
      this.backend.GetGoogleByUUID(row.UUID, (response) => {
        if (response !== null) {
          tempState.formFields = response;
          this.setState(tempState);
        } else {
          let tempState = this.state;
          tempState.formFields = this.emptyFields;
          this.setState(tempState);
        }
      });
    });
  };

  //###########################################################
  //###########################################################
  //###########################################################

  getTextField = (options) => {
    let readonly = false;
    if (options.readonly !== undefined) {
      readonly = options.readonly;
    }

    let multiline = false;
    if (options.multiline !== undefined) {
      multiline = options.multiline;
    }

    let rows = 2;
    if (options.rows !== undefined) {
      rows = options.rows;
    }

    return (
      <div style={{ position: "relative", width: "100%" }}>
        <TextField
          fullWidth
          multiline={multiline ? true : false}
          rows={rows}
          label={options.label}
          value={this.parent.commonCalls.nullToString(
            this.state.formFields[options.formField]
          )}
          style={{
            background: readonly
              ? this.parent.colors.blue
              : this.parent.commonCalls.nullToString(
                  this.state.formFields[options.formField]
                ) === this.emptyFields[options.formField]
              ? this.parent.colors.red
              : this.parent.colors.green,
          }}
          onChange={(event) => {
            if (!readonly) {
              let tempState = this.state;
              tempState.formFields[options.formField] = event.target.value;
              this.setState(tempState);
            }
          }}
        />

        {/* button(s) container */}
        <div
          className="flex-row-end"
          style={{
            display: options.button_1 !== undefined ? "" : "none",
            position: "absolute",
            right: "-3px",
            bottom: "0px",
          }}
        >
        <div
          className="btn-primary btn-primary-small"
          style={{
            display: options.button_3 !== undefined ? "" : "none",
            minWidth: "50px",
          }}
          onClick={
            options.button_3 !== undefined
              ? options.button_3.onClick
              : () => {}
          }
        >
          {options.button_3 !== undefined ? options.button_3.label : ""}
        </div>

          <div
            className="btn-primary btn-primary-small"
            style={{
              display: options.button_2 !== undefined ? "" : "none",
              minWidth: "50px",
            }}
            onClick={
              options.button_2 !== undefined
                ? options.button_2.onClick
                : () => {}
            }
          >
            {options.button_2 !== undefined ? options.button_2.label : ""}
          </div>

          <div
            className="btn-primary btn-primary-small"
            style={{
              display: options.button_1 !== undefined ? "" : "none",
              minWidth: "50px",
            }}
            onClick={
              options.button_1 !== undefined
                ? options.button_1.onClick
                : () => {}
            }
          >
            {options.button_1 !== undefined ? options.button_1.label : ""}
          </div>
        </div>
      </div>
    );
  };

  //###########################################################
  //###########################################################
  //#########################################################

  render() {
    return (
      <div>
        {this.getTextField({
          label: "Business Name",
          formField: "BusinessName",
          button_1: {
            label: "Copy",
            onClick: () => {
              navigator.clipboard.writeText(this.state.formFields.BusinessName);
              this.header.mySnackbar.alertSuccess("copied");
            },
          },
        })}
        {/* ############################ */}
        <div className="divider-height-15" />
        {this.getTextField({
          label: "Direct Link",
          formField: "DirectLink",
          button_1: {
            label: "Go",
            onClick: () => {
              window.open(this.state.formFields.DirectLink, "_blank");
            },
          },
          button_2: {
            label: "Get",
            onClick: () => {
              window.open("https://supple.com.au/tools/google-review-link-generator/", "_blank");
            },
          },
          button_3: {
            label: "Copy",
            onClick: () => {
              navigator.clipboard.writeText(this.state.formFields.DirectLink);
              this.header.mySnackbar.alertSuccess("copied");
            },
          },
        })}
        {/* ############################ */}
        <div className="divider-height-15" />
        {this.getTextField({
          label: "Direct Link Mobile",
          formField: "DirectLinkMobile",
          button_1: {
            label: "Go",
            onClick: () => {
              window.open(this.state.formFields.DirectLinkMobile, "_blank");
            },
          }
        })}
        {/* ############################ */}
        <div className="divider-height-15" />
        <div className="flex-row-start">
          <div className="flex-row-start" style={{ width: "50%" }}>
            {this.getTextField({
              label: "Count",
              formField: "Count",
              readonly: true,
            })}
          </div>

          <div className="divider-width-15" />
          <div className="flex-row-start" style={{ width: "50%" }}>
            <div className="flex-row-start" style={{ width: "50%" }}>
              {this.getTextField({
                label: "Star",
                formField: "Star",
                readonly: true,
              })}
            </div>
            <div className="divider-width-15" />
            <div className="flex-row-start" style={{ width: "50%" }}>
              {this.getTextField({
                label: "Last10Avg",
                formField: "Last10Avg",
                readonly: true,
              })}
            </div>
          </div>
        </div>

        {/* ############################ */}
        <div className="divider-height-15" />
        {this.getTextField({ label: "Address", formField: "Address" })}
        {/* ############################ */}
        <div className="divider-height-15" />
        <div className="flex-row-start">
          <div className="flex-row-start" style={{ width: "50%" }}>
            {this.getTextField({
              label: "Lat",
              formField: "Lat",
              readonly: true,
            })}
          </div>
          <div className="divider-width-15" />
          <div className="flex-row-start" style={{ width: "50%" }}>
            {this.getTextField({
              label: "Lang",
              formField: "Lang",
              readonly: true,
            })}
          </div>
        </div>

        {/* ############################ */}
        <div className="divider-height-15" />
        <div className="flex-row-start">
          <div className="flex-row-start" style={{ width: "50%" }}>
            {this.getTextField({
              label: "LastReview",
              formField: "LastReview",
              readonly: true,
            })}
          </div>
          <div className="divider-width-15" />
          <div className="flex-row-start" style={{ width: "50%" }}>
            {this.getTextField({
              label: "FirstReview",
              formField: "FirstReview"
            })}
          </div>
        </div>
        {/* ############################ */}
        <div className="divider-height-15" />
        <div className="flex-row-start">
          <div className="flex-row-start" style={{ width: "50%" }}>
            {this.getTextField({
              label: "FirstReviewerID",
              formField: "FirstReviewerID",
              button_1: {
                label: "Go",
                onClick: () => {
                  window.open("https://www.google.com/maps/contrib/" + this.state.formFields.FirstReviewerID + "/reviews", "_blank");
                },
              },
            })}
          </div>
          <div className="divider-width-15" />
          <div className="flex-row-start" style={{ width: "50%" }}>
            {this.getTextField({
              label: "FirstReviewDataReviewID",
              formField: "FirstReviewDataReviewID",
            })}
          </div>
        </div>
        {/* ############################ */}
        <div className="divider-height-15" />
        {this.getTextField({
          label: "Place URL",
          formField: "PlaceURL",
          button_1: {
            label: "Go",
            onClick: () => {
              window.open(this.state.formFields.PlaceURL, "_blank");
            },
          },
        })}
        {/* ############################ */}
        <div className="divider-height-15" />
        <div className="flex-row-start">
          <div className="flex-row-start" style={{ width: "50%" }}>
            {this.getTextField({
              label: "UpdateDate",
              formField: "UpdateDate",
              readonly: true,
            })}
          </div>
          <div className="divider-width-15" />
          <div className="flex-row-start" style={{ width: "50%" }}>
            {this.getTextField({
              label: "FirstReviewUpdateDate",
              formField: "FirstReviewUpdateDate",
              readonly: true,
            })}
          </div>
        </div>
        {/* ############################ */}
        <div className="divider-height-15" />
        <div className="flex-row-end">
          <div
            className="btn-primary"
            onClick={() => {
              if (
                this.state.formFields.DirectLink !==
                  this.emptyFields.DirectLink &&
                this.state.formFields.DirectLink.indexOf(
                  this.state.currentRow.DataID
                ) < 0
              ) {
                alert("Google Direct Link does not contain Data ID");
                return false;
              }

              this.backend.UpdateGoogleViaAdminForm(
                this.state.currentRow.UUID,
                this.state.formFields.DirectLink,
                this.state.formFields.DirectLinkMobile,
                this.state.formFields.BusinessName,
                this.state.formFields.Address,
                this.state.formFields.FirstReview,
                this.state.formFields.FirstReviewerID,
                this.state.formFields.FirstReviewDataReviewID,
                this.state.formFields.PlaceURL,
                () => {
                  this.header.mySnackbar.alertSuccess(
                    this.state.currentRow.ClinicURL + " google saved."
                  );
                }
              );
            }}
          >
            Save - {this.state.currentRow !== null?this.state.currentRow.ClinicURL:""}
          </div>
        </div>
      </div>
    );
  }
}

//###########################################################
//###########################################################
//###########################################################

export default function(props) {
  const navigate = useNavigate();
  return <Google {...props} navigate={navigate} />;
}

//###########################################################
//###########################################################
//###########################################################



