import { StrictMode } from "react";
import { BrowserRouter } from "react-router-dom";
import ReactDOM from "react-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import App from "./App";

import { SnackbarProvider } from "notistack";

const rootElement = document.getElementById("root");

//### source: https://mui.com/customization/breakpoints/
const theme = createTheme({
  typography: {
    allVariants: {
      fontFamily: "inherit",
      color: "#484c7a",
      // color: "#F00"
    },
    button: {
      textTransform: "none",
      fontSize: "1rem",
      lineHeight: "1.5",
    },
  },

  palette: {
    primary: {
      main: "#1360ef",
      dark: "#1565c0",
    },
    // bootstrapDefault: {
    //   main: "#f00",
    //   dark: "#0f0",
    //   contrastText: "#fff"
    // },
    // secondary: {
    //   main: "#F00",
    //   dark: "#FFF",
    //   light: "#FFF",
    //   contrastText: "#fff"
    // }
  },

  breakpoints: {
    // Define custom breakpoint values.
    // These will apply to Material-UI components that use responsive
    // breakpoints, such as `Grid` and `Hidden`. You can also use the
    // theme breakpoint functions `up`, `down`, and `between` to create
    // media queries for these breakpoints
    values: {
      xs: 0,
      sm: 0,
      md: 560,
      lg: 840,
      xl: 1120,

      // https://react.school/material-ui/grid
      // xs, extra-small: 0px
      // sm, small: 600px
      // md, medium: 960px
      // lg, large: 1280px
      // xl, extra-large: 1920px
    },
  },
});

ReactDOM.render(
  <StrictMode>
    <ThemeProvider theme={theme}>
      {/* source: https://iamhosseindhv.com/notistack/api */}
      <SnackbarProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </SnackbarProvider>
    </ThemeProvider>
  </StrictMode>,
  rootElement
);
