import React from "react";
import { Link, useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Backend from "../../classes/Backend";

import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";

//###########################################################
//###########################################################
//###########################################################

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

//###########################################################
//###########################################################
//###########################################################

class GeneralInfo extends React.Component {
  //###########################################################
  //###########################################################
  //###########################################################

  constructor(props) {
    super(props);

    this.backend = new Backend();
    //### the parent is the admin control
    this.parent = this.props.parent;
    this.testPage = this.parent.testPage;
    this.header = this.parent.testPage.header;

    //### for forward references
    if (this.parent !== undefined && this.parent.generalInfo !== undefined) {
      this.parent.generalInfo = this;
    }

    this.loading = {
      UUID: "loading...",
      DataID: "loading...",
      ClinicURL: "loading...",
      CorrectedURL: "loading...",
      Name: "loading...",
      About: "loading...",
      Email: "loading...",
      Phone: "loading...",
      WhatsApp: "loading...",
      Ready: "loading...",
      Deleted: "loading...",
      CommentFreeText: "loading...",
      CommentEnums: [],
      MainBranch: "loading...",
      BeforeAfterCount: "loading...",
      UpdateDate: "loading...",
      Methods: [],
    };

    this.emptyFields = {
      UUID: "",
      DataID: "",
      ClinicURL: "",
      CorrectedURL: "",
      Name: "",
      About: "",
      Email: "",
      Phone: "",
      WhatsApp: "",
      Ready: "",
      Deleted: "",
      CommentFreeText: "",
      CommentEnums: [],
      MainBranch: "",
      BeforeAfterCount: "",
      UpdateDate: "",
      Methods: [],
    };

    this.methods = [
      "FUE Hair Transplant", //https://www.aekhairclinic.com/fue-hair-transplant-in-turkey/ *** https://internationalplus.net/hair-transplant/
      "Sapphire FUE Hair Transplant", // https://internationalplus.net/hair-transplant/
      "DHI Hair Transplant", // https://internationalplus.net/hair-transplant/
      "DHI Pro Hair Transplant", // https://www.clinicexpert.com/eng/hair-transplant/dhi-pro-hair-transplant/
      "DHI Plus Gold Hair Transplant", // https://en.estethica.com.tr/hair-transplant
      "Manual Hair Transplant",
      "Mustache Transplant",
      "Beard Transplant", // https://www.aekhairclinic.com/beard-transplant-in-turkey/
      "Eyebrow Transplant", // https://www.aekhairclinic.com/eyebrow-transplant/
      "Soft FUE Hair Transplantation",
      "FUT Hair Transplant", //https://www.aekhairclinic.com/fut-hair-transplant-in-turkey/
      "FUT+FUE Hair Transplant", // https://www.aekhairclinic.com/futfue-hair-transplant/
      "Biofibre Hair Transplant", // https://www.yetkinbayer.com/en/bio-fue-hair-transplant.html
      "Bio-FUE Hair Transplant",
      "Micro FUE Hair Transplant",
      "Robotic Hair Transplant",
      "Organic Hair Transplant",
      "Hair Transplants Using Stem Cells",
      "Unshaven Hair Transplant", // https://www.aekhairclinic.com/unshaven-hair-transplant/
      "Fi Hair Transplant",
      "Fio Hair Transplant", // https://nowhairtime.com/fio-hair-transplant-in-turkey,
      "Women Hair Transplant",
      "Afro Hair Transplant",
      "Eyelash Transplant",
      "Long Hair Transplant", // https://www.aekhairclinic.com/long-hair-transplant/
      "Body Hair Transplant", // https://www.aekhairclinic.com/body-hair-transplant-bht/
      "Hair Transplant Repair", //https://www.aekhairclinic.com/hair-transplant-repair/
      "Diamond FUE Hair Transplant", // https://www.aklpoliklinik.com/sac-ekimi/fue-sac-ekimi-2/#diamond-fue-sac-ekimi
      "Pain Free Hair Transplant", // >>> painless hair transplant >>> needle-free hair transplant // https://www.drserkanaygin.com/hair-transplant/without-pain/
      "TDF Hair Transplant", // https://www.esteworldturkey.com/tdf-hair-transplant
      "Hair Transplant With Sedation", // https://www.esteworldturkey.com/hair-transplant-with-sedation
      "Mega FUE Hair Transplant", // https://www.turkeyanaclinic.com/en/hair-transplantation-for-men/
      "Ultimate FUE Hair Transplant", // // https://www.turkeyanaclinic.com/en/hair-transplantation-for-men/
      "Mega DHI Hair Transplant", // // https://www.turkeyanaclinic.com/en/hair-transplantation-for-men/
      "Extremely DHI Hair Transplant", // // https://www.turkeyanaclinic.com/en/hair-transplantation-for-men/
      "Icegraft Hair Transplant", // https://www.emphair.com/icegraft-hair-transplantation/
      "Fue Gold Hair Transplant", // https://www.emphair.com/fue-gold-hair-transplantation/
      "Perkutan Hair Transplant", // https://www.clinicana.com/hair-transplant/perkutan/
      "Nano FUE Hair Transplant", //https://www.infinityhairtransplant.com/en/cost-turkey
      "FAT Hair Transplantation", // https://www.azizaksoz.com/tedaviler/fat-hair-transplantion/?lang=en
      "Sapphire DHI Hair Transplant", // https://elithair.co.uk/hair-transplant/
    ];
    this.methods.sort();

    this.comments = [
      "*Deleted*",
      "*Ready*",
      "*Main-Branch*",
      "*Not-Main-Branch*",
      "*Check-Later*",
      "*Not-Good-For-Listing*",
      "*Good-For-Price-Request*",
      "*Has-No-About*",
      "*Very-Good*",
      "*About-By-Google-Translate*"
    ];

    this.state = {
      formFields: this.emptyFields,
      currentRow: null,
    };
  }

  //###########################################################
  //###########################################################
  //###########################################################

  componentDidMount() {
    this.props.onGeneralInfoCurrentRowChanged.current = this.onCurrentRowChanged;
  }
  //###########################################################
  //###########################################################
  //###########################################################

  onCurrentRowChanged = (row) => {
    let tempState = this.state;
    tempState.formFields = this.loading;
    tempState.currentRow = row;
    this.setState(tempState, () => {
      this.backend.GetClinicByUUID(row.UUID, (response) => {
        if (response !== null) {
          tempState.formFields = response;

          //### Methods need to be an array, so I need to check here
          if (
            tempState.formFields.Methods === null ||
            tempState.formFields.Methods === ""
          ) {
            tempState.formFields.Methods = [];
          } else {
            tempState.formFields.Methods = tempState.formFields.Methods.split(
              ","
            );
          }

          //### CommentEnums need to be an array, so I need to check here
          if (
            tempState.formFields.CommentEnums === null ||
            tempState.formFields.CommentEnums === ""
          ) {
            tempState.formFields.CommentEnums = [];
          } else {
            tempState.formFields.CommentEnums = tempState.formFields.CommentEnums.split(
              ","
            );
          }

          this.setState(tempState);
        } else {
          let tempState = this.state;
          tempState.formFields = this.emptyFields;
          this.setState(tempState);
        }
      });
    });
  };

  //###########################################################
  //###########################################################
  //###########################################################

  getTextField = (options) => {
    let readonly = false;
    if (options.readonly !== undefined) {
      readonly = options.readonly;
    }

    let multiline = false;
    if (options.multiline !== undefined) {
      multiline = options.multiline;
    }

    let rows = 2;
    if (options.rows !== undefined) {
      rows = options.rows;
    }

    return (
      <div style={{ position: "relative", width: "100%" }}>
        <TextField
          fullWidth
          multiline={multiline ? true : false}
          rows={rows}
          label={options.label}
          value={this.parent.commonCalls.nullToString(
            this.state.formFields[options.formField]
          )}
          style={{
            background: readonly
              ? this.parent.colors.blue
              : this.parent.commonCalls.nullToString(
                  this.state.formFields[options.formField]
                ) === this.emptyFields[options.formField]
              ? this.parent.colors.red
              : this.parent.colors.green,
          }}
          onChange={(event) => {
            if (!readonly) {
              let tempState = this.state;
              tempState.formFields[options.formField] = event.target.value;
              this.setState(tempState);
            }
          }}
        />

        {/* button(s) container */}
        <div
          className="flex-row-end"
          style={{
            display: options.button_1 !== undefined ? "" : "none",
            position: "absolute",
            right: "-3px",
            bottom: "0px",
          }}
        >
        <div
          className="btn-primary btn-primary-small"
          style={{
            display: options.button_3 !== undefined ? "" : "none",
            minWidth: "50px",
          }}
          onClick={
            options.button_3 !== undefined
              ? options.button_3.onClick
              : () => {}
          }
        >
          {options.button_3 !== undefined ? options.button_3.label : ""}
        </div>

          <div
            className="btn-primary btn-primary-small"
            style={{
              display: options.button_2 !== undefined ? "" : "none",
              minWidth: "50px",
            }}
            onClick={
              options.button_2 !== undefined
                ? options.button_2.onClick
                : () => {}
            }
          >
            {options.button_2 !== undefined ? options.button_2.label : ""}
          </div>

          <div
            className="btn-primary btn-primary-small"
            style={{
              display: options.button_1 !== undefined ? "" : "none",
              minWidth: "50px",
            }}
            onClick={
              options.button_1 !== undefined
                ? options.button_1.onClick
                : () => {}
            }
          >
            {options.button_1 !== undefined ? options.button_1.label : ""}
          </div>
        </div>
      </div>
    );
  };

  //###########################################################
  //###########################################################
  //###########################################################

  getMultiSelectMethods = () => {
    return (
      <FormControl fullWidth>
        <InputLabel>Methods</InputLabel>
        <Select
          multiple
          value={this.state.formFields.Methods}
          onChange={(event) => {
            let value = event.target.value;
            value = typeof value === "string" ? value.split(",") : value;
            let tempState = this.state;
            tempState.formFields.Methods = value;
            tempState.formFields.Methods.sort();
            this.setState(tempState, () => {});
          }}
          input={<OutlinedInput label="Methods" />}
          renderValue={(selected) => (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value} label={value} />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {this.methods.map((val) => (
            <MenuItem key={val} value={val}>
              <div className="flex-row-start" style={{ width: "100%" }}>
                <Checkbox
                  checked={this.state.formFields.Methods.indexOf(val) > -1}
                />
                <ListItemText primary={val} />
              </div>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  getMultiSelectComments = () => {
    return (
      <FormControl fullWidth>
        <InputLabel>Comments</InputLabel>
        <Select
          multiple
          value={this.state.formFields.CommentEnums}
          onChange={(event) => {
            let value = event.target.value;
            value = typeof value === "string" ? value.split(",") : value;
            let tempState = this.state;
            tempState.formFields.CommentEnums = value;
            tempState.formFields.CommentEnums.sort();
            this.setState(tempState, () => {});
          }}
          input={<OutlinedInput label="Comments" />}
          renderValue={(selected) => (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value} label={value} />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {this.comments.map((val) => (
            <MenuItem key={val} value={val}>
              <div className="flex-row-start" style={{ width: "100%" }}>
                <Checkbox
                  checked={this.state.formFields.CommentEnums.indexOf(val) > -1}
                />
                <ListItemText primary={val} />
              </div>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  //###########################################################
  //###########################################################
  //###########################################################

  render() {
    return (
      <div>
        {/* ############################ */}
        <div className="flex-row-start">
          <div className="flex-row-start" style={{ width: "50%" }}>
            {this.getTextField({
              label: "UUID",
              formField: "UUID",
              readonly: true,
              button_1: {
                label: "Copy",
                onClick: () => {
                  navigator.clipboard.writeText(this.state.formFields.UUID);
                  this.header.mySnackbar.alertSuccess("copied");
                },
              },
              button_2: {
                label: "Select Query",
                onClick: () => {
                  let q1 =
                    "SELECT * FROM ClinicURLsV2 WHERE UUID = '" +
                    this.state.formFields.UUID +
                    "';";
                  let q2 =
                    "SELECT * FROM GoogleV2 WHERE UUID = '" +
                    this.state.formFields.UUID +
                    "';";
                  let q3 =
                    "SELECT * FROM ClinicsV2 WHERE UUID = '" +
                    this.state.formFields.UUID +
                    "';";
                  navigator.clipboard.writeText(q1 + q2 + q3);
                  this.header.mySnackbar.alertSuccess("copied");
                },
              },
            })}
          </div>
          <div className="divider-width-15" />
          <div className="flex-row-start" style={{ width: "50%" }}>
            {this.getTextField({
              label: "DataID",
              formField: "DataID",
              readonly: true,
              button_1: {
                label: "Copy",
                onClick: () => {
                  navigator.clipboard.writeText(this.state.formFields.DataID);
                  this.header.mySnackbar.alertSuccess("copied");
                },
              },
            })}
          </div>
        </div>
        {/* ############################ */}
        <div className="divider-height-15" />
        {this.getTextField({
          label: "Clinic URL",
          formField: "ClinicURL",
          readonly: true,
          button_1: {
            label: "Go",
            onClick: () => {
              window.open(
                "http://" + this.state.formFields.ClinicURL,
                "_blank"
              );
            },
          },
        })}
        {/* ############################ */}
        <div className="divider-height-15" />
        {this.getTextField({
          label: "Corrected URL",
          formField: "CorrectedURL",
          button_1: {
            label: "Go",
            onClick: () => {
              window.open(this.state.formFields.CorrectedURL, "_blank");
            },
          },
        })}
        {/* ############################ */}
        <div className="divider-height-15" />
        {this.getTextField({ label: "Name", formField: "Name" })}
        {/* ############################ */}
        <div className="divider-height-15" />
        <div className="flex-row-start">
          <div className="flex-row-start" style={{ width: "50%" }}>
            {this.getTextField({ label: "Phone", formField: "Phone" })}
          </div>
          <div className="divider-width-15" />
          <div className="flex-row-start" style={{ width: "50%" }}>
            {this.getTextField({ label: "WhatsApp", formField: "WhatsApp" })}
          </div>
        </div>
        {/* ############################ */}
        <div className="divider-height-15" />
        <div className="flex-row-start">
          <div className="flex-row-start" style={{ width: "50%" }}>
            {this.getTextField({ label: "Email", formField: "Email" })}
          </div>
          <div className="divider-width-15" />
          <div className="flex-row-start" style={{ width: "50%" }}>
            {this.getTextField({
              label: "BeforeAfterCount",
              formField: "BeforeAfterCount",
              button_1: {
                label: "Root Path",
                onClick: () => {
                  let clinicsPath =
                    "C:\\Users\\HAKANBULU\\Desktop\\ToDropbox\\Calismalarim\\Projelerim\\TurkishHairClinics-Medisina_2021\\individual-components-local-run\\public\\assets\\images\\clinics";
                  navigator.clipboard.writeText(clinicsPath);
                  this.header.mySnackbar.alertSuccess("copied");
                },
              },
              button_2: {
                label: "Clinic Path",
                onClick: () => {
                  let clinicsPath =
                    "C:\\Users\\HAKANBULU\\Desktop\\ToDropbox\\Calismalarim\\Projelerim\\TurkishHairClinics-Medisina_2021\\individual-components-local-run\\public\\assets\\images\\clinics";
                  navigator.clipboard.writeText(
                    clinicsPath + "\\" + this.state.currentRow.UUID + "\\galery"
                  );

                  this.header.mySnackbar.alertSuccess("copied");
                },
              },
            })}
          </div>
        </div>
        {/* ############################ */}
        <div className="divider-height-15" />
        <div className="flex-row-start">
          <div className="flex-row-start" style={{ width: "50%" }}>
            <div className="flex-row-start" style={{ width: "33%" }}>
              {this.getTextField({ label: "Ready", formField: "Ready" })}
            </div>
            <div className="divider-width-15" />
            <div className="flex-row-start" style={{ width: "33%" }}>
              {this.getTextField({ label: "Deleted", formField: "Deleted" })}
            </div>
            <div className="divider-width-15" />
            <div className="flex-row-start" style={{ width: "33%" }}>
              {this.getTextField({
                label: "Main Branch",
                formField: "MainBranch",
              })}
            </div>
          </div>
          <div className="divider-width-15" />
          <div className="flex-row-start" style={{ width: "50%" }}>
            {this.getTextField({
              label: "UpdateDate",
              formField: "UpdateDate",
              readonly: true,
            })}
          </div>
        </div>
        {/* ############################ */}
        <div className="divider-height-15" />
        {this.getMultiSelectMethods()}
        {/* ############################ */}
        <div className="divider-height-15" />
        {this.getTextField({
          label: "About",
          formField: "About",
          multiline: true,
          rows: 4,
        })}
        {/* ############################ */}
        <div className="divider-height-15" />
        {this.getTextField({
          label: "Comment",
          formField: "CommentFreeText",
          multiline: true,
          rows: 4,
        })}
        {/* ############################ */}
        <div className="divider-height-15" />
        {this.getMultiSelectComments()}
        {/* ############################ */}
        <div className="divider-height-15" />
        <div className="flex-row-end">
          <div
            className="btn-primary"
            onClick={() => {
              //#############
              // let tempMethods = [];
              // this.state.formFields.Methods.forEach((m) => {
              //   if (
              //     m !== "Beard & Mustache Transplant" &&
              //     tempMethods.indexOf(m) < 0
              //   ) {
              //     tempMethods.push(m);
              //   }
              // });

              this.backend.UpdateClinicViaAdminForm(
                this.state.formFields.UUID,
                this.state.formFields.CorrectedURL,
                this.state.formFields.Name,
                this.state.formFields.Phone,
                this.state.formFields.WhatsApp,
                this.state.formFields.Email,
                this.state.formFields.BeforeAfterCount,
                this.state.formFields.Ready,
                this.state.formFields.Deleted,
                this.state.formFields.Methods.join(","),
                this.state.formFields.About,
                this.state.formFields.CommentFreeText,
                this.state.formFields.CommentEnums.join(","),
                this.state.formFields.MainBranch,
                () => {
                  this.header.mySnackbar.alertSuccess(
                    this.state.currentRow.ClinicURL + " clinic saved."
                  );
                }
              );
            }}
          >
            Save -{" "}
            {this.state.currentRow !== null
              ? this.state.currentRow.ClinicURL
              : ""}
          </div>
        </div>
      </div>
    );
  }
}

//###########################################################
//###########################################################
//###########################################################

export default function(props) {
  const navigate = useNavigate();
  return <GeneralInfo {...props} navigate={navigate} />;
}

//###########################################################
//###########################################################
//###########################################################



// https://sule-hairtransplant.com/
// hairofistanbul.com
// estevienclinic.com
// healthbeautyturkey.com
// https://www.azizaksoz.com/?lang=en
// https://esthetichairturkey.com/en/hair-transplant-before-after/
// https://en.dk-hairklinik.com/
// nowhairtime.com
// https://istanbulhaircenter.com/en/
// https://elithair.co.uk/
// estemedicalgroup.com



// https://www.istanbulhairlab.com/en/
// https://www.este-grande.com/
// https://www.classhair.net/en/
// https://www.arenamedhairclinic.com/
// https://renovaclinic.net/en/
// https://en.hairtrans.net/
// https://www.microfueistanbul.com/
// https://www.medhairclinic.com/en/
// https://vatanmed.com/
// https://avrupasacekimi.com/
// https://www.estetikinternational.com/
