import React from "react";
import "./Authentication.css";

import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import FormValidation from "./FormValidation";
import InputAdornment from "@mui/material/InputAdornment";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import HomeIcon from "@mui/icons-material/Home";

import Logo from "../logo/Logo";

//###########################################################
//###########################################################
//###########################################################

class ForgotPassword extends React.Component {
  //###########################################################
  //###########################################################
  //###########################################################

  constructor(props) {
    super(props);

    this.appData = this.props.appData;
    this.parent = this.props.parent;
    this.formValidation = new FormValidation(this.appData);

    this.state = {
      loading: false,
      btnHomePageLoading: false,
      errors: {
        email: false,
      },
    };
  }

  //###########################################################
  //###########################################################
  //###########################################################

  componentDidMount() {}

  //###########################################################
  //###########################################################
  //###########################################################

  isFormInputValid = (callBack) => {
    this.formValidation.formInputs(
      {
        email: this.txtEmail.value,
      },
      (validationResult) => {
        let tempState = this.state;

        tempState.errors.email =
          validationResult.email.length > 0 ? true : false;

        if (validationResult.warningText !== "") {
          this.appData.snackbar().alertError(validationResult.warningText);
        } else {
          this.appData.snackbar().close();
        }

        this.setState(tempState);
        callBack(validationResult.warningText === "");
      }
    );
  };

  onTextFieldFocus = () => {
    this.appData.snackbar().close();
    let tempState = this.state;
    tempState.errors.email = false;
    this.setState(tempState);
  };

  //###########################################################
  //###########################################################
  //###########################################################

  btnPasswordResetClicked = () => {
    this.appData.snackbar().close();

    this.isFormInputValid((isFormValid) => {
      //#### form is valid
      if (isFormValid) {
        //### set as loading True
        let tempState = this.state;
        tempState.loading = true;
        this.setState(tempState, () => {
          //### set the params
          let params = {
            email: this.txtEmail.value,
          };
          //### make your call to backend
          this.appData.accountManager.sendPasswordResetLink(
            params,
            (response) => {
              if (this.appData.printConsole) console.log(response);
              //### got your response
              //### set as loading False
              let tempState = this.state;
              tempState.loading = false;
              this.setState(tempState, () => {
                //### lets handle the error first
                if (response.Result === "Error") {
                  this.appData.snackbar().alertError(response.Message);
                } else {
                  this.parent.openAuthenticationMessages({
                    type: "ResetPassword",
                    email: this.txtEmail.value,
                  });

                  //### snack the success message
                  let successMessage = (
                    <div>
                      We have sent an email to
                      <br />
                      <b>{this.txtEmail.value}</b>
                      <br />
                      so you can create a new password.
                    </div>
                  );
                  this.appData.snackbar().alertSuccess(successMessage);
                }
              });
            }
          );
        });
      }
    });
  };

  //###########################################################
  //###########################################################
  //###########################################################

  render() {
    return (
      <>
        <div
          className="sign-in-container"
          style={{
            border: this.parent.authenticationDialog !== null ? "none" : "",
          }}
        >
        {/* message container */}
        <div
          className="authentication-message-div"
          style={{
            display: this.props.options.message !== undefined ? "" : "none",
          }}
        >
          {this.props.options.message}
        </div>
        {/* logo container */}
        <div
          className="authentication-logo-div"
          style={{
            display: this.props.options.logo !== undefined ? "" : "none",
          }}
        >
          {this.props.options.logo}
        </div>
          <div
            style={{
              textAlign: "center",
              fontWeight: "bold",
              fontSize: "1.4rem",
              padding: "0px 20px 0px 20px",
              marginBottom: "30px",
            }}
            className="text-color"
          >
            Forgot your password?
          </div>
          <div
            style={{
              fontSize: "1rem",
              lineHeight: "1.7",
              textAlign: "justify",
              marginBottom: "20px",
            }}
            className="text-color"
          >
            Please enter your email address and we’ll send you a link to create
            a new password.
            {/* Please enter your e-mail address below. We will send a{" "}
          <b>password reset link</b> to you. */}
          </div>
          <TextField
            error={this.state.errors.email}
            inputRef={(el) => (this.txtEmail = el)}
            label="E-Mail"
            variant="outlined"
            style={{ width: "100%", marginBottom: "30px" }}
            InputProps={{
              style: { fontFamily: "Poppins" },
              startAdornment: (
                <InputAdornment position="start">
                  <EmailOutlinedIcon />
                </InputAdornment>
              ),
            }}
            onFocus={() => {
              this.onTextFieldFocus();
            }}
          />

          <LoadingButton
            style={{
              width: "100%",
              paddingTop: "15px",
              paddingBottom: "15px",
              fontSize: "1rem",
              marginBottom: "20px",
            }}
            onClick={() => {
              this.btnPasswordResetClicked();
            }}
            loading={this.state.loading}
            loadingIndicator="Please wait..."
            variant="contained"
          >
            Send Password Reset Link
          </LoadingButton>

          {/* <Divider style={{marginTop:"20px", marginBottom:"20px"}}>
          <Chip label="Not a member?" />
        </Divider> */}

          <div
            style={{
              textAlign: "center",
              fontSize: "1.0rem",
              marginBottom: "30px",
            }}
            className="text-color flex-row-center"
          >
            Back to
            <span
              className="link-color"
              style={{
                marginLeft: "5px",
                padding: "0px",
                fontSize: "1.0rem",
              }}
              onClick={() => {
                this.props.parent.signInClicked();
              }}
            >
              Sign in
            </span>
          </div>
          <div
            className="text-color"
            style={{
              fontSize: "0.8rem",
              borderTop: "1px solid #CCC",
              textAlign: "justify",
              paddingTop: "5px",
              paddingBottom: "5px",
            }}
          >
            {/* <div className="divider-height-15"></div> */}
            If you no longer have access to the email address you registered
            with us, please contact{" "}
            <span className="link-color">Customer Service</span>.
          </div>
          <div
            style={{
              display: this.parent.authenticationDialog !== null ? "none" : "",
            }}
          >
            <div
              className="text-color flex-row-center"
              style={{
                borderTop: "1px solid #CCC",
                textAlign: "center",
                paddingTop: "30px",
              }}
            >
              <LoadingButton
                size="small"
                onClick={() => {
                  let tempState = this.state;
                  tempState.btnHomePageLoading = true;
                  this.setState(tempState, () => {
                    setTimeout(function() {
                      window.location.href = "/";
                    }, 250);
                  });
                }}
                loading={this.state.btnHomePageLoading}
                loadingPosition="start"
                startIcon={<HomeIcon />}
                variant="contained"
              >
                Home Page
              </LoadingButton>
            </div>
          </div>
        </div>
      </>
    );
  }
}

//###########################################################
//###########################################################
//###########################################################

export default ForgotPassword;

//###########################################################
//###########################################################
//###########################################################
