import React from "react";
import "../bootstrap-studio/bootstrap/bootstrap.scoped.css";

import { Link, useNavigate } from "react-router-dom";

import Grid from "@mui/material/Grid";
import ClinicCard from "../clinic-card/ClinicCard";
import ClinicCardBootstrapStudio from "../clinic-card-bootstrap-studio/ClinicCardBootstrapStudio";
import BackEnd from "../../classes/Backend";
import BackendClinicList from "../../classes/BackendClinicList";
import ScreenConsole from "./../screen-console/ScreenConsole";

import FacebookCircularProgress from "../screen-console/FacebookCircularProgress";
import ReviewColorCodesTooltip from "../review-color-codes/ReviewColorCodesTooltip.js";
import MySessionManager from "../commons/MySessionManager";
import MyCookieManager from "../commons/MyCookieManager";

class ListClinicCardsBootstrapStudio extends React.Component {
  //###########################################################
  //###########################################################
  //###########################################################

  constructor(props) {
    super(props);

    this.appData = this.props.appData;

    this.reviewColorCodesTooltip = null;

    this.backendClinicList = new BackendClinicList();

    this.groupIndex = this.appData.getSessionObject().groupIndex;

    this.state = {
      clinicCardContairs: [],
      displayLoadingDiv: "",
      clinicIDs: [],
    };
    // this.backend.getAll((dataLiight) => {
    //   this.dataObject = dataLiight;
    // });
  }

  //###########################################################
  //###########################################################
  //###########################################################

  componentDidMount() {
    this.screenConsole.show(() => {
      this.fetchClinics(null, this.groupIndex, 0);
    });
  }

  //###########################################################
  //###########################################################
  //###########################################################

  fetchClinics = (runID, groupIndex, pageIndex) => {
    if (runID === null) {
      this.backendClinicList.getClinicListByGroupIndex(
        groupIndex,
        (response) => {
          let runID = response.RunID;
          let JsonStr = response.JSON;
          let isLast = response.IsLast;

          this.appendClinics(JsonStr, () => {
            this.screenConsole.hide();
            if (isLast === "0") {
              this.fetchClinics(runID, groupIndex, pageIndex + 1);
            } else {
              let tempState = this.state;
              tempState.displayLoadingDiv = "none";
              this.setState(tempState);
            }
          });
        }
      );
    } else {
      this.backendClinicList.getClinicListByRunIDGroupIndexPageIndex(
        runID,
        groupIndex,
        pageIndex,
        (response) => {
          let runID = response.RunID;
          let JsonStr = response.JSON;
          let isLast = response.IsLast;

          this.appendClinics(JsonStr, () => {
            if (isLast === "0") {
              this.fetchClinics(runID, groupIndex, pageIndex + 1);
            } else {
              let tempState = this.state;
              tempState.displayLoadingDiv = "none";
              this.setState(tempState);
            }
          });
        }
      );
    }
  };

  appendClinics = (JsonStr, callBack) => {
    let tempState = this.state;

    //### parse the json str
    let clinicList = JSON.parse(JsonStr);

    //### create clinic card instances
    clinicList.forEach((clinicData) => {
      // let clinicCard = <ClinicCard parent={this} data={clinicData} />;
      let clinicCard = (
        <ClinicCardBootstrapStudio
          key={this.appData.getUUID()}
          parent={this}
          data={clinicData}
        />
      );

      if (!tempState.clinicIDs.includes(clinicData.UUID)) {
        //### check list to avoid adding same clinic to the container
        tempState.clinicIDs.push(clinicData.UUID);

        // sm:mobile, md:tablet, lg:laptop, xl:desktop
        tempState.clinicCardContairs.push(clinicCard);
      }
    });

    this.setState(tempState, () => {
      callBack();
    });
  };

  //###########################################################
  //###########################################################
  //###########################################################

  render() {
    return (
      <>
        <ScreenConsole ref={(el) => (this.screenConsole = el)} />
        <div
          className="container"
          style={{
            maxWidth: "1400px",
            // marginTop: "50px",
            marginBottom: "50px",
          }}
        >
          <div
            style={{
              width: "100%",
              marginTop: "20px",
              marginBottom: "20px",
              lineHeight: "1.8",
              textAlign: "center",
              fontSize: "2rem",
            }}
          >
            List of Best Hair Transplant Clinics in Turkey
          </div>
          <div
            style={{ width: "100%", marginBottom: "30px", lineHeight: "1.8" }}
          >
            {" "}
            If you are looking for the best hair transplant clinics in Turkey,
            you have come to the right place. Our list includes some of the most
            reputable and highly qualified clinics in the country. So whether
            you are considering a hair transplant surgery or just want to learn
            more about the process, these clinics can provide you with all the
            information you need. Contact them today to get started!
          </div>
          <div className="row gx-4 gy-4 row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4">
            {this.state.clinicCardContairs}
          </div>
        </div>

        <div
          style={{
            padding: "50px 0px 50px 0px",
            display: this.state.displayLoadingDiv,
          }}
        >
          <FacebookCircularProgress />
        </div>
      </>
    );
  }
}

//###########################################################
//###########################################################
//###########################################################

// export default ListClinicCards;

export default function(props) {
  const navigate = useNavigate();
  return <ListClinicCardsBootstrapStudio {...props} navigate={navigate} />;
}

//###########################################################
//###########################################################
//###########################################################
