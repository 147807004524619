import React from "react";
import { Link, useNavigate } from "react-router-dom";
import FacebookCircularProgress from "../screen-console/FacebookCircularProgress";
import GoogleIcon from "@mui/icons-material/Google";
import Button from "@mui/material/Button";

//###########################################################
//###########################################################
//###########################################################

class Google extends React.Component {
  //###########################################################
  //###########################################################
  //###########################################################

  constructor(props) {
    super(props);


    //### for forward references
    if (
      this.props.parent !== undefined &&
      this.props.parent.google !== undefined
    ) {
      this.props.parent.google = this;
    }

    
    this.parent = this.props.parent;
    this.sessionObject = this.parent.sessionObject;
    this.backend = this.parent.backend;
    this.uuid = this.parent.props.uuid;
    this.commonsWithClinic = this.parent.commonsWithClinic;

    this.state = {
      dataFromBackend: null,
    };
  }

  //###########################################################
  //###########################################################
  //###########################################################

  componentDidMount() {
  }

  //###########################################################
  //###########################################################
  //###########################################################
  
  onClinicGoogleDataHasBeenRetrieved = (data) => {
    let tempState = this.state;
    tempState.dataFromBackend = data;
    this.setState(tempState);
  };

  //###########################################################
  //###########################################################
  //###########################################################

  render() {
    return (
      <div className="flex-column-center">
        {this.state.dataFromBackend !== null ? (
          <div style={{ width: "100%" }} className="flex-column-center-left">
            {this.commonsWithClinic.googleReviews(
              this.state.dataFromBackend,
              "clinic"
            )}
            {/* <div className="divider-height-20" />
            <Button
              variant="contained"
              startIcon={<GoogleIcon style={{ fontSize: "32px" }} />}
              style={{ paddingTop: "10px", paddingBottom: "10px" }}
              // onClick={onClickSendMessage}
            >
              See all reviews
            </Button> */}
          </div>
        ) : (
          <div>
            <FacebookCircularProgress />
          </div>
        )}
      </div>
    );
  }
}

//###########################################################
//###########################################################
//###########################################################

export default function(props) {
  const navigate = useNavigate();
  return <Google {...props} navigate={navigate} />;
}

//###########################################################
//###########################################################
//###########################################################
