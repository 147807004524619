import React from "react";
import parse from "html-react-parser";
import axios from "axios";

//###########################################################
//###########################################################
//###########################################################

class CommonCalls extends React.Component {
  //###########################################################
  //###########################################################
  //###########################################################

  constructor(props) {
    super(props);
    this.myCipher = this.cipher("al896fg!@3dene09e");
    this.myDecipher = this.decipher("al896fg!@3dene09e");
  }
  //###########################################################
  //###########################################################
  //###########################################################

  clone = (obj) => {
    return JSON.parse(JSON.stringify(obj));
  };

  //###########################################################
  //###########################################################
  //###########################################################

  boolToCssDisplay = (val) => {
    // console.log("============================");
    // console.log(val);
    if (val) {
      // console.log("it is true");
      return "";
    }
    // console.log("it is false");
    return "none";
  };

  //###########################################################
  //###########################################################
  //###########################################################

  strToFlexClassName = (val) => {
    switch (val) {
      case "left":
        return "flex-row-start";
      case "right":
        return "flex-row-end";
      case "center":
        return "flex-row-center";
      case "space-between":
        return "flex-row-space-between";
      case "top-left":
        return "flex-column-start-left";
      case "top-center":
        return "flex-column-start";
      case "middle-left":
        return "flex-column-center-left";
      case "middle-center":
        return "flex-column-center";
      default:
        return "";
    }
  };

  //###########################################################
  //###########################################################
  //###########################################################

  getRandomInt = (min, max) => {
    return Math.floor(Math.random() * max + min);
  };

  //###########################################################
  //###########################################################
  //###########################################################

  setState = (sender, keys, values, callBack) => {
    let tempState = sender.state;
    for (let i = 0; i < keys.length; i++) {
      tempState[keys[i]] = values[i];
    }
    sender.setState(tempState, () => {
      if (callBack !== undefined) {
        callBack();
      }
    });
  };

  //###########################################################
  //###########################################################
  //###########################################################

  nullToString = (val) => {
    return val === null ? "" : val;
  };

  //###########################################################
  //###########################################################
  //###########################################################

  updateDatabaseResponse = (response) => {
    let str = JSON.stringify(response);
    str = str.replaceAll("amp;", "");
    str = str.replaceAll("***and***", "&");
    str = str.replaceAll("***sq***", "'");
    return JSON.parse(str);

    // let keys = Object.keys(response);
    // keys.forEach((key) => {
    //   if (response[key] !== undefined && response[key] !== null) {
    //     response[key] = response[key].toString().replaceAll("amp;", "");
    //   }
    // });
    // return response;
  };

  safeString = (str) => {
    str = str.replaceAll("'", "***sq***");
    str = str.replaceAll('"', '***dq***');
    return JSON.parse(str);
  };

  //###########################################################
  //###########################################################
  //###########################################################

  //https://stackoverflow.com/questions/18279141/javascript-string-encryption-and-decryption
  // To create a cipher
  // const myCipher = cipher('mySecretSalt')

  // //Then cipher any text:
  // console.log(myCipher('the secret string'))

  // //To decipher, you need to create a decipher and use it:
  // const myDecipher = decipher('mySecretSalt')
  // console.log(myDecipher("7c606d287b6d6b7a6d7c287b7c7a61666f"))

  cipher = (salt) => {
    const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
    const byteHex = (n) => ("0" + Number(n).toString(16)).substr(-2);
    const applySaltToChar = (code) =>
      textToChars(salt).reduce((a, b) => a ^ b, code);

    return (text) =>
      text
        .split("")
        .map(textToChars)
        .map(applySaltToChar)
        .map(byteHex)
        .join("");
  };

  decipher = (salt) => {
    const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
    const applySaltToChar = (code) =>
      textToChars(salt).reduce((a, b) => a ^ b, code);
    return (encoded) =>
      encoded
        .match(/.{1,2}/g)
        .map((hex) => parseInt(hex, 16))
        .map(applySaltToChar)
        .map((charCode) => String.fromCharCode(charCode))
        .join("");
  };

  //###########################################################
  //###########################################################
  //###########################################################

  textToHTML = (str) => {
    return parse(str);
  };

  //###########################################################
  //###########################################################
  //###########################################################

  getGeoLocation = (callBack) => {
    let defaultResponse = {country_code:"TR"};
    axios
      .get("https://geolocation-db.com/json/")
      .then(function(response) {
        try{
          if(response.status === 200){
            callBack(response.data);
          }
          else{
            callBack(defaultResponse);
          }
        }catch (e) {
          callBack(defaultResponse);
        }
      })
      .catch(function(error) {
        callBack(defaultResponse);
      });
  };
  
  //###########################################################
  //###########################################################
  //###########################################################

  getCountryCode = (callBack) => {
    this.getGeoLocation((response)=>{
      callBack(response.country_code);
    });
  };

  //###########################################################
  //###########################################################
  //###########################################################


  safeJsonString = (str) => {
    let LINE_EXPRESSION = /\r\n|\n\r|\n|\r/g; // expression symbols order is very important

    str = str.replaceAll(LINE_EXPRESSION, "***nl***");
    str = str.replaceAll('"', "***dq***");
    return str;
  };

  restoreJsonString = (str) =>{
    str = str.replaceAll("***nl***", '\n');
    str = str.replaceAll("***dq***", '"');
    return str;
  }
}

//###########################################################
//###########################################################
//###########################################################

export default CommonCalls;

//###########################################################
//###########################################################
//###########################################################
