import React from "react";
import { Link, useNavigate } from "react-router-dom";
// import DesktopLogoSVG from "/mavi-turuncu-desktop.svg";

//###########################################################
//###########################################################
//###########################################################

class Logo extends React.Component {
  //###########################################################
  //###########################################################
  //###########################################################

  constructor(props) {
    super(props);

    this.appData = this.props.appData;

    //### for forward references
    if (
      this.props.parent !== undefined &&
      this.props.parent.xxxxx !== undefined
    ) {
      this.props.parent.xxxxx = this;
    }

    this.state = {};
  }

  //###########################################################
  //###########################################################
  //###########################################################

  componentDidMount() {}

  //###########################################################
  //###########################################################
  //###########################################################

  desktopLogo = () => {
    return (
      <img
        src={`${"../assets/images/logos/mavi-turuncu-desktop.svg"}`}
        alt="Turkish Hair Clinics Logo"
        style={{ maxHeight: "80px", cursor: "pointer", textAlign:"left" }}
        onClick={() => {
          this.onLogoClick();
        }}
      />
    );
  };

  mobileLogo = () => {
    return (
      <img
        src={`${"../assets/images/logos/mavi-turuncu-mobile.svg"}`}
        alt="Turkish Hair Clinics Logo"
        style={{ maxHeight: "80px", cursor: "pointer" }}
        onClick={() => {
          this.onLogoClick();
        }}
      />
    );
  };

  onLogoClick = () => {
    this.appData.backToHome();
  };

  render() {
    if (this.props.type === undefined || this.props.type === "desktop") {
      return this.desktopLogo();
    } else if (this.props.type === "mobile") {
      return this.mobileLogo();
    } else if (this.props.type === "none") {
      return <></>;
    }
  }
}

//###########################################################
//###########################################################
//###########################################################
export default Logo;
// export default function(props) {
//   const navigate = useNavigate();
//   return <Logo {...props} navigate={navigate} />;
// }

//###########################################################
//###########################################################
//###########################################################
