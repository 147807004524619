import React from "react";
import SignUp from "../authentication/SignUp";
import CloseIcon from "@mui/icons-material/Close";
import CancelIcon from "@mui/icons-material/Cancel";
import IconButton from "@mui/material/IconButton";
import { autocompleteClasses } from "@mui/material";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";

//###########################################################
//###########################################################
//###########################################################

class MyContainer extends React.Component {
  //###########################################################
  //###########################################################
  //###########################################################

  constructor(props) {
    super(props);

    if (this.props.appData !== undefined) {
      this.appData = this.props.appData;
      // this.appData.container() = this;
    }

    this.defaultOptions = {
      hasCloseButton: true,
      vAlign: "center",
      hAlign: "center",
    };

    this.state = {
      display: "none",
      componentToRender: <></>,
      options: {},
    };
  }

  //###########################################################
  //###########################################################
  //###########################################################

  componentDidMount() {}

  //###########################################################
  //###########################################################
  //###########################################################

  open = (componentToRender, options) => {
    let tempState = this.state;

    //### set the display
    tempState.display = "flex";
    if (componentToRender !== undefined) {
      tempState.componentToRender = componentToRender;
    }

    //### set the default options
    tempState.options = this.appData.commonCalls.clone(this.defaultOptions);

    //### set the options passed by caller
    if (options !== undefined) {
      let keys = Object.keys(options);
      keys.forEach((key) => {
        tempState.options[key] = options[key];
      });
    }

    this.setState(tempState, () => {
      //### if the container div has vertical scrollbar, disable the justifyContent attribute
      //### because, when the vertical scroll bar is appear and justifyContent is 'center'
      //### the content does not display properly. My current solution is;
      //### detect if the vertical scroll bar is exist; if yes: set the justifyContent = ''
      let obj = this.isScrollable(this.containerDiv);

      if (obj !== null) {
        if (obj.verticallyScrollable) {
          tempState.options.vAlign = "";
        }
        this.setState(tempState);
      }

      this.scrollTop();
    });
  };

  close = () => {
    let tempState = this.state;
    tempState.display = "none";
    this.setState(tempState);
  };

  //###########################################################
  //###########################################################
  //###########################################################

  isScrollable = (el) => {
    if (el === undefined || el === null) return null;

    /*The scrollTop() method sets or returns the
    vertical scrollbar position for the selected elements*/
    var y1 = el.scrollTop;
    el.scrollTop += 1;
    var y2 = el.scrollTop;
    el.scrollTop -= 1;
    var y3 = el.scrollTop;
    el.scrollTop = y1;

    /*The scrollLeft() method returns the horizontal
    scrollbar position for the selected elements.*/
    var x1 = el.scrollLeft;
    el.scrollLeft += 1;
    var x2 = el.scrollLeft;
    el.scrollLeft -= 1;
    var x3 = el.scrollLeft;
    el.scrollLeft = x1;

    //returns true or false accordingly
    return {
      horizontallyScrollable: x1 !== x2 || x2 !== x3,
      verticallyScrollable: y1 !== y2 || y2 !== y3,
    };
  };

  //###########################################################
  //###########################################################
  //###########################################################

  scrollTop = () => {
    this.containerDiv.scrollTo(0, 0);
  };

  //###########################################################
  //###########################################################
  //###########################################################

  getCloseButton = () => {
    return (
      <IconButton
        onClick={this.close}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: "#9E9E9E",
        }}
      >
        <HighlightOffOutlinedIcon style={{ fontSize: "32px" }} />
      </IconButton>
    );
  };

  //###########################################################
  //###########################################################
  //###########################################################

  render() {

    let clientSize = this.appData.getClientSize();

    let divStyle = {
      display: this.state.display,
      background: "rgb(255,255,255)",
      width: clientSize.width,
      height: clientSize.height,
      maxHeight: clientSize.height,
      position: "absolute",
      left: 0,
      top: 0,
      overflow: "auto",
      zIndex: 1001,
      flexDirection: "column",
      justifyContent: this.state.options.vAlign,
      alignItems: this.state.options.hAlign,
    };

    return (
      <>
        <div ref={(el) => (this.containerDiv = el)} style={divStyle}>
          {/* if has close button, render the top place holder and the close button */}
          {this.state.options.hasCloseButton ? (
            <>
              <div style={{ width: "100%", minHeight: "64px" }} />
              {this.getCloseButton()}
            </>
          ) : (
            <></>
          )}

          <div
            ref={(el) => (this.containerDivInner = el)}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: this.state.options.vAlign,
              alignItems: this.state.options.hAlign,
              flexGrow: 1,
              // background:"#FF0",
              width:"100%"
            }}
          >
            {this.state.componentToRender}
          </div>

          {/* if has close button, render the bottom place holder */}
          {this.state.options.hasCloseButton ? (
            <>
              <div style={{ width: "100%", minHeight: "64px" }} />
            </>
          ) : (
            <></>
          )}
        </div>
      </>
    );
  }
}

//###########################################################
//###########################################################
//###########################################################

export default MyContainer;

//###########################################################
//###########################################################
//###########################################################
