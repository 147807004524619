import React from "react";
import PropTypes from "prop-types";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import FacebookCircularProgress from "./FacebookCircularProgress";

//###########################################################
//###########################################################
//###########################################################
function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary">
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   * @default 0
   */
  value: PropTypes.number.isRequired,
};

//###########################################################
//###########################################################
//###########################################################

class MyScreenConsole extends React.Component {
  //###########################################################
  //###########################################################
  //###########################################################

  constructor(props) {
    super(props);
    this.state = {
      display: "none",
      content: "Please wait...",
      progressBar: false,
      progress: 0,
    };
  }

  //###########################################################
  //###########################################################
  //###########################################################

  componentDidMount() {}

  //###########################################################
  //###########################################################
  //###########################################################

  open = (obj) => {
    //### a promise instance

    return new Promise((resolve, reject) => {
      let tempState = this.state; 
        tempState.content= "Please wait...";
        tempState.progressBar= false;
        tempState.progress= 0;
      
      //### display
      tempState.display = "flex";
      //### content
      if (obj !== undefined && obj.content !== undefined)
        tempState.content = content;
        //### progressBar
      if (obj !== undefined && obj.progressBar !== undefined && obj.progressBar)
        tempState.progressBar = obj.progressBar;
      this.setState(tempState, () => {
        resolve();
      });
    });
  };

  close = () => {
    //### a promise instance
    return new Promise((resolve, reject) => {
      let tempState = this.state;
      //### display
      tempState.display = "none";
      this.setState(tempState, () => {
        resolve();
      });
    });
  };

  set = (obj) => {
    return new Promise((resolve, reject) => {
      let tempState = this.state;
      //### content
      if (obj.content !== undefined) tempState.content = content;
      //### progressBar
      if (obj.progressBar !== undefined && obj.progressBar)
        tempState.progressBar = obj.progressBar;
        //### progress
      if (obj.progress !== undefined && obj.progress)
        tempState.progress = obj.progress;
      this.setState(tempState, () => {
        resolve();
      });
    });
  };

  isOpen = () => {
    return this.state.display !== "none";
  };

  //###########################################################
  //###########################################################
  //###########################################################

  render() {
    let clientSize = this.props.appData.getClientSize();

    return (
      <div
        style={{
          display: this.state.display,
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          position: "fixed",
          top: "0px",
          left: "0px",
          width: clientSize.width + "px",
          height: clientSize.height + "px",
          background: "#FFF",
          zIndex: 9999999,
        }}
      >
        <div style={{ display: this.state.progressBar ? "none" : "", marginBottom:"15px" }}>
          <FacebookCircularProgress />
        </div>

        <div style={{ display: this.state.progressBar ? "" : "none" }}>
          <CircularProgressWithLabel value={this.state.progress} />
        </div>

        {this.state.content}
      </div>
    );
  }
}

//###########################################################
//###########################################################
//###########################################################

export default MyScreenConsole;

//###########################################################
//###########################################################
//###########################################################
