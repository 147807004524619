import axios from "axios";

//###########################################################
//###########################################################
//###########################################################

class InstantBackend {
  constructor(config) {
    this.applicationConfigurationID = config.applicationConfigurationID;
    this.serverURL = config.serverURL + "/api/";
    this.printConsole =
      config.printConsole !== undefined ? config.printConsole : false;
  }

  //###########################################################
  //###########################################################
  //###########################################################

  getConfig = (data) => {
    return {
      method: "post",
      url: this.serverURL + "PerformQueryWithName",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
  };

  //###########################################################
  //###########################################################
  //###########################################################

  perform = (queryName, queryParams, callBack) => {
    if (this.printConsole) console.log(">>> Performing " + queryName + "...");

    if (queryParams === undefined || queryParams === null) {
      queryParams = {};
    }

    for (var key of Object.keys(queryParams)) {
      if (queryParams[key] === undefined) {
        let errorMesssage =
          ">>> ERROR: QueryName: " +
          queryName +
          " >>> Param " +
          key +
          " is undefined!";
        if (this.printConsole) {
          alert(errorMesssage);
          console.log(errorMesssage);
        }
        return;
      }
    }

    var data = {
      ApplicationConfigurationID: this.applicationConfigurationID,
      QueryName: queryName,
      QueryParams: JSON.stringify(queryParams),
    };

    axios(this.getConfig(data))
      .then(function (response) {
        //### shape the response
        response.data.Data = JSON.parse(response.data.Data);
        response = response.data;

        //### there is an issue
        if (response.Result !== "OK") {
          let errorMessage =
            ">>> ERROR: " + queryName + " >>> " + response.Message;
          if (this.printConsole) {
            console.log("----------");
            console.log(errorMessage);
            console.log("----------");
          }
          callBack(null);
        }
        //### everything is fine
        else {
          callBack(response.Data);
        }
      })
      .catch(function (error) {
        let errorMessage = ">>> ERROR: " + queryName + " >>> " + error;
        if (this.printConsole) {
          console.log("----------");
          console.log(errorMessage);
          console.log("----------");
        }
        callBack(null);
      });
  };

  //###########################################################
  //###########################################################
  //###########################################################

  performWithPromise = (queryName, queryParams) => {

    let _this = this;

    //### a promise instance
    return new Promise((resolve, reject) => {
      //### print console if avaliable
      if (_this.printConsole) console.log(">>> Performing " + queryName + "...");

      //### check if queryParams has not been set
      if (queryParams === undefined || queryParams === null) {
        queryParams = {};
      }

      //### check if all param's attributes have been set/initialized
      for (var key of Object.keys(queryParams)) {
        if (queryParams[key] === undefined) {
          let errorMesssage =
            ">>> QueryName: " +
            queryName +
            " >>> Param " +
            key +
            " is undefined!";
          reject(errorMesssage);
          return;
        }
      }

      //### set data for backend post
      var data = {
        ApplicationConfigurationID: _this.applicationConfigurationID,
        QueryName: queryName,
        QueryParams: JSON.stringify(queryParams),
      };

      //### perform axios
      axios(_this.getConfig(data))
        .then(function (response) {
          //### shape the response
          response.data.Data = JSON.parse(response.data.Data);
          response = response.data;

          //### there is an issue
          if (response.Result !== "OK") {
            let errorMessage =
              ">>> ERROR: " + queryName + " >>> " + response.Message;
            if (_this.printConsole) {
              console.log("----------");
              console.log(errorMessage);
              console.log("----------");
            }
            reject(errorMessage);
            return;
          }
          //### everything is fine
          else {
            resolve(response.Data);
          }
        })
        .catch(function (error) {
          let errorMessage = ">>> ERROR: " + queryName + " >>> " + error;
          if (_this.printConsole) {
            console.log("----------");
            console.log(errorMessage);
            console.log("----------");
          }
          reject(errorMessage);
          return;
        });
    });
  };

  //###########################################################
  //###########################################################
  //###########################################################

  resizeImage = (base64, maxWidth, maxHeight, callBack) => {
    if (maxWidth <= 0 || maxHeight <= 0) {
      callBack(base64);
    } else {
      var img = document.createElement("img");
      img.onload = function (event) {
        //### Dynamically create a canvas element
        var canvas = document.createElement("canvas");
        var ctx = canvas.getContext("2d");

        let imageWidth = img.width;
        let imageHeight = img.height;
        let ratio = imageWidth / imageHeight;

        if (imageWidth > maxWidth) {
          imageWidth = maxWidth;
          imageHeight = imageWidth / ratio;
        }

        if (imageHeight > maxHeight) {
          imageHeight = maxHeight;
          imageWidth = imageHeight * ratio;
        }

        //### Actual resizing
        canvas.width = imageWidth;
        canvas.height = imageHeight;
        ctx.drawImage(img, 0, 0, imageWidth, imageHeight);

        //### result base64
        let res = canvas.toDataURL();

        //### call callBack
        callBack(res);
      };
      img.src = base64;
    }
  };

  uploadImage = (fileBase64, options) => {
    // console.log(
    //   JSON.parse(
    //     "{imageName:'04d6567b-4869-47c9-8927-cef30369c2ef.jpg', url:'https://localhost:44328/UploadedFiles/instant-backend/04d6567b-4869-47c9-8927-cef30369c2ef.jpg'}"
    //   )
    // );

    let _this = this;

    let allowedTypes = ["image/bmp", "image/png", "image/jpeg"];

    if (options === undefined) {
      options = {};
    }

    if (options.maxWidth === undefined || options.maxHeight === undefined) {
      options.maxWidth = 0;
      options.maxHeight = 0;
    }
    if (options.expiresInDay === undefined) {
      options.expiresInDay = -1;
    }

    return new Promise((resolve, reject) => {
      if (!allowedTypes.includes(fileBase64.type)) {
        let errorMessage =
          ">>> ERROR: UploadImage >>> Not correct file's type (bmp, png, jpeg)!";
        if (this.printConsole) {
          console.log("");
          console.log(errorMessage);
          console.log("");
        }
        reject(errorMessage);
        return;
      }

      this.resizeImage(
        fileBase64.base64,
        options.maxWidth,
        options.maxHeight,
        (resizedBase64) => {
          if (options.callBackResizedImage !== undefined) {
            options.callBackResizedImage(resizedBase64);
          }
          fetch(resizedBase64)
            .then((res) => res.blob())
            .then((blob) => {
              const file = new File([blob], fileBase64.name, {
                type: fileBase64.type,
              });
              var formData = new FormData();
              formData.append("ConnectionName", _this.connectionName);
              formData.append("ExpiresInDay", options.expiresInDay);
              formData.append("Image", file);

              axios
                .post(this.serverURL + "UploadImage", formData, {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                })
                .then((response) => {
                  //### shape the response
                  if (this.printConsole) console.log(response);

                  response.data.Data = JSON.parse(response.data.Data);
                  response = response.data;

                  //### there is an issue
                  if (response.Result !== "OK") {
                    let errorMessage =
                      ">>> ERROR: UploadImage >>> " + response.Message;
                    if (this.printConsole) {
                      console.log("----------");
                      console.log(errorMessage);
                      console.log("----------");
                    }
                    reject(errorMessage);
                    return;
                  }
                  //### everything is fine
                  else {
                    resolve(response.Data);
                  }
                })
                .catch(function (error) {
                  let errorMessage = ">>> ERROR: UploadImage >>> " + error;
                  if (this.printConsole) {
                    console.log("----------");
                    console.log(errorMessage);
                    console.log("----------");
                  }
                  reject(errorMessage);
                  return;
                });
            });
        }
      );
    });
  };

  _performBackendOperationByImageName = (imageName, callName) => {
    return new Promise((resolve, reject) => {
      //### set data for backend post
      var data = {
        ApplicationID: this.applicationID,
        ConnectionID: this.connectionID,
        QueryName: "",
        QueryParams: JSON.stringify({ imageName: imageName }),
      };

      //### perform axios
      axios({
        method: "post",
        url: this.serverURL + callName,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      })
        .then(function (response) {
          if (this.printConsole) console.log(response);
          //### shape the response
          response.data.Data = JSON.parse(response.data.Data);
          response = response.data;

          //### there is an issue
          if (response.Result !== "OK") {
            let errorMessage = ">>> ERROR: DeleteImage >>> " + response.Message;
            if (this.printConsole) {
              console.log("----------");
              console.log(errorMessage);
              console.log("----------");
            }
            reject(errorMessage);
            return;
          }
          //### everything is fine
          else {
            resolve(response.Data);
          }
        })
        .catch(function (error) {
          let errorMessage = ">>> ERROR: DeleteImage >>> " + error;
          if (this.printConsole) {
            console.log("----------");
            console.log(errorMessage);
            console.log("----------");
          }
          reject(errorMessage);
          return;
        });
    });
  };

  deleteImage = (imageName) => {
    return this._performBackendOperationByImageName(imageName, "DeleteImage");
  };

  refreshImageTimeStamp = (imageName) => {
    return this._performBackendOperationByImageName(
      imageName,
      "RefreshImageTimeStamp"
    );
  };

  isImageExist = (imageName) => {
    return this._performBackendOperationByImageName(imageName, "IsImageExist");
  };

  //###########################################################
  //###########################################################
  //###########################################################
}

//###########################################################
//###########################################################
//###########################################################

export default InstantBackend;

//###########################################################
//###########################################################
//###########################################################
