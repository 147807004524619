import React from "react";
import FacebookCircularProgress from "../screen-console/FacebookCircularProgress";
import Chip from '@mui/material/Chip';

//###########################################################
//###########################################################
//###########################################################

class Methods extends React.Component {
  //###########################################################
  //###########################################################
  //###########################################################

  constructor(props) {
    super(props);

    //### for forward references
    if (
      this.props.parent !== undefined &&
      this.props.parent.methods !== undefined
    ) {
      this.props.parent.methods = this;
    }

    this.state = {
      methods: [],
      ready: false,
    };
  }

  //###########################################################
  //###########################################################
  //###########################################################

  componentDidMount() {}

  //###########################################################
  //###########################################################
  //###########################################################

  onClinicDataHasBeenRetrieved = (data) => {
    let tempState = this.state;

    let temp = data.Methods.split(",");
    temp.forEach(method => {
        tempState.methods.push(<Chip style={{marginRight:"5px", marginBottom:"5px"}} label={method} />);
    });
    tempState.ready = true;
    this.setState(tempState);
  };

  //###########################################################
  //###########################################################
  //###########################################################

  render() {
    return (
      <>
        <div
          style={{
            display: this.state.ready ? "none" : "",
          }}
        >
          <div className="flex-row-center">
            <FacebookCircularProgress />
          </div>
        </div>

        {/* ##################### */}
        {/* ##################### */}
        {/* ##################### */}
        <div
          style={{
            display: this.state.ready ? "inline-block" : "none",
          }}
        >
            {this.state.methods}
        </div>
      </>
    );
  }
}

//###########################################################
//###########################################################
//###########################################################

export default Methods;

//###########################################################
//###########################################################
//###########################################################
