import React from "react";

import Logo from "../logo/Logo";

import LoadingButton from "@mui/lab/LoadingButton";
import HomeIcon from "@mui/icons-material/Home";

//###########################################################
//###########################################################
//###########################################################

class FinalMessage extends React.Component {
  //###########################################################
  //###########################################################
  //###########################################################

  constructor(props) {
    super(props);
    this.state = {};
  }

  //###########################################################
  //###########################################################
  //###########################################################

  componentDidMount() {}

  //###########################################################
  //###########################################################
  //###########################################################

  
  render() {
    return (
      <>
        <div
          className="get-quote-final-message-container"
          
        >
        {/* logo */}
          <div style={{width:"100%", textAlign:"center"}}>
            <Logo /></div>
          <div
            style={{
              textAlign: "center",
              fontWeight: "bold",
              fontSize: "1.4rem",
              padding: "0px 20px 0px 20px",
              marginBottom: "30px",
            }}
            className="text-color"
          >
            Thank you
          </div>
          
          {/* ########################## */}
          {/* ########################## */}
          {/* ########################## */}

          <div style={{width:"100%", textAlign:"left"}}>
          <p>Thank you for submitting your Quote Request Form.</p>
          <p>We will review your form and share with the Turkish Hair Clinics.</p>
          <p>You can see your Quote Request Form under your account.</p>
          </div>
    
        
          {/* ########################## */}
          {/* ########################## */}
          {/* ########################## */}
          {/* ########################## */}
          {/* ########################## */}
          {/* ########################## */}

   
            <div
              className="text-color flex-row-center"
              style={{
                borderTop: "1px solid #CCC",
                textAlign: "center",
                marginTop: "30px",
                paddingTop: "30px",
              }}
            >
              <LoadingButton
                size="small"
                onClick={() => {
                  let tempState = this.state;
                  tempState.btnHomePageLoading = true;
                  this.setState(tempState, () => {
                    setTimeout(function() {
                      window.location.href = "/";
                    }, 250);
                  });
                }}
                loading={this.state.btnHomePageLoading}
                loadingPosition="start"
                startIcon={<HomeIcon />}
                variant="contained"
              >
                Home Page
              </LoadingButton>
            </div>
          </div>
      </>
    );
  }
}

//###########################################################
//###########################################################
//###########################################################

export default FinalMessage;

//###########################################################
//###########################################################
//###########################################################
