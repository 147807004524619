import React from "react";
import FacebookCircularProgress from "../screen-console/FacebookCircularProgress";

//###########################################################
//###########################################################
//###########################################################

class About extends React.Component {
  //###########################################################
  //###########################################################
  //###########################################################

  constructor(props) {
    super(props);

    //### for forward references
    if (
      this.props.parent !== undefined &&
      this.props.parent.about !== undefined
    ) {
      this.props.parent.about = this;
    }

    this.parent = this.props.parent;
    this.sessionObject = this.parent.sessionObject;

    this.state = {
      about: "",
      ready: false,
      readFull: false,
    };
  }

  //###########################################################
  //###########################################################
  //###########################################################

  componentDidMount() {}

  //###########################################################
  //###########################################################
  //###########################################################

  onClinicDataHasBeenRetrieved = (data) => {
    let tempState = this.state;
    tempState.about = data.About;
    tempState.ready = true;

    this.setState(tempState);
  };

  //###########################################################
  //###########################################################
  //###########################################################

  render() {
    return (
      <>
        <div
          style={{
            display: this.state.ready ? "none" : "",
          }}
        >
          <div className="flex-row-center">
            <FacebookCircularProgress />
          </div>
        </div>

        {/* ##################### */}
        {/* ##################### */}
        {/* ##################### */}
        <div
          style={{
            display: this.state.ready ? "" : "none",
          }}
        >
          <div style={{ display: this.state.readFull ? "none" : "" }}>
            <div className="clinic-about clinic-about-short">
              {this.state.about}
            </div>
            <div
              className="flex-row-end"
              style={{ width: "100%", marginTop: "5px" }}
            >
              <div
                className="btn-primary"
                style={{ paddingTop: "5px", paddingBottom: "5px" }}
                onClick={() => {
                  let tempState = this.state;
                  tempState.readFull = !tempState.readFull;
                  this.setState(tempState);

                  //### create a log
                  this.parent.backend.CreateLog(
                    this.sessionObject.uuid,
                    "clinic-about-read-more",
                    '{"uuid": "' + this.parent.props.uuid + '"}',
                    (response) => {}
                  );
                }}
              >
                Read More
              </div>
            </div>
          </div>

          {/* ##################### */}
          {/* ##################### */}
          {/* ##################### */}
          <div style={{ display: this.state.readFull ? "" : "none" }}>
            <div className="clinic-about">{this.state.about}</div>
            <div
              className="flex-row-end"
              style={{ width: "100%", marginTop: "5px" }}
            >
              <div
                className="btn-primary"
                style={{ paddingTop: "5px", paddingBottom: "5px" }}
                onClick={() => {
                  let tempState = this.state;
                  tempState.readFull = !tempState.readFull;
                  this.setState(tempState);

                  //### create a log
                  this.parent.backend.CreateLog(
                    this.sessionObject.uuid,
                    "clinic-about-read-less",
                    '{"uuid": "' + this.parent.props.uuid + '"}',
                    (response) => {}
                  );
                  
                }}
              >
                Read Less
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

//###########################################################
//###########################################################
//###########################################################

export default About;

//###########################################################
//###########################################################
//###########################################################
