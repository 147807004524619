import React from "react";
import MySnackbar from "../commons/MySnackbar";
import MyCookieManager from "../commons/MyCookieManager";

import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Commons from "./Commons";
import Countries from "../../data/countries.json";
import Grid from "@mui/material/Grid";
import { alpha, styled } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import Collapse from "@mui/material/Collapse";

// import "react-phone-number-input/style.css";
// import PhoneInput from "react-phone-number-input";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import startsWith from "lodash.startswith";
// import "react-phone-input-2/lib/material.css";

import { isPossiblePhoneNumber } from "react-phone-number-input/mobile";

//###########################################################
//###########################################################
//###########################################################

const CssTextField = styled(TextField)({
  // "& label.Mui-focused": {
  //   color: "green"
  // },
  "& label": {
    color: "#125799",
    fontWeight: "450",
  },
  // "& .MuiInput-underline:after": {
  //   borderBottomColor: "green"
  // },
  // "& .MuiOutlinedInput-root": {
  //   "& fieldset": {
  //     borderColor: "red"
  //   },
  //   "&:hover fieldset": {
  //     borderColor: "yellow"
  //   },
  //   "&.Mui-focused fieldset": {
  //     borderColor: "green"
  //   }
  // }
});

const CssTextField2 = styled(TextField)({
  "& label": {
    color: "#000",
  },
});

class Step4 extends React.Component {
  //###########################################################
  //###########################################################
  //###########################################################

  constructor(props) {
    super(props);

    this.parent = this.props.parent;
    this.appData = this.props.appData;
    this.commons = new Commons();

    this.stepIndex = 4;

    this.isPhoneVerified = false;
    this.myCookieManager = new MyCookieManager();
    this.timeToWaitForNewVerificationCode = 10;

    this.inputLabels = {
      verificationCode: "__  __  __  __  __",
    };

    this.state = {
      display: "",
      ipCountryCode: "TR",
      phoneNumber: "",
      formattedPhoneNumber: "",
      country: null,

      txtVerificationCode: "",
      inputLabelVerificationCode: this.inputLabels.verificationCode,
      inputPropVerificationCode: (
        <OutlinedInput label={this.inputLabels.verificationCode} />
      ),

      collapseIndex: 1,
      textSendMyVerificationCode: "Send my verification code",
      textResendMyVerificationCode: "Resend my verification code",
      textVeriftMyPhoneNumber: "Verify my phone number",
      buttonClassName: "btn-primary",
      isEnable: {
        buttonSendMyVerificationCode: true,
        buttonResendMyVerificationCode: true,
        buttonVerifyMyPhoneNumber: true,
      },

      clickedTime: null,
      displayTimer: "none",
      timer: null,
      timerText: this.secondsToTimeText(this.timeToWaitForNewVerificationCode),
    };
  }

  //###########################################################
  //###########################################################
  //###########################################################

  componentDidMount() {
    let tempState = this.state;

    let temp1 = [];
    let temp2 = [];

    // #######################
    // #######################
    // #######################
    // "215***+90***TUR***TR***Turkey",

    for (let i = 0; i < Countries.length; i++) {
      let items = Countries[i].split("***");
      temp1.push(<MenuItem value={items[3]}>{items[4]}</MenuItem>);
      temp2.push(
        <MenuItem value={items[3]}>{items[1] + " " + items[2]}</MenuItem>
      );
    }
    tempState.listCountries = temp1;
    tempState.listAreaCodes = temp2;

    // #######################
    // #######################
    // #######################

    this.setState(tempState);

    // #######################
    // #######################
    // #######################

    //### check if the user's phone has been verified???
    let currentUser = this.appData.getCurrentUser();
    if (currentUser !== null && currentUser !== undefined)
      this.isPhoneVerified = currentUser.PhoneVerified === "1";
  }

  //###########################################################
  //###########################################################
  //###########################################################

  setStateOne = (key, value, callBack) => {
    let tempState = this.state;
    tempState[key] = value;
    this.setState(tempState, () => {
      if (callBack !== undefined) {
        callBack();
      }
    });
  };

  setStateMultiple = (keyValuePairs, callBack) => {
    let tempState = this.state;

    keyValuePairs.map((keyValuePair) => {
      tempState[keyValuePair[0]] = keyValuePair[1];
    });

    this.setState(tempState, () => {
      if (callBack !== undefined) {
        callBack();
      }
    });
  };

  //###########################################################
  //###########################################################
  //###########################################################

  show = () => {
    // if(this.appData.getCurrentUser().PhoneVerified === "1"){
    //   this.props.parent.setStepIndex(5);
    // }

    let tempState = this.state;
    tempState.display = "";

    let remaningTime = this.getRemaningTime();
    if (remaningTime > 0) {
      this.startTimer();
    } else {
      this.stopTimer();
    }

    this.setState(tempState);
  };

  hide = () => {
    let tempState = this.state;
    tempState.display = "none";
    this.setState(tempState);
  };

  //###########################################################
  //###########################################################
  //###########################################################

  setIPCountyCode = (val) => {
    let tempState = this.state;
    tempState.ipCountryCode = val;
    this.setState(tempState);
  };

  //###########################################################
  //###########################################################
  //###########################################################

  enterPhoneNumber = () => {
    // https://catamphetamine.gitlab.io/react-phone-number-input/
    // https://www.npmjs.com/package/react-phone-input-2
    return (
      <div style={this.commons.styleQuestionContainerSelect}>
        <FormLabel style={this.commons.styleQuestion}>
          Your mobile phone number{" "}
          {
            <span
              className="link"
              style={{ display: this.appData.isOnServer ? "none" : "" }}
              onClick={() => {
                let tempState = this.state;
                tempState.phoneNumber = "+16506226709";
                tempState.formattedPhoneNumber = "+1 (650) 622-6709";
                this.setState(tempState);
              }}
            >
              [Test]
            </span>
          }
        </FormLabel>
        <div className="divider-height-10" />
        <PhoneInput
          country={this.state.ipCountryCode.toLowerCase()}
          enableSearch={true}
          inputClass="regular-text"
          value={this.state.phoneNumber}
          onChange={(value, country, e, formattedValue) => {
            console.clear();
            console.log(country);
            // console.log(e);
            console.log(formattedValue);
            // console.log(value);

            console.log(
              this.maskThePhoneNumber(country.dialCode, formattedValue)
            );
            console.log("-------------");

            this.setStateMultiple([
              ["phoneNumber", "+" + value],
              ["formattedPhoneNumber", formattedValue],
              ["country", country],
            ]);
          }}
        />
        <div className="flex-row-end" style={{ paddingTop: "20px" }}>
          {/* ############################ */}
          {/* button send my verification code */}
          <div
            className={
              this.state.isEnable.buttonSendMyVerificationCode
                ? "btn-primary"
                : "btn-primary btn-primary-disabled"
            }
            onClick={() => {
              if (!this.state.isEnable.buttonSendMyVerificationCode) {
                return false;
              } else {
                this.sendMyVerificationCode();
              }
            }}
          >
            {this.state.textSendMyVerificationCode}
            <p
              className="no-padding no-margin text-color"
              style={{
                marginTop: "5px",
                display: this.state.displayTimer,
              }}
            >
              {this.state.timerText}
            </p>
          </div>
        </div>
      </div>
    );
  };

  //###########################################################
  //###########################################################
  //###########################################################

  editPhoneNumber = () => {
    return (
      <div style={this.commons.styleQuestionContainerSelect}>
        <FormLabel style={this.commons.styleQuestion}>
          Your mobile phone number
        </FormLabel>
        <div className="divider-height-10" />
        <div>
          <span style={{ fontWeight: 400, fontSize: "1rem" }}>
            {this.state.formattedPhoneNumber}
          </span>
          <span
            style={{ marginLeft: "10px" }}
            className="link"
            onClick={() => {
              let tempState = this.state;
              tempState.collapseIndex = 1;
              this.setState(tempState, () => {});
            }}
          >
            [Edit]
          </span>
        </div>
        <div className="flex-row-end" style={{ paddingTop: "20px" }}>
          {/* ############################ */}
          {/* button re-send my verification code */}
          <div
            className={
              this.state.isEnable.buttonResendMyVerificationCode
                ? "btn-primary"
                : "btn-primary btn-primary-disabled"
            }
            onClick={() => {
              if (!this.state.isEnable.buttonResendMyVerificationCode) {
                return false;
              } else {
                this.sendMyVerificationCode();
              }

              // if (this.state.isBtnSendVerificationEnable === false) {
              //   return false;
              // }
              // this.setClickedTime();
              // this.startTimer();
              // this.appData.snackbar().alertInfo("Verification code has been sent.");
            }}
          >
            {this.state.textResendMyVerificationCode}
            <p
              className="no-padding no-margin text-color"
              style={{
                marginTop: "5px",
                display: this.state.displayTimer,
              }}
            >
              {this.state.timerText}
            </p>
          </div>
        </div>
      </div>
    );
  };

  //###########################################################
  //###########################################################
  //###########################################################

  enterVerificationCode = () => {
    return (
      <div style={this.commons.styleQuestionContainerSelect}>
        <FormControl fullWidth>
          <FormLabel style={this.commons.styleQuestion}>
            Enter your verification code
          </FormLabel>
          <div className="divider-height-10" />
          <CssTextField2
            inputRef={(el) => (this.txtVerificationCode = el)}
            label={this.state.inputLabelVerificationCode}
            input={this.state.inputPropVerificationCode}
            style={{ maxWidth: "400px" }}
            InputProps={{
              className: "regular-text",
            }}
            value={this.state.txtVerificationCode}
            onChange={(e) => {
              this.setStateOne("txtVerificationCode", e.target.value);
            }}
            onFocus={() => {
              let tempState = this.state;
              tempState.inputLabelVerificationCode = undefined;
              tempState.inputPropVerificationCode = undefined;
              this.setState(tempState);
            }}
            onBlur={() => {
              let tempState = this.state;
              if (this.state.txtVerificationCode.trim() === "") {
                tempState.inputLabelVerificationCode = this.inputLabels.verificationCode;
                tempState.inputPropVerificationCode = (
                  <OutlinedInput label={this.inputLabels.verificationCode} />
                );
              } else {
                tempState.inputLabelVerificationCode = undefined;
                tempState.inputPropVerificationCode = undefined;
              }

              this.setState(tempState, () => {});
            }}
            helperText="Input the verification code we sent to +1 (655) 622-6709"
          />
        </FormControl>
        <div className="divider-height-20" />
        <div className="flex-row-end">
          {/* ############################ hakan*/}
          {/* button verify my phone number */}
          <div
            className={
              this.state.isEnable.buttonVerifyMyPhoneNumber
                ? "btn-primary"
                : "btn-primary btn-primary-disabled"
            }
            onClick={() => {
              if (!this.state.isEnable.buttonVerifyMyPhoneNumber) {
                return false;
              } else {
                if (
                  !this.appData.validator.isNumeric(
                    this.state.txtVerificationCode
                  ) ||
                  this.state.txtVerificationCode.length !== 5
                ) {
                  this.appData
                    .snackbar()
                    .alertError("Verification code is wrong.");
                } else {
                  //### step 1
                  let tempState = this.state;
                  tempState.isEnable.buttonVerifyMyPhoneNumber = false;
                  tempState.textVeriftMyPhoneNumber = "Please wait...";
                  this.setState(tempState);

                  //### step 2
                  this.appData.verificationManager.verifyPhoneWithVerificationCode(
                    this.appData.getCurrentUser().UUID,
                    this.state.txtVerificationCode,
                    (response) => {
                      let tempState = this.state;
                      tempState.isEnable.buttonVerifyMyPhoneNumber = true;
                      tempState.textVeriftMyPhoneNumber =
                        "Verify my phone number";
                      this.setState(tempState);

                      if (response.Result === "Correct") {
                        this.appData.snackbar().close();
                        this.appData
                          .snackbar()
                          .alertSuccess("Your phone number has been verified.");
                        this.isPhoneVerified = true;
                        this.props.parent.setStepIndex(5);
                      } else if (response.Result === "Wrong") {
                        this.appData
                          .snackbar()
                          .alertError("Verification code is wrong");
                      } else {
                        this.appData.snackbar().alertError(response.Result);
                      }
                    }
                  );
                }
              }
            }}
          >
            {this.state.textVeriftMyPhoneNumber}
          </div>
        </div>
      </div>
    );
  };

  //###########################################################
  //###########################################################
  //###########################################################

  maskThePhoneNumber = (dialCode, formattedPhoneNumber) => {
    let numbers = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
    let res = "+";

    let len = formattedPhoneNumber.length;
    let lenDialCode = dialCode.length;

    let indexNumbers = 0;
    let countNummbers = 0;
    for (let i = 0; i < len; i++) {
      let char = formattedPhoneNumber[i].toString();
      if (numbers.includes(char)) {
        countNummbers++;
      }
    }

    for (let i = 1; i < len; i++) {
      let char = formattedPhoneNumber[i].toString();

      if (!numbers.includes(char)) {
        res += char;
      } else if (
        indexNumbers < lenDialCode + 2 ||
        indexNumbers >= countNummbers - 2
      ) {
        res += char;
        indexNumbers++;
      } else {
        res += "X";
        indexNumbers++;
      }
    }

    return res;
  };

  //###########################################################
  //###########################################################
  //###########################################################

  disableButtons = () => {
    let tempState = this.state;
    if (tempState.isEnable.buttonSendMyVerificationCode)
      tempState.textSendMyVerificationCode = "Please wait...";
    if (tempState.isEnable.buttonResendMyVerificationCode)
      tempState.textResendMyVerificationCode = "Please wait...";
    tempState.isEnable.buttonSendMyVerificationCode = false;
    tempState.isEnable.buttonResendMyVerificationCode = false;
    this.setState(tempState);
  };

  enableButtons = () => {
    let tempState = this.state;
    tempState.textSendMyVerificationCode = "Send my verification code";
    tempState.textResendMyVerificationCode = "Resend my verification code";
    tempState.isEnable.buttonSendMyVerificationCode = true;
    tempState.isEnable.buttonResendMyVerificationCode = true;
    this.setState(tempState);
  };

  //###########################################################
  //###########################################################
  //###########################################################

  sendMyVerificationCode = () => {
    if (
      !this.appData.isOnServer &&
      this.state.phoneNumber !== "+16506226709" &&
      this.state.phoneNumber !== "+16506844746"
    ) {
      alert("You can not send this number!!!");
      return false;
    } else {
      //### step 1 - check if the phone number is possibly valid
      if (!isPossiblePhoneNumber(this.state.phoneNumber)) {
        this.appData.snackbar().close();
        this.appData.snackbar().alertError("Please check your phone number.");
      } else {
        //### step 2 - disable the button
        this.disableButtons();

        //### step 2.1 create phone data
        let phoneData = this.state.country;
        phoneData.maskedPhoneNumber = this.maskThePhoneNumber(
          this.state.country.dialCode,
          this.state.formattedPhoneNumber
        );

        //### step 3 - send verification code.
        this.appData.verificationManager.sendPhoneVerificationCode(
          this.appData.getCurrentUser().UUID,
          this.state.phoneNumber,
          this.state.formattedPhoneNumber,
          JSON.stringify(phoneData),
          (response) => {
            //### the phone number is not valid
            if (response.Result === "Error") {
              this.enableButtons();
              this.appData.snackbar().close();
              this.appData
                .snackbar()
                .alertError("Please check your phone number.");
            } else {
              this.setClickedTime();
              this.startTimer();

              this.appData.snackbar().close();
              this.appData
                .snackbar()
                .alertSuccess("Verification code has been sent.");

              let tempState = this.state;
              tempState.textSendMyVerificationCode =
                "Send my verification code";
              tempState.textResendMyVerificationCode =
                "Resend my verification code";
              tempState.collapseIndex = 2;
              this.setState(tempState, () => {
                setTimeout(() => {
                  this.txtVerificationCode.focus();
                }, 100);
              });
            }
          }
        );
      }
    }
  };

  //###########################################################
  //###########################################################
  //###########################################################

  setClickedTime = () => {
    let tempState = this.state;
    tempState.clickedTime = Date.now();
    this.myCookieManager.set("ClickedTime", tempState.clickedTime, 1);
    this.setState(tempState);
  };

  getClickedTime = () => {
    let clickedTimeFromCookie = this.myCookieManager.get("ClickedTime");
    if (clickedTimeFromCookie !== undefined) {
      return clickedTimeFromCookie;
    }

    return this.state.clickedTime;
  };

  clearClicedTime = () => {
    let tempState = this.state;
    tempState.clickedTime = null;
    this.myCookieManager.delete("ClickedTime");
    this.setState(tempState);
  };

  //###########################################################
  //###########################################################
  //###########################################################

  secondsToTimeText = (val) => {
    let minutes = parseInt(val / 60, 10);
    let seconds = parseInt(val % 60, 10);

    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;

    return minutes + ":" + seconds;
  };

  //###########################################################
  //###########################################################
  //###########################################################

  startTimer = () => {
    let tempState = this.state;

    let remaningTime = this.getRemaningTime();

    if (this.state.timer === null) {
      let _this = this;
      let timer = setInterval(function() {
        remaningTime--;

        if (remaningTime <= 0) {
          _this.stopTimer();
        } else {
          //### set the timerText
          let tempStateInInterval = _this.state;
          tempStateInInterval.timerText = _this.secondsToTimeText(remaningTime);
          tempStateInInterval.displayTimer = "";
          _this.setState(tempStateInInterval);
        }
      }, 1000);

      tempState.timer = timer;
    }

    tempState.isEnable.buttonSendMyVerificationCode = false;
    tempState.isEnable.buttonResendMyVerificationCode = false;
    this.setState(tempState);
  };

  stopTimer = () => {
    let tempState = this.state;

    tempState.clickedTime = null;
    this.myCookieManager.delete("ClickedTime");
    tempState.isEnable.buttonSendMyVerificationCode = true;
    tempState.isEnable.buttonResendMyVerificationCode = true;
    tempState.displayTimer = "none";
    tempState.timerText = this.secondsToTimeText(
      this.timeToWaitForNewVerificationCode
    );

    if (tempState.timer !== null) {
      clearInterval(tempState.timer);
      tempState.timer = null;
    }

    this.setState(tempState);
  };

  getRemaningTime = () => {
    let clickedTime = this.getClickedTime();

    if (clickedTime === null) return 0;

    let diff = Math.abs(clickedTime - Date.now()) / 1000;
    diff = Math.round(diff);

    return this.timeToWaitForNewVerificationCode - diff;

    // if (diff <= 0) {
    //   this.myCookieManager.delete("ClickedTime");
    //   return 180;
    // }

    // return diff;
  };

  //###########################################################
  //###########################################################
  //###########################################################

  getValues = () => {
    let res = [];

    // let selectedCountry = this.state.selectedCountry;
    // let selectedAreaCode = this.state.selectedAreaCode;
    // if (selectedCountry.trim() !== "") {
    //   for (let i = 0; i < Countries.length; i++) {
    //     let items = Countries[i].split("***");
    //     if (items[3] === selectedCountry) {
    //       selectedCountry = items[4];
    //     }
    //     if (items[3] === selectedAreaCode) {
    //       selectedAreaCode = items[1];
    //     }
    //   }
    // }

    if (this.state.country !== null) {
      res.push({ key: "Country", value: this.state.country.name });
    }
    res.push({
      key: "Phone Number",
      value: this.state.formattedPhoneNumber,
    });
    res.push({
      key: "_phoneNumber",
      value: this.state.phoneNumber,
    });
    res.push({
      key: "_country",
      value: this.state.country,
    });
    return res;
  };

  //###########################################################
  //###########################################################
  //###########################################################

  validate = (alwaysReturnTrue) => {
    let validationResult = { result: true, message: "" };

    let bullets = [];

    //############################

    //### remove this part for production
    if (alwaysReturnTrue !== undefined && alwaysReturnTrue === true) {
      while (bullets.length > 0) {
        bullets.pop();
      }
    }

    //############################

    if (bullets.length > 0) {
      validationResult.result = false;
      validationResult.message = (
        <>
          <p>Please answer the following question(s):</p>
          <ul>{bullets}</ul>
        </>
      );
    }

    return validationResult;
  };

  //###########################################################
  //###########################################################
  //###########################################################

  render() {
    return (
      <div
        className="width-with-padding-20"
        style={{
          display: this.state.display,
          padding: "10px 20px 20px 20px",
        }}
      >
        {/* ############ */}
        {/* ############ */}
        {/* ############ */}
        {/* title */}
        <div
          className="flex-column-center"
          style={this.commons.styleQuestionContainerSelect}
        >
          <span
            className="title-3"
            style={{ marginTop: "10px", marginBottom: "10px" }}
          >
            Verification
          </span>
          <p
            className="no-padding no-margin regular-text"
            style={{ textAlign: "center" }}
          >
            Please verify your phone number.
          </p>
        </div>
        {/* ############ */}
        {/* ############ */}
        {/* ############ */}
        <div className="divider-height-25" />
        <Collapse in={this.state.collapseIndex === 1}>
          {this.enterPhoneNumber()}
        </Collapse>
        <Collapse in={this.state.collapseIndex === 2}>
          {this.editPhoneNumber()}
          <div className="divider-height-25" />
          {this.enterVerificationCode()}
        </Collapse>
      </div>
    );
  }
}

//###########################################################
//###########################################################
//###########################################################

export default Step4;

//###########################################################
//###########################################################
//###########################################################
