import React from "react";
import "./Authentication.css";

import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";

import InputAdornment from "@mui/material/InputAdornment";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";

import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import FormValidation from "./FormValidation";
import HomeIcon from "@mui/icons-material/Home";

// import { withSnackbar } from "notistack";

//###########################################################
//###########################################################
//###########################################################

class SignUp extends React.Component {
  //###########################################################
  //###########################################################
  //###########################################################

  constructor(props) {
    super(props);

    this.appData = this.props.appData;
    this.parent = this.props.parent;
    this.formValidation = new FormValidation(this.appData);   

    this.state = {
      showPassword: false,
      loading: false,
      btnHomePageLoading: false,
      errors: {
        fullName: false,
        email: false,
        password: false,
      },
    };
  }

  //###########################################################
  //###########################################################
  //###########################################################

  isFormInputValid = (callBack) => {
    this.formValidation.formInputs(
      {
        name: this.txtFullName.value,
        email: this.txtEmail.value,
        password: this.txtPassword.value,
      },
      (validationResult) => {
        let tempState = this.state;

        tempState.errors.email =
          validationResult.email.length > 0 ? true : false;
        tempState.errors.password =
          validationResult.password.length > 0 ? true : false;
        tempState.errors.fullName =
          validationResult.fullName.length > 0 ? true : false;

        if (validationResult.warningText !== "") {
          this.appData.snackbar().alertError(validationResult.warningText);
        } else {
          this.appData.snackbar().close();
        }

        this.setState(tempState);
        callBack(validationResult.warningText === "");
      }
    );
  };

  //###########################################################
  //###########################################################
  //###########################################################

  btnSignUpClicked = () => {
    this.appData.snackbar().close();
    // this.props.closeSnackbar();

    this.isFormInputValid((isFormValid) => {
      //#### form is valid
      if (isFormValid) {
        //### set as loading True
        let tempState = this.state;
        tempState.loading = true;
        this.setState(tempState, () => {
          //### set the params
          let paramsCreateUser = {
            name: this.txtFullName.value,
            email: this.txtEmail.value,
            password: this.txtPassword.value,
          };
          //### make your call to backend
          this.appData.accountManager.signUp(paramsCreateUser, (response) => {
            if (this.appData.printConsole) console.log(response);
            //### got your response
            //### set as loading False
            let tempState = this.state;
            tempState.loading = false;
            this.setState(tempState, () => {
              //### lets handle the error first
              if (response.Result === "Error") {
                this.appData.snackbar().alertError(response.Message);
              } else {
                //### a new user
                if (response.Result === "New") {
                  //### go to email verification
                  this.parent.openConfirmationCode();
                }
                //### an existing user
                else if (response.Result === "Exist") {
                  this.parent.openAuthenticationMessages({
                    type: "UserExist",
                  });
                  // this.parent.openUserIsExist();
                }
              }
            });
          });
        });
      }
    });

    // this.validate((validationResult) => {
    //   if (!validationResult) {
    //     let tempState = this.state;
    //     tempState.errors.email = true;
    //     this.setState(tempState);
    //     this.props.enqueueSnackbar("Please check your e-mail", {
    //       variant: "error",
    //       autoHideDuration: 3500,
    //       anchorOrigin: {
    //         vertical: "bottom",
    //         horizontal: "center"
    //       }
    //     });
    //   }
    // });
  };

  handleClickShowPassword = () => {
    let tempState = this.state;
    tempState.showPassword = !tempState.showPassword;
    this.setState(tempState);
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  //###########################################################
  //###########################################################
  //###########################################################

  onTextFieldFocus = () => {
    this.appData.snackbar().close();
    let tempState = this.state;
    tempState.errors.fullName = false;
    tempState.errors.email = false;
    tempState.errors.password = false;
    this.setState(tempState);
  };

  //###########################################################
  //###########################################################
  //###########################################################

  componentDidMount() {}

  //###########################################################
  //###########################################################
  //###########################################################

  render() {
    return (
      <>
        <div
          className="sign-in-container"
          style={{
            border: this.parent.authenticationDialog !== null ? "none" : "",
           
          }}
        >
        {/* message container */}
        <div
          className="authentication-message-div"
          style={{
            display: this.props.options.message !== undefined ? "" : "none",
          }}
        >
          {this.props.options.message}
        </div>
        {/* logo container */}
        <div
          className="authentication-logo-div"
          style={{
            display: this.props.options.logo !== undefined ? "" : "none",
          }}
        >
          {this.props.options.logo}
        </div>
          <div
            className="flex-column-start"
            style={{
              padding: "0px 20px 0px 20px",
              marginBottom: "30px",
            }}
          >
            <span
              style={{
                textAlign: "center",
                fontWeight: "bold",
                fontSize: "1.4rem",
              }}
              className="text-color"
            >
              Sign Up
            </span>
            <span style={{ fontSize: "0.9rem" }}>
              We're so happy you're here
            </span>
          </div>

          
          {/* UI TEST */}
          <div
            style={{
              display: this.appData.isOnServer ? "none" : "",
              textAlign: "center",
              padding: "10px",
              background: "#efefef",
              marginBottom: "30px",
              borderRadius: "5px",
            }}
          >
            <span
              className="link"
              onClick={() => {
                this.txtFullName.value = "hakan bulu";
                this.txtEmail.value = "a@b.com";
                this.txtPassword.value = "112233";
              }}
            >
              Test
            </span>
          </div>


          <TextField
            error={this.state.errors.fullName}
            inputRef={(el) => (this.txtFullName = el)}
            label="Full Name"
            variant="outlined"
            style={{ width: "100%", marginBottom: "20px" }}
            InputProps={{
              style: { fontFamily: "Poppins" },
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircleOutlinedIcon />
                </InputAdornment>
              ),
            }}
            onFocus={() => {
              this.onTextFieldFocus();
            }}
          />
          <TextField
            error={this.state.errors.email}
            inputRef={(el) => (this.txtEmail = el)}
            label="E-Mail"
            variant="outlined"
            style={{ width: "100%", marginBottom: "20px" }}
            InputProps={{
              style: { fontFamily: "Poppins" },
              startAdornment: (
                <InputAdornment position="start">
                  <EmailOutlinedIcon />
                </InputAdornment>
              ),
            }}
            onFocus={() => {
              this.onTextFieldFocus();
            }}
          />
          <TextField
            error={this.state.errors.password}
            inputRef={(el) => (this.txtPassword = el)}
            label="Password"
            variant="outlined"
            style={{ width: "100%", marginBottom: "30px" }}
            type={this.state.showPassword ? "text" : "password"}
            helperText="(Minimum  6 characters.)"
            InputProps={{
              style: { fontFamily: "Poppins" },
              startAdornment: (
                <InputAdornment position="start">
                  <LockOutlinedIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={this.handleClickShowPassword}
                    onMouseDown={this.handleMouseDownPassword}
                    edge="end"
                  >
                    {this.state.showPassword ? (
                      <VisibilityOff />
                    ) : (
                      <Visibility />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onFocus={() => {
              this.onTextFieldFocus();
            }}
          />
          <LoadingButton
            style={{
              width: "100%",
              paddingTop: "15px",
              paddingBottom: "15px",
              fontSize: "1rem",
              marginBottom: "20px",
            }}
            onClick={this.btnSignUpClicked}
            loading={this.state.loading}
            loadingIndicator="Please wait..."
            variant="contained"
          >
            Create Your Free Account
          </LoadingButton>
          <div
            style={{
              textAlign: "center",
              fontSize: "1.0rem",
              marginBottom: "30px",
            }}
            className="text-color flex-row-center"
          >
            Already registered?
            <span
              className="link-color"
              style={{
                marginLeft: "5px",
                padding: "0px",
                fontSize: "1.0rem",
              }}
              onClick={(e) => {
                this.parent.signInClicked(true);
              }}
            >
              Sign in
            </span>
          </div>
          <div
            className="text-color"
            style={{
              fontSize: "0.8rem",
              borderTop: "1px solid #CCC",
              textAlign: "justify",
              paddingTop: "5px",
              paddingBottom: "5px",
            }}
          >
            By continuing, you agree to our{" "}
            <span className="link-color">Term's and Privacy Policy</span>.
          </div>

          {/* <div
            style={{
              fontSize: "0.85rem",
              textAlign: "justify",
              marginBottom: "0px",
            }}
          >
            By continuing, you agree to our
            <span className="link-color">Term's and Privacy Policy</span>.
          </div> */}

          <div
            style={{
              display: this.parent.authenticationDialog !== null ? "none" : "",
            }}
          >
            <div
              className="text-color flex-row-center"
              style={{
                borderTop: "1px solid #CCC",
                textAlign: "center",
                paddingTop: "30px",
              }}
            >
              <LoadingButton
                size="small"
                onClick={() => {
                  let tempState = this.state;
                  tempState.btnHomePageLoading = true;
                  this.setState(tempState, () => {
                    setTimeout(function() {
                      window.location.href = "/";
                    }, 250);
                  });
                }}
                loading={this.state.btnHomePageLoading}
                loadingPosition="start"
                startIcon={<HomeIcon />}
                variant="contained"
              >
                Home Page
              </LoadingButton>
            </div>
          </div>
        </div>
      </>
    );
  }
}

//###########################################################
//###########################################################
//###########################################################

// export default withSnackbar(SignUp);
export default SignUp;

//###########################################################
//###########################################################
//###########################################################
