import axios from "axios";

//###########################################################
//###########################################################
//###########################################################

class AccountManager {
  constructor(config) {
    this.serverURL = config.serverURL + "/Authentication/";
    this.applicationConfigurationID = config.applicationConfigurationID;
    this.printConsole =
      config.printConsole !== undefined ? config.printConsole : false;
    this.sessionStorage = window.sessionStorage;
    this.sessionKey = "~account~manager~user~";
  }

  //###########################################################
  //###########################################################
  //###########################################################

  getAxiosConfig = (backEndCallName, data) => {
    return {
      method: "post",
      url: this.serverURL + backEndCallName,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
  };

  postProcessResponse = (response) => {
    //### I just need data value
    response = response.data;
    //### parse the Data
    response.Data = JSON.parse(response.Data);

    return response;
  };

  //###########################################################
  //###########################################################
  //###########################################################

  /*
    *** USAGE ***

    params = {
      name: @name,
      email: @email,
      password: @password,
    };

    response.Result => ["New", "Exist", "Error"]
    
    if response.Result in ["New", "Exist"] => 
      response.Data is the userObject
    and
      userObject can be accessed via this.getUser()

  */

  signUp = (params, callBack) => {
    var data = {
      ApplicationConfigurationID: this.applicationConfigurationID,
      QueryName: "",
      QueryParams: JSON.stringify(params),
    };

    let _this = this;

    axios(_this.getAxiosConfig("CreateUser", data))
      .then(function (response) {
        //### post process the response
        response = _this.postProcessResponse(response);

        if (response.Result === "New") {
          let userObject = response.Data[0];
          //### from array to an object
          response.Data = userObject;

          //### set the session with the user
          _this.sessionStorage.setItem(
            _this.sessionKey,
            JSON.stringify(userObject)
          );
        }

        callBack(response);
      })
      .catch(function (error) {
        callBack(error);
      });
  };

  //###########################################################
  //###########################################################
  //###########################################################

  /*

    *** USAGE ***
  
    params = {
      uuid: @uuid,
      verificationCode: @verificationCode,
    };

    response.Result => ["Correct", "Wrong, "Error"]

    if response.Result in ["Correct"] => 
      response.Data is the userObject
    and
      userObject can be accessed via this.getUser()

  */

  // verifyEmail = (params, callBack) => {
  //   var data = {
  //     ApplicationConfigurationID: this.applicationConfigurationID,
  //     QueryName: "",
  //     QueryParams: JSON.stringify(params),
  //   };

  //   let _this = this;

  //   axios(this.getAxiosConfig("VerifyEmail", data))
  //     .then(function (response) {
  //       //### post process the response
  //       response = _this.postProcessResponse(response);

  //       if (response.Result === "Correct") {
  //         let userObject = response.Data[0];
  //         //### from array to an object
  //         response.Data = userObject;

  //         //### set the session with the user
  //         _this.sessionStorage.setItem(
  //           _this.sessionKey,
  //           JSON.stringify(userObject)
  //         );
  //       }

  //       callBack(response);
  //     })
  //     .catch(function (error) {
  //       callBack(error);
  //     });
  // };

  //###########################################################
  //###########################################################
  //###########################################################

  /*
    params = {
      email: @email,
      password: @password,
    };

    response.Result => ["Succeeded", "Failed", "Error"]

    if response.Result in ["Correct"] => 
      response.Data is the userObject
    and
      userObject can be accessed via this.getUser()

  */

  signIn = (params, callBack) => {
    var data = {
      ApplicationConfigurationID: this.applicationConfigurationID,
      QueryName: "",
      QueryParams: JSON.stringify(params),
    };

    let _this = this;

    axios(_this.getAxiosConfig("GetUser", data))
      .then(function (response) {
        //### post process the response
        response = _this.postProcessResponse(response);

        if (response.Result === "Succeeded") {
          let userObject = response.Data[0];
          if(userObject.PhoneData !== null){
            userObject.PhoneData = JSON.parse(userObject.PhoneData);
          }
          //### from array to an object
          response.Data = userObject;

          //### set the session with the user
          _this.sessionStorage.setItem(
            _this.sessionKey,
            JSON.stringify(userObject)
          );
        }

        callBack(response);
      })
      .catch(function (error) {
        callBack(error);
      });
  };

  //###########################################################
  //###########################################################
  //###########################################################

  /*

    *** USAGE ***
  
    params = {
      email: @email,
    };

    response.Result => ["NotExist", "Sent", "Error"]

  */

  sendPasswordResetLink = (params, callBack) => {
    var data = {
      ApplicationConfigurationID: this.applicationConfigurationID,
      QueryName: "",
      QueryParams: JSON.stringify(params),
    };

    let _this = this;

    axios(_this.getAxiosConfig("SendPasswordResetLink", data))
      .then(function (response) {
        if (_this.printConsole) console.log(response);
        //### post process the response
        response = _this.postProcessResponse(response);

        callBack(response);
      })
      .catch(function (error) {
        callBack(error);
      });
  };

  //###########################################################
  //###########################################################
  //###########################################################

  /*

    *** USAGE ***
  
    params = {
      queryType: @queryType,
      uuid: @uuid,
    };

    response.Result => ["NotExist", "Expired", "OK", "Error"]

    if response.Result in ["OK"] => 
      response.Data is the userObject but this userObject only contains UUID and Email
    and
      userObject can be accessed via this.getUser()

  */

  // processQueryUUID = (params, callBack) => {
  //   var data = {
  //     ApplicationConfigurationID: this.applicationConfigurationID,
  //     QueryName: "",
  //     QueryParams: JSON.stringify(params),
  //   };

  //   let _this = this;

  //   axios(this.getAxiosConfig("ProcessQueryUUID", data))
  //     .then(function (response) {
  //       //### post process the response
  //       response = _this.postProcessResponse(response);

  //       if (response.Result === "OK") {
  //         let userObject = response.Data[0];
  //         //### from array to an object
  //         response.Data = userObject;

  //         //### set the session with the user
  //         _this.sessionStorage.setItem(
  //           _this.sessionKey,
  //           JSON.stringify(userObject)
  //         );
  //       }

  //       callBack(response);
  //     })
  //     .catch(function (error) {
  //       callBack(error);
  //     });
  // };

  //###########################################################
  //###########################################################
  //###########################################################

  /*

    *** USAGE ***
  
    params = {
      uuid: @uuid,
      password: @password,
    };

    response.Result => ["OK", "Error"]

  */

  updatePassword = (params, callBack) => {
    var data = {
      ApplicationConfigurationID: this.applicationConfigurationID,
      QueryName: "",
      QueryParams: JSON.stringify(params),
    };

    let _this = this;

    axios(this.getAxiosConfig("UpdatePassword", data))
      .then(function (response) {
        //### post process the response
        response = _this.postProcessResponse(response);

        callBack(response);
      })
      .catch(function (error) {
        callBack(error);
      });
  };

  //###########################################################
  //###########################################################
  //###########################################################

  logOut = () => {
    return this.sessionStorage.removeItem(this.sessionKey);
  };

  getUser = () => {
    let sessionValue = this.sessionStorage.getItem(this.sessionKey);
    if (sessionValue === null || sessionValue === undefined) {
      return null;
    }
    return JSON.parse(this.sessionStorage.getItem(this.sessionKey));
  };

  //###########################################################
  //###########################################################
  //###########################################################

  setFlag = () => {};

  //###########################################################
  //###########################################################
  //###########################################################
}

//###########################################################
//###########################################################
//###########################################################

export default AccountManager;

//###########################################################
//###########################################################
//###########################################################
