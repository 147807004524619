import React from "react";
import { Link, useNavigate } from "react-router-dom";
import MyAccount from "./MyAccount";
import Header from "../header/Header";
import Footer from "../footer/Footer";

//###########################################################
//###########################################################
//###########################################################

class TestPage extends React.Component {
  //###########################################################
  //###########################################################
  //###########################################################

  constructor(props) {
    super(props);

    this.appData = this.props.appData;
    this.isPrivate = true;

    this.state = {
      minHeight: 100,
    };
  }

  //###########################################################
  //###########################################################
  //###########################################################

  componentDidMount() {
    if (
      this.appData.header !== undefined &&
      this.appData.footer !== undefined
    ) {
      let tempState = this.state;
      let clientHeight = this.appData.getClientSize().height;
      let headerHeight = this.appData.header.getHeight();
      let footerHeight = this.appData.footer.getHeight();
      tempState.minHeight = clientHeight - headerHeight - 1;
      this.setState(tempState);
    }
  }

  //###########################################################
  //###########################################################
  //###########################################################

  render() {
    let minHeight = 100;
    if (
      this.appData.header !== undefined &&
      this.appData.footer !== undefined
    ) {
      let clientHeight = this.appData.getClientSize().height;
      let headerHeight = this.appData.header.getHeight();
      let footerHeight = this.appData.footer.getHeight();
      minHeight = clientHeight - headerHeight - 1;
    }

    return (
      <div>
        <Header parent={this} appData={this.appData} action={this.action} />
        <div className="flex-column-start">
          <MyAccount
            parent={this}
            appData={this.appData}
            minHeight={minHeight}
          />
        </div>
        <Footer
          parent={this}
          appData={this.appData}
          alternateClassName="footer-blue"
        />
      </div>
    );
  }
}

//###########################################################
//###########################################################
//###########################################################

export default function(props) {
  const navigate = useNavigate();
  return <TestPage {...props} navigate={navigate} />;
}

//###########################################################
//###########################################################
//###########################################################
