import React from "react";

//###########################################################
//###########################################################
//###########################################################

class Commons extends React.Component {
  //###########################################################
  //###########################################################
  //###########################################################

  constructor(props) {
    super(props);

    this.styleQuestionContainerRadio = {
      padding: "20px 15px 10px 15px",
      borderRadius: "5px",
      // background: "#F8F9F9",
      // border: "1px solid #F2F2F2",

      // background: "#F4F4F4",
      border: "1px solid #125799",

      background: "#F7F7F7"
    };

    this.styleQuestionContainerSelect = {
      padding: "20px 15px 20px 15px",
      borderRadius: "5px",
      // background: "#F8F9F9",
      background: "#F7F7F7",
      border: "1px solid #125799"
      // width: "100%"
    };
    this.styleQuestion = {
      // color: "#095484",
      // color: "#A93226", // bordo
      color: "#125799", // mavi
      fontWeight: 450,
      fontFamily: "inherit"
    };
    this.styleQuestionSelect = {
      color: "#FF5700",
      fontWeight: 450,
      paddingBottom: "25px",
      fontFamily: "inherit"
    };
  }
}

//###########################################################
//###########################################################
//###########################################################

export default Commons;

//###########################################################
//###########################################################
//###########################################################
