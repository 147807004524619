import React from "react";
import MainCommonCalls from "../../../classes/CommonCalls";

//###########################################################
//###########################################################
//###########################################################

class CommonCalls extends React.Component {


  constructor(props) {
    super(props);
    this.mainCommonCalls = new MainCommonCalls();
    this.state = {};
  }

  signInClicked = () => {
    setTimeout(function() {
      window.location.href = "/signin";
    }, 250);
  };

  signUpClicked = () => {
    setTimeout(function() {
      window.location.href = "/signup";
    }, 250);
  };

  openConfirmationCode = () => {
    setTimeout(function() {
      window.location.href = "/confirmationCode";
    }, 250);
  };

  openForgotPassword = () =>{    
    setTimeout(function() {
      window.location.href = "/forgotPassword";
    }, 250);
  }

  openAuthenticationMessages = (params) => {
    let str = JSON.stringify(params);
    str = this.mainCommonCalls.myCipher(str);
    setTimeout(function() {
      window.location.href = "/authenticationMessages?params=" + str;
    }, 250);
  };

  refresh = () =>{
    setTimeout(function() {
      window.location.href = "/";
    }, 250);
  }

  //###########################################################
  //###########################################################
  //###########################################################
}

//###########################################################
//###########################################################
//###########################################################

export default CommonCalls;

//###########################################################
//###########################################################
//###########################################################
