import React from "react";
import { Link, useNavigate } from "react-router-dom";
import SignIn from "./SignIn";
import SignUp from "./SignUp";
import ConfirmationCode from "./ConfirmationCode";
import ForgotPassword from "./ForgotPassword";
// import UserIsExist from "./UserIsExist";
import CreateNewPassword from "./CreateNewPassword";
import MySnackbar from "../commons/MySnackbar";
import Message from "./Message";

//###########################################################
//###########################################################
//###########################################################

class TestPage extends React.Component {
  //###########################################################
  //###########################################################
  //###########################################################

  constructor(props) {
    super(props);
    this.state = {};
  }

  //###########################################################
  //###########################################################
  //###########################################################

  componentDidMount() {}

  //###########################################################
  //###########################################################
  //###########################################################

  render() {
    return (
      <>
        <MySnackbar ref={(el) => (this.mySnackbar = el)} />
        <div className="flex-column-start" style={{ paddingTop: "30px" }}>
          <Link className="link" to="/">
            Home
          </Link>
          <hr className="line-25" />
          <SignUp parent={this} />
          <hr className="line-25" />
          <SignIn parent={this} />
          <hr className="line-25" />
          <ConfirmationCode parent={this} />
          <hr className="line-25" />
          <ForgotPassword parent={this} />
          {/* <hr className="line-25" />
          <UserIsExist parent={this} /> */}
          <hr className="line-25" />
          <CreateNewPassword parent={this} />
          <hr className="line-25" />
          <Message parent={this} />
          <hr className="line-25" />
        </div>
      </>
    );
  }
}

//###########################################################
//###########################################################
//###########################################################

export default function(props) {
  const navigate = useNavigate();
  return <TestPage {...props} navigate={navigate} />;
}

//###########################################################
//###########################################################
//###########################################################
