import React from "react";

import { withSnackbar } from "notistack";
//###########################################################
//###########################################################
//###########################################################

class MySnackbar extends React.Component {
  //###########################################################
  //###########################################################
  //###########################################################

  constructor(props) {
    super(props);

    if (this.props.appData !== undefined) {
      this.appData = this.props.appData;
      // this.appData.snackbar = this;
    }

    this.state = {};
  }

  //###########################################################
  //###########################################################
  //###########################################################

  componentDidMount() {}

  //###########################################################
  //###########################################################
  //###########################################################

  _alert = (message, variant) => {
    this.props.enqueueSnackbar(message, {
      variant: variant,
      autoHideDuration: 3500,
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "right"
      },
      style: { whiteSpace: "pre-line" }
    });
  };

  alert = (message) => {
    this._alert(message, "default");
  };

  alertSuccess = (message) => {
    this._alert(message, "success");
  };

  alertError = (message) => {
    this._alert(message, "error");
  };

  alertWarning = (message) => {
    this._alert(message, "warning");
  };

  alertInfo = (message) => {
    this._alert(message, "info");
  };

  //###########################################################
  //###########################################################
  //###########################################################

  close = () => {
    this.props.closeSnackbar();
  };

  //###########################################################
  //###########################################################
  //###########################################################

  render() {
    return <></>;
  }
}

//###########################################################
//###########################################################
//###########################################################

export default withSnackbar(MySnackbar);

//###########################################################
//###########################################################
//###########################################################
