import React from "react";
import InstantBackend from "./build_in/InstantBackend";
import CommonCalls from "./CommonCalls";

//###########################################################
//###########################################################
//###########################################################

class Backend {
  //###########################################################
  //###########################################################
  //###########################################################

  constructor(instantBackend) {
    this.commonCalls = new CommonCalls();

    this.instantBackend = new InstantBackend({
      serverURL: process.env.REACT_APP_INSTANT_BACKEND_URL,
      applicationConfigurationID:
        process.env.REACT_APP_INSTANT_BACKEND_APPLICATION_CONFIGURATION_ID,
      printConsole:
        process.env.REACT_APP_INSTANT_BACKEND_PRINT_CONSOLE.toLowerCase() ===
        "true",
    });

    if (instantBackend !== undefined) this.instantBackend = instantBackend;

    this.state = {};
  }

  //###########################################################
  //###########################################################
  //###########################################################

  CreateLog = (sessionID, event, comment) => {
    let paramsCreateLog = {
      sessionID: sessionID,
      event: event,
      comment: comment,
    };

    return new Promise((resolve, reject) => {
      this.instantBackend
        .performWithPromise("CreateLog", paramsCreateLog)
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  //###########################################################
  //###########################################################
  //###########################################################

  GetCookieDataByID = (clientUUID) => {
    let paramsGetCookieDataByID = {
      clientUUID: clientUUID,
    };

    return new Promise((resolve, reject) => {
      this.instantBackend
        .performWithPromise("GetCookieDataByID", paramsGetCookieDataByID)
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  SetCookieData = (name, clientUUID, data, expiresInDay) => {
    let paramsSetCookieData = {
      name: name,
      clientUUID: clientUUID,
      data: data,
      expiresInDay: expiresInDay,
    };

    return new Promise((resolve, reject) => {
      this.instantBackend
        .performWithPromise("SetCookieData", paramsSetCookieData)
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  //###########################################################
  //###########################################################
  //###########################################################

  getCurrentYear = () => {
    return 2022;
  };
}

//###########################################################
//###########################################################
//###########################################################

export default Backend;

//###########################################################
//###########################################################
//###########################################################
