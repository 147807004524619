import React from "react";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import OutlinedInput from "@mui/material/OutlinedInput";
import ListItemText from "@mui/material/ListItemText";
import FormGroup from "@mui/material/FormGroup";
import TextField from "@mui/material/TextField";
import Collapse from "@mui/material/Collapse";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import Backend from "../../classes/Backend";
import Commons from "./Commons";

import CommonCalls from "../../classes/CommonCalls";

//###########################################################
//###########################################################
//###########################################################

class Step2 extends React.Component {
  //###########################################################
  //###########################################################
  //###########################################################

  constructor(props) {
    super(props);

    this.parent = this.props.parent;
    this.appData = this.props.appData;
    this.commons = new Commons();

    this.stepIndex = 2;
    this.savedToDatabase = false;

    this.listIllnesses = [
      "Heart Disease",
      "Kidney or Liver Failure",
      "Diabetes",
      "Cancer",
      "Panic Attack",
    ];

    this.listSkinDiseases = ["Psoriasis", "Eczema", "Dermatitis"];

    this.listDiseases = ["Hepatitis B", "Hepatitis C", "HIV", "Syphilis"];

    this.ITEM_HEIGHT = 48;
    this.ITEM_PADDING_TOP = 8;
    this.MenuProps = {
      PaperProps: {
        style: {
          maxHeight: this.ITEM_HEIGHT * 4.5 + this.ITEM_PADDING_TOP,
          width: 250,
        },
      },
    };

    this.inputLabels = {
      illnesses: "Select if applicable",
      skinDiseases: "Select if applicable",
      diseases: "Select if applicable",
    };

    this.state = {
      display: "",

      smoker: "No",

      selectedIllnesses: [],
      haveOtherIllnesses: false,
      haveNoIllnesses: true,
      txtOtherIllnesses: "",
      inputLabelIllnesses: this.inputLabels.illnesses,
      inputPropIllnesses: <OutlinedInput label={this.inputLabels.illnesses} />,

      useMedications: "No",
      showTxtUseMedications: false,
      txtUseMedications: "",

      selectedSkinDiseases: [],
      haveOtherSkinDiseases: false,
      haveNoSkinDiseases: true,
      txtOtherSkinDiseases: "",
      inputLabelSkinDiseases: this.inputLabels.skinDiseases,
      inputPropSkinDiseases: (
        <OutlinedInput label={this.inputLabels.skinDiseases} />
      ),

      hadSurgery: "No",
      showTxtHadSurgery: false,
      txtHadSurgery: "",

      haveAllergie: "No",
      showTxtHaveAllergie: false,
      txtHaveAllergie: "",

      selectedBloodborneDiseases: [],
      haveNoBloodborneDiseases: true,
      inputLabelBloodborneDiseases: this.inputLabels.diseases,
      inputPropBloodborneDiseases: (
        <OutlinedInput label={this.inputLabels.diseases} />
      ),
    };

    //##########################
    //##########################
    //##########################
  }

  //###########################################################
  //###########################################################
  //###########################################################

  setStateOne = (key, value, callBack) => {
    let tempState = this.state;
    tempState[key] = value;
    this.setState(tempState, () => {
      if (callBack !== undefined) {
        callBack();
      }
    });
  };

  setStateMultiple = (keyValuePairs, callBack) => {
    let tempState = this.state;

    keyValuePairs.map((keyValuePair) => {
      tempState[keyValuePair[0]] = keyValuePair[1];
    });

    this.setState(tempState, () => {
      if (callBack !== undefined) {
        callBack();
      }
    });
  };

  //###########################################################
  //###########################################################
  //###########################################################

  areYouSmoker = () => {
    return (
      <div style={this.commons.styleQuestionContainerRadio}>
        <FormLabel style={this.commons.styleQuestion}>
          Are you a smoker?
        </FormLabel>
        <RadioGroup
          row
          defaultValue={this.state.smoker}
          value={this.state.smoker}
          onChange={(e) => {
            this.setStateOne("smoker", e.target.value);
          }}
        >
          <FormControlLabel value="No" control={<Radio />} label="No" />
          <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
        </RadioGroup>
      </div>
    );
  };

  chronicIllnesses = () => {
    return (
      <div style={this.commons.styleQuestionContainerRadio}>
        {/* Select selectedIllnesses */}
        <FormLabel style={this.commons.styleQuestion}>
          Do you have any of these chronic illnesses?
        </FormLabel>
        <div className="divider-height-10" />
        <FormControl fullWidth>
          {/* <InputLabel>
            {this.state.inputLabelIllnesses}
          </InputLabel> */}
          <InputLabel />
          <Select
            className="regular-text"
            multiple
            value={this.state.selectedIllnesses}
            // input={this.state.inputPropIllnesses}
            onChange={(event) => {
              let val = event.target.value;
              let tempState = this.state;

              let res = [];

              this.listIllnesses.map((illness) => {
                if (val.includes(illness)) {
                  res.push(illness);
                }
              });

              if (res.length > 0) {
                tempState.haveNoIllnesses = false;
              } else if (!tempState.haveOtherIllnesses) {
                tempState.haveNoIllnesses = true;
              } else {
                tempState.haveNoIllnesses = false;
              }

              tempState.selectedIllnesses = res;
              this.setState(tempState);
            }}
            // onOpen={() => {
            //   let tempState = this.state;
            //   tempState.inputLabelIllnesses = "";
            //   tempState.inputPropIllnesses = null;
            //   this.setState(tempState);
            // }}
            // onClose={() => {
            //   let tempState = this.state;
            //   if (tempState.selectedIllnesses.length <= 0) {
            //     tempState.inputLabelIllnesses = this.inputLabels.illnesses;
            //     tempState.inputPropIllnesses = (
            //       <OutlinedInput label={this.inputLabels.illnesses} />
            //     );
            //   } else {
            //     tempState.inputLabelIllnesses = "";
            //     tempState.inputPropIllnesses = null;
            //   }
            //   this.setState(tempState, () => {
            //     setTimeout(() => {
            //       document.activeElement.blur();
            //     }, 0);
            //   });
            // }}
            renderValue={(selected) => (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {selected.map((value) => (
                  <Chip
                    key={value}
                    label={
                      <Typography className="font-family">{value}</Typography>
                    }
                  />
                ))}
              </Box>
            )}
            // renderValue={(selected) => selected.join(", ")}
            MenuProps={this.MenuProps}
          >
            {this.listIllnesses.map((illness) => (
              <MenuItem key={illness} value={illness}>
                <Checkbox
                  checked={this.state.selectedIllnesses.indexOf(illness) > -1}
                />
                <ListItemText primary={illness} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* ############ */}
        <div style={{ height: "5px", width: "1px" }} />

        <FormGroup>
          {/* Checkbox haveOtherIllnesses*/}
          <FormControlLabel
            control={
              <Checkbox
                checked={this.state.haveOtherIllnesses}
                onChange={(event) => {
                  let val = event.target.checked;
                  let tempState = this.state;
                  if (val) {
                    tempState.haveNoIllnesses = false;
                  } else if (tempState.selectedIllnesses.length <= 0) {
                    tempState.haveNoIllnesses = true;
                  }
                  tempState.haveOtherIllnesses = val;
                  this.setState(tempState);
                }}
              />
            }
            label="I have other(s)"
          />
          {/* TextField haveOtherIllnesses */}
          <Collapse in={this.state.haveOtherIllnesses}>
            <TextField
              label="Please Specify"
              multiline
              rows={4}
              style={{ width: "100%", marginBottom: "5px", marginTop: "10px" }}
              InputProps={{
                className: "regular-text",
              }}
              value={this.state.txtOtherIllnesses}
              onChange={(e) => {
                this.setStateOne("txtOtherIllnesses", e.target.value);
              }}
            />
          </Collapse>

          {/* Checkbox haveNoIllnesses */}
          <FormControlLabel
            control={
              <Checkbox
                checked={this.state.haveNoIllnesses}
                onChange={(event) => {
                  let val = event.target.checked;
                  let tempState = this.state;
                  if (val) {
                    tempState.selectedIllnesses = [];
                    tempState.haveOtherIllnesses = false;
                    tempState.inputLabelIllnesses = "Select if applicable";
                    tempState.inputPropIllnesses = (
                      <OutlinedInput label="Select if applicable" />
                    );
                  }
                  tempState.haveNoIllnesses = val;
                  this.setState(tempState);
                }}
              />
            }
            label="No, I don't have any chronic illnesses."
          />
        </FormGroup>
      </div>
    );
  };

  anyMedication = () => {
    return (
      <div style={this.commons.styleQuestionContainerRadio}>
        <FormLabel style={this.commons.styleQuestion}>
          Do you currently use any medication(s)?
        </FormLabel>

        <RadioGroup
          row
          defaultValue={this.state.useMedications}
          value={this.state.useMedications}
          onChange={(event) => {
            let tempState = this.state;

            //### somehow the value come as string and that mass up following collapse control
            let boolVal = false;
            if (event.target.value === "Yes") {
              boolVal = true;
            }

            tempState.useMedications = event.target.value;
            tempState.showTxtUseMedications = boolVal;
            this.setState(tempState);
          }}
        >
          <FormControlLabel value={"No"} control={<Radio />} label="No" />
          <FormControlLabel value={"Yes"} control={<Radio />} label="Yes" />
        </RadioGroup>

        <Collapse in={this.state.showTxtUseMedications}>
          <TextField
            label="Please Specify"
            multiline
            rows={4}
            style={{
              width: "100%",
              marginBottom: "10px",
              marginTop: "10px",
            }}
            InputProps={{
              className: "regular-text",
            }}
            value={this.state.txtUseMedications}
            onChange={(e) => {
              this.setStateOne("txtUseMedications", e.target.value);
            }}
          />
        </Collapse>
      </div>
    );
  };

  skinDiseases = () => {
    return (
      <div style={this.commons.styleQuestionContainerRadio}>
        <FormLabel style={this.commons.styleQuestion}>
          Do you have any of these skin diseases?
        </FormLabel>
        <div className="divider-height-10" />
        <FormControl fullWidth>
          {/* <InputLabel>
            {this.state.inputLabelSkinDiseases}
          </InputLabel> */}
          <InputLabel />
          <Select
            className="regular-text"
            multiple
            value={this.state.selectedSkinDiseases}
            // input={this.state.inputPropSkinDiseases}
            onChange={(event) => {
              let val = event.target.value;
              let tempState = this.state;

              let res = [];

              this.listSkinDiseases.map((skinDisease) => {
                if (val.includes(skinDisease)) {
                  res.push(skinDisease);
                }
              });

              if (res.length > 0) {
                tempState.haveNoSkinDiseases = false;
              } else if (!tempState.haveOtherSkinDiseases) {
                tempState.haveNoSkinDiseases = true;
              } else {
                tempState.haveNoSkinDiseases = false;
              }

              tempState.selectedSkinDiseases = res;
              this.setState(tempState);
            }}
            // onOpen={() => {
            //   let tempState = this.state;
            //   tempState.inputLabelSkinDiseases = "";
            //   tempState.inputPropSkinDiseases = null;
            //   this.setState(tempState);
            // }}
            // onClose={() => {
            //   let tempState = this.state;
            //   if (tempState.selectedSkinDiseases.length <= 0) {
            //     tempState.inputLabelSkinDiseases = this.inputLabels.skinDiseases;
            //     tempState.inputPropSkinDiseases = (
            //       <OutlinedInput label={this.inputLabels.skinDiseases} />
            //     );
            //   } else {
            //     tempState.inputLabelSkinDiseases = "";
            //     tempState.inputPropSkinDiseases = null;
            //   }
            //   this.setState(tempState, () => {
            //     setTimeout(() => {
            //       document.activeElement.blur();
            //     }, 0);
            //   });
            // }}
            renderValue={(selected) => (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {selected.map((value) => (
                  <Chip
                    key={value}
                    label={
                      <Typography className="font-family">{value}</Typography>
                    }
                  />
                ))}
              </Box>
            )}
            // renderValue={(selected) => selected.join(", ")}
            MenuProps={this.MenuProps}
          >
            {this.listSkinDiseases.map((skinDisease) => (
              <MenuItem key={skinDisease} value={skinDisease}>
                <Checkbox
                  checked={
                    this.state.selectedSkinDiseases.indexOf(skinDisease) > -1
                  }
                />
                <ListItemText primary={skinDisease} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* ############ */}
        <div style={{ height: "5px", width: "1px" }} />

        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={this.state.haveOtherSkinDiseases}
                onChange={(event) => {
                  let val = event.target.checked;
                  let tempState = this.state;
                  if (val) {
                    tempState.haveNoSkinDiseases = false;
                  } else if (tempState.selectedSkinDiseases.length <= 0) {
                    tempState.haveNoSkinDiseases = true;
                  }
                  tempState.haveOtherSkinDiseases = val;
                  this.setState(tempState);
                }}
              />
            }
            label="I have other(s)"
          />

          <Collapse in={this.state.haveOtherSkinDiseases}>
            <TextField
              label="Please Specify"
              multiline
              rows={4}
              style={{ width: "100%", marginBottom: "5px", marginTop: "10px" }}
              InputProps={{
                className: "regular-text",
              }}
              value={this.state.txtOtherSkinDiseases}
              onChange={(e) => {
                this.setStateOne("txtOtherSkinDiseases", e.target.value);
              }}
            />
          </Collapse>

          <FormControlLabel
            control={
              <Checkbox
                checked={this.state.haveNoSkinDiseases}
                onChange={(event) => {
                  let val = event.target.checked;
                  let tempState = this.state;
                  if (val) {
                    tempState.selectedSkinDiseases = [];
                    tempState.haveOtherSkinDiseases = false;
                    tempState.inputLabelSkinDiseases = "Select if applicable";
                    tempState.inputPropSkinDiseases = (
                      <OutlinedInput label="Select if applicable" />
                    );
                  }
                  tempState.haveNoSkinDiseases = val;
                  this.setState(tempState);
                }}
              />
            }
            label="No, I don't have any skin disease."
          />
        </FormGroup>
      </div>
    );
  };

  anySurgery = () => {
    return (
      <div style={this.commons.styleQuestionContainerRadio}>
        <FormLabel style={this.commons.styleQuestion}>
          Have you had any kind of surgery?
        </FormLabel>
        <RadioGroup
          row
          defaultValue={this.state.hadSurgery}
          value={this.state.hadSurgery}
          onChange={(event) => {
            let tempState = this.state;

            //### somehow the value come as string and that mass up following collapse control
            let boolVal = false;
            if (event.target.value === "Yes") {
              boolVal = true;
            }

            tempState.hadSurgery = event.target.value;
            tempState.showTxtHadSurgery = boolVal;
            this.setState(tempState);
          }}
        >
          <FormControlLabel value={"No"} control={<Radio />} label="No" />
          <FormControlLabel value={"Yes"} control={<Radio />} label="Yes" />
        </RadioGroup>

        <Collapse in={this.state.showTxtHadSurgery}>
          <TextField
            label="Please Specify"
            multiline
            rows={4}
            style={{
              width: "100%",
              marginBottom: "10px",
              marginTop: "10px",
            }}
            InputProps={{
              className: "regular-text",
            }}
            value={this.state.txtHadSurgery}
            onChange={(e) => {
              this.setStateOne("txtHadSurgery", e.target.value);
            }}
          />
        </Collapse>
      </div>
    );
  };

  anyAllergies = () => {
    return (
      <div style={this.commons.styleQuestionContainerRadio}>
        <FormLabel style={this.commons.styleQuestion}>
          Do you have any allergy?
        </FormLabel>
        <RadioGroup
          row
          defaultValue={this.state.haveAllergie}
          value={this.state.haveAllergie}
          onChange={(event) => {
            let tempState = this.state;

            //### somehow the value come as string and that mass up following collapse control
            let boolVal = false;
            if (event.target.value === "Yes") {
              boolVal = true;
            }

            tempState.haveAllergie = event.target.value;
            tempState.showTxtHaveAllergie = boolVal;
            this.setState(tempState);
          }}
        >
          <FormControlLabel value={"No"} control={<Radio />} label="No" />
          <FormControlLabel value={"Yes"} control={<Radio />} label="Yes" />
        </RadioGroup>

        <Collapse in={this.state.showTxtHaveAllergie}>
          <TextField
            label="Please Specify"
            multiline
            rows={4}
            style={{
              width: "100%",
              marginBottom: "10px",
              marginTop: "10px",
            }}
            InputProps={{
              className: "regular-text",
            }}
            value={this.state.txtHaveAllergie}
            onChange={(e) => {
              this.setStateOne("txtHaveAllergie", e.target.value);
            }}
          />
        </Collapse>
      </div>
    );
  };

  anyDiseases = () => {
    return (
      <div style={this.commons.styleQuestionContainerRadio}>
        <FormLabel style={this.commons.styleQuestion}>
          Have you had any of these bloodborne diseases before?
        </FormLabel>
        <div className="divider-height-10" />
        <FormControl fullWidth>
          {/* <InputLabel>
            {this.state.inputLabelBloodborneDiseases}
          </InputLabel> */}
          <InputLabel />
          <Select
            className="regular-text"
            multiple
            value={this.state.selectedBloodborneDiseases}
            // input={this.state.inputPropBloodborneDiseases}
            onChange={(event) => {
              let val = event.target.value;
              let tempState = this.state;

              let res = [];

              this.listDiseases.map((disease) => {
                if (val.includes(disease)) {
                  res.push(disease);
                }
              });

              if (res.length > 0) {
                tempState.haveNoBloodborneDiseases = false;
              } else if (!tempState.haveOtherDiseases) {
                tempState.haveNoBloodborneDiseases = true;
              } else {
                tempState.haveNoBloodborneDiseases = false;
              }

              tempState.selectedBloodborneDiseases = res;
              this.setState(tempState);
            }}
            // onOpen={() => {
            //   let tempState = this.state;
            //   tempState.inputLabelBloodborneDiseases = "";
            //   tempState.inputPropBloodborneDiseases = null;
            //   this.setState(tempState);
            // }}
            // onClose={() => {
            //   let tempState = this.state;
            //   if (tempState.selectedBloodborneDiseases.length <= 0) {
            //     tempState.inputLabelBloodborneDiseases = this.inputLabels.diseases;
            //     tempState.inputPropBloodborneDiseases = (
            //       <OutlinedInput label={this.inputLabels.diseases} />
            //     );
            //   } else {
            //     tempState.inputLabelBloodborneDiseases = "";
            //     tempState.inputPropBloodborneDiseases = null;
            //   }
            //   this.setState(tempState, () => {
            //     setTimeout(() => {
            //       document.activeElement.blur();
            //     }, 0);
            //   });
            // }}
            renderValue={(selected) => (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {selected.map((value) => (
                  <Chip
                    key={value}
                    label={
                      <Typography className="font-family">{value}</Typography>
                    }
                  />
                ))}
              </Box>
            )}
            // renderValue={(selected) => selected.join(", ")}
            MenuProps={this.MenuProps}
          >
            {this.listDiseases.map((disease) => (
              <MenuItem key={disease} value={disease}>
                <Checkbox
                  checked={
                    this.state.selectedBloodborneDiseases.indexOf(disease) > -1
                  }
                />
                <ListItemText primary={disease} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* ############ */}
        <div style={{ height: "5px", width: "1px" }} />

        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={this.state.haveNoBloodborneDiseases}
                onChange={(event) => {
                  let val = event.target.checked;
                  let tempState = this.state;
                  if (val) {
                    tempState.selectedBloodborneDiseases = [];
                    tempState.inputLabelBloodborneDiseases =
                      "Select if applicable";
                    tempState.inputPropBloodborneDiseases = (
                      <OutlinedInput label="Select if applicable" />
                    );
                  }
                  tempState.haveNoBloodborneDiseases = val;
                  this.setState(tempState);
                }}
              />
            }
            label="No, I have not."
          />
        </FormGroup>
      </div>
    );
  };

  //###########################################################
  //###########################################################
  //###########################################################

  componentDidMount() {}

  //###########################################################
  //###########################################################
  //###########################################################

  show = () => {
    let tempState = this.state;
    tempState.display = "";
    this.setState(tempState);
  };

  hide = () => {
    let tempState = this.state;
    tempState.display = "none";
    this.setState(tempState);
  };

  //###########################################################
  //###########################################################
  //###########################################################

  getValues = () => {
    let res = [];

    //##########################

    res.push({ key: "Are you a smoker?", value: this.state.smoker });

    //##########################

    let selectedIllnesses = "";
    this.state.selectedIllnesses.map((selectedIllness) => {
      selectedIllnesses += selectedIllness + ", ";
    });

    selectedIllnesses = selectedIllnesses.slice(0, -2);

    let txtOtherIllnesses = "";
    let connector = "";
    if (selectedIllnesses.trim() !== "") {
      connector = ". ";
    }

    if (
      this.state.haveOtherIllnesses === true &&
      this.state.txtOtherIllnesses.trim() !== ""
    ) {
      txtOtherIllnesses = connector + this.state.txtOtherIllnesses.trim();
    }

    selectedIllnesses = selectedIllnesses + txtOtherIllnesses;
    if (selectedIllnesses === "") {
      selectedIllnesses = "No";
    }

    res.push({
      // key: "Do you have any of these chronic illnesses?",
      key: "Do you have any chronic illness?",
      value: selectedIllnesses,
    });

    //##########################

    let txtUseMedications = "";
    if (
      this.state.useMedications === "Yes" &&
      this.state.txtUseMedications.trim() !== ""
    ) {
      txtUseMedications = ". " + this.state.txtUseMedications.trim();
    }
    res.push({
      key: "Do you currently use any medication(s)?",
      value: this.state.useMedications + txtUseMedications,
    });

    //##########################

    let selectedSkinDiseases = "";
    this.state.selectedSkinDiseases.map((selectedIllness) => {
      selectedSkinDiseases += selectedIllness + ", ";
    });

    selectedSkinDiseases = selectedSkinDiseases.slice(0, -2);

    let txtOtherSkinDiseases = "";
    connector = "";
    if (selectedSkinDiseases.trim() !== "") {
      connector = ". ";
    }

    if (
      this.state.haveOtherSkinDiseases === true &&
      this.state.txtOtherSkinDiseases.trim() !== ""
    ) {
      txtOtherSkinDiseases = connector + this.state.txtOtherSkinDiseases.trim();
    }

    selectedSkinDiseases = selectedSkinDiseases + txtOtherSkinDiseases;
    if (selectedSkinDiseases === "") {
      selectedSkinDiseases = "No";
    }

    res.push({
      // key: "Do you have any of these skin diseases?",
      key: "Do you have any skin disease?",
      value: selectedSkinDiseases,
    });

    //#############################

    let txtHadSurgery = "";
    if (
      this.state.hadSurgery === "Yes" &&
      this.state.txtHadSurgery.trim() !== ""
    ) {
      txtHadSurgery = ". " + this.state.txtHadSurgery.trim();
    }
    res.push({
      key: "Have you had any kind of surgery?",
      value: this.state.hadSurgery + txtHadSurgery,
    });

    let txtHaveAllergie = "";
    if (
      this.state.haveAllergie === "Yes" &&
      this.state.txtHaveAllergie.trim() !== ""
    ) {
      txtHaveAllergie = ". " + this.state.txtHaveAllergie.trim();
    }
    res.push({
      key: "Do you have any allergy?",
      value: this.state.haveAllergie + txtHaveAllergie,
    });

    //################################
    let selectedBloodborneDiseases = "";
    this.state.selectedBloodborneDiseases.map((selectedIllness) => {
      selectedBloodborneDiseases += selectedIllness + ", ";
    });

    selectedBloodborneDiseases = selectedBloodborneDiseases.slice(0, -2);

    selectedBloodborneDiseases =
      selectedBloodborneDiseases + txtOtherSkinDiseases;
    if (selectedBloodborneDiseases === "") {
      selectedBloodborneDiseases = "No";
    }

    res.push({
      // key: "Have you had any of these bloodborne diseases before?",
      key: "Have you had any bloodborne disease before?",
      value: selectedBloodborneDiseases,
    });
    return res;
  };

  //###########################################################
  //###########################################################
  //###########################################################

  validate = (alwaysReturnTrue) => {
    let validationResult = { result: true, message: "" };

    let bullets = [];

    //############################

    let haveOtherIllnesses = this.state.haveOtherIllnesses;
    if (
      haveOtherIllnesses === true &&
      this.state.txtOtherIllnesses.trim() === ""
    ) {
      haveOtherIllnesses = false;
    }

    if (
      this.state.selectedIllnesses.length <= 0 &&
      haveOtherIllnesses === false &&
      this.state.haveNoIllnesses === false
    ) {
      bullets.push(
        <li style={{ marginBottom: "5px" }}>
          Do you have any of these chronic illnesses?
        </li>
      );
    }

    //############################if (
    if (
      this.state.useMedications === "Yes" &&
      this.state.txtUseMedications.trim() === ""
    ) {
      bullets.push(
        <li style={{ marginBottom: "5px" }}>
          Do you currently use any medication(s)?
        </li>
      );
    }

    //############################

    let haveOtherSkinDiseases = this.state.haveOtherSkinDiseases;
    if (
      haveOtherSkinDiseases === true &&
      this.state.txtOtherSkinDiseases.trim() === ""
    ) {
      haveOtherSkinDiseases = false;
    }

    if (
      this.state.selectedSkinDiseases.length <= 0 &&
      haveOtherSkinDiseases === false &&
      this.state.haveNoSkinDiseases === false
    ) {
      bullets.push(
        <li style={{ marginBottom: "5px" }}>
          Do you have any of these skin diseases?
        </li>
      );
    }

    //############################
    if (
      this.state.hadSurgery === "Yes" &&
      this.state.txtHadSurgery.trim() === ""
    ) {
      bullets.push(
        <li style={{ marginBottom: "5px" }}>
          Have you had any kind of surgery?
        </li>
      );
    }

    //############################
    if (
      this.state.haveAllergie === "Yes" &&
      this.state.txtHaveAllergie.trim() === ""
    ) {
      bullets.push(
        <li style={{ marginBottom: "5px" }}>Do you have any allergy?</li>
      );
    }

    //############################

    if (
      this.state.selectedBloodborneDiseases.length <= 0 &&
      this.state.haveNoBloodborneDiseases === false
    ) {
      bullets.push(
        <li style={{ marginBottom: "5px" }}>
          Have you had any of these bloodborne diseases before?
        </li>
      );
    }

    //############################

    //### remove this part for production
    if (alwaysReturnTrue !== undefined && alwaysReturnTrue === true) {
      while (bullets.length > 0) {
        bullets.pop();
      }
    }

    //############################

    if (bullets.length > 0) {
      validationResult.result = false;
      validationResult.message = (
        <>
          <p>Please answer the following question(s):</p>
          <ul>{bullets}</ul>
        </>
      );
    }

    //############################

    return validationResult;
  };

  //###########################################################
  //###########################################################
  //###########################################################

  getJSON = () => {
    let stateData = JSON.parse(JSON.stringify(this.state));

    delete stateData.display;
    delete stateData.inputLabelIllnesses;
    delete stateData.inputPropIllnesses;
    delete stateData.inputLabelSkinDiseases;
    delete stateData.inputPropSkinDiseases;
    delete stateData.inputLabelBloodborneDiseases;
    delete stateData.inputPropBloodborneDiseases;

    let res = {};
    res.stateData = stateData;
    res.printData = this.getValues();

    //### handle double quotes in the json string
    res.stateData.txtOtherIllnesses = this.appData.commonCalls.safeJsonString(
      res.stateData.txtOtherIllnesses
    );
    res.stateData.txtUseMedications = this.appData.commonCalls.safeJsonString(
      res.stateData.txtUseMedications
    );
    res.stateData.txtOtherSkinDiseases = this.appData.commonCalls.safeJsonString(
      res.stateData.txtOtherSkinDiseases
    );
    res.stateData.txtHadSurgery = this.appData.commonCalls.safeJsonString(
      res.stateData.txtHadSurgery
    );
    res.stateData.txtHaveAllergie = this.appData.commonCalls.safeJsonString(
      res.stateData.txtHaveAllergie
    );

    for (let index = 0; index < res.printData.length; index++) {
      let element = res.printData[index];
      if (typeof element.value === "string") {
        element.value = this.appData.commonCalls.safeJsonString(element.value);
      }
    }

    return res;
  };

  setJSON = (obj, callBack) => {
    if (obj.stateData !== undefined) {
      obj = obj.stateData;
    }

    let tempState = this.state;

    let keys = Object.keys(obj);

    keys.forEach((key) => {
      //### handle double quotes in the json string
      if (typeof obj[key] === "string") {
        tempState[key] = this.appData.commonCalls.restoreJsonString(obj[key]);
      } else tempState[key] = obj[key];
    });

    this.setState(tempState, () => {
      if (callBack) {
        callBack();
      }
    });
  };

  //###########################################################
  //###########################################################
  //###########################################################

  render() {
    return (
      <>
        <div
          className="width-with-padding-20"
          style={{
            display: this.state.display,
            padding: "10px 20px 20px 20px",
          }}
        >
          {/* ############ */}
          {/* ############ */}
          {/* ############ */}
          {/* title */}
          <div
            className="flex-column-center"
            style={this.commons.styleQuestionContainerSelect}
          >
            <span
              className="title-3"
              style={{ marginTop: "10px", marginBottom: "10px" }}
            >
              General Questions
            </span>
            <p
              className="no-padding no-margin regular-text"
              style={{ textAlign: "center" }}
            >
              Please answer the following questions related to your general
              health condition that will help us to provide accurate evaluation
              of your hair transplant procedure.
            </p>
          </div>
          {/* ############ */}
          {/* ############ */}
          {/* ############ */}
          {/* <hr className="line-20" /> */}
          <div className="divider-height-25" />
          {this.areYouSmoker()}
          {/* <hr className="line-20" /> */}
          <div className="divider-height-25" />
          {this.chronicIllnesses()}
          {/* <hr className="line-20" /> */}
          <div className="divider-height-25" />
          {this.anyMedication()}
          {/* <hr className="line-20" /> */}
          <div className="divider-height-25" />
          {this.skinDiseases()}
          {/* <hr className="line-20" /> */}
          <div className="divider-height-25" />
          {this.anySurgery()}
          {/* <hr className="line-20" /> */}
          <div className="divider-height-25" />
          {this.anyAllergies()}
          {/* <hr className="line-20" /> */}
          <div className="divider-height-25" />
          {this.anyDiseases()}
          {/* ############ */}
          {/* ############ */}
          {/* ############ */}
          {/* <div className="divider-height-25"></div>
          <div
            className="button"
            onClick={() => {
              let values = this.getValues();
              console.clear();
              console.log(values);
            }}
          >
            Test Values
          </div> */}
          {/* ############ */}
          {/* ############ */}
          {/* ############ */}
        </div>
      </>
    );
  }
}

//###########################################################
//###########################################################
//###########################################################

export default Step2;

//###########################################################
//###########################################################
//###########################################################
