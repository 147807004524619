import React from "react";
import "./ClinicCard.css";
import Rating from "@mui/material/Rating";
import OutlinedDiv from "../commons/OutlinedDiv";
import Divider from "@mui/material/Divider";
import MyDialog from "../commons/MyDialog";
import CommonCalls from "../../classes/CommonCalls";
import Clinic from "../clinic/Clinic";
import CommonsWithClinic from "./CommonsWithClinic";

//###########################################################
//###########################################################
//###########################################################

class ClinicCard extends React.Component {
  //###########################################################
  //###########################################################
  //###########################################################

  constructor(props) {
    super(props);

    this.commonCalls = new CommonCalls();
    this.parent = this.props.parent;
    this.sessionObject = this.parent.sessionObject;
    this.commonsWithClinic = new CommonsWithClinic(
      this.props.data.UUID,
      this.parent.backend,
      this.sessionObject
    );

    this.state = {};
  }

  //###########################################################
  //###########################################################
  //###########################################################

  componentDidMount() {}

  //###########################################################
  //###########################################################
  //###########################################################

  onClincCardClicked = () => {
    this.myDialog.open({
      type: "container",
      hasCloseButton: false,
      contentLayout: "top-center",
      background: "#F8F8F8",
      content: (
        <Clinic
          container={this.myDialog}
          parent={this}
          uuid={this.props.data.UUID}
        />
      ),
    });
  };

  //###########################################################
  //###########################################################
  //###########################################################

  renderAmazon = () => {
    return (
      <>
        <MyDialog ref={(el) => (this.myDialog = el)} />
        <div
          className="flex-column-start clinic-card-container"
          onClick={this.onClincCardClicked}
        >
          {/* logo */}
          <div className="flex-row-center clinic-card-logo-container">
            <img
              className="clinic-card-logo"
              src={`${"../assets/images/clinics/" +
                this.props.data.UUID +
                "/logo.png"}`}
              alt={this.props.data.Name}
              // onLoad={()=>{console.log("image loaded");}}
            />
          </div>
          {/* name */}
          <p className="clinic-card-name header-color ">
            {this.props.data.Name}
          </p>
          {/* about */}
          <p className="clinic-card-about text-color">
            {this.props.data.About}
          </p>
          {/* google */}
          <div className="flex-column-center clinic-card-rating-container">
            <Divider style={{ width: "100%", fontSize: "0.85rem" }}>
              Google Reviews
            </Divider>
            <div className="divider-height-10" />
            {this.commonsWithClinic.googleReviews(
              this.props.data,
              "clinic-card"
            )}
          </div>
        </div>
      </>
    );
  };

  //###########################################################
  //###########################################################
  //###########################################################

  render() {
    return this.renderAmazon();
  }
}

//###########################################################
//###########################################################
//###########################################################

export default ClinicCard;

//###########################################################
//###########################################################
//###########################################################
