import * as React from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";

import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

import GeneralInfo from "./GeneralInfo";
import Google from "./Google";
import Links from "./Links";
// import AboutAndComment from "./AboutAndComment";
import ConsolePrinter from "./ConsolePrinter";
// import GoogleSearchResult from "./GoogleSearchResult";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export default function CustomizedAccordions(props) {
  //###########################
  //###########################
  //###########################
  //### source >>> ### https://dilshankelsen.com/call-child-function-from-parent-component-in-react/#:~:text=One%20way%20to%20call%20a,function%20in%20the%20parent%20component.

  React.useEffect(() => {
    // props.parent.sideBarOnCurrentClinicChanged = onCurrentClinicChanged;
    // props.parent.sideBarOnCurrentUUIDChanged = onCurrentUUIDChanged;
    props.parent.sideBarOnCurrentRowChanged = onCurrentRowChanged;
  }, []);

  // const onCurrentClinicChanged = (clinic) => {
  //   onGeneralInfoCurrentClinicChanged.current(clinic.ClinicURL);
  //   onGoogleCurrentClinicChanged.current(clinic.ClinicURL);
  //   onAboutAndCommentCurrentClinicChanged.current(clinic.ClinicURL);
  //   setClinicName(clinic.ClinicURL);
  // };

  // const onCurrentUUIDChanged = (uuid) => {
  //   // onGeneralInfoCurrentClinicChanged.current(clinic.ClinicURL);
  //   // onGoogleCurrentClinicChanged.current(clinic.ClinicURL);
  //   // onAboutAndCommentCurrentClinicChanged.current(clinic.ClinicURL);
  //   onGoogleSearchCurrentUUIDChanged.current(uuid);
  //   // setClinicName(clinic.ClinicURL);
  // };

  const onCurrentRowChanged = (row) => {
    // onGeneralInfoCurrentClinicChanged.current(clinic.ClinicURL);
    // onGoogleCurrentClinicChanged.current(clinic.ClinicURL);
    // onAboutAndCommentCurrentClinicChanged.current(clinic.ClinicURL);
    // onAboutAndCommentCurrentClinicChanged.current(clinic.ClinicURL);
    // onAboutAndCommentCurrentClinicChanged.current(clinic.ClinicURL);
    onGeneralInfoCurrentRowChanged.current(row);
    onGoogleCurrentRowChanged.current(row);
    onLinksCurrentRowChanged.current(row);
    // onAboutAndCommentCurrentRowChanged.current(row);
    // onGoogleSearchCurrentRowChanged.current(row);

    setClinicName(row.ClinicURL);
  };

  // const onGeneralInfoCurrentClinicChanged = React.useRef(null);
  // const onGoogleCurrentClinicChanged = React.useRef(null);
  // const onAboutAndCommentCurrentClinicChanged = React.useRef(null);


  const onGeneralInfoCurrentRowChanged = React.useRef(null);
  const onGoogleCurrentRowChanged = React.useRef(null);
  const onLinksCurrentRowChanged = React.useRef(null);
  // const onAboutAndCommentCurrentRowChanged = React.useRef(null);
  // const onGoogleSearchCurrentRowChanged = React.useRef(null);

  //###########################
  //###########################
  //###########################

  const [expanded, setExpanded] = React.useState("panel1");
  const [width, setWidth] = React.useState(1200);
  // const [task, setTask] = React.useState("clinic-annotation");
  const [expandAll, setExpandAll] = React.useState(false);
  const [clinicName, setClinicName] = React.useState("");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const header = () => {
    return (
      <div
        className="flex-row-space-between"
        style={{
          marginBottom: "15px",
          paddingBottom: "15px",
          borderBottom: "1px solid #CCCCCC",
        }}
      >
        <div>
          <div
            className="btn-primary"
            style={{
              display:
                props.parent.state.currentTask === "clinic-annotation"
                  ? ""
                  : "none",
            }}
          >
            Save All
          </div>
        </div>
        <div>
          <b>{clinicName}</b>
        </div>
        <div className="flex-row-end">
          <FormControlLabel
            style={{
              display:
                props.parent.state.currentTask === "clinic-annotation"
                  ? ""
                  : "none",
            }}
            control={<Switch value={expandAll} />}
            label="Expand all"
            onChange={(event) => {
              setExpandAll(event.target.checked);
            }}
          />
          <FormControl size="small" style={{ width: "100px" }}>
            <InputLabel>Width</InputLabel>
            <Select
              value={width}
              label="Width"
              onChange={(event) => {
                setWidth(event.target.value);
              }}
            >
              <MenuItem value={200}>200</MenuItem>
              <MenuItem value={300}>300</MenuItem>
              <MenuItem value={400}>400</MenuItem>
              <MenuItem value={500}>500</MenuItem>
              <MenuItem value={600}>600</MenuItem>
              <MenuItem value={700}>700</MenuItem>
              <MenuItem value={800}>800</MenuItem>
              <MenuItem value={900}>900</MenuItem>
              <MenuItem value={1000}>1000</MenuItem>
              <MenuItem value={1200}>1200</MenuItem>
              <MenuItem value={1400}>1400</MenuItem>
            </Select>
          </FormControl>
          <div className="divider-width-15" />
          <FormControl size="small" style={{ width: "150px" }}>
            <InputLabel>Task</InputLabel>
            <Select
              value={props.parent.state.currentTask}
              label="Task"
              onChange={(event) => {
                props.parent.setCurrentTask(event.target.value);
              }}
            >
              <MenuItem value={"clinic-annotation"}>Clinic Annotation</MenuItem>
              <MenuItem value={"candidate-selection"}>
                Candidate Selection
              </MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>
    );
  };

  return (
    <div
      style={{
        padding: "15px",
        // background: "#FF0",
        height: props.innerContainerHeight - 31,
        minWidth: width,
        maxWidth: width,
        overflowY: "scroll",
        overflowX: "hidden",
      }}
    >
      {/* ####################### */}
      {/* ####################### */}
      {/* ####################### */}

      {header()}

      {/* ####################### */}
      {/* ####################### */}
      {/* ####################### */}
      <Accordion
        style={{
          display:
          props.parent.state.currentTask === "clinic-annotation" ||
          props.parent.state.currentTask === "clinic-annotation-ready-ones" ||
          props.parent.state.currentTask === "clinic-annotation-has-no-image"
              ? ""
              : "none",
        }}
        expanded={expanded === "panel1" || expandAll}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary>
          <Typography>General Info</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <GeneralInfo
              parent={props.parent}
              onGeneralInfoCurrentRowChanged={
                onGeneralInfoCurrentRowChanged
              }
            />
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        style={{
          display:
          props.parent.state.currentTask === "clinic-annotation" ||
          props.parent.state.currentTask === "clinic-annotation-ready-ones" ||
          props.parent.state.currentTask === "clinic-annotation-has-no-image"
              ? ""
              : "none",
        }}
        expanded={expanded === "panel2" || expandAll}
        onChange={handleChange("panel2")}
      >
        <AccordionSummary>
          <Typography>Google</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <Google
              parent={props.parent}
              onGoogleCurrentRowChanged={onGoogleCurrentRowChanged}
            />
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        style={{
          display:
          props.parent.state.currentTask === "clinic-annotation" ||
          props.parent.state.currentTask === "clinic-annotation-ready-ones" ||
          props.parent.state.currentTask === "clinic-annotation-has-no-image"
              ? ""
              : "none",
        }}
        expanded={expanded === "panel3" || expandAll}
        onChange={handleChange("panel3")}
      >
        <AccordionSummary>
          <Typography>Links</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <Links
              parent={props.parent}
              onLinksCurrentRowChanged={onLinksCurrentRowChanged}
            />
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        style={{
          display:
          props.parent.state.currentTask === "clinic-annotation"
              ? ""
              : "none",
        }}
        expanded={expanded === "panel4" || expandAll}
        onChange={handleChange("panel4")}
      >
        <AccordionSummary>
          <Typography>Console Printing</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <ConsolePrinter parent={props.parent} />
          </Typography>
        </AccordionDetails>
      </Accordion>
      {/* <Accordion
        style={{
          display:
            props.parent.state.currentTask === "candidate-selection"
              ? ""
              : "none",
        }}
        expanded={expanded === "panel5" || expandAll}
        onChange={handleChange("panel5")}
      >
        <AccordionSummary>
          <Typography>Google Search Result</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <GoogleSearchResult
              parent={props.parent}
              onGoogleSearchCurrentRowChanged={onGoogleSearchCurrentRowChanged}
            />
          </Typography>
        </AccordionDetails>
      </Accordion> */}
    </div>
  );
}
