import React from "react";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import EmailIcon from "@mui/icons-material/Email";
import FacebookCircularProgress from "../screen-console/FacebookCircularProgress";
import Button from "@mui/material/Button";

//###########################################################
//###########################################################
//###########################################################

class Contact extends React.Component {
  //###########################################################
  //###########################################################
  //###########################################################

  constructor(props) {
    super(props);

    this.readyCount = 0;

    //### for forward references
    if (
      this.props.parent !== undefined &&
      this.props.parent.contact !== undefined
    ) {
      this.props.parent.contact = this;
    }

    this.parent = this.props.parent;
    this.sessionObject = this.parent.sessionObject;
    this.isMobile = this.sessionObject.device.isMobile;

    this.thumbnailSize = { width: 120, height: 120 };

    if (this.isMobile) {
      this.thumbnailSize = { width: 80, height: 80 };
    }

    this.state = {
      email: "",
      whatsapp: "",
      address: "",
      placeURL: "",
      ready: false,
    };
  }

  //###########################################################
  //###########################################################
  //###########################################################

  componentDidMount() {}

  //###########################################################
  //###########################################################
  //###########################################################

  onClinicDataHasBeenRetrieved = (data) => {
    let tempState = this.state;
    tempState.whatsapp = data.WhatsApp;
    tempState.email = data.Email;

    this.readyCount++;
    if (this.readyCount >= 2) {
      tempState.ready = true;
    }

    this.setState(tempState);
  };

  onClinicGoogleDataHasBeenRetrieved = (data) => {
    let tempState = this.state;
    tempState.address = data.Address;
    tempState.placeURL = data.PlaceURL;

    this.readyCount++;
    if (this.readyCount >= 2) {
      tempState.ready = true;
    }

    this.setState(tempState);
  };

  //###########################################################
  //###########################################################
  //###########################################################

  render() {
    return (
      <>
        <div
          style={{
            display: this.state.ready ? "none" : "",
          }}
        >
          <div className="flex-row-center">
            <FacebookCircularProgress />
          </div>
        </div>

        {/* ##################### */}
        {/* ##################### */}
        {/* ##################### */}
        <div
          style={{
            display: this.state.ready ? "" : "none",
          }}
        >
          <Button
            variant="outlined"
            style={{
              // fontSize: this.isMobile ? "0.85rem" : "1rem",
              fontSize: "1rem",
              width: this.thumbnailSize.width + 30,
              // height: this.thumbnailSize.height,
              marginRight: "15px",
              marginBottom: "15px",
            }}
            onClick={() => {
              window.open(
                "https://api.whatsapp.com/send?phone=" + this.state.whatsapp,
                "_blank"
              );

              //### create a log
              this.parent.backend.CreateLog(
                this.sessionObject.uuid,
                "clinic-whatsapp",
                '{"uuid": "' + this.parent.props.uuid + '"}',
                (response) => {}
              );
            }}
          >
            <div className="flex-column-center">
              <WhatsAppIcon style={{ fontSize: "32px", marginBottom: "5px" }} />
              WhatsApp
            </div>
          </Button>
          <Button
            variant="outlined"
            style={{
              // fontSize: this.isMobile ? "0.85rem" : "1rem",
              fontSize: "1rem",
              width: this.thumbnailSize.width,
              // height: this.thumbnailSize.height,
              marginRight: "15px",
              marginBottom: "15px",
            }}
            onClick={() => {
              window.open(this.state.placeURL, "_blank");

              //### create a log
              this.parent.backend.CreateLog(
                this.sessionObject.uuid,
                "clinic-google-map",
                '{"uuid": "' + this.parent.props.uuid + '"}',
                (response) => {}
              );
            }}
          >
            <div className="flex-column-center">
              <FmdGoodIcon style={{ fontSize: "32px", marginBottom: "5px" }} />
              Map
            </div>
          </Button>
          <Button
            variant="outlined"
            style={{
              // fontSize: this.isMobile ? "0.85rem" : "1rem",
              fontSize: "1rem",
              width: this.thumbnailSize.width,
              // height: this.thumbnailSize.height,
              marginBottom: "15px",
            }}
            onClick={() => {
              this.parent.myDialog.open({
                type: "alert",
                hasCloseButton: true,
                title: "",
                hasCloseButton: false,
                content: this.state.email,
              });
            }}
          >
            <div className="flex-column-center">
              <EmailIcon style={{ fontSize: "32px", marginBottom: "5px" }} />
              Email
            </div>
          </Button>

          {/* <div className="flex-column-center-left">
            <Button
              variant="outlined"
              startIcon={<WhatsAppIcon style={{ fontSize: "32px" }} />}
              style={{ marginBottom: "10px" }}
              onClick={() => {
                window.open(
                  "https://api.whatsapp.com/send?phone=" + this.state.whatsapp,
                  "_blank"
                );

                //### create a log
                this.parent.backend.CreateLog(
                  this.sessionObject.uuid,
                  "clinic-whatsapp",
                  '{"uuid": "' + this.parent.props.uuid + '"}',
                  (response) => {}
                );
              }}
            >
              Chat on WhatsApp
            </Button>
            <Button
              variant="outlined"
              startIcon={<FmdGoodIcon style={{ fontSize: "32px" }} />}
              style={{ marginBottom: "10px" }}
              onClick={() => {
                window.open(this.state.placeURL, "_blank");

                //### create a log
                this.parent.backend.CreateLog(
                  this.sessionObject.uuid,
                  "clinic-google-map",
                  '{"uuid": "' + this.parent.props.uuid + '"}',
                  (response) => {}
                );
              }}
            >
              See on Google Map
            </Button>
            <div className="flex-row-start">
              <EmailIcon style={{ fontSize: "32px" }} />
              <div style={{ marginLeft: "10px" }}>{this.state.email}</div>
            </div>
          </div> */}
        </div>
      </>
    );
  }
}

//###########################################################
//###########################################################
//###########################################################

export default Contact;

//###########################################################
//###########################################################
//###########################################################
