import React from "react";
import "./Authentication.css";

import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import HomeIcon from "@mui/icons-material/Home";

import Logo from "../logo/Logo";

// import { withSnackbar } from "notistack";

//###########################################################
//###########################################################
//###########################################################

class ConfirmationCode extends React.Component {
  //###########################################################
  //###########################################################
  //###########################################################

  constructor(props) {
    super(props);

    this.appData = this.props.appData;
    this.parent = this.props.parent;

    this.state = {
      loading: false,
      btnHomePageLoading: false,
    };
  }

  //###########################################################
  //###########################################################
  //###########################################################

  componentDidMount() {
    if (this.appData.printConsole)
      console.log(this.appData.accountManager.getUser());
  }

  //###########################################################
  //###########################################################
  //###########################################################

  render() {
    return (
      <>
        <div
          className="sign-in-container"
          style={{
            border: this.parent.authenticationDialog !== null ? "none" : "",
          }}
        >
          <div style={{ textAlign: "center" }}>
            <Logo type="desktop" appData={this.appData} />
          </div>
          <div
            style={{
              textAlign: "center",
              fontWeight: "bold",
              fontSize: "1.4rem",
              padding: "0px 20px 0px 20px",
              marginBottom: "30px",
            }}
            className="text-color"
          >
            Confirm Your E-Mail
          </div>
          <div
            style={{
              fontSize: "1rem",
              lineHeight: "1.7",
              marginBottom: "20px",
            }}
            className="text-color"
          >
            Enter the confirmation code received via e-mail, or click on the
            link, to <b>confirm your account.</b>
            {/* Enter the confirmation code received via e-mail to{" "}
          <b>confirm your account.</b> */}
          </div>
          <TextField
            inputRef={(el) => (this.txtConformationCode = el)}
            label="Conformation Code"
            variant="outlined"
            style={{ width: "100%", marginBottom: "30px" }}
            InputProps={{
              style: { fontFamily: "Poppins" },
            }}
            helperText={
              <span>
                Don't forget to check your <b>spam/junk</b> folder too.
              </span>
            }
          />
          <LoadingButton
            style={{
              width: "100%",
              paddingTop: "15px",
              paddingBottom: "15px",
              fontSize: "1rem",
              marginBottom: "20px",
            }}
            onClick={() => {
              //### set as loading True
              let tempState = this.state;
              tempState.loading = true;
              this.setState(tempState, () => {
                //### user UUID and the verification code
                let userUUID = this.appData.accountManager.getUser().UUID;
                let verificationCode = this.txtConformationCode.value;
                //### make your call to backend
                this.appData.verificationManager.verifyEmailWithVerificationCode(
                  userUUID,
                  verificationCode,
                  (response) => {
                    //### got your response
                    //### set as loading False
                    let tempState = this.state;
                    tempState.loading = false;
                    this.setState(tempState, () => {
                      //### do your stuff with your response
                      if (response.Result === "Correct") {
                        //### go to the sign in
                        this.parent.signInClicked(true);
                        //### alert success message
                        this.appData.snackbar().alertSuccess("Please sign in.");
                        this.appData.snackbar().alertSuccess(
                          "Your e-mail has been verified. Thank you."
                        );
                      } else if (response.Result === "Wrong") {
                        let message = "The confirmation code is wrong.";
                        this.appData.snackbar().alertError(message);
                      } else if (response.Result === "Error") {
                        this.appData.snackbar().alertError(response.Message);
                      }
                    });
                  }
                );
              });
            }}
            loading={this.state.loading}
            loadingIndicator="Please wait..."
            variant="contained"
          >
            Submit
          </LoadingButton>
          <div
            style={{
              textAlign: "center",
              fontSize: "1.0rem",
            }}
            className="text-color flex-row-center"
          >
            E-mail not received?
            <span
              className="link-color"
              style={{
                marginLeft: "5px",
                padding: "0px",
                fontSize: "1.0rem",
              }}
            >
              Send again
            </span>
          </div>
          <div
            style={{
              display: this.parent.authenticationDialog !== null ? "none" : "",
            }}
          >
            <div
              className="text-color flex-row-center"
              style={{
                borderTop: "1px solid #CCC",
                textAlign: "center",
                paddingTop: "30px",
                marginTop: "30px",
              }}
            >
              <LoadingButton
                size="small"
                onClick={() => {
                  let tempState = this.state;
                  tempState.btnHomePageLoading = true;
                  this.setState(tempState, () => {
                    setTimeout(function() {
                      window.location.href = "/";
                    }, 250);
                  });
                }}
                loading={this.state.btnHomePageLoading}
                loadingPosition="start"
                startIcon={<HomeIcon />}
                variant="contained"
              >
                Home Page
              </LoadingButton>
            </div>
          </div>
        </div>
      </>
    );
  }
}

//###########################################################
//###########################################################
//###########################################################

// export default withSnackbar(EmailConfirmation);
export default ConfirmationCode;

//###########################################################
//###########################################################
//###########################################################
