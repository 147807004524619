import React from "react";
import CommonCalls from "./CommonCalls";
import ScreenConsole from "../../screen-console/ScreenConsole";
import AccountManager from "../../../classes/build_in/AccountManager";
import Backend from "../../../classes/Backend";
import MySnackbar from "../../commons/MySnackbar";
import Message from "../Message";

//###########################################################
//###########################################################
//###########################################################

class PageMessage extends React.Component {
  //###########################################################
  //###########################################################
  //###########################################################

  constructor(props) {
    super(props);

    this.commonCalls = new CommonCalls();

    this.accountManager = new AccountManager({
      serverURL: process.env.REACT_APP_INSTANT_BACKEND_URL,
      applicationConfigurationID:
        process.env.REACT_APP_INSTANT_BACKEND_APPLICATION_CONFIGURATION_ID,
      printConsole:
        process.env.REACT_APP_INSTANT_BACKEND_PRINT_CONSOLE.toLowerCase() ===
        "true",
    });

    this.state = { content: null };
  }
  //###########################################################
  //###########################################################
  //###########################################################

  componentDidMount() {
    const queryParams = new URLSearchParams(window.location.search);
    let params = this.commonCalls.mainCommonCalls.myDecipher(
      queryParams.get("params")
    );
    params = JSON.parse(params);

    let tempState = this.state;
    tempState.content = (
      <Message
        parent={this}
        accountManager={this.accountManager}
        params={params}
      />
    );
    this.setState(tempState);
  }

  signUpClicked = () => {
    this.screenConsole.show(() => {
      this.commonCalls.signUpClicked();
    });
  };

  signInClicked = () => {
    this.screenConsole.show(() => {
      this.commonCalls.signInClicked();
    });
  };

  openForgotPassword = () => {
    this.screenConsole.show(() => {
      this.commonCalls.openForgotPassword();
    });
  };

  //###########################################################
  //###########################################################
  //###########################################################

  render() {
    return (
      <>
        <MySnackbar ref={(el) => (this.mySnackbar = el)} />
        <ScreenConsole ref={(el) => (this.screenConsole = el)} />
        <div
          className="flex-column-center"
          style={{ height: this.props.clientSize.height }}
        >
          {this.state.content}
        </div>
      </>
    );
  }
}

//###########################################################
//###########################################################
//###########################################################

export default PageMessage;

//###########################################################
//###########################################################
//###########################################################
