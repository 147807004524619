import React from "react";
import { Link, useNavigate } from "react-router-dom";
import LandingPage from "./LandingPageV3_0";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import "./LandingPage.css";

//###########################################################
//###########################################################
//###########################################################

class TestPage extends React.Component {
  //###########################################################
  //###########################################################
  //###########################################################

  constructor(props) {
    super(props);

    this.appData = this.props.appData;
    this.isPrivate = false;
    // this.appData.navigate = this.props.navigate;

    const queryParams = new URLSearchParams(window.location.search);
    this.action = queryParams.get("action");

    this.state = {};
  }

  //###########################################################
  //###########################################################
  //###########################################################

  componentDidMount() {
    let title =
      "Hair Transplant Clinic Search Engine • Compare Prices & Reviews • Multiple Quotes";
    let description =
      "We are the leading online platform connecting patients with hair transplant clinics in Turkey ★ Enquire for a fast multiple quotes ★ Compare prices and reviews to find your perfect hair transplant clinic.";

    document.title = title;
    document.getElementsByTagName("meta")[1].content = title;
    document.getElementsByTagName("meta")[2].content = description;
  }

  //###########################################################
  //###########################################################
  //###########################################################

  render() {
    return (
      <>
        <Header parent={this} appData={this.appData} action={this.action} />
        <LandingPage parent={this} appData={this.appData} />{" "}
        <Footer
          parent={this}
          appData={this.appData}
          alternateClassName="footer-blue"
        />
      </>
    );
  }
}

//###########################################################
//###########################################################
//###########################################################

export default function(props) {
  const navigate = useNavigate();
  return <TestPage {...props} navigate={navigate} />;
}

//###########################################################
//###########################################################
//###########################################################
