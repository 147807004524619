import React from "react";
import "./ReviewColorCodes.css";

import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import MyDialog from "../commons/MyDialog";

//###########################################################
//###########################################################
//###########################################################

class ReviewColorCodes extends React.Component {
  //###########################################################
  //###########################################################
  //###########################################################

  constructor(props) {
    super(props);
  }

  //###########################################################
  //###########################################################
  //###########################################################

  componentDidMount() {}

  //###########################################################
  //###########################################################
  //###########################################################

  getCell = (color, text) => {
    return (
      <div
        className={"clinic-card-chip-" + color}
        style={{
          textAlign: "center",
          padding: "10px",
          borderRadius: "5px",
          width: "50%"
        }}
      >
        {text}
      </div>
    );
  };

  getCellSubTitle = (text) => {
    return (
      <div
        style={{
          textAlign: "center",
          // padding: "10px",
          borderRadius: "5px",
          width: "50%",
          // fontWeight: "550",
          fontSize: "1.1rem"
        }}
      >
        {text}
      </div>
    );
  };

  //###########################################################
  //###########################################################
  //###########################################################

  getRow = (color, left, right) => {
    return (
      <div className="flex-row-center">
        {this.getCell(color, left)}
        <div
          style={{
            marginLeft: "8px",
            marginRight: "8px",
            width: "1px",
            height: "20px",
            background: "#CCC"
          }}
        ></div>
        {this.getCell(color, right)}
      </div>
    );
  };

  getRowSubTitle = () => {
    return (
      <div className="text-color flex-row-center">
        {this.getCellSubTitle("If the first Google review")}
        {this.getCellSubTitle("If the latest Google review")}
      </div>
    );
  };

  //###########################################################
  //###########################################################
  //###########################################################

  render() {
    return (
      <div className="width-with-padding-15" style={{ maxWidth: "500px" }}>
        {this.getRowSubTitle()}
        <div className="divider-height-15"></div>
        {this.getRow("green", "More than 6 years ago", "Less than 1 week ago")}
        <div className="divider-height-15"></div>
        {this.getRow(
          "yellow",
          "Between 4 and 6 years ago",
          "Between 1 and 4 weeks ago"
        )}
        <div className="divider-height-15"></div>
        {this.getRow(
          "orange",
          "Between 2 and 4 years ago",
          "Between 1 and 2 months ago"
        )}
        <div className="divider-height-15"></div>
        {this.getRow("red", "Less than 2 years ago", "More than 2 months ago")}
      </div>
    );
  }
}

//###########################################################
//###########################################################
//###########################################################

export default ReviewColorCodes;

//###########################################################
//###########################################################
//###########################################################
