import React from "react";
import "../bootstrap-studio/bootstrap/bootstrap.scoped.css";
import "../bootstrap-studio/styles/bs-listing.css";

import Rating from "@mui/material/Rating";
import OutlinedDiv from "../commons/OutlinedDiv";
import Divider from "@mui/material/Divider";
import MyDialog from "../commons/MyDialog";
import CommonCalls from "../../classes/CommonCalls";
import ClinicBootstrapStudio from "../clinic-bootstrap-studio/ClinicBootstrapStudio";
import FirstGoogleReviewSlider from "./FirstGoogleReviewSlider";

//###########################################################
//###########################################################
//###########################################################

class ClinicCardBootstrapStudio extends React.Component {
  //###########################################################
  //###########################################################
  //###########################################################

  constructor(props) {
    super(props);
    
    this.parent = this.props.parent;
    this.appData = this.parent.appData;

    this.commonCalls = new CommonCalls();
    this.sessionObject = this.parent.sessionObject;

    this.state = {};
  }

  //###########################################################
  //###########################################################
  //###########################################################

  componentDidMount() {}

  //###########################################################
  //###########################################################
  //###########################################################

  googleStars = () => {
    let data = this.props.data;

    let startStr =
      data.Star.toString().length == 1
        ? data.Star.toString() + ".0"
        : data.Star;

    let yearsAgo = 0;
    let firstReview = data.FirstReview.trim().toLowerCase();

    if (firstReview.includes("year")) {
      yearsAgo = firstReview.split(" ")[0];
      yearsAgo = parseInt(yearsAgo);
    }

    let since = new Date().getFullYear() - yearsAgo;

    return (
      <>
        <Divider
          style={{ width: "100%", fontSize: "0.85rem", marginBottom: "15px" }}
        >
          Google Reviews
        </Divider>

        <div className="fs-6" style={{ width: "100%" }}>
          <div className="flex-row-center" style={{ lineHeight: "1.0" }}>
            <div style={{ marginTop: "2px", marginRight: "10px" }}>
              {startStr}
            </div>
            <Rating
              value={Number(data.Star)}
              precision={0.1}
              readOnly
              size="medium"
            />
            <div style={{ marginTop: "2px", marginLeft: "10px" }}>
              {data.Count}
            </div>
          </div>
          <div
            style={{
              textAlign: "center",
              marginTop: "3px",
              fontSize: "0.85rem",
            }}
          >
            Since {since}
            <span style={{ display: yearsAgo <= 0 ? "none" : "" }}>
              {" "}
              ({yearsAgo} years)
            </span>
          </div>
        </div>
      </>
    );
  };

  //###########################################################
  //###########################################################
  //###########################################################

  onClinicCardClicked = () => {
    window.history.pushState(null, "", window.location.href);
    window.onpopstate = () => {
      this.myDialog.close();
    };

    this.myDialog.open({
      type: "container",
      hasCloseButton: false,
      contentLayout: "top-center",
      // background: "#F8F8F8",
      content: (
        <ClinicBootstrapStudio
          container={this.myDialog}
          parent={this}
          uuid={this.props.data.UUID}
        />
      ),
    });
  };

  //###########################################################
  //###########################################################
  //###########################################################

  renderAmazon = () => {
    return (
      <>
        <MyDialog ref={(el) => (this.myDialog = el)} />

        <div className="col" onClick={this.onClinicCardClicked}>
          <div className="d-flex flex-column align-items-center bs-clinic-card-container">
            <div
              className="d-flex justify-content-center align-items-center"
              style={{
                height: "100px",
                background: "#f8f8f8",
                width: "100%",
                borderTopLeftRadius: "5px",
                borderTopRightRadius: "5px",
              }}
            >
              {/* <img
                className="bs-clinic-card-logo d-none"
                src="assets/img/logo.png"
              /> */}

              <img
                src={`${"../assets/images/clinics-resized/" +
                  this.props.data.UUID +
                  "/logo.png"}`}
                className="bs-clinic-card-logo"
                alt={this.props.data.Name}
              />
            </div>
            <div>
              <p
                className="fs-5 fw-bold text-center bs-clinic-card-about d-none"
                style={{ marginTop: "20px" }}
              >
                Amazon Clinic
              </p>
              <p
                className="fs-5 fw-bold text-center bs-clinic-card-about"
                style={{ marginTop: "20px" }}
              >
                {this.props.data.Name}
              </p>
              <p className="fs-6 text-center bs-clinic-card-about d-none">
                Amazon Clinic was established by a team of doctors with more
                than fifteen years of experience, holding honorary certificates
                and licenses from the Turkish government and the Ministry of
                Health, in addition to being well versed in using the latest
                international technologies and keeping abreast of the latest
                research and developments in the field. Medical and cosmetic in
                the world, we also add their presence in the largest multi-award
                winning hospitals in Turkey.
              </p>
              <p className="fs-6 text-center bs-clinic-card-about">
                {this.props.data.About}
              </p>
              <p className="text-center d-none">Google Review</p>
              {this.googleStars()}
            </div>
          </div>
        </div>
      </>
    );
  };

  //###########################################################
  //###########################################################
  //###########################################################

  render() {
    return this.renderAmazon();
  }
}

//###########################################################
//###########################################################
//###########################################################

export default ClinicCardBootstrapStudio;

//###########################################################
//###########################################################
//###########################################################
