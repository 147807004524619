import axios from "axios";

// https://codesandbox.io/s/react-percentage-progress-upload-file-gem1k?from-embed=&file=/src/index.js:540-556
//###########################################################
//###########################################################
//###########################################################

class ImageProcessing {
  constructor(config) {
    this.applicationConfigurationID = config.applicationConfigurationID;
    this.serverURL = config.serverURL + "/Image/";
    this.printConsole =
      config.printConsole !== undefined ? config.printConsole : false;
  }

  //###########################################################
  //###########################################################
  //###########################################################

  _performBackendOperationByImageName = (imageName, callName) => {
    return new Promise((resolve, reject) => {
      //### set data for backend post
      var data = {
        ApplicationID: this.applicationID,
        ConnectionID: this.connectionID,
        QueryName: "",
        QueryParams: JSON.stringify({ imageName: imageName }),
      };

      //### perform axios
      axios({
        method: "post",
        url: this.serverURL + callName,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      })
        .then(function(response) {
          if (this.printConsole) console.log(response);
          //### shape the response
          response.data.Data = JSON.parse(response.data.Data);
          response = response.data;

          //### there is an issue
          if (response.Result !== "OK") {
            let errorMessageForConsole = ">>> ERROR: DeleteImage >>> " + response.Message;
            let errorMessageForEndUser = "There is an error during deleting your image: " + response.Message;
            if (this.printConsole) {
              console.log("-------------------------------------");
              console.log(errorMessageForConsole);
              console.log("-------------------------------------");
            }
            reject(errorMessageForEndUser);
            return;
          }
          //### everything is fine
          else {
            resolve(response.Data);
          }
        })
        .catch(function(error) {
          let errorMessageForConsole = ">>> ERROR: DeleteImage >>> " + error;
          let errorMessageForEndUser = "There is an error during deleting your image: " + error;
          if (this.printConsole) {
            console.log("-------------------------------------");
            console.log(errorMessageForConsole);
            console.log("-------------------------------------");
          }
          reject(errorMessageForEndUser);
          return;
        });
    });
  };

  //###########################################################
  //###########################################################
  //###########################################################

  resizeImage = (base64, maxWidth, maxHeight, callBack) => {
    if (maxWidth <= 0 || maxHeight <= 0) {
      callBack(base64);
    } else {
      var img = document.createElement("img");
      img.onload = function(event) {
        //### Dynamically create a canvas element
        var canvas = document.createElement("canvas");
        var ctx = canvas.getContext("2d");

        let imageWidth = img.width;
        let imageHeight = img.height;
        let ratio = imageWidth / imageHeight;

        if (imageWidth > maxWidth) {
          imageWidth = maxWidth;
          imageHeight = imageWidth / ratio;
        }

        if (imageHeight > maxHeight) {
          imageHeight = maxHeight;
          imageWidth = imageHeight * ratio;
        }

        //### Actual resizing
        canvas.width = imageWidth;
        canvas.height = imageHeight;
        ctx.drawImage(img, 0, 0, imageWidth, imageHeight);

        //### result base64
        let res = canvas.toDataURL();

        //### call callBack
        callBack(res);
      };
      img.src = base64;
    }
  };

  //###########################################################
  //###########################################################
  //###########################################################
  // axois upload progress bar!!!
  // https://codesandbox.io/s/react-percentage-progress-upload-file-gem1k?from-embed=&file=/src/index.js:322-334

  uploadImage = (fileBase64, options) => {
    let _this = this;

    let allowedTypes = ["image/bmp", "image/png", "image/jpeg"];

    if (options === undefined) {
      options = {};
    }

    if (options.maxWidth === undefined || options.maxHeight === undefined) {
      options.maxWidth = 0;
      options.maxHeight = 0;
    }
    if (options.expiresInDay === undefined) {
      options.expiresInDay = -1;
    }

    return new Promise((resolve, reject) => {
      if (!allowedTypes.includes(fileBase64.type)) {
        let errorMessageForConsole = ">>> ERROR: UploadImage >>> Not correct file's type (bmp, png, jpeg)!";
        let errorMessageForEndUser = "Please provide an image of type PNG, JPG or BMP.";
        if (this.printConsole) {
          console.log("-------------------------------------");
          console.log(errorMessageForConsole);
          console.log("-------------------------------------");
        }
        reject(errorMessageForEndUser);
        return;
      }

      this.resizeImage(
        fileBase64.base64,
        options.maxWidth,
        options.maxHeight,
        (resizedBase64) => {
          if (options.callBackResizedImage !== undefined) {
            options.callBackResizedImage(resizedBase64);
          }
          fetch(resizedBase64)
            .then((res) => res.blob())
            .then((blob) => {
              const file = new File([blob], fileBase64.name, {
                type: fileBase64.type,
              });

              //### data to backend
              var formData = new FormData();
              formData.append(
                "ApplicationConfigurationID",
                _this.applicationConfigurationID
              );
              formData.append("ExpiresInDay", options.expiresInDay);
              formData.append("Image", file);

              axios
                .post(this.serverURL + "UploadImage", formData, {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                  onUploadProgress: progressEvent => console.log(progressEvent.loaded)
                  // onUploadProgress: (progressEvent) => {
                  //   const percentage = Math.round(
                  //     (progressEvent.loaded * 100) / progressEvent.total
                  //   );

                  //   console.log(percentage);
                  // }
                })
                .then((response) => {
                  //### shape the response
                  // if (this.printConsole) console.log(response);

                  response.data.Data = JSON.parse(response.data.Data);
                  response = response.data;

                  //### there is an issue
                  if (response.Result !== "OK") {
                    let errorMessageForConsole = ">>> ERROR: UploadImage >>> " + response.Message;
                    let errorMessageForEndUser = "There is an error during image upload: " + response.Message;
                      
                    if (this.printConsole) {
                      console.log("-------------------------------------");
                      console.log(errorMessageForConsole);
                      console.log("-------------------------------------");
                    }
                    reject(errorMessageForEndUser);
                    return;
                  }
                  //### everything is fine
                  else {
                    resolve(response.Data);
                  }
                })
                .catch(function(error) {
                  let errorMessageForConsole = ">>> ERROR: UploadImage >>> " + error;
                  let errorMessageForEndUser = "There is an error during image upload: " + error;
                  if (_this.printConsole) {
                    console.log("-------------------------------------");
                    console.log(errorMessageForConsole);
                    console.log("-------------------------------------");
                  }
                  reject(errorMessageForEndUser);
                  return;
                });
            });
        }
      );
    });
  };

  //###########################################################
  //###########################################################
  //###########################################################

  deleteImage = (imageName) => {
    return this._performBackendOperationByImageName(imageName, "DeleteImage");
  };

  //###########################################################
  //###########################################################
  //###########################################################

  refreshImageTimeStamp = (imageName) => {
    return this._performBackendOperationByImageName(
      imageName,
      "RefreshImageTimeStamp"
    );
  };

  //###########################################################
  //###########################################################
  //###########################################################

  isImageExist = (imageName) => {
    return this._performBackendOperationByImageName(imageName, "IsImageExist");
  };

  //###########################################################
  //###########################################################
  //###########################################################
}

//###########################################################
//###########################################################
//###########################################################

export default ImageProcessing;

//###########################################################
//###########################################################
//###########################################################
