import React from "react";
import { Link, useNavigate } from "react-router-dom";
import FacebookCircularProgress from "../screen-console/FacebookCircularProgress";
import Button from "@mui/material/Button";
import LanguageIcon from "@mui/icons-material/Language";
import ButtonGroup from "@mui/material/ButtonGroup";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

//###########################################################
//###########################################################
//###########################################################

class Title extends React.Component {
  //###########################################################
  //###########################################################
  //###########################################################

  constructor(props) {
    super(props);

    //### for forward references
    if (
      this.props.parent !== undefined &&
      this.props.parent.title !== undefined
    ) {
      this.props.parent.title = this;
    }

    this.parent = this.props.parent;
    this.sessionObject = this.parent.sessionObject;
    this.isMobile = this.sessionObject.device.isMobile;

    this.uuid = this.props.parent.props.uuid;

    this.logo = <></>;

    this.readyCount = 0;

    this.state = {
      clinicName: "",
      correctedURL: "",

      ready: false,
      // clinicDataFetched: false,
      // logoImageLoaded: false,

      facebook: "",
      instagram: "",
      youtube: "",
      twitter: "",
      linkedin: "",
      ready: false,
    };
  }

  //###########################################################
  //###########################################################
  //###########################################################

  componentDidMount() {}

  //###########################################################
  //###########################################################
  //###########################################################

  onClinicDataHasBeenRetrieved = (data) => {
    let tempState = this.state;
    tempState.clinicName = data.Name;
    tempState.correctedURL = data.CorrectedURL;

    this.readyCount++;
    if (this.readyCount >= 3) {
      tempState.ready = true;
    }

    this.setState(tempState);
  };

  onClinicURLsHasBeenRetrieved = (data) => {
    let tempState = this.state;
    data.forEach((d) => {
      tempState[d.Name] = d.URL;
    });

    this.readyCount++;
    if (this.readyCount >= 3) {
      tempState.ready = true;
    }

    this.setState(tempState);
  };

  //###########################################################
  //###########################################################
  //###########################################################

  render() {
    return (
      <>
        <div
          style={{
            display: this.state.ready ? "none" : "",
          }}
        >
          <div className="flex-row-center">
            <FacebookCircularProgress />
          </div>
        </div>

        {/* ##################### */}
        {/* ##################### */}
        {/* ##################### */}

        <div
          style={{
            display: this.state.ready ? "" : "none",
          }}
        >
          <div
            className="flex-column-center"
            style={{ width: "100%", background: "#FFF" }}
          >
            {/* logo */}
            <div className="flex-row-center">
              <img
                src={`${"../assets/images/clinics/" + this.uuid + "/logo.png"}`}
                alt={this.state.clinicName}
                style={{
                  maxWidth: "250px",
                  maxHeight: "150px",
                  borderRadius: "5px",
                }}
                onLoad={() => {
                  let tempState = this.state;
                  this.readyCount++;
                  if (this.readyCount >= 3) {
                    tempState.ready = true;
                  }
                  this.setState(tempState);
                }}
              />
            </div>
            <div
              className="flex-column-center-left"
              style={{ width: "100%", background: "#FFF", marginTop: "15px" }}
            >
              <div style={{ fontSize: "28px" }}>{this.state.clinicName}</div>

              <ButtonGroup size={this.isMobile ? "small" : "large"}>
                <Button
                  className="clinic-active-button-border"
                  onClick={() => {
                    window.open(this.state.correctedURL, "_blank");
                    //### create a log
                    this.parent.backend.CreateLog(
                      this.sessionObject.uuid,
                      "clinic-website-clicked",
                      '{"uuid": "' + this.parent.props.uuid + '"}',
                      (response) => {}
                    );
                  }}
                >
                  {this.isMobile ? (
                    <div className="flex-column-center">
                      <LanguageIcon style={{ fontSize: "32px" }} />
                      <span style={{ fontSize: "0.7rem" }}>Website</span>
                    </div>
                  ) : (
                    <>
                      <LanguageIcon
                        style={{ fontSize: "32px", marginRight: "5px" }}
                      />
                      Website
                    </>
                  )}
                </Button>
                <Button
                  disabled={this.state.facebook === "" ? true : false}
                  className={
                    this.state.facebook === ""
                      ? ""
                      : "clinic-active-button-border"
                  }
                  onClick={() => {
                    window.open(this.state.facebook, "_blank");
                     //### create a log
                     this.parent.backend.CreateLog(
                      this.sessionObject.uuid,
                      "clinic-facebook-clicked",
                      '{"uuid": "' + this.parent.props.uuid + '"}',
                      (response) => {}
                    );
                  }}
                >
                  <FacebookIcon style={{ fontSize: "32px" }} />
                </Button>
                <Button
                  disabled={this.state.instagram === "" ? true : false}
                  className={
                    this.state.instagram === ""
                      ? ""
                      : "clinic-active-button-border"
                  }
                  onClick={() => {
                    window.open(this.state.instagram, "_blank");
                    //### create a log
                    this.parent.backend.CreateLog(
                     this.sessionObject.uuid,
                     "clinic-instagram-clicked",
                     '{"uuid": "' + this.parent.props.uuid + '"}',
                     (response) => {}
                   );
                  }}
                >
                  <InstagramIcon style={{ fontSize: "32px" }} />
                </Button>
                <Button
                  disabled={this.state.youtube === "" ? true : false}
                  className={
                    this.state.youtube === ""
                      ? ""
                      : "clinic-active-button-border"
                  }
                  onClick={() => {
                    window.open(this.state.youtube, "_blank");
                    //### create a log
                    this.parent.backend.CreateLog(
                     this.sessionObject.uuid,
                     "clinic-youtube-clicked",
                     '{"uuid": "' + this.parent.props.uuid + '"}',
                     (response) => {}
                   );
                  }}
                >
                  <YouTubeIcon style={{ fontSize: "32px" }} />
                </Button>
                <Button
                  disabled={this.state.twitter === "" ? true : false}
                  className={
                    this.state.twitter === ""
                      ? ""
                      : "clinic-active-button-border"
                  }
                  onClick={() => {
                    window.open(this.state.twitter, "_blank");
                    //### create a log
                    this.parent.backend.CreateLog(
                     this.sessionObject.uuid,
                     "clinic-twitter-clicked",
                     '{"uuid": "' + this.parent.props.uuid + '"}',
                     (response) => {}
                   );
                  }}
                >
                  <TwitterIcon style={{ fontSize: "32px" }} />
                </Button>
                <Button
                  disabled={this.state.linkedin === "" ? true : false}
                  className={
                    this.state.linkedin === ""
                      ? ""
                      : "clinic-active-button-border"
                  }
                  onClick={() => {
                    window.open(this.state.linkedin, "_blank");
                    //### create a log
                    this.parent.backend.CreateLog(
                     this.sessionObject.uuid,
                     "clinic-linkedin-clicked",
                     '{"uuid": "' + this.parent.props.uuid + '"}',
                     (response) => {}
                   );
                  }}
                >
                  <LinkedInIcon style={{ fontSize: "32px" }} />
                </Button>
              </ButtonGroup>
            </div>
          </div>

          {/* <div className="flex-row-center">
            <div className="flex-column-center">
              <img
                src={`${"../assets/images/clinics/" + this.uuid + "/logo.png"}`}
                alt={this.state.clinicName}
                style={{
                  maxWidth: "250px",
                  maxHeight: "150px",
                  borderRadius: "5px",
                }}
                onLoad={() => {
                  let tempState = this.state;
                  tempState.logoImageLoaded = true;
                  this.setState(tempState);
                }}
              />
              <div className="divider-height-10" />

              <Button
                variant="outlined"
                startIcon={<LanguageIcon style={{ fontSize: "32px" }} />}
                style={{ paddingTop: "10px", paddingBottom: "10px" }}
                // onClick={onClickSendMessage}
              >
                Visit {this.state.clinicName}
              </Button>


              
            </div>
          </div> */}
        </div>
      </>
    );
  }
}

//###########################################################
//###########################################################
//###########################################################

export default function(props) {
  const navigate = useNavigate();
  return <Title {...props} navigate={navigate} />;
}

//###########################################################
//###########################################################
//###########################################################
