import { ThirtyFpsSharp } from "@mui/icons-material";
import React from "react";
import "./Clinic.css";
import { Link, useNavigate } from "react-router-dom";
import MyDialog from "../commons/MyDialog";
import Divider from "@mui/material/Divider";
import Backend from "../../classes/Backend";
import CommonsWithClinic from "../clinic-card/CommonsWithClinic";
import MySessionManager from "../commons/MySessionManager";
import Header from "./Header";
import Title from "./Title";
import Google from "./Google";
import Galery from "./Galery";
import BeforeAfter from "./BeforeAfter";
import Contact from "./Contact";
import About from "./About";
import Methods from "./Methods";

class Clinic extends React.Component {
  //###########################################################
  //###########################################################
  //###########################################################

  constructor(props) {
    super(props);

    this.title = null;
    this.galery = null;
    this.beforeAfter = null;
    this.google = null;
    this.contact = null;
    this.about = null;
    this.methods = null;

    this.pageListClinicCards = this.props.parent.props.parent.props.parent;
    this.backend = new Backend();
    this.sessionManager = new MySessionManager();
    this.sessionObject = JSON.parse(this.sessionManager.get("sessionObject"));
    this.commonsWithClinic = new CommonsWithClinic(
      this.props.uuid,
      this.backend,
      this.sessionObject
    );

    //### for forward references
    if (
      this.props.parent !== undefined &&
      this.props.parent.clinic !== undefined
    ) {
      this.props.parent.clinic = this;
    }

    this.state = {
      clinicData: null,
      clinicURLs: null,
      clinicGoogleData: null,
    };

    this.styleSectionHeader = {
      background: "#505559",
      color: "#FFF",
      padding: "5px 10px 5px 10px",
      borderRadius: "5px",
    };
  }

  //###########################################################
  //###########################################################
  //###########################################################

  componentDidMount() {
    //### get clinic data and call the event handler
    this.backend.GetClinicByUUID(this.props.uuid, (response) => {
      this.onClinicDataHasBeenRetrieved(response);
    });

    //### get clinic urls and call the event handler
    this.backend.GetClinicURLsByUUID(this.props.uuid, (response) => {
      this.onClinicURLsHasBeenRetrieved(response);
    });

    //### get clinic's google data
    this.backend.GetGoogleByUUID(this.props.uuid, (response) => {
      this.onClinicGoogleDataHasBeenRetrieved(response);
    });
  }

  //###########################################################
  //###########################################################
  //###########################################################

  onClinicDataHasBeenRetrieved = (data) => {
    let tempState = this.state;
    tempState.clinicData = data;
    this.setState(tempState);
    this.title.onClinicDataHasBeenRetrieved(data);
    this.contact.onClinicDataHasBeenRetrieved(data);
    this.about.onClinicDataHasBeenRetrieved(data);
    this.methods.onClinicDataHasBeenRetrieved(data);

    //### init galery
    let imageURLsGalery = [];
    for (let i = 1; i <= data.GaleryCount; i++) {
      let src = `${"../assets/images/clinics/" +
        this.props.uuid +
        "/galery/" +
        i +
        ".png"}`;
      imageURLsGalery.push(src);
    }
    this.galery.setImageUrls(imageURLsGalery);

    //### init beforeAfter
    let imageURLsBeforeAfter = [];
    for (let i = 1; i <= data.BeforeAfterCount; i++) {
      let src = `${"../assets/images/clinics/" +
        this.props.uuid +
        "/blured/" +
        i +
        ".png"}`;
      imageURLsBeforeAfter.push(src);
    }
    this.beforeAfter.setImageUrls(imageURLsBeforeAfter);

    //### create a log
    this.backend.CreateLog(
      this.sessionObject.uuid,
      "clinic-loaded",
      '{"uuid": "' + data.UUID + '", "name":"' + data.Name + '"}',
      (response) => {}
    );
  };

  onClinicURLsHasBeenRetrieved = (data) => {
    let tempState = this.state;
    tempState.clinicURLs = data;
    this.setState(tempState);
    this.title.onClinicURLsHasBeenRetrieved(data);
  };

  onClinicGoogleDataHasBeenRetrieved = (data) => {
    let tempState = this.state;
    tempState.clinicGoogleData = data;
    this.setState(tempState);
    this.google.onClinicGoogleDataHasBeenRetrieved(data);
    this.contact.onClinicGoogleDataHasBeenRetrieved(data);
  };

  //###########################################################
  //###########################################################
  //###########################################################

  close = () => {
    this.props.container.close();
  };

  //###########################################################
  //###########################################################
  //###########################################################

  render() {
    return (
      <>
        <MyDialog ref={(el) => (this.myDialog = el)} />
        <div
          className="clinic-container"
          // style={{
          //   minHeight: this.pageListClinicCards.props.clientSize.height - 30,
          // }}
        >
          <Header parent={this} />
          <div className="divider-height-5" />
          <Title parent={this} />
          <div className="divider-height-25" />
          <Divider style={{ width: "100%" }}>
            <span style={this.styleSectionHeader}>Google Reviews</span>
          </Divider>
          <div className="divider-height-25" />
          <Google parent={this} />
          <div className="divider-height-25" />
          <Divider style={{ width: "100%" }}>
            <span style={this.styleSectionHeader}>Galery</span>
          </Divider>
          <div className="divider-height-25" />
          <Galery parent={this} />
          <div className="divider-height-20" />
          <Divider style={{ width: "100%" }}>
            <span style={this.styleSectionHeader}>Before and After</span>
          </Divider>
          <div className="divider-height-25" />
          <BeforeAfter parent={this} />
          <div className="divider-height-20" />
          <Divider style={{ width: "100%" }}>
            <span style={this.styleSectionHeader}>Services</span>
          </Divider>
          <div className="divider-height-25" />
          <Methods parent={this} />
          <div className="divider-height-20" />
          <Divider style={{ width: "100%" }}>
            <span style={this.styleSectionHeader}>Contact</span>
          </Divider>
          <div className="divider-height-25" />
          <Contact parent={this} />
          <div className="divider-height-15" />
          <Divider style={{ width: "100%" }}>
            <span style={this.styleSectionHeader}>About</span>
          </Divider>
          <div className="divider-height-25" />
          <About parent={this} />
          <div className="divider-height-25" />
          <p
            style={{
              fontSize: "0.85rem",
              lineHeight: "1.1",
              textAlign: "justify",
            }}
          >
            (*) We provide these images from the clinic's social media platforms
            and/or their websites. So, Turkish Hair Clinics does not guarantee
            truth of these images.
          </p>
        </div>
      </>
    );
  }
}

//###########################################################
//###########################################################
//###########################################################

export default function(props) {
  const navigate = useNavigate();
  return <Clinic {...props} navigate={navigate} />;
}

//###########################################################
//###########################################################
//###########################################################
