import React from "react";
import MyDialog from "./MyDialog";
import Button from "@mui/material/Button";

//###########################################################
//###########################################################
//###########################################################

class MyAlert extends React.Component {
  //###########################################################
  //###########################################################
  //###########################################################

  constructor(props) {
    super(props);
    this.state = {};
  }

  //###########################################################
  //###########################################################
  //###########################################################

  /* USAGE
  
 this.appData.alert().open("This is an allert :) ", {
      callBack: () => {
        alert("aletted");
      },
    });

  
  */

  open = (message, options) => {

    if(options === undefined){
      options = {};
    }

    //### set the dialog options.
    let dialogOptions = options;
    dialogOptions.type = "alert";
    dialogOptions.hasDividers= true;
    dialogOptions.message = message;

    dialogOptions.callBackButton = dialogOptions.callBack;
    dialogOptions.zIndex= 2000;


    this.myDialog.open(dialogOptions);
  };

  close = () => {
    this.myDialog.close();
  };

  //###########################################################
  //###########################################################
  //###########################################################

  componentDidMount() {}

  //###########################################################
  //###########################################################
  //###########################################################

  render() {
    return <MyDialog ref={(el) => (this.myDialog = el)} />;
  }
}

//###########################################################
//###########################################################
//###########################################################

export default MyAlert;

//###########################################################
//###########################################################
//###########################################################
