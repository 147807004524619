import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { IconButton } from "@mui/material";
import Button from "@mui/material/Button";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import EmailIcon from "@mui/icons-material/Email";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";

//###########################################################
//###########################################################
//###########################################################

class Header extends React.Component {
  //###########################################################
  //###########################################################
  //###########################################################

  constructor(props) {
    super(props);

    //### for forward references
    if (
      this.props.parent !== undefined &&
      this.props.parent.header !== undefined
    ) {
      this.props.parent.header = this;
    }

    this.parent = this.props.parent;
    this.sessionObject = this.parent.sessionObject;

    this.state = {};
  }

  //###########################################################
  //###########################################################
  //###########################################################

  componentDidMount() {}

  //###########################################################
  //###########################################################
  //###########################################################

  backButton = () => {
    let clientWidth = this.props.parent.pageListClinicCards.props.clientSize
      .width;

    let onClick = () => {
      //### create a log
      this.parent.backend.CreateLog(
        this.sessionObject.uuid,
        "clinic-closed",
        '{"uuid": "' + this.parent.props.uuid + '"}',
        (response) => {}
      );

      this.props.parent.close();
    };

    if (clientWidth < 650) {
      return (
        <IconButton color="primary" onClick={onClick}>
          <ArrowBackIosIcon style={{ fontSize: "1.8rem" }} />
        </IconButton>
      );
    } else {
      return (
        <Button
          variant="contained"
          startIcon={<ArrowBackIosIcon />}
          // style={{ paddingTop: "10px", paddingBottom: "10px" }}
          onClick={onClick}
        >
          Back
        </Button>
      );
    }
  };

  //###########################################################
  //###########################################################
  //###########################################################

  rightMenu = () => {
    if (this.sessionObject.version <= 1.0) {
      return <div />;
    }

    let clientWidth = this.props.parent.pageListClinicCards.props.clientSize
      .width;

    let onClickSendMessage = () => {
      alert("Send Message");
    };
    let onClickAddFavorites = () => {
      alert("Add to favorites");
    };

    if (clientWidth < 650) {
      return (
        <div className="flex-row-start">
          <IconButton color="primary" onClick={onClickSendMessage}>
            <EmailIcon style={{ fontSize: "2.0rem" }} />
          </IconButton>
          <div className="divider-width-5" />
          <IconButton color="primary" onClick={onClickAddFavorites}>
            <FavoriteBorderIcon style={{ fontSize: "2.0rem" }} />
          </IconButton>
        </div>
      );
    } else {
      return (
        <div className="flex-row-start">
          <Button
            variant="contained"
            startIcon={<EmailIcon />}
            style={{ paddingTop: "10px", paddingBottom: "10px" }}
            onClick={onClickSendMessage}
          >
            Send Message
          </Button>
          <div className="divider-width-10" />
          <Button
            variant="contained"
            startIcon={<FavoriteBorderIcon />}
            style={{ paddingTop: "10px", paddingBottom: "10px" }}
            onClick={onClickAddFavorites}
          >
            Add to Favorites
          </Button>
        </div>
      );
    }
  };

  //###########################################################
  //###########################################################
  //###########################################################

  render() {
    return (
      <div className="flex-row-space-between">
        {this.backButton()}
        {this.rightMenu()}
      </div>
    );
  }
}

//###########################################################
//###########################################################
//###########################################################

export default function(props) {
  const navigate = useNavigate();
  return <Header {...props} navigate={navigate} />;
}

//###########################################################
//###########################################################
//###########################################################
