import { Cookies } from "react-cookie";

//###########################################################
//###########################################################
//###########################################################

class MyCookieManager  {
  //###########################################################
  //###########################################################
  //###########################################################

  constructor() {
    this.cookies = new Cookies();
  }

  //###########################################################
  //###########################################################
  //###########################################################

  set = (name, value, hours) => {
    let options = {};
    options.path = "/";

    if (hours === undefined || hours <= 0) {
      hours = 24 * 30;
    }

    var date = new Date();
    date.setTime(date.getTime() + hours * 60 * 60 * 1000);
    options.expires = date;

    this.cookies.set(name, value, options);
  };

  //### returns undefined if not exist
  get = (name) => {
    return this.cookies.get(name);
  };

  delete = (name) => {
    return this.cookies.remove(name);
  };
}

//###########################################################
//###########################################################
//###########################################################

export default MyCookieManager;

//###########################################################
//###########################################################
//###########################################################
