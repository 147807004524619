import React from "react";
import Commons from "./Commons";
import { isMobile } from "react-device-detect";

//###########################################################
//###########################################################
//###########################################################

class Navigator extends React.Component {
  //###########################################################
  //###########################################################
  //###########################################################

  constructor(props) {
    super(props);
    this.commons = new Commons();
    this.defaults = { backText: "<<< Back <<<", nextText: ">>> Next >>>" };

    this.commons.styleQuestionContainerRadio.margin = "5px 20px 20px 20px";
    this.commons.styleQuestionContainerRadio.padding = "15px";
    this.state = {
      backIsEnable: false,
      nextIsEnable: true,
      backText: this.defaults.backText,
      nextText: this.defaults.nextText,
    };
  }

  //###########################################################
  //###########################################################
  //###########################################################

  componentDidMount() {}

  //###########################################################
  //###########################################################
  //###########################################################

  setBackIsEnable = (val) => {
    let tempState = this.state;
    tempState.backIsEnable = val;

    this.setState(tempState);
  };

  setNextIsEnable = (val) => {
    let tempState = this.state;
    tempState.nextIsEnable = val;
    this.setState(tempState);
  };

  //###########################################################
  //###########################################################
  //###########################################################

  getClassNameBack = () => {
    if (this.state.backIsEnable) return "btn-primary";
    else return "btn-primary btn-primary-disabled";
  };

  getClassNameNext = () => {
    if (this.state.nextIsEnable) return "btn-primary";
    else return "btn-primary btn-primary-disabled";
  };

  //###########################################################
  //###########################################################
  //###########################################################

  setNextText = (val) => {
    let tempState = this.state;
    tempState.nextText = val;
    this.setState(tempState);
  };

  setNextTextDefault = () => {
    this.setNextText(this.defaults.nextText);
  };

  //###########################################################
  //###########################################################
  //###########################################################

  render() {
    return (
      <>
        <div
          className="flex-row-space-between"
          style={this.commons.styleQuestionContainerRadio}
        >
          <div
            className={this.getClassNameBack()}
            style={{ marginTop: "10px", marginBottom: "10px" }}
            onClick={() => {
              if (!this.state.backIsEnable) {
                return false;
              }
              this.props.onBack();
            }}
          >
            {this.state.backText}
          </div>
          <div
            className={this.getClassNameNext()}
            onClick={() => {
              if (!this.state.nextIsEnable) {
                return false;
              }
              this.props.onNext();
            }}
          >
            {this.state.nextText}
          </div>
        </div>
        <div style={{ display: isMobile ? "block" : "none", height:"30px" }}></div>
      </>
    );
  }
}

//###########################################################
//###########################################################
//###########################################################

export default Navigator;

//###########################################################
//###########################################################
//###########################################################
