import React from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

//###########################################################
//###########################################################
//###########################################################

class QuoteRequestForm extends React.Component {
  //###########################################################
  //###########################################################
  //###########################################################

  constructor(props) {
    super(props);

    this.appData = this.props.appData;

    this.state = { data: { 1: null, 2: null, 3: null } };
  }

  //###########################################################
  //###########################################################
  //###########################################################

  componentDidMount() {
    let dataFromBackend = this.props.formData;
    let tempState = this.state;
    for (let i = 0; i < dataFromBackend.length; i++) {
      let item = dataFromBackend[i];
      tempState.data[item.StepIndex] = JSON.parse(item.JSONString);
      let printData = tempState.data[item.StepIndex].printData;
      let stateData = tempState.data[item.StepIndex].stateData;

      //### restore Json String for printData
      let keys = Object.keys(printData);
      keys.forEach((key) => {
        if (typeof printData[key].value === "string") {
          printData[key].value = this.appData.commonCalls.restoreJsonString(
            printData[key].value
          );
        }
      });

      //### for step 1 and 2, I use printData.
      //### for step 3, I use stateData
      if (Object.keys(printData).length > 0) {
        tempState.data[item.StepIndex] = printData;
      } else {
        tempState.data[item.StepIndex] = stateData;
      }
    }
    this.setState(tempState, () => {
      if (this.appData.printConsole) console.log(this.state);
    });

    // let testContent = [];
    // testContent.push(
    //   <p style={{ margin: 0, padding: 0, lineHeight: "100%" }}>eda</p>
    // );
    // for (let i = 0; i < 1000; i++) {
    //   testContent.push(
    //     <p style={{ margin: 0, padding: 0, lineHeight: "100%" }}>hakan bulu</p>
    //   );
    // }
    // testContent.push(
    //   <p style={{ margin: 0, padding: 0, lineHeight: "100%" }}>ege</p>
    // );
    // let tempState = this.state;
    // tempState.content = testContent;
  }

  //###########################################################
  //###########################################################
  //###########################################################

  renderStepOneAndTwo = (stepIndex) => {
    let data = this.state.data[stepIndex];

    if (data === null) {
      return <></>;
    }
    let res = [];
    data.forEach((d) => {
      res.push(this.getItem(d.key, d.value));
    });

    return <div>{res}</div>;
  };

  //###########################################################
  //###########################################################
  //###########################################################

  getItem = (title, content, icon) => {
    if (icon === undefined) {
      icon = <></>;
    }

    let styleContainer = {
      position: "relative",
      padding: "0px 15px 0px 15px",
      background: "#F7F7F8",
      borderRadius: "5px",
      marginBottom: "15px",
      width: "calc(100% - 30px)",
    };

    let styleTitle = {
      fontSize: "0.80rem",
      background: "#E5E5E5",
      borderRadius: "5px",
      padding: "6px 10px 6px 10px",
      fontWeight: "bold",
      marginLeft: -15,
    };

    return (
      <div className="flex-column-start-left" style={styleContainer}>
        <div className="flex-row-start" style={styleTitle}>
          {icon}
          <div style={{ paddingTop: 1 }}>{title}</div>
        </div>
        <div className="divider-height-10 yellow1 fullWidth" />
        <div className="flex-row-start">{content}</div>
        <div className="divider-height-10 yellow1 fullWidth" />
      </div>
    );
  };

  //###########################################################
  //###########################################################
  //##########################################################

  renderStepThree = () => {
    let data = this.state.data[3];

    if (data === null) {
      return <></>;
    }

    return (
      <Grid
        container
        spacing={{ xs: 0, md: 0 }}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        style={{ background: "#F7F7F8", padding: 5, borderRadius: "5px" }}
      >
        {this.getGrid(data.imgFront.thumbnail)}
        {this.getGrid(data.imgSide.thumbnail)}
        {this.getGrid(data.imgTop.thumbnail)}
        {this.getGrid(data.imgBack.thumbnail)}
      </Grid>
    );
  };

  getGrid = (imgSrc) => {
    return (
      <Grid
        className="flex-row-center"
        item
        sm={12}
        md={6}
        lg={3}
        style={{ padding: "10px" }}
      >
        <img
          style={{ width: "100%", maxHeight: "240px", borderRadius: "5px" }}
          alt=""
          src={imgSrc}
        />
      </Grid>
    );
  };

  render() {
    return (
      <div
        style={{
          // background: "#F00",
          paddingLeft: 15,
          paddingRight: 15,
          width: "calc(100% - 30px)",
          maxWidth: 1000,
        }}
      >
        <div
          style={{
            width: "100%",
            textAlign: "center",
            fontSize: "1.25rem",
            marginBottom: "15px",
          }}
        >
          Hair Questions
        </div>
        {this.renderStepOneAndTwo(1)}
        <div
          style={{
            width: "100%",
            textAlign: "center",
            fontSize: "1.25rem",
            paddingTop: "15px",
            marginBottom: "15px",
          }}
        >
          General Questions
        </div>
        {this.renderStepOneAndTwo(2)}
        <div
          style={{
            width: "100%",
            textAlign: "center",
            fontSize: "1.25rem",
            paddingTop: "15px",
            marginBottom: "15px",
          }}
        >
          Photos
        </div>
        {this.renderStepThree()}
        <div
          className="flex-row-end"
          style={{
            background: "#F7F7F8",
            padding: "30px 15px 30px 15px",
            width: "calc(100% - 30px)",
            borderRadius: "5px",
            marginTop: "60px",
          }}
        >
          <Button
            onClick={() => {
              this.appData.container().close();
            }}
            variant="contained"
          >
            Close
          </Button>
        </div>
      </div>
    );
  }
}

//###########################################################
//###########################################################
//###########################################################

export default QuoteRequestForm;

//###########################################################
//###########################################################
//###########################################################
