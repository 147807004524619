import React from "react";
import { Link, useNavigate } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";

//###########################################################
//###########################################################
//###########################################################

class Message extends React.Component {
  //###########################################################
  //###########################################################
  //###########################################################

  constructor(props) {
    super(props);

    this.appData = this.props.appData;
    this.parent = this.props.parent;

    this.state = {
      btnHomePageLoading: false,
    };
  }

  //###########################################################
  //###########################################################
  //###########################################################

  componentDidMount() {}

  //###########################################################
  //###########################################################
  //###########################################################

  getHomePageButton = () => {
    return (
      <div
        style={{
          display: this.parent.authenticationDialog !== null ? "none" : "",
        }}
      >
        <div
          className="text-color flex-row-center"
          style={{
            borderTop: "1px solid #CCC",
            textAlign: "center",
            paddingTop: "30px",
            marginTop: "30px",
          }}
        >
          <LoadingButton
            size="small"
            onClick={() => {
              let tempState = this.state;
              tempState.btnHomePageLoading = true;
              this.setState(tempState, () => {
                setTimeout(function() {
                  window.location.href = "/";
                }, 250);
              });
            }}
            loading={this.state.btnHomePageLoading}
            loadingPosition="start"
            startIcon={<HomeIcon />}
            variant="contained"
          >
            Home Page
          </LoadingButton>
        </div>
      </div>
    );
  };

  //###########################################################
  //###########################################################
  //###########################################################

  resetPasswordEmailSent = (email) => {
    return (
      <>
        <div
          className="sign-in-container"
          style={{
            border: this.parent.authenticationDialog !== null ? "none" : "",
          }}
        >
          {/* logo container */}
          <div
            className="authentication-logo-div"
            style={{
              display: this.appData.commonCalls.boolToCssDisplay(
                this.options.logo
              ),
            }}
          >
            {this.options.logo}
          </div>
          <div
            style={{
              textAlign: "center",
              fontWeight: "bold",
              fontSize: "1.4rem",
              padding: "0px 20px 0px 20px",
              marginBottom: "30px",
            }}
            className="text-color"
          >
            Reset Password E-mail Sent
          </div>
          <div
            style={{
              fontSize: "1rem",
              lineHeight: "1.7",
              marginBottom: "20px",
            }}
            className="text-color"
          >
            We have sent an email to <b>{email}</b> so you can create a new
            password.
          </div>
          <div
            style={{
              fontSize: "1rem",
              lineHeight: "1.7",
              marginBottom: "30px",
            }}
            className="text-color"
          >
            Be sure the address submitted is the right one. The password link
            will be <b>only</b> sent the e-mail registered to your account.
          </div>
          <div
            style={{
              textAlign: "center",
              fontSize: "1.0rem",
            }}
            className="text-color flex-row-center"
          >
            Back to
            <span
              className="link-color"
              style={{
                marginLeft: "5px",
                padding: "0px",
                fontSize: "1.0rem",
              }}
              onClick={() => {
                this.props.parent.signInClicked();
              }}
            >
              Sign in
            </span>
          </div>
          {this.getHomePageButton()}
        </div>
      </>
    );
  };

  //###########################################################
  //###########################################################
  //###########################################################

  userExist = () => {
    return (
      <>
        <div className="sign-in-container">
        {/* message container */}
        <div
          className="authentication-message-div"
          style={{
            display: this.props.options.message !== undefined ? "" : "none",
          }}
        >
          {this.props.options.message}
        </div>
        {/* logo container */}
        <div
          className="authentication-logo-div"
          style={{
            display: this.props.options.logo !== undefined ? "" : "none",
          }}
        >
          {this.props.options.logo}
        </div>
          <div
            style={{
              textAlign: "center",
              fontWeight: "bold",
              fontSize: "1.4rem",
              padding: "0px 20px 0px 20px",
              marginBottom: "30px",
            }}
            className="text-color"
          >
            User is Exist
          </div>
          <div
            style={{
              fontSize: "1rem",
              lineHeight: "1.7",
            }}
            className="text-color"
          >
            <div
              style={{
                marginBottom: "30px",
              }}
            >
              The e-mail address you entered is associated with an existing
              account.
            </div>
            <Button
              variant="contained"
              style={{
                width: "100%",
                paddingTop: "15px",
                paddingBottom: "15px",
                fontSize: "1rem",
              }}
              onClick={() => {
                this.props.parent.signInClicked();
              }}
            >
              Try Signing in Instead
            </Button>
            <div
              className="link-color"
              style={{
                padding: "0px",
                marginTop: "5px",
                fontSize: "1.0rem",
                marginBottom: "20px",
              }}
              onClick={() => {
                this.props.parent.openForgotPassword();
              }}
            >
              Forgot password?
            </div>
            <div
              style={{
                textAlign: "center",
                fontSize: "1.0rem",
                marginBottom: "30px",
              }}
              className="text-color flex-row-center"
            >
              Back to
              <span
                className="link-color"
                style={{
                  marginLeft: "5px",
                  padding: "0px",
                  fontSize: "1.0rem",
                }}
                onClick={() => {
                  this.props.parent.signUpClicked();
                }}
              >
                Sign up
              </span>
            </div>
          </div>
          {this.getHomePageButton()}
        </div>
      </>
    );
  };

  //###########################################################
  //###########################################################
  //###########################################################

  render() {
    let params = this.props.params;

    if (params && params.type === "ResetPassword") {
      return <>{this.resetPasswordEmailSent(params.email)}</>;
    } else if (params && params.type === "UserExist") {
      return <>{this.userExist()}</>;
    } else {
      return <>No Params</>;
    }
  }
}

//###########################################################
//###########################################################
//###########################################################

export default function(props) {
  const navigate = useNavigate();
  return <Message {...props} navigate={navigate} />;
}

//###########################################################
//###########################################################
//###########################################################
