import React from "react";

import CommonCalls from "../../classes/CommonCalls";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import CancelIcon from "@mui/icons-material/Cancel";
import IconButton from "@mui/material/IconButton";
import { ThemeProvider, createTheme, styled } from "@mui/material/styles";

import { isMobile } from "react-device-detect";

//###########################################################
//###########################################################
//###########################################################

// const theme = createTheme({
//   typography: {
//     allVariants: {
//       color: "#F00"
//     }
//   }
// });

class MyDialog extends React.Component {
  //###########################################################
  //###########################################################
  //###########################################################

  constructor(props) {
    super(props);


    if (this.props.appData !== undefined) {
      this.appData = this.props.appData;
      this.appData.dialog = this;
    }

    this.commonCalls = new CommonCalls();

    //### default settings will be set as an alert dialog
    this.defaultOptions = {
      type: "alert", // "alert", "confirmation", "container"
      title: "Turkish Hair Clinics says",
      message: "Default Message",
      maxWidth: 400,

      //### these will be rendered
      header: "",
      content: "",

      hasHeader: true,
      hasCloseButton: false,
      hasActions: true,
      hasButton: true,
      hasLeftButton: false,
      hasRightButton: false,
      hasDividers: false,

      dividers: false,

      closeButtonPosition: { right: 8, top: 8 },

      allowBackdrop: false,

      fullScreenMobile: true,
      fullScreenDesktop: false,

      buttonsLayout: "right", // "left", "right", "center", "space-between",
      contentLayout: "middle-center", // "top-left", "top-center", "middle-left", "middle-center"

      button: <Button variant="contained">OK</Button>,
      leftButton: <Button variant="contained">Yes</Button>,
      rightButton: <Button>No</Button>,

      callBackButton: () => {},
      callBackLeftButton: () => {},
      callBackRightButton: () => {},

      background: "#FFF",

      closeButton: "round", // "cross", "round"
      closeButtonSize: "32px",

      zIndex: 1000, // ### react-lightgallery nin dialog uzerinde acilabilmesi icin z-index in bir tik asagi inmesi gerekiyordu

      _fullScreen: false,
      _contentPadding: "",
    };

    this.state = {
      open: false,
      options: this.defaultOptions,
    };
  }

  //###########################################################
  //###########################################################
  //###########################################################

  componentDidMount() {
  }

  //###########################################################
  //###########################################################
  //###########################################################

  setTheDefaultValuesByType = (type) => {
    switch (type) {
      case "alert":
        this.defaultOptions.hasHeader = true;
        this.defaultOptions.hasCloseButton = false;
        this.defaultOptions.hasActions = true;
        this.defaultOptions.hasButton = true;
        this.defaultOptions.hasLeftButton = false;
        this.defaultOptions.hasRightButton = false;
        this.defaultOptions.buttonsLayout = "right";
        this.defaultOptions.contentLayout = "middle-left";
        this.defaultOptions.fullScreenMobile = false;
        this.defaultOptions.fullScreenDesktop = false;
        this.defaultOptions.hasDividers = false;
        this.defaultOptions._contentPadding = "";
        this.defaultOptions.title = "Turkish Hair Clinics says";
        break;
      case "confirmation":
        this.defaultOptions.hasHeader = true;
        this.defaultOptions.hasCloseButton = false;
        this.defaultOptions.hasActions = true;
        this.defaultOptions.hasButton = false;
        this.defaultOptions.hasLeftButton = true;
        this.defaultOptions.hasRightButton = true;
        this.defaultOptions.buttonsLayout = "space-between";
        this.defaultOptions.contentLayout = "middle-left";
        this.defaultOptions.fullScreenMobile = false;
        this.defaultOptions.fullScreenDesktop = false;
        this.defaultOptions.hasDividers = true;
        this.defaultOptions._contentPadding = "";
        this.defaultOptions.title = "Turkish Hair Clinics asks";
        break;
      case "container":
        this.defaultOptions.hasHeader = false;
        this.defaultOptions.hasCloseButton = true;
        this.defaultOptions.hasActions = false;
        this.defaultOptions.buttonsLayout = "";
        this.defaultOptions.contentLayout = "middle-center";
        this.defaultOptions.fullScreenMobile = true;
        this.defaultOptions.fullScreenDesktop = true;
        this.defaultOptions.hasDividers = false;
        this.defaultOptions._contentPadding = "0px";
        this.defaultOptions.title = "";
        break;
      default:
        break;
    }
  };

  //###########################################################
  //###########################################################
  //###########################################################

  getCloseButton = () => {
    return (
      <IconButton
        onClick={this.close}
        style={{
          display: this.commonCalls.boolToCssDisplay(
            this.state.options.hasCloseButton
          ),
          position: "absolute",
          right: this.state.options.closeButtonPosition.right,
          top: this.state.options.closeButtonPosition.top,
          zIndex: 1000,
        }}
      >
        {this.state.options.closeButton === "cross" ? (
          <CloseIcon style={{ fontSize: this.state.options.closeButtonSize }} />
        ) : (
          <CancelIcon style={{ fontSize: this.state.options.closeButtonSize }} />
        )}
      </IconButton>
    );
  };

  //###########################################################
  //###########################################################
  //###########################################################

  open = (options) => {
    if (options !== undefined) {
      //##########################################
      if (options.type === undefined) {
        options.type = "alert";
      }

      //##########################################
      this.setTheDefaultValuesByType(options.type);

      //##########################################
      let keys = Object.keys(this.defaultOptions);
      for (let i = 0; i < keys.length; i++) {
        if (options[keys[i]] === undefined) {
          options[keys[i]] = this.defaultOptions[keys[i]];
        }
      }
    } else {
      //##########################################
      options = this.defaultOptions;
    }

    //##########################################
    if (isMobile) {
      options._fullScreen = options.fullScreenMobile;
    } else {
      options._fullScreen = options.fullScreenDesktop;
    }

    //##########################################
    if (options._fullScreen) {
      options.maxWidth = Number.MAX_SAFE_INTEGER;
    }

    //##########################################
    if (options.header === "") {
      options.header = <span>{options.title}</span>;
    }
    if (options.content === "") {
      options.content = <span>{options.message}</span>;
    }

    //##########################################
    if (options.title !== this.defaultOptions.title) {
      options.hasHeader = true;
    }

    //##########################################
    let tempState = this.state;
    tempState.open = true;
    tempState.options = options;
    this.setState(tempState);
  };

  close = () => {
    let tempState = this.state;
    tempState.open = false;
    this.setState(tempState);
  };

  //###########################################################
  //###########################################################
  //###########################################################

  scrollTop = () => {
    this.dialogContent.scrollTo(0, 0);
  };  
  
  //###########################################################
  //###########################################################
  //###########################################################

  isScrollable = (el) => {
    if (el === undefined || el === null) return null;

    /*The scrollTop() method sets or returns the
    vertical scrollbar position for the selected elements*/
    var y1 = el.scrollTop;
    el.scrollTop += 1;
    var y2 = el.scrollTop;
    el.scrollTop -= 1;
    var y3 = el.scrollTop;
    el.scrollTop = y1;

    /*The scrollLeft() method returns the horizontal
    scrollbar position for the selected elements.*/
    var x1 = el.scrollLeft;
    el.scrollLeft += 1;
    var x2 = el.scrollLeft;
    el.scrollLeft -= 1;
    var x3 = el.scrollLeft;
    el.scrollLeft = x1;

    //returns true or false accordingly
    return {
      horizontallyScrollable: x1 !== x2 || x2 !== x3,
      verticallyScrollable: y1 !== y2 || y2 !== y3,
    };
  };

  //###########################################################
  //###########################################################
  //###########################################################

  render() {
    return (
      // <ThemeProvider theme={theme}>
      <Dialog
        PaperProps={{
          sx: {
            width: this.state.options.maxWidth,
            background: this.state.options.background,
          },
        }}
        fullScreen={this.state.options._fullScreen}
        fullWidth={true}
        maxWidth={"sx"}
        open={this.state.open}
        style={{ zIndex: this.state.options.zIndex }}
        onBackdropClick={
          this.state.options.allowBackdrop ? this.close : () => {}
        }
        // onClose={this.close}
      >
        {/* ############### */}
        {/* ############### */}

        {/* close button */}
        {this.getCloseButton()}

        {/* ############### */}
        {/* ############### */}
        {/* header */}
        <DialogTitle
          style={{
            display: this.commonCalls.boolToCssDisplay(
              this.state.options.hasHeader
            ),
          }}
        >
          {/* ############### */}
          {/* header to render */}
          {this.state.options.header}
        </DialogTitle>

        {/* ############### */}
        {/* ############### */}
        {/* content */}
        <DialogContent
          ref={(el) => (this.dialogContent = el)}
          className={this.commonCalls.strToFlexClassName(
            this.state.options.contentLayout
          )}
          style={{
            padding: this.state.options._contentPadding,
            overflowX: "hidden",
          }}
          dividers={this.state.options.hasDividers}
        >
          {this.state.options.content}
        </DialogContent>

        {/* ############### */}
        {/* ############### */}
        {/* actions - footer */}
        <DialogActions
          style={{
            display: this.commonCalls.boolToCssDisplay(
              this.state.options.hasActions
            ),
            padding: "15px 15px 15px 15px",
          }}
          className={this.commonCalls.strToFlexClassName(
            this.state.options.buttonsLayout
          )}
        >
          {/* ############### */}
          {/* button */}
          <div
            style={{
              display: this.commonCalls.boolToCssDisplay(
                this.state.options.hasButton
              ),
            }}
            onClick={() => {
              this.state.options.callBackButton();
              this.close();
            }}
          >
            {this.state.options.button}
          </div>

          {/* ############### */}
          {/* left button */}
          <div
            style={{
              display: this.commonCalls.boolToCssDisplay(
                this.state.options.hasLeftButton
              ),
            }}
            onClick={() => {
              this.close();
              this.state.options.callBackLeftButton();
            }}
          >
            {this.state.options.leftButton}
          </div>

          {/* ############### */}
          {/* right button */}
          <div
            style={{
              display: this.commonCalls.boolToCssDisplay(
                this.state.options.hasRightButton
              ),
            }}
            onClick={() => {
              this.state.options.callBackRightButton();
              this.close();
            }}
          >
            {this.state.options.rightButton}
          </div>
        </DialogActions>
      </Dialog>
      // </ThemeProvider>
    );
  }
}

//###########################################################
//###########################################################
//###########################################################

export default MyDialog;

//###########################################################
//###########################################################
//###########################################################
