import React from "react";
import { Link, useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Backend from "../../classes/Backend";

//###########################################################
//###########################################################
//###########################################################

class Links extends React.Component {
  //###########################################################
  //###########################################################
  //###########################################################

  constructor(props) {
    super(props);

    this.backend = new Backend();
    //### the parent is the admin control
    this.parent = this.props.parent;
    this.testPage = this.parent.testPage;
    this.header = this.parent.testPage.header;

    //### for forward references
    if (this.parent !== undefined && this.parent.links !== undefined) {
      this.parent.links = this;
    }

    this.linkNames = [
      "facebook",
      "instagram",
      "youtube",
      "twitter",
      "linkedin",
      "before-after",
    ];

    this.loading = {};
    this.emptyFields = {};

    this.linkNames.forEach((linkName) => {
      this.loading[linkName] = {
        Name: "loading...",
        URL: "loading...",
        ValidationSearchTerm: "loading...",
        ValidationResult: "loading...",
        UpdateDate: "loading...",
      };
      this.emptyFields[linkName] = {
        Name: "",
        URL: "",
        ValidationSearchTerm: "",
        ValidationResult: "",
        UpdateDate: "",
      };
    });

    this.state = {
      formFields: this.emptyFields,
      currentRow: null,
    };
  }

  //###########################################################
  //###########################################################
  //###########################################################

  componentDidMount() {
    this.props.onLinksCurrentRowChanged.current = this.onCurrentRowChanged;
  }

  //###########################################################
  //###########################################################
  //###########################################################

  onCurrentRowChanged = (row) => {
    let tempState = this.state;
    tempState.currentRow = row;
    tempState.formFields = this.loading;
    this.setState(tempState, () => {
      this.backend.GetClinicURLsByUUID(row.UUID, (response) => {
        if (response !== null) {
          response.forEach((item) => {
            tempState.formFields[item.Name] = item;
          });
          this.setState(tempState, () => {
            console.log(this.state);
          });
        } else {
          let tempState = this.state;
          tempState.formFields = this.emptyFields;
          this.setState(tempState);
        }
      });
    });
  };

  //###########################################################
  //###########################################################
  //###########################################################

  getTextField = (options) => {
    let readonly = false;
    if (options.readonly !== undefined) {
      readonly = options.readonly;
    }

    let multiline = false;
    if (options.multiline !== undefined) {
      multiline = options.multiline;
    }

    let rows = 2;
    if (options.rows !== undefined) {
      rows = options.rows;
    }

    return (
      <div style={{ position: "relative", width: "100%" }}>
        <TextField
          fullWidth
          multiline={multiline ? true : false}
          rows={rows}
          label={options.label}
          value={this.parent.commonCalls.nullToString(
            this.state.formFields[options.name][options.formField]
          )}
          style={{
            background: readonly
              ? this.parent.colors.blue
              : this.parent.commonCalls.nullToString(
                  this.state.formFields[options.name][options.formField]
                ) === this.emptyFields[options.name][options.formField]
              ? this.parent.colors.red
              : this.parent.colors.green,
          }}
          onChange={(event) => {
            if (!readonly) {
              let tempState = this.state;
              tempState.formFields[options.name][options.formField] = event.target.value;
              this.setState(tempState);
            }
          }}
        />

        {/* button(s) container */}
        <div
          className="flex-row-end"
          style={{
            display: options.button_1 !== undefined ? "" : "none",
            position: "absolute",
            right: "-3px",
            bottom: "0px",
          }}
        >
        <div
          className="btn-primary btn-primary-small"
          style={{
            display: options.button_3 !== undefined ? "" : "none",
            minWidth: "50px",
          }}
          onClick={
            options.button_3 !== undefined
              ? options.button_3.onClick
              : () => {}
          }
        >
          {options.button_3 !== undefined ? options.button_3.label : ""}
        </div>

          <div
            className="btn-primary btn-primary-small"
            style={{
              display: options.button_2 !== undefined ? "" : "none",
              minWidth: "50px",
            }}
            onClick={
              options.button_2 !== undefined
                ? options.button_2.onClick
                : () => {}
            }
          >
            {options.button_2 !== undefined ? options.button_2.label : ""}
          </div>

          <div
            className="btn-primary btn-primary-small"
            style={{
              display: options.button_1 !== undefined ? "" : "none",
              minWidth: "50px",
            }}
            onClick={
              options.button_1 !== undefined
                ? options.button_1.onClick
                : () => {}
            }
          >
            {options.button_1 !== undefined ? options.button_1.label : ""}
          </div>
        </div>
      </div>
    );
  };

  //###########################################################
  //###########################################################
  //###########################################################

  section = (name) => {
    return (
      <div>
        {/* ############################ */}
        <div className="flex-row-start">
          <div className="flex-row-start" style={{ width: "50%" }}>
            {this.getTextField({
              label: "Name",
              name: name,
              formField: "Name",
              readonly: true,
            })}
          </div>
          <div className="divider-width-15" />
          <div className="flex-row-start" style={{ width: "50%" }}>
            {this.getTextField({
              label: "Update Date",
              name: name,
              formField: "UpdateDate",
              readonly: true,
            })}
          </div>
        </div>
        {/* ############################ */}
        <div className="divider-height-15" />
        {this.getTextField({
          label: "URL",
          name: name,
          formField: "URL",
          button_1: {
            label: "Go",
            onClick: () => {
              window.open(this.state.formFields[name]["URL"], "_blank");
            },
          },
        })}
        {/* ############################ */}
        <div className="divider-height-15" />
        <div className="flex-row-start">
          <div className="flex-row-start" style={{ width: "50%" }}>
            {this.getTextField({
              label: "Validation Search Term",
              name: name,
              formField: "ValidationSearchTerm",
            })}
          </div>
          <div className="divider-width-15" />
          <div className="flex-row-start" style={{ width: "50%" }}>
            {this.getTextField({
              label: "Validation Result",
              name: name,
              formField: "ValidationResult",
            })}
          </div>
        </div>
      </div>
    );
  };

  //###########################################################
  //###########################################################
  //###########################################################

  render() {
    return (
      <div>
        {/* ############################ */}
        {this.section("facebook")}
        <hr className="line-25" />
        {this.section("instagram")}
        <hr className="line-25" />
        {this.section("youtube")}
        <hr className="line-25" />
        {this.section("twitter")}
        <hr className="line-25" />
        {this.section("linkedin")}
        <hr className="line-25" />
        {this.section("before-after")}
        <hr className="line-25" />
        {/* ############################ */}
        <div className="flex-row-end">
          <div
            className="btn-primary"
            onClick={() => {
              let counter = 0;

              /* ############################ */
              let name = "facebook";
              this.backend.UpdateClinicURLViaAdminForm(
                this.state.currentRow.UUID,
                name,
                this.state.formFields[name]["URL"],
                this.state.formFields[name]["ValidationSearchTerm"],
                this.state.formFields[name]["ValidationResult"],

                () => {
                  counter++;
                  if (counter === this.linkNames.length) {
                    this.header.mySnackbar.alertSuccess(
                      this.state.currentRow.ClinicURL + " clinic saved."
                    );
                  }
                }
              );

              /* ############################ */
              name = "instagram";
              this.backend.UpdateClinicURLViaAdminForm(
                this.state.currentRow.UUID,
                name,
                this.state.formFields[name]["URL"],
                this.state.formFields[name]["ValidationSearchTerm"],
                this.state.formFields[name]["ValidationResult"],

                () => {
                  counter++;
                  if (counter === this.linkNames.length) {
                    this.header.mySnackbar.alertSuccess(
                      this.state.currentRow.ClinicURL + " clinic saved."
                    );
                  }
                }
              );

              /* ############################ */
              name = "youtube";
              this.backend.UpdateClinicURLViaAdminForm(
                this.state.currentRow.UUID,
                name,
                this.state.formFields[name]["URL"],
                this.state.formFields[name]["ValidationSearchTerm"],
                this.state.formFields[name]["ValidationResult"],

                () => {
                  counter++;
                  if (counter === this.linkNames.length) {
                    this.header.mySnackbar.alertSuccess(
                      this.state.currentRow.ClinicURL + " clinic saved."
                    );
                  }
                }
              );

              /* ############################ */
              name = "twitter";
              this.backend.UpdateClinicURLViaAdminForm(
                this.state.currentRow.UUID,
                name,
                this.state.formFields[name]["URL"],
                this.state.formFields[name]["ValidationSearchTerm"],
                this.state.formFields[name]["ValidationResult"],

                () => {
                  counter++;
                  if (counter === this.linkNames.length) {
                    this.header.mySnackbar.alertSuccess(
                      this.state.currentRow.ClinicURL + " clinic saved."
                    );
                  }
                }
              );

              /* ############################ */
              name = "linkedin";
              this.backend.UpdateClinicURLViaAdminForm(
                this.state.currentRow.UUID,
                name,
                this.state.formFields[name]["URL"],
                this.state.formFields[name]["ValidationSearchTerm"],
                this.state.formFields[name]["ValidationResult"],

                () => {
                  counter++;
                  if (counter === this.linkNames.length) {
                    this.header.mySnackbar.alertSuccess(
                      this.state.currentRow.ClinicURL + " clinic saved."
                    );
                  }
                }
              );

              /* ############################ */
              name = "before-after";
              this.backend.UpdateClinicURLViaAdminForm(
                this.state.currentRow.UUID,
                name,
                this.state.formFields[name]["URL"],
                this.state.formFields[name]["ValidationSearchTerm"],
                this.state.formFields[name]["ValidationResult"],

                () => {
                  counter++;
                  if (counter === this.linkNames.length) {
                    this.header.mySnackbar.alertSuccess(
                      this.state.currentRow.ClinicURL + " clinic saved."
                    );
                  }
                }
              );



            }}
          >
            Save - {this.state.currentRow !== null?this.state.currentRow.ClinicURL:""}
          </div>
        </div>
      </div>
    );
  }
}

//###########################################################
//###########################################################
//###########################################################

export default function(props) {
  const navigate = useNavigate();
  return <Links {...props} navigate={navigate} />;
}

//###########################################################
//###########################################################
//###########################################################
