import React from "react";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import OutlinedInput from "@mui/material/OutlinedInput";
import ListItemText from "@mui/material/ListItemText";
import FormGroup from "@mui/material/FormGroup";
import TextField from "@mui/material/TextField";
import Collapse from "@mui/material/Collapse";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import uuid from "react-uuid";

import Backend from "../../classes/Backend";
import Commons from "./Commons";

import CommonCalls from "../../classes/CommonCalls";

//###########################################################
//###########################################################
//###########################################################

class Step5 extends React.Component {
  //###########################################################
  //###########################################################
  //###########################################################

  constructor(props) {
    super(props);

    this.parent = this.props.parent;
    this.appData = this.props.appData;

    this.stepIndex = 5;

    // this.backend = new Backend();
    this.commons = new Commons();
    // this.commonCalls = new CommonCalls();

    this.state = {
      // display: "none",
      display: "",

      isUserAggree: false,
    };
  }

  //###########################################################
  //###########################################################
  //###########################################################

  componentDidMount() {}

  //###########################################################
  //###########################################################
  //###########################################################

  show = () => {
    let tempState = this.state;
    tempState.display = "";
    this.setState(tempState);
  };

  hide = () => {
    let tempState = this.state;
    tempState.display = "none";
    this.setState(tempState);
  };

  //###########################################################
  //###########################################################
  //###########################################################

  getItem = (key, value) => {
    return (
      <div
        key={this.appData.getUUID()}
        className="flex-row-start"
        style={{
          paddingTop: "15px",
          // background: "#FF0",
          width: "100%",
        }}
      >
        <div
          key={this.appData.getUUID()}
          style={{
            width: "50%",
            maxWidth: "400px",
            textAlign: "right",
            paddingRight: "15px",
            color: "#125799",
            fontWeight: 450,
          }}
        >
          {key}
        </div>
        <div key={this.appData.getUUID()}>{value}</div>
      </div>
    );
  };

  //###########################################################
  //###########################################################
  //###########################################################

  getGroup = (items, stepIndex) => {
    return (
      <div
        className="flex-column-start"
        style={{
          position: "relative",
          padding: "0px 10px 10px 10px",
          border: "1px solid #CCCCCC",
          borderRadius: "5px",
          marginBottom: "30px",
          // background: "#F00"
        }}
      >
        <div
          className="link"
          style={{
            position: "absolute",
            right: "5px",
            zIndex: "1",
            top: "5px",
          }}
          onClick={() => {
            this.props.parent.setStepIndex(stepIndex);
          }}
        >
          [Edit]
        </div>
        {items}
      </div>
    );
  };

  //###########################################################
  //###########################################################
  //###########################################################

  // getContent = () => {
  //   return this.props.parent.step1.getReviewControl();
  // };

  //###########################################################
  //###########################################################
  //###########################################################

  getReviewControl = () => {
    if (this.props.parent.step1 === undefined) {
      return <></>;
    }

    //###########################
    //###########################
    //###########################

    let valuesStep1 = this.props.parent.step1.getValues();
    let valuesStep2 = this.props.parent.step2.getValues();
    let valuesStep4 = this.props.parent.step4.getValues();

    //###########################
    //###########################
    //###########################

    let itemsStep1 = [];
    let itemsStep2 = [];
    let itemsStep4 = [];

    valuesStep1.map((value) => {
      if (!value.key.startsWith("_")) {
        itemsStep1.push(this.getItem(value.key, value.value));
      }
    });
    valuesStep2.map((value) => {
      if (!value.key.startsWith("_")) {
        itemsStep2.push(this.getItem(value.key, value.value));
      }
    });
    valuesStep4.map((value) => {
      if (!value.key.startsWith("_")) {
        itemsStep4.push(this.getItem(value.key, value.value));
      }
    });

    //###########################
    //###########################
    //###########################

    let formValues = this.props.parent.step1.getValues();
    formValues.push.apply(formValues, this.props.parent.step2.getValues());
    formValues.push.apply(formValues, this.props.parent.step4.getValues());

    let step3Values = this.props.parent.step3.getValues();
    let images = (
      <div className="flex-row-space-between">
        <img
          style={{ maxWidth: "23%", borderRadius: "5px" }}
          alt=""
          src={step3Values[0].value}
        />
        <img
          style={{ maxWidth: "23%", borderRadius: "5px" }}
          alt=""
          src={step3Values[1].value}
        />
        <img
          style={{ maxWidth: "23%", borderRadius: "5px" }}
          alt=""
          src={step3Values[2].value}
        />
        <img
          style={{ maxWidth: "23%", borderRadius: "5px" }}
          alt=""
          src={step3Values[3].value}
        />
      </div>
    );

    //###########################
    //###########################
    //###########################

    let items = [];

    formValues.map((value) => {
      items.push(
        <div
          className="flex-row-start"
          style={{
            paddingTop: "15px",
            // background: "#FF0"
          }}
        >
          <div
            style={{
              width: "50%",
              maxWidth: "400px",
              textAlign: "right",
              paddingRight: "15px",
              color: "#125799",
              fontWeight: 450,
            }}
          >
            {value.key}
          </div>
          <div>{value.value}</div>
        </div>
      );
    });

    //###########################
    //###########################
    //###########################

    return (
      <>
        {this.getGroup(itemsStep1, 1)}
        {this.getGroup(itemsStep2, 2)}
        {/* {this.getGroup(itemsStep4, 4)} */}
        {/* {items} */}
        {/* <div    className="divider-height-20"></div> */}

        <div
          className="flex-column-start"
          style={{
            position: "relative",
            padding: "30px 10px 15px 10px",
            border: "1px solid #CCCCCC",
            borderRadius: "5px",
            marginBottom: "30px",
            // background: "#F00"
          }}
        >
          <div
            className="link"
            style={{
              position: "absolute",
              right: "5px",
              zIndex: "1",
              top: "5px",
            }}
            onClick={() => {
              this.props.parent.setStepIndex(3);
            }}
          >
            [Edit]
          </div>
          {images}
        </div>
      </>
    );
  };

  //###########################################################
  //###########################################################
  //###########################################################

  validate = (alwaysReturnTrue) => {
    let validationResult = { result: true, message: "" };

    let bullets = [];

    //############################

    //### remove this part for production
    if (alwaysReturnTrue !== undefined && alwaysReturnTrue === true) {
      while (bullets.length > 0) {
        bullets.pop();
      }
    }

    //############################

    if (bullets.length > 0) {
      validationResult.result = false;
      validationResult.message = (
        <>
          <p>Please answer the following question(s):</p>
          <ul>{bullets}</ul>
        </>
      );
    }

    return validationResult;
  };

  //###########################################################
  //###########################################################
  //###########################################################

  submitThePatient = (callBack) => {
    let valuesStep1 = this.props.parent.step1.getValues();
    let valuesStep2 = this.props.parent.step2.getValues();
    let valuesStep3 = this.props.parent.step3.getValues();
    let valuesStep4 = this.props.parent.step4.getValues();

    //#################
    //#################
    //#################

    let gender = "";
    let yearOfBirth = "";
    let country = "";

    valuesStep1.map((item) => {
      if (item.key === "Gender") {
        gender = item.value;
      } else if (item.key === "Year of birth") {
        yearOfBirth = item.value;
      }
    });
    valuesStep4.map((item) => {
      if (item.key === "_country") {
        country = item.value.countryCode;
      }
    });

    //#################
    //#################
    //#################

    let patient = {};
    patient.ID = uuid();
    patient.Short = (yearOfBirth + "-" + gender + "-" + country).toLowerCase();
    patient.Name = "Hakan Bulu";
    patient.attributes = [];

    patient.attributes = patient.attributes.concat(valuesStep1);
    patient.attributes = patient.attributes.concat(valuesStep2);
    patient.attributes = patient.attributes.concat(valuesStep3);
    patient.attributes = patient.attributes.concat(valuesStep4);

    console.clear();
    console.log(patient);
    callBack();
  };

  //###########################################################
  //###########################################################
  //###########################################################

  render() {
    return (
      <>
        <div
          className="width-with-padding-20"
          style={{
            display: this.state.display,
            padding: "10px 20px 20px 20px",
          }}
        >
          {/* ############ */}
          {/* ############ */}
          {/* ############ */}
          {/* title */}
          <div
            className="flex-column-center"
            style={this.commons.styleQuestionContainerSelect}
          >
            <span
              className="title-3"
              style={{ marginTop: "10px", marginBottom: "10px" }}
            >
              Review and Submit
            </span>
            <p
              className="no-padding no-margin regular-text"
              style={{ textAlign: "center" }}
            >
              Please review your information before submitting.{" "}
              <span style={{ background: "#FF0" }}>
                You won't be able to change your information after the
                submission.
              </span>
            </p>
          </div>
          {/* ############ */}
          {/* ############ */}
          {/* ############ */}
          <div className="divider-height-20" />
          {/* ############ */}
          {/* ############ */}
          {/* ############ */}
          {this.getReviewControl()}
          {/* ############ */}
          {/* ############ */}
          {/* ############ */}
          {/* <hr className="line-20" /> */}
          {/* ############ */}
          {/* ############ */}
          {/* ############ */}
          <div
            className="flex-row-center"
            style={{
              padding: "10px 10px 10px 10px",
              border: "1px solid #CCCCCC",
              borderRadius: "5px",
              marginBottom: "20px",
              // background: "#F00"
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.isUserAggree}
                  onChange={(event) => {
                    let val = event.target.checked;
                    let tempState = this.state;
                    tempState.isUserAggree = Boolean(val);
                    this.setState(tempState, () => {
                      this.props.parent.showCurrentStep();
                    });
                  }}
                />
              }
              label={
                <span style={{ background: "#FF0" }}>
                  I agree with my information.
                </span>
              }
            />
          </div>
          {/* ############ */}
          {/* ############ */}
          {/* ############ */}
        </div>
      </>
    );
  }
}

//###########################################################
//###########################################################
//###########################################################

export default Step5;

//###########################################################
//###########################################################
//###########################################################
