import React from "react";
import { Link, useNavigate } from "react-router-dom";
import ListClinicCardsBootstrapStudio from "./ListClinicCardsBootstrapStudio";
import Header from "../header/Header";
import Footer from "../footer/Footer";

//###########################################################
//###########################################################
//###########################################################

class TestPage extends React.Component {
  //###########################################################
  //###########################################################
  //###########################################################

  constructor(props) {
    super(props);

    this.appData = this.props.appData;

    this.state = {};
  }

  //###########################################################
  //###########################################################
  //###########################################################

  componentDidMount() {
    let title = "What are the best clinics for hair transplants in Turkey?";
    let description = "You can easily find a hair transplant clinic in Turkey. Get more information about clinics, doctors, and prices by clicking on a clinic in the list.";
    
    document.title = title;
    document.getElementsByTagName("meta")[1].content = title;
    document.getElementsByTagName("meta")[2].content = description;  }

  //###########################################################
  //###########################################################
  //###########################################################

  render() {
    return (
      <>
        <Header parent={this} appData={this.appData} private={false} />
        <div
          className="flex-column-start"
          style={{ paddingTop: "20px", paddingBottom: "20px" }}
        >
          <ListClinicCardsBootstrapStudio
            parent={this}
            appData={this.appData}
          />
        </div>
        <Footer
          parent={this}
          appData={this.appData}
          alternateClassName="footer-blue"
        />
      </>
    );
  }
}

//###########################################################
//###########################################################
//###########################################################

export default function(props) {
  const navigate = useNavigate();
  return <TestPage {...props} navigate={navigate} />;
}

//###########################################################
//###########################################################
//###########################################################
